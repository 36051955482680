import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import auth from "./../Auth.js";
import PageTitle from './../components/pageTitle.js'

class AGEMob extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  render() {
    const { classes } = this.props;
    console.log('state', this.state)
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: '146px' }}>
        <PageTitle title="Assessing GCSE English" img="/assets/assess-eng-banner-mob.jpg" />
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ margin: '0 5% 0 5%' }}>
              <Typography
                gutterBottom
                style={{
                  margin: "20px 0 20px 0",
                  //fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  color: "#af1280"
                }}
              >
                Assessing GCSE English makes it easy for you to standardise your GCSE English assessment with other schools. Taking part in six assessment windows over the year, covering AQA Language and Literature papers, you will get to judge the essays of your pupils alongside the essays of pupils from other schools. Judging the work online, your marking will be automatically standardised not only within your own school but with other schools also taking part at the same time. Our Comparative Judgement engine ensures that the process is quick while the results you receive are reliable and nationally standardised against the GCSE grades and mark schemes.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item style={{ xwidth: "669px", margin: "20px 0 20px 0", padding: '0 5% 0 5%' }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  title={
                    <Typography
                      style={{
                        fontSize: "21px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      Save time and reduce the assessment burden…
                      <br />
                      Comparative Judgement in 4 easy steps!
                    </Typography>
                  }
                />
                <CardContent xstyle={{ position: 'relative', xheight: '325px' }}>
                    <Grid container justify="center">
                        <Grid item>
                        <img
                    src="/assets/step-0.1-icon.svg"
                    alt="Step 1"
                  />
                        </Grid>
                    </Grid>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        1
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;We set the task, so everyone is writing against the same prompt.
                      </span>
                  </CardContent>
                  <Divider />
                  <CardContent>
                  <Grid container justify="center">
                        <Grid item>
                  <img
                    src="/assets/step-1-icon.svg"
                    alt="Step 1"
                  />
                  </Grid>
                  </Grid>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        2
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Upload your pupils’ scripts on our specially barcoded paper
                      </span>
                  </CardContent>
                  <Divider />
                  <CardContent>
                  <Grid container justify="center">
                        <Grid item>
                  <img
                    src="/assets/step-2-icon.svg"
                    alt="Step 1"
                    xstyle={{ position: 'absolute', top: '60px', left: '354px' }}
                  />
                  </Grid>
                  </Grid>
                                
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        3
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Judge writing, comparing two pieces of writing on screen, side-by-side to determine which is best
                      </span>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container justify="center">
                        <Grid item>
                  <img
                    src="/assets/step-3-icon.svg"
                    alt="Step 1"
                    xstyle={{ position: 'absolute', top: '54px', left: '526px' }}
                  />
                  </Grid>
                  </Grid>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        4
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Benchmark attainment and progress across your school, and nationally, with the information and statistics in your <a
                          href="/reports/age-demo-feedback.pdf"
                          target="_blank"
                        >
                          school’s individual report
                        </a>
                      </span>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "676px", margin: "20px 0 20px 0", padding: '0 5% 0 5%' }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: '#af1280' }}
                  title={
                    <Typography
                      className={classes.topBannerHeading}
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        color: "#ffffff",
                        textAlign: "center"
                      }}
                    >
                      Here’s a summary of how it works:
                    </Typography>
                  }
                />
                <CardContent style={{ fontFamily: "Source Sans Pro" }}>
                  <ul>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      There are six assessment windows per year, 4 Language windows and 2 Literature windows.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You can enter year 10 or year 11 or both sets of pupils into each window.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      Each window involves a specific essay taken from a past paper.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      In each window you judge the work of your students as well as the work of students across the country.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You never directly compare the results of your students with students from other schools.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      Rather, every 10th judgement is a moderation judgement where you compare the work of students from other schools.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      Our judgement process ensures every script is externally standardised and all judging quality monitored in real time.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You can carry out your own internal judging sessions using our anchor scripts to monitor pupil progress.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You receive marks and grades for every student as well as reports aggregated by school, gender and pupil premium status.
                    </li>
                  </ul>
                </CardContent>
                </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "669px", margin: "20px 0 20px 0", padding: '0 5% 0 5%' }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  xclassName={classes.topBannerBackground}
                  title={
                    <Typography className={classes.topBannerHeading}>
                      What are the benefits of No More Marking: Assessing GCSE English?
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="space-around">
                    <Grid item style={{ width: "300px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Workload
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Teachers in our pilot study judged an entire year group's essays in just over half an hour to a much higher reliability than they can mark them.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Accuracy
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Research by Ofqual shows that marking at exam boards is done to an accuracy of +/- 5 marks on a 40 mark scale. In our pilot study, teachers judged with +/- 2 marks accuracy.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Validity
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          The marks derived from judging are consistent with the average marks derived from double or triple marking essays.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Progress measures
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          You can track the progress of pupils across the twelve assessment windows from the start of year 10 to the end of year 11.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Comparisons between years
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          You can directly compare the performance of your year 10 to your year 11 pupils.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    <Grid item style={{ width: "300px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Nationally-standardised results
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Results are based on the performance of thousands of
                          pupils and the judgements of hundreds of teachers from
                          across the country.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Robust data controls
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Every judge’s performance is recorded, and decisions
                          made by inaccurate judges are removed from the
                          results.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Exemplar scripts
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          After each assessment, we provide you with exemplar
                          scripts from pupils across the country, which can be
                          used in lessons or for CPD.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Built-in moderation
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          As you are judging, you are also silently and
                          anonymously moderating the judgements of other
                          teachers.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Assess every script
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          With traditional moderation, most scripts are never
                          seen by anyone other than the class teacher. With us,
                          every script is seen twenty times.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                //position: "relative",
                //width: "1024px",
                //height: "165px",
                backgroundImage: "url(/assets/quote-banner-extended.jpg)"
              }}
            >
              <Typography
                style={{
                  //position: "absolute",
                  //width: "669px",
                  padding: "20px 0 0 10px",
                  fontSize: "23px",
                  color: "#3f1f71",
                  textAlign: "center"
                }}
              >
                All of the teachers in the judging session were very positive on how the switch to judging had impacted on their working life. They felt better supported, less alone, more reflective of their teaching practice
              </Typography>
              <Typography
                style={{
                  //position: "absolute",
                  //width: "669px",
                  bottom: "20px",
                  fontSize: "15px",
                  color: "#af1280",
                  textAlign: "center"
                }}
              >
                Emma Hockey, Vice Principal, Farnham Heath End School
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                position: "relative",
                backgroundColor: "#efefef",
                width: '100%',
                //width: "1180px",
                //height: "169px"
              }}
            >
              <Typography
                style={{
                  //position: "absolute",
                  //fontFamily: "Source Sans Pro",
                  top: "22px",
                  fontSize: "21px",
                  color: "#af1280",
                  textAlign: "center",
                  //width: "1180px"
                }}
              >
Standardised and nationally benchmarked GCSE assessment for Years 10 and 11, just £595 + VAT per year
              </Typography>
              <Button
                size="small"
                variant="contained"
                style={{
                    margin: '10px 25% 10px 25%',
                  //position: "absolute",
                  //left: "517px",
                  //top: "90px",
                  //width: "145px",
                  height: "40px",
                  backgroundColor: "#3f1f71",
                  color: "#ffffff",
                  textTransform: "inherit",
                  //fontFamily: "Source Sans Pro"
                  width: '50%',
                }}
                onClick={() => {
                  auth.login("subscribe2?product=AGE");
                }}
              >
                Sign up
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AGEMob);
