import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogContent, Hidden
} from "@material-ui/core";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";
import auth from "./../Auth.js";
import HomeMob from "./homeMob";

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

class Home2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("boot", {
      app_id: "zodtosc9"
    });
  }
  state = {
    isOpen: false
  };
  render() {
    const { isOpen } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <HomeMob />
        <Hidden xsDown>
        <CookieConsent
          location="bottom"
          style={{ padding: "0 30px 0 0", justifyContent: "space-evenly" }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item className={classes.deskBlurred}>
                <Typography
                  className={classes.ComparativeJudgemen}
                  style={{
                    fontSize: "23px",
                    left: "132px",
                    top: "60px",
                    color: "#af1280"
                  }}
                >
                  Comparative Judgement
                </Typography>
                <Typography
                  className={classes.ComparativeJudgemen}
                  style={{
                    left: "132px",
                    top: "99px",
                    width: "366px",
                    height: "82px",
                    fontSize: "33px",
                    fontWeight: "normal",
                    textAlign: "left"
                  }}
                >
                  Simplifying assessment
                  <br />
                  for schools
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Grid container justify="center">
              <Grid
                container
                justify="center"
                alignItems="stretch"
                style={{ width: "1180px" }}
              >
                <Grid item style={{ width: "328px", margin: "0 10px 0 10px" }}>
                  <Card style={{ height: "596px", backgroundColor: "#efefef" }}>
                    <CardHeader
                      className={classes.topBannerBackground}
                      title={
                        <Typography className={classes.topBannerHeading}>
                          The Issue
                        </Typography>
                      }
                    />
                    <CardMedia
                      image="/assets/books-home.jpg"
                      style={{ width: "328px", height: "155px" }}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        style={{
                          fontFamily: "Source Sans Pro",
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#af1280",
                          lineHeight: 1.17
                        }}
                      >
                        Traditional marking and moderation are time-consuming
                        and inaccurate
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          fontFamily: "Source Sans Pro",
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#363636"
                        }}
                      >
                        Marking work accurately is difficult.&nbsp; Moderators
                        standardise the marks, but it’s hard to review more than
                        a tiny fraction of scripts in a typical moderation
                        session, and even agreeing on those is not easy.&nbsp;
                        And the whole process is very time-consuming.
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          fontFamily: "Source Sans Pro",
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#3f1f71"
                        }}
                      >
                        There is another way...
                      </Typography>
                    </CardContent>
                    {/*<CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "92px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      component={NavLink}
                      to={`/findOutMore`}
                    >
                      Find out more
                    </Button>
                    </CardActions>*/}
                  </Card>
                </Grid>
                <Grid
                  item
                  style={{ width: "328px", margin: "0 10px 0 10px" }}
                >
                  <Card style={{ height: "596px", backgroundColor: "#efefef" }}>
                    <CardHeader
                      className={classes.topBannerBackground}
                      title={
                        <Typography
                          className={classes.topBannerHeading}
                          style={{}}
                        >
                          What is Comparative Judgement?
                        </Typography>
                      }
                    />
                    <CardMedia
                      image="/assets/apples-home.jpg"
                      style={{ width: "328px", height: "155px" }}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        style={{
                          fontFamily: "Source Sans Pro",
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#af1280",
                          lineHeight: 1.17
                        }}
                      >
                        Comparative Judgement assesses open-ended work more
                        reliably than traditional marking
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          // fontFamily: "Source Sans Pro",
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#363636"
                        }}
                      >
                        Comparative judgement is a process where judges compare
                        two responses and decide which is better.&nbsp;
                        Following repeated comparisons, the resulting data is
                        statistically modelled and responses placed on a scale
                        of relative quality.
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          // fontFamily: "Source Sans Pro",
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#3f1f71"
                        }}
                      >
                        Research has shown the process to be as reliable as
                        double marking, but much quicker.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        variant="contained"
                        style={{
                          left: "92px",
                          width: "145px",
                          height: "40px",
                          backgroundColor: "#3f1f71",
                          color: "#ffffff",
                          textTransform: "inherit"
                          // fontFamily: "Source Sans Pro"
                        }}
                        component={ForwardNavLink}
                        to={`/demo2`}
                      >
                        Try the Demo
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid
                  item
                  style={{ width: "328px", margin: "0 10px 0 10px" }}
                >
                  <Card style={{ height: "596px", backgroundColor: "#efefef" }}>
                    <CardHeader
                      className={classes.topBannerBackground}
                      title={
                        <Typography
                          className={classes.topBannerHeading}
                          style={{}}
                        >
                          The Solution is Simple
                        </Typography>
                      }
                    />
                    <CardMedia
                      image="/assets/teachers-home.jpg"
                      style={{ width: "328px", height: "155px" }}
                    />

                    {/*<iframe title="Judging" src="https://player.vimeo.com/video/347302507?title=0&byline=0&portrait=0" width="328" height="172" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>*/}
                    <CardMedia
                      image="/assets/home-video-thumb.jpg"
                      style={{
                        width: "328px",
                        height: "155px",
                        marginTop: "10px"
                      }}
                      onClick={() => this.setState({ isOpen: true })}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        style={{
                          fontFamily: "Source Sans Pro",
                          fontSize: "19px",
                          fontWeight: "600",
                          color: "#3f1f71",
                          lineHeight: 1.17,
                          letterSpacing: "normal",
                          textAlign: "center"
                        }}
                      >
                        Join the thousands of schools who are already enjoying
                        the benefits of judging.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        variant="contained"
                        style={{
                          left: "92px",
                          width: "145px",
                          height: "40px",
                          backgroundColor: "#3f1f71",
                          color: "#ffffff",
                          textTransform: "inherit",
                          fontFamily: "Source Sans Pro"
                        }}
                        onClick={() => {
                          auth.login("subscribe2");
                        }}
                      >
                        Sign up
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Grid container justify="center">
              <Grid
                item
                style={{
                  position: "relative",
                  backgroundColor: "#efefef",
                  width: "1180px",
                  height: "169px"
                }}
              >
                <Typography
                  style={{
                    position: "absolute",
                    fontFamily: "Source Sans Pro",
                    top: "42px",
                    fontSize: "21px",
                    color: "#af1280",
                    textAlign: "center",
                    width: "1180px"
                  }}
                >
                  Using Comparative Judgement to provide a quicker, more
                  reliable method of assessment{" "}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    position: "absolute",
                    left: "517px",
                    top: "90px",
                    width: "145px",
                    height: "40px",
                    backgroundColor: "#3f1f71",
                    color: "#ffffff",
                    textTransform: "inherit",
                    fontFamily: "Source Sans Pro"
                  }}
                  onClick={() => {
                    auth.login("subscribe2");
                  }}
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          fullWidth
        >
          <DialogContent>
            <iframe
              title="Judging"
              src="https://player.vimeo.com/video/347302507?title=0&byline=0&portrait=0"
              width="560px"
              height="420px"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </DialogContent>
        </Dialog>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(Home2);
