import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Button,
  Typography,
  FormControl,
} from "@material-ui/core";
import request from "superagent";
import { animateScroll } from "react-scroll";

import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import * as Yup from "yup";

const _ = require("lodash");

class SettingsScriptGen extends Component {
  state = {
    selTemplate: "ps",
    pugTemplate: "",
    templatePage: "",
    pugCandDetails: "",
    pugPages: 1,
    templates: [],
    loaded: false,
    genMsg: <span />,
    disabled: false
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const promTask = AxiosAll.post(`/tasks/getTask`, {
      task: task,
      extras: ["withModTask"]
    });
    const promTemplates = AxiosAll.post("/candidates/getTemplates");
    Promise.all([promTask, promTemplates]).then(oRes => {
      let templates = _.map(oRes[1].data, oRow => {
        return {
          value: oRow._id,
          label: `${oRow.title} (${oRow.description})`,
          content: oRow.content
        };
      });
      templates.push({
        value: "pdf",
        label: "Upload PDF",
        content: ""
      });
      templates.unshift({
        value: "ps",
        label: "Please select...",
        content: ""
      });
      this.setState({
        templates: templates,
        pugTemplate: oRes[0].data.pugTemplate,
        pugPages: (oRes[0].data.pugPages || 1),
        selTemplate: oRes[0].data.pugTemplate,
        pugCandDetails: oRes[0].data.pugCandDetails,
        loaded: true
      });
    });
  }
  render() {
    const { match } = this.props;
    const task = match.params.task;
    const {
      templates,
      pugTemplate,
      pugPages,
      pugCandDetails,
      loaded,
      selTemplate,
      genMsg,
      disabled
    } = this.state;
    if (!loaded) return <span />;
    console.log("state", this.state);
    if (pugTemplate === "") {
      return (
        <Paper
          elevation={4}
          style={{
            margin: "0 15px 15px 0",
            padding: "15px 15px 15px 15px",
            width: "100%"
          }}
        >
          <Formik id="formik_option"
            enableReinitialize
            initialValues={{
              pugOption: "ps"
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("submitted", values);
              if (values.pugOption === "pdf") {
                this.setState({ pugTemplate: "pdf" });
              } else {
                AxiosAll.post(`/tasks/updateTask`, {
                  task: task,
                  pugTemplate: _.find(templates, { value: values.pugOption }).content,
                  pugPages: 1,
                }).then(oResult => {
                  this.setState({
                    templatePage: _.find(templates, { value: values.pugOption })
                      .content,
                    pugTemplate: _.find(templates, { value: values.pugOption })
                      .content
                  });
                })
              }

              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              pugOption: Yup.mixed().notOneOf(["ps"], "Must select an option")
            })}
          >
            {({ isSubmitting, values, errors, dirty, setFieldValue }) => (
              <Form id="frm_option">
                <Typography variant="caption">Option</Typography>
                <FormikSelectField
                  name="pugOption"
                  label=""
                  options={templates}
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  style={{
                    textTransform: "inherit",
                    marginBottom: "20px"
                  }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
      );
    }
    if (pugTemplate === "pdf") {
      return (
        <Paper
          elevation={4}
          style={{
            margin: "0 15px 15px 0",
            padding: "15px 15px 15px 15px",
            width: "100%"
          }}
        >
          <FormControl>
            {genMsg}
            <Typography variant="caption" gutterBottom>Upload PDF Template</Typography>
            <input
              name="file"
              id="idUploadTemplate"
              type="file"
              disabled={disabled}
              fullWidth
              onChange={e => {
                const { match } = this.props;
                const task = match.params.task;
                console.log("e", e.currentTarget.files[0]);
                this.setState({
                  disabled: true,
                  genMsg: (
                    <Typography gutterBottom color="secondary">
                      Processing...
                    </Typography>
                  )
                });
                request
                  .post(
                    `https://api.cloudinary.com/v1_1/no-more-marking-ltd/image/upload`
                  )
                  .field("upload_preset", "tqgw3b0n")
                  .field("file", e.currentTarget.files[0])
                  .end((error, oResponse) => {
                    console.log("oResp", oResponse);
                    const {
                      pages,
                      public_id,
                      format,
                    } = oResponse.body;
                    AxiosAll.post(`/scans/processScriptImages`, {
                      task: task,
                      public_id: public_id,
                      pages: pages,
                      format: format
                    }).then(oRes => {
                      console.log("processScriptImages", oRes);
                      if (_.has(oRes.data, "error")) {
                        this.setState({
                          genMsg: (
                            <Typography gutterBottom>
                              Error: {oRes.data.error}
                            </Typography>
                          )
                        });
                      } else {
                        this.setState({
                          genMsg: (
                            <Typography gutterBottom color="primary">
                              Template Uploaded
                            </Typography>
                          )
                        });
                      }
                    });
                  });
              }}
            />
          </FormControl>
        </Paper>
      );
    }
    return (
      <Paper elevation={4} style={{ padding: "15px" }}>
        <Formik id="formik_main"
          enableReinitialize
          initialValues={{
            pugTemplate: pugTemplate,
            pugCandDetails: pugCandDetails,
            pugPages: pugPages
          }}
          onSubmit={(values, { setSubmitting }) => {
            values.task = task;
            const firstPage = values.pugTemplate.split('\nnewpage\n')[0]
            const fullTemplate = _.map(
              _.range(1, parseInt(values.pugPages, 10) + 1),
              page => {
                return firstPage;
              }
            ).join("\nnewpage\n");
            values.pugTemplate = fullTemplate
            AxiosAll.post(`/tasks/updateTask`, values).then(oResult => {
              console.log('A', firstPage, values.pugPages, fullTemplate, _.range(1, parseInt(values.pugPages, 10) + 1))
              this.setState({
                pugTemplate: fullTemplate,
                pugCandDetails: values.pugCandDetails,
                pugPages: values.pugPages,
                genMsg: (
                  <Typography color="secondary" gutterBottom>
                    Settings updated
                  </Typography>
                )
              });
              animateScroll.scrollToTop();
              setSubmitting(false);
            });
          }}
          onReset={(values, { setSubmitting, resetForm }) => {
            console.log("reset", values);
            this.setState({ pugTemplate: "" });
            resetForm({
              pugTemplate: ""
            });
          }}
          validationSchema={Yup.object().shape({
            pugTemplate: Yup.string().required("Required"),
            pugPages: Yup.number()
              .min(1, "1-5 pages")
              .max(5, "1-5 pages")
              .required("Required")
          })}
        >
          {({ isSubmitting, values, errors, dirty, setFieldValue }) => (
            <Form id="frm_main">
              {genMsg}
              <Typography variant="caption">Template Layout</Typography>
              <FormikTextField
                name="pugTemplate"
                label=""
                fullWidth
                style={{ marginBottom: "20px" }}
                multiline
                rows="5"
                rowsMax="10"
                helperText=""
              />
              <Typography gutterBottom>
                Example commands:
                <br />
                &nbsp;&nbsp;&nbsp;text,&lt;x&gt;,&lt;y&gt;,&lt;text&gt;,&lt;font&gt;
                <br />
                &nbsp;&nbsp;&nbsp;line,&lt;x1&gt;,&lt;y1&gt;,&lt;x2&gt;,&lt;y2&gt;,&lt;color&gt;
                <br />
                &nbsp;&nbsp;&nbsp;img,&lt;image data url&gt;,&lt; x &gt;,&lt; y
                &gt;,&lt; width &gt;,&lt; height &gt;
                <br />
                &nbsp;&nbsp;&nbsp;newpage
                <br />
                xMax: 250, yMax: 297
                <br />
              </Typography>
              <Typography variant="caption">Candidate Details</Typography>
              <FormikSelectField
                name="pugCandDetails"
                label=""
                options={[
                  {
                    value:
                      "First Name / Last Name Initial / Date of Birth (DD/MM) / Class Name",
                    label:
                      "First Name / Last Name Initial / Date of Birth (DD/MM) / Class Name"
                  },
                  {
                    value:
                      "First Name / Last Name / Date of Birth (DD/MM/YYYY) / Class Name",
                    label:
                      "First Name / Last Name / Date of Birth (DD/MM/YYYY) / Class Name"
                  },
                  {
                    value: "First Name / Last Name / Class Name",
                    label: "First Name / Last Name / Class Name"
                  },
                  {
                    value: "First Name / Last Name Initial / Class Name",
                    label: "First Name / Last Name Initial / Class Name"
                  }
                ]}
                fullWidth
                style={{ marginBottom: "20px" }}
              />
              <Typography variant="caption">Number of Pages</Typography>
              <FormikTextField
                name="pugPages"
                label=""
                fullWidth
                style={{ marginBottom: "20px" }}
                disabled={selTemplate === "pdf"}
              />

              <Typography>&nbsp;</Typography>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                style={{
                  textTransform: "inherit",
                  marginBottom: "20px"
                }}
              >
                Update
              </Button>
              <Button
                type="reset"
                size="small"
                variant="contained"
                disabled={isSubmitting}
                style={{
                  textTransform: "inherit",
                  marginLeft: "50px",
                  marginBottom: "20px"
                }}
              >
                Reset
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  }
}

export default withRouter(SettingsScriptGen);
