import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Button,
  Fab,
  Typography,

} from "@material-ui/core";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {  Delete } from "@material-ui/icons";
import { animateScroll } from "react-scroll";
// import "typeface-roboto";
import PageTitle from "./../components/pageTitle.js";
import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import * as Yup from "yup";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import FixedJudgements from "./fixedJudgements.js";
import TaskMenu2 from './../layout/taskMenu2.js'
import SettingsScriptGen from './settingsScriptGen'

const CheckboxTable = checkboxHOC(ReactTable);

const _ = require('lodash')

class Settings2 extends Component {
  state = {
    tab: 0,
    loaded: false,
    locked: true,
    useCodes: false,
    purchases: false,
    disabled: false,
    templates: [],
    owners: [],
    selection: [],
    selectAll: false,
    msg: <span />,
    _judgements: 0,
    genMsg: <span />,
    allowMod: false,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const promTask = AxiosAll.post(`/tasks/getTask`, {
      task: task,
      extras: ["withModTask"]
    });
    // const promPurchases = AxiosAll.post("/purchases/getPurchases");
    const promTemplates = AxiosAll.post("/candidates/getTemplates");
    const promOwners = AxiosAll.post(`/tasks/getOwners`, { task: task });
    Promise.all([promTask, promTemplates, promOwners]).then(
      oResults => {
        const oTask = oResults[0].data;
        const oSchools = oResults[1].data;
        this.setState({
          loaded: true,
          locked: oTask.locked,
          useCodes: oTask.useCodes,
          templates: oResults[1].data,
          owners: oResults[2].data,
          /*purchases:
            oSchools.length === 0 ? false : oSchools[0].purchases.length > 0,*/
          schoolName: oSchools.length === 0 ? '' : oSchools[0].schoolName,
          name: oTask.name,
          judgeQuestion: oTask.judgeQuestion,
          judgeInfo: oTask.judgeInfo,
          scaledScoreMin: oTask.scaledScoreMin,
          scaledScoreRange: oTask.scaledScoreRange,
          useAnchors: oTask.useAnchors,
          // defaultJudgements: oTask.defaultJudgements,
          pugTemplate: oTask.pugTemplate,
          pugCandDetails: oTask.pugCandDetails,
          modTaskName: oTask.modTaskName,
          isModTask: oTask.isModTask,
          modCode: oTask.modCode,
          modSample: oTask.modSample,
          modFreq: oTask.modFreq,
          _judgements: oTask._judgements,
          allowMod: oTask.allowMod,
        });
      }
    );
  }
  handleChange = (event, tab) => {
    this.setState({ tab, genMsg: <span /> });
  };
  handleTemplateSelect = (e, template, content) => {
    this.setState({
      pugTemplate: content
    });
  };
  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  render() {
    const self = this;
    const { toggleSelection, toggleAll, isSelected } = this;
    const {
      tab,
      locked,
      loaded,
      schoolName,
      disabled,
      // templates,
      owners,
      selection,
      selectAll,
      msg,
      genMsg,
      modTaskName } = this.state;
    const {
      name,
      judgeQuestion,
      judgeInfo,
      useCodes,
      scaledScoreMin,
      scaledScoreRange,
      useAnchors,
      // defaultJudgements,
      _judgements
    } = this.state;
    // const { pugTemplate, pugCandDetails } = this.state;
    const { isModTask, modCode, modSample, modFreq } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    // console.log("state", this.state);
    const { match } = this.props;
    const task = match.params.task;
    const columns = [
      {
        Header: "Email",
        accessor: "email",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.email}
          </div>
        )
      }
    ];
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };

    // console.log('props', this.props)
    return (
      <Grid container style={{ margin: '0 15px 15px 15px', paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2 taskName={name} locked={locked} isModTask={isModTask} schoolName={schoolName} />
        </Grid>
        <Grid item xs={8}>
        <Grid item xs={12}>
          <PageTitle content="Settings" />
          <Paper elevation={4}>
            <Tabs value={tab} onChange={this.handleChange}>
              <Tab label="General" />
              <Tab
                label="Script Generation"
                disabled={locked || !useCodes || modCode !== ""}
              />
              <Tab label="Moderation" disabled={locked} />
              {/*<Tab label="Moderation" disabled={locked || !allowMod} />*/}
              <Tab label="Owners" />
              <Tab label="Fixed Judgements" xdisabled={locked || true} />
              <Tab label="Progress Task" disabled />
            </Tabs>
            <Paper elevation={4} style={{ padding: "20px" }}>
              {tab === 0 && (
                <Grid container>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{
                        name,
                        judgeQuestion,
                        judgeInfo,
                        useCodes,
                        scaledScoreMin,
                        scaledScoreRange,
                        useAnchors,
                        modCode,
                        // defaultJudgements
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        values.task = task;
                        // console.log("submitted", values);
                        AxiosAll.post(`/tasks/updateTask`, values).then(
                          oResult => {
                              AxiosAll.post(`/tasks/getTask`, {
                                task: task,
                                extras: ["withModTask"]
                              })
                              .then(oTask => {
                                setSubmitting(false)
                                self.setState({
                                  name: values.name,
                                  modTaskName: oTask.data.modTaskName,
                                  judgeQuestion: values.judgeQuestion,
                                  judgeInfo: values.judgeInfo,
                                  useCodes: values.useCodes,
                                  scaledScoreMin: values.scaledScoreMin,
                                  scaledScoreRange: values.scaledScoreRange,
                                  useAnchors: values.useAnchors,
                                  modCode: values.modCode,
                                  genMsg: (
                                    <Typography color="secondary" gutterBottom>
                                      Settings updated
                                    </Typography>
                                  )
                                });
                              });
                          }
                        );
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required("Required"),
                        judgeQuestion: Yup.string().required("Required"),
                        judgeInfo: Yup.string(),
                        scaledScoreMin: Yup.number()
                          .min(0, "Min value 0")
                          .max(1000, "Max value 1000")
                          .required(),
                        scaledScoreRange: Yup.number()
                          .min(1, "Min value 1")
                          .max(1000, "Max value 1000")
                          .required(),
                          modCode: Yup.string()
                          .min(36)
                          .max(36),
                      })}
                    >
                      {({ isSubmitting, values, errors, dirty }) => (
                        <Form>
                          {genMsg}
                          <Grid container>
                            <FormikTextField
                              name="name"
                              label="Task Name"
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikTextField
                              name="judgeQuestion"
                              label="Judge Question"
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikTextField
                              name="judgeInfo"
                              label="Judge Info"
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="useCodes"
                              label="Use Codes"
                              options={[
                                { label: "Yes", value: true },
                                { label: "No", value: false }
                              ]}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                              helperText={<span>Pre-generated assessment sheets (Yes): if you are using our coded sheets, you can scan in class batches to a single pdf. There is a maximum of 20MBs maximum file size & 300 maximum pages for each pdf. The system will read the code on the pages and assign them to the correct pupil.<br />General pdfs (No): if you aren't using our coded sheets you need to scan every piece of work to a separate pdf and upload each separately. The system will use the file name of the pdf as the id for the piece of work.</span>}
                            />
                            <FormikTextField
                              type="number"
                              name="scaledScoreMin"
                              label="Scaled Score Min"
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikTextField
                              type="number"
                              name="scaledScoreRange"
                              label="Scaled Score Range"
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="useAnchors"
                              label="Use Anchors"
                              options={[
                                { label: "Yes", value: true },
                                { label: "No", value: false }
                              ]}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikTextField
                                name="modCode"
                                label="Moderation Code"
                                helperText={modTaskName || ''}
                                fullWidth
                                style={{ marginBottom: "20px" }}
                                disabled={isModTask}
                            />
                            <Button
                              type="submit"
                              size="small"
                              color="primary"
                              variant="contained"
                              disabled={isSubmitting || locked}
                              style={{
                                textTransform: "inherit",
                                marginTop: "20px"
                              }}
                            >
                              Update
                            </Button>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      elevation={4}
                      style={{ padding: "20px", marginTop: "20px" }}
                    >
                      <Typography variant="title" gutterBottom>
                        Delete Task?
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        You cannot undo this option.
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={disabled || locked}
                        style={{ textTransform: "inherit" }}
                        onClick={e => {
                          const r = window.confirm(
                            `Delete Task "${name}"?\nThis cannot be undone, are you sure?`
                          );
                          if (!r) return;
                          self.setState({ disabled: true });
                          AxiosAll.post(`/tasks/removeTask`, {
                            task: task
                          }).then(oResult => {
                            self.props.history.push("/tasks");
                          });
                        }}
                      >
                        Delete Task
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              )}

              {tab === 1 && (
                <Grid container>
                  <SettingsScriptGen />
                  {/*pugTemplate === "" && (
                    <Grid item xs={12}>
                      <div>
                        <Typography gutterBottom>
                          Select a starting template
                        </Typography>
                        <Paper elevation={4}>
                          <List style={{ paddingBottom: "0" }}>
                            {templates.map(oTemplate => {
                              return (
                                <ListItem
                                  key={oTemplate._id}
                                  button
                                  divider
                                  onClick={e => {
                                    this.handleTemplateSelect(
                                      e,
                                      oTemplate._id,
                                      oTemplate.content
                                    );
                                    // console.log("handle click", e, e.target.id);
                                  }}
                                >
                                  <Avatar>
                                    <Code />
                                  </Avatar>
                                  <ListItemText
                                    primary={oTemplate.title}
                                    secondary={
                                      oTemplate.description || "description"
                                    }
                                    secondaryx={
                                      <ul>
                                        {oTemplate.content
                                          .split("\n")
                                          .map((line, i) => {
                                            return <li key={i}>{line}</li>;
                                          })}
                                      </ul>
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Paper>
                      </div>
                    </Grid>
                  )*/}
                  {/*pugTemplate !== "" && (
                    <Grid item xs={12}>
                      <Formik
                        initialValues={{ pugTemplate, pugCandDetails }}
                        onSubmit={(values, { setSubmitting }) => {
                          // console.log("submitted", values);
                          values.task = task;
                          AxiosAll.post(`/tasks/updateTask`, values).then(
                            oResult => {
                              self.setState({
                                pugTemplate: values.pugTemplate,
                                pugCandDetails: values.pugCandDetails,
                                genMsg: (
                                  <Typography color="secondary" gutterBottom>
                                    Settings updated
                                  </Typography>
                                )
                              });
                              animateScroll.scrollToTop();
                              setSubmitting(false);
                            }
                          );
                        }}
                        validationSchema={Yup.object().shape({
                          pugTemplate: Yup.string()
                            .required("Required")
                            .test(
                              "validTemplate",
                              "Invalid Template",
                              value => {
                                // console.log("<validTemplate", value);
                                let valid = true;
                                _.each(value.split("\n"), function(line) {
                                  if (valid && line.trim() !== "") {
                                    valid = /newpage|text,[0-9]*,[0-9]*,[A-Za-z0-9 &#;.+/]+,[0-30].,[A-Za-z0-9 ]+|line,[0-9]*,[0-9]*,[0-9]*,[0-9]*,#[0-9a-fA-F]{6}$/i.test(
                                      line
                                    );
                                  }
                                });
                                return valid;
                              }
                            ),
                          pugCandDetails: Yup.string().required("Required")
                        })}
                      >
                        {({ isSubmitting, values, errors, dirty }) => (
                          <Form>
                            {genMsg}
                            <Grid container>
                              <FormikTextField
                                name="pugTemplate"
                                label="Template Layout"
                                fullWidth
                                style={{ xmarginBottom: "20px" }}
                                multiline
                                rows="5"
                                rowsMax="10"
                                helperText=""
                              />
                              <Typography gutterBottom>
                                Example commands:
                                <br />
                                &nbsp;&nbsp;&nbsp;text,&lt;x&gt;,&lt;y&gt;,&lt;text&gt;,&lt;font&gt;
                                <br />
                                &nbsp;&nbsp;&nbsp;line,&lt;x1&gt;,&lt;y1&gt;,&lt;x2&gt;,&lt;y2&gt;,&lt;color&gt;
                                <br />
                                &nbsp;&nbsp;&nbsp;newpage
                                <br />
                                <br />
                                xMax: 250, yMax: 297
                                <br />
                              </Typography>
                              <FormikSelectField
                                name="pugCandDetails"
                                label="Candidate Details"
                                options={[
                                  {
                                    value:
                                      "First Name / Last Name Initial / Date of Birth (DD/MM) / Class Name",
                                    label:
                                      "First Name / Last Name Initial / Date of Birth (DD/MM) / Class Name"
                                  },
                                  {
                                    value:
                                      "First Name / Last Name / Date of Birth (DD/MM/YYYY) / Class Name",
                                    label:
                                      "First Name / Last Name / Date of Birth (DD/MM/YYYY) / Class Name</option"
                                  },
                                  {
                                    value:
                                      "First Name / Last Name / Class Name",
                                    label: "First Name / Last Name / Class Name"
                                  },
                                  {
                                    value:
                                      "First Name / Last Name Initial / Class Name",
                                    label:
                                      "First Name / Last Name Initial / Class Name"
                                  }
                                ]}
                                fullWidth
                                style={{ marginBottom: "20px" }}
                              />
                              <Button
                                type="submit"
                                size="small"
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting}
                                style={{
                                  textTransform: "inherit",
                                  marginTop: "20px"
                                }}
                              >
                                Update
                              </Button>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                )*/}
                </Grid>
              )}

              {tab === 2 && (
                <Grid container>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{ isModTask, modSample, modFreq }}
                      onSubmit={(values, { setSubmitting }) => {
                        // console.log("submitted", values);
                        values.task = task;
                        AxiosAll.post(`/tasks/updateTask`, values).then(
                          oResult => {
                            AxiosAll.post(`/tasks/getTask`, {
                              task: task,
                              extras: ["withModTask"]
                            }).then(oTask => {
                              self.setState({
                                isModTask: values.isModTask,
                                modTaskName: oTask.data.modTaskName,
                                modSample: values.modSample,
                                modFreq: values.modFreq,
                                genMsg: (
                                  <Typography color="secondary" gutterBottom>
                                    Settings updated
                                  </Typography>
                                )
                              });
                              animateScroll.scrollToTop();
                              setSubmitting(false);
                            });
                          }
                        );
                      }}
                      validationSchema={Yup.object().shape({
                        /*modCode: Yup.string()
                          .min(36)
                          .max(36),*/
                        modSample: Yup.number()
                          .min(0, "Min value 0")
                          .max(100, "Max value 100")
                          .required(),
                        modFreq: Yup.number()
                        .min(0, "Min value 0")
                        .max(20, "Max value 20")
                        .required(),
                      })}
                    >
                      {({ isSubmitting, values, errors, dirty }) => (
                        <Form>
                          {genMsg}
                          <Grid container>
                            <Grid item xs={9}>
                              <FormikSelectField
                                name="isModTask"
                                label="Is Moderation Task"
                                options={[
                                  { label: "Yes", value: true },
                                  { label: "No", value: false }
                                ]}
                                fullWidth
                                xstyle={{ marginBottom: "20px" }}
                                disabled={_judgements > 0}
                              />
                              <Typography gutterBottom style={{ marginBottom: "20px" }} variant="caption">Is this a moderation task drawing samples from other local tasks?&nbsp; (If uncertain please leave as 'No').</Typography>
                              {/*<FormikTextField
                                name="modCode"
                                label="Moderation Code"
                                fullWidth
                                style={{ marginBottom: "20px" }}
                              />*/}
                              <FormikTextField
                                type="number"
                                name="modSample"
                                label="Moderation Sample %"
                                fullWidth
                                style={{ marginBottom: "20px" }}
                                disabled={!isModTask && modCode !== ""}
                              />
                              <FormikTextField
                                type="number"
                                name="modFreq"
                                label="Moderation Frequency"
                                fullWidth
                                style={{ marginBottom: "20px" }}
                                disabled={!isModTask && modCode !== ""}
                              />
                              <Button
                                type="submit"
                                size="small"
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting || locked}
                                style={{
                                  textTransform: "inherit",
                                  marginTop: "20px"
                                }}
                              >
                                Update
                              </Button>

                              <Grid item xs={12}>
                                <Paper elevation={4} style={{ padding: "20px", marginTop: "20px" }}>
                                  <Typography variant="body2" gutterBottom>Set 'Use Anchors' for all local tasks, set all to:</Typography>
                                  <Button variant="raised" size="small" color="primary" disabled={!isModTask || disabled} style={{ textTransform: 'inherit', marginRight: '20px' }} onClick={e => {
                                    self.setState({ disabled: true })
                                    AxiosAll.post(`/tasks/updateAllLocalAnchors`, {
                                      task: task,
                                      set: true,
                                    })
                                    .then(oRes => {
                                       const arrProms = _.map(oRes.data, async (task) => {
                                        return AxiosAll.post(`/tasks/refreshTask`, { task: task })
                                      })
                                      Promise.all(arrProms)
                                      .then(oResult => {
                                        self.setState({
                                          disabled: false,
                                          genMsg: <Typography color="secondary" gutterBottom>Use Anchors set to yes for all local tasks</Typography>
                                        })
                                      })
                                    })
                                  }}>Yes</Button>
                                  <Button variant="raised" size="small" color="primary" disabled={!isModTask || disabled} style={{ textTransform: 'inherit' }} onClick={e => {
                                    self.setState({ disabled: true })
                                    AxiosAll.post(`/tasks/updateAllLocalAnchors`, {
                                      task: task,
                                      set: false,
                                    })
                                    .then(oRes => {
                                      const arrProms = _.map(oRes.data, async (task) => {
                                        return AxiosAll.post(`/tasks/refreshTask`, { task: task })
                                      })
                                      Promise.all(arrProms)
                                      .then(oResult => {
                                        self.setState({
                                          disabled: false,
                                          genMsg: <Typography color="secondary" gutterBottom>Use Anchors set to no for all local tasks</Typography>
                                        })
                                      })
                                    })
                                  }}>No</Button>
                                </Paper>
                              </Grid>

                            </Grid>
                            <Grid item xs={3} style={{ paddingLeft: "20px" }}>
                              <Card>
                                <CardHeader
                                  title="Task Details"
                                  subheader={task}
                                />
                                <CardContent>
                                  <Typography variant="body2">
                                    Enter the task identifier above into the
                                    Moderation code field of each task.
                                  </Typography>
                                </CardContent>
                                {/*<Divider />
                                <CardContent>
                                  <Typography variant="body1">
                                    Moderation Task Name:
                                  </Typography>
                                  <Typography variant="body2">
                                    {modTaskName || "-"}
                                  </Typography>
                                </CardContent>*/}
                              </Card>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>



                </Grid>
              )}

              {tab === 3 && (
                <Grid container>
                  <Grid item xs={6}>
                    <Formik
                      initialValues={{ email: "" }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        AxiosAll.post(`/tasks/addOwner`, {
                          task: task,
                          email: values.email
                        }).then(oResult => {
                          // console.log("addOwners", oResult.data);
                          if (oResult.data.body !== "") {
                            setSubmitting(false);
                            this.setState({
                              msg: (
                                <Typography color="secondary" gutterBottom>
                                  {oResult.data.body}
                                </Typography>
                              )
                            });
                          } else {
                            AxiosAll.post(`/tasks/getOwners`, {
                              task: task
                            }).then(oResult => {
                              // console.log("oResult", oResult.data);
                              this.setState({
                                owners: oResult.data,
                                msg: <span />
                              });
                            });
                            AxiosAll.post(`/tasks/getTask`, {
                              task: task
                            }).then(oTask => {
                              setSubmitting(false);
                              resetForm({ email: "" });
                              AxiosAll.post(`/utils/sendEmail`, {
                                to: values.email,
                                subject: "No More Marking - Added as Owner",
                                text: [
                                  `Dear Sir/Madam\n`,
                                  `You have been added as an owner to the task: "${name}".\n\n`,
                                  `Best Wishes,`
                                ].join("\n"),
                                html: ""
                              });
                            });
                          }
                        });
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Invalid Email")
                          .required()
                      })}
                    >
                      {({ isSubmitting, values, errors, dirty }) => (
                        <Form>
                          <Grid container>
                            {msg}
                            <FormikTextField
                              name="email"
                              label="Email Address"
                              fullWidth
                            />
                            <Button
                              type="submit"
                              size="small"
                              color="primary"
                              variant="contained"
                              disabled={isSubmitting}
                              style={{
                                textTransform: "inherit",
                                marginTop: "20px"
                              }}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                  <Grid item xs={6}>
                    <Fab
                      size="small"
                      color="secondary"
                      style={{ margin: "0 0 -15px 20px" }}
                      disabled={self.state.selection.length === 0 || disabled}
                      onClick={e => {
                        const c = window.confirm(
                          `Delete Owner(s)\nAre you sure?`
                        );
                        if (!c) return;
                        this.setState({ disabled: true });
                        AxiosAll.post(`/tasks/removeOwners`, {
                          task: task,
                          ids: selection
                        }).then(oResult => {
                          // console.log("delete res", oResult);
                          AxiosAll.post(`/tasks/getOwners`, {
                            task: task
                          }).then(oResult2 => {
                            this.setState({
                              msg: (oResult.data.body === '' ? <span /> : <Typography color="secondary">{oResult.data.body}</Typography>),
                              owners: oResult2.data,
                              disabled: false
                            });
                          });
                        });
                      }}
                    >
                      <Delete />
                    </Fab>
                    <CheckboxTable defaultPageSize={50}
                      data={owners}
                      columns={columns}
                      minRows={0}
                      showPagination={false}
                      ref={r => (this.checkboxTable = r)}
                      style={{ margin: "20px", width: "100%" }}
                      {...checkboxProps}
                    />
                  </Grid>
                </Grid>
              )}

              {tab === 4 && (
                <Grid container>
                  <FixedJudgements />
                </Grid>
              )}

              {tab === 5 && (
                <Grid container>
                  <Grid item xs={12}>
                    {msg}
                    <Typography>
                      Create a new task that includes the candidates from this
                      task that can be used to measure the progress from this
                      task.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="raised"
                      color="primary"
                      style={{ marginTop: "20px", textTransform: "inherit" }}
                      disabled={disabled}
                      onClick={e => {
                        self.setState({ disabled: true });
                      }}
                    >
                      Add Progress Task
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Paper>
        </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Settings2);
