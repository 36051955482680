import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Grid, Button, Divider } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";

const _ = require("lodash");

class EditCandidate extends Component {
  handleDialogClose = () => {
    this.props.onCancel();
  };
  render() {
    const self = this;
    const { candidate, candInfo, dialogOpen } = this.props;
    if (!candInfo) return <span />;
    return (
      <Dialog open={dialogOpen} maxWidth="md">
        <DialogTitle>
          Edit Candidate ({candInfo.qrcode})
          {(_.has(candInfo, "assemblyId") || _.has(candInfo, "misId")) && (
            <p style={{ marginTop: 0, marginBottom: 0 }}>
              <small>
                {candInfo.assemblyId} / {candInfo.misId}
              </small>
            </p>
          )}
          <Button
            mini
            variant="fab"
            style={{ float: "right" }}
            onClick={e => {
              this.props.onCancel();
            }}
          >
            <Cancel />
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={{
              firstName: candInfo.firstName,
              lastName: candInfo.lastName,
              dob: candInfo.dob,
              gender: candInfo.gender,
              yg: candInfo.yg,
              PP: candInfo.PP ? "Y" : "N",
              EAL: candInfo.EAL ? "Y" : "N",
              group: candInfo.group
            }}
            onSubmit={(values, { setSubmitting }) => {
              // console.log("submitted", values);
              const oCand = {
                candidate: candidate,
                firstName: values.firstName,
                lastName: values.lastName,
                dob: values.dob,
                gender: values.gender,
                yg: parseInt(values.yg, 10),
                PP: values.PP === "Y" ? true : false,
                EAL: values.EAL === "Y" ? true : false,
                group: values.group,
              };
              AxiosAll.post(`/candidates/updateCandidate`, oCand).then(
                oResult => {
                  // console.log("getCandidatesResult", oResult);
                  self.props.onCancel();
                }
              );
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              dob: Yup.string()
                .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "Must be in YYYY-MM-DD format")
                .required("Required"),
              gender: Yup.string()
                .matches(/M|F/, "M/F expected")
                .required("Required"),
              yg: Yup.number()
                .min(0, "Min value is 0")
                .max(13, "Max value is 13")
                .required("Required"),
              PP: Yup.string().matches(/Y|N/, "Y/N expected"),
              EAL: Yup.string().matches(/Y|N/, "Y/N expected"),
              group: Yup.string().required(),
            })}
          >
            {({ isSubmitting, values, errors, dirty }) => (
              <Form>
                <Grid container>
                  <Grid item xs={6} style={{ padding: "5px" }}>
                    <FormikTextField
                      name="firstName"
                      label="First Name"
                      fullWidth
                    />
                    <FormikTextField
                      name="dob"
                      label="Date of Birth (YYYY-MM-DD)"
                      fullWidth
                    />
                    <FormikTextField name="yg" label="Year Group / Grade" type="number" fullWidth />
                    <FormikTextField
                      name="PP"
                      label="Pupil Premium"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ padding: "5px" }}>
                    <FormikTextField
                      name="lastName"
                      label="Last Name"
                      fullWidth
                    />
                    <FormikTextField name="gender" label="Gender" fullWidth />
                    <FormikTextField name="group" label="Group" fullWidth />
                    <FormikTextField name="EAL" label="EAL / ESL" fullWidth />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ textTransform: "inherit" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withRouter(EditCandidate);
