// import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton
} from "@material-ui/core";
import ImageZoom from "react-medium-image-zoom";

import AxiosAll from "./../axiosAll";
import TaskMenu2 from "./../layout/taskMenu2.js";
import ScansMenu from "./../layout/scansMenu.js";
import {
  Star,
  StarBorder
} from "@material-ui/icons";
import { SizeMe } from "react-sizeme";

const _ = require("lodash");

class ScansExcluded extends Component {
  state = {
    scans: [],
    loaded: false,
    taskName: "",
    locked: false,
    isModTask: false,
    useCodes: false,
    selection: []
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const promTask = AxiosAll.post(`/tasks/getTask`, { task: task });
    const promMenu = AxiosAll.post(`/scans/getScanMenu`, { task: task });
    const promScans = AxiosAll.post(`/scans/getScannedPagesForTask`, {
      task: task
    });
    Promise.all([promTask, promMenu, promScans]).then(oResult => {
      this.setState({
        taskName: oResult[0].data.name,
        locked: oResult[0].data.locked,
        isModTask: oResult[0].data.isModTask,
        useCodes: oResult[0].data.useCodes,
        pdfFiles: oResult[1].data.pdfFiles,
        codesToFix: oResult[1].data.codesToFix,
        candsWithScans: oResult[1].data.candsWithScans,
        hasDuplicates: oResult[1].data.hasDuplicates,
        excludedPages: oResult[1].data.excludedPages,
        scans: oResult[2].data,
        loaded: true
      });
    });
  }
  render() {
    const { match } = this.props;
    const task = match.params.task;
    const {
      scans,
      loaded,
      taskName,
      locked,
      isModTask,
      useCodes,
      pdfFiles,
      codesToFix,
      candsWithScans,
      hasDuplicates,
      selection
    } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    if (isModTask) return <Typography gutterBottom>Not available for moderation tasks</Typography>
    let filtered = _.filter(scans, (oScan, i) => {
      return _.has(oScan, "exclude");
    });
    console.log("state", this.state, filtered);
    return (
      <Grid container style={{ paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2
            taskName={taskName}
            locked={locked}
            isModTask={isModTask}
            useCodes={useCodes}
          />
        </Grid>
        <Grid item xs={8}>
          <ScansMenu
            tab={5}
            useCodes={useCodes}
            pdfFiles={pdfFiles}
            codesToFix={codesToFix}
            candsWithScans={candsWithScans}
            hasDuplicates={hasDuplicates}
          />
          {filtered.length === 0 && (
            <Typography
              gutterBottom
              color="secondary"
              style={{ margin: "15px 0 0 0" }}
            >
              No Images found
            </Typography>
          )}
          <SizeMe>
            {({ size }) => (
              <GridList
                cellHeight={282}
                cols={parseInt(size.width / 200)}
                style={{ margin: "20px 20px 20px 0" }}
              >
                {filtered.map((oScan, i) => (
                  <GridListTile key={`glt_${i}`} cols={1}>
                    <ImageZoom
                      image={{
                        src:
                          oScan.angle === 0
                            ? oScan.thumbnail0
                            : oScan.thumbnail180,
                        alt: `*`,
                        style: {
                          width: "200px",
                          height: "282px",
                          border: "1px solid #021a40",
                          margin: "5px",
                          padding: "5px"
                        }
                      }}
                      zoomImage={{
                        src: oScan.full_url,
                        alt: `*`
                      }}
                    />
                    <GridListTileBar
                        style={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"}}
                      title={
                        oScan.qrpage === 0
                          ? ""
                          : `${oScan.qrcode}${oScan.qrpage}`
                      }
                      titlePosition="top"
                    />
                    <GridListTileBar
                        style={{ background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"}}
                      actionIcon={
                        <IconButton
                          onClick={e => {
                              console.log('>', oScan)
                              AxiosAll.post(`/scans/toggleExcludeImgs`, {
                                task: task,
                                selection: [oScan._id]
                              })
                              .then(oRes => {
                                AxiosAll.post(`/scans/getScannedPagesForTask`, { task: task })
                                .then(oRes => {
                                    this.setState({
                                        scans: oRes.data,
                                    })
                                })
                              })
                            /*if (_.includes(selection, oScan._id)) {
                              this.setState({
                                selection: _.difference(selection, [oScan._id])
                              });
                            } else {
                              this.setState(prevState => ({
                                selection: [...prevState.selection, oScan._id]
                              }));
                            }*/
                          }}
                        >
                          {_.includes(selection, oScan._id) ? (
                            <Star />
                          ) : (
                            <StarBorder />
                          )}
                        </IconButton>
                      }
                    />
                  </GridListTile>
                ))}
              </GridList>
            )}
          </SizeMe>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ScansExcluded);
