import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Grid, Button, Tooltip, Fab } from "@material-ui/core";
import { Check, Close, ArrowDropDownCircle } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
// import "typeface-roboto";
import AxiosAll from "./../axiosAll";

const CheckboxTable = checkboxHOC(ReactTable);
const FileSaver = require("file-saver");
const _ = require("lodash");

const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

class CandidatesReporting extends Component {
  state = {
    disabled: false,
    candidates: [],
    selection: [],
    selectAll: false,
    loaded: false
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    // console.log(">componentDidMount reporting cands");
    AxiosAll.post(`/tasks/refreshTask`, { task: task })
    .then(oRes => {
      // console.log('refresh task', oRes)
      const promCands = AxiosAll.post(`/candidates/getCandidatesForTask`, {
        task: task
      })
      Promise.all([promCands])
      .then(oResult => {
        const oCandidates = oResult[0].data
        //const oScans = oResult[1].data
        // console.log("oCandidates", oCandidates);
        this.setState({
          candidates: oCandidates,
          //scans: oScans,
          loaded: true
        });
      });
    })
  }
  toggleSelection = (key, shift, row) => {
    key = key.replace('select-', '')
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  handleDownloadCandidates = oData => {
    const { country } = this.props;
    const oCols = [
      { field: "identifier", title: "Candidate" },
      { field: "qrcode", title: "QRCode" },
      { field: "firstName", title: "First Name" },
      { field: "lastName", title: "Last Name" },
      { field: "dob", title: "DOB" },
      { field: "gender", title: "Gender" },
      { field: "group", title: "Group" },
      { field: "PP", title: "Pupil Premium" },
      { field: "EAL", title: country === 'GB' ? "EAL": 'ESL' },
      { field: "yg", title: country === 'GB' ? "YG" : 'Grade' },
      { field: "scaledScore", title: "Scaled Score" },
      { field: "seScaledScore", title: "Scaled Score SE" },
      { field: "trueScore", title: "True Score" },
      { field: "seTrueScore", title: "True Score SE" },
      { field: "infit", title: "Infit" },
      { field: "score", title: "Score" },
      { field: "propScore", title: "Prop Score" },
      { field: "level", title: "Level" },
      { field: "levelValue", title: "Level Value" },
      { field: "link", title: "Link" },
      { field: "localComparisons", title: "Local Comparisons" },
      { field: "modComparisons", title: "Mod Comparisons" },
      { field: "anchor", title: "Anchor" },
      { field: "moderated", title: "Moderated" },
      { field: "taskName", title: "Task Name" },
      { field: "observedScore", title: "Observed Score"},
      { field: "losses", title: "Losses" },
      { field: "anchorScore", title: "Anchor Score" },
      { field: "fromTaskName", title: "From Task" },
    ];
    const arrCols = _.map(oCols, "field");
    let oResult = JSON.parse(JSON.stringify(oData));
    oResult = _.map(oResult, function(oRow) {
      oRow.PP = oRow.PP ? "Y" : "N";
      oRow.EAL = oRow.EAL ? "Y" : "N";
      oRow.anchor = oRow.anchor ? "Y" : "N";
      oRow.moderated = oRow.moderated ? 'Y' : 'N'
      _.each(arrCols, function(attr) {
        if (!_.has(oRow, attr)) oRow[attr] = "*";
        if (
          _.includes(["firstName", "lastName", "group"], attr) &&
          _.has(oRow, attr)
        )
          oRow[attr] = oRow[attr].replace(/,/, ".");
      });
      oRow.link = `${window.location.origin}/display/${oRow._id}`;
      return oRow;
    });
    oResult = _.map(oResult, function(oRow) {
      return _.values(_.pick(oRow, arrCols)).join(",");
    });
    const arrTitles = _.map(oCols, "title");
    oResult.unshift(arrTitles.join(","));
    const blob = new Blob([oResult.join("\n")], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(blob, "results.csv");
  };
  render() {
    const self = this;
    const { toggleSelection, toggleAll, isSelected } = this;
    let { disabled, candidates, loaded, selection, selectAll } = this.state;
    const { match, country } = this.props;
    const task = match.params.task;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };
    candidates = _.filter(candidates, function(o) {
      return o.status !== "External";
    });
    candidates = _.filter(candidates, { hasScans: true }) //_.filter(candidates, function(oCandidate) { return (oCandidate._pages > 0) })
    // console.log("candidates", candidates);
    const arrGroups = _.uniq(_.map(candidates, "group"));
    const columns = [
      {
        Header: "Code",
        accessor: "qrcode",
        Cell: cellInfo => {
          /*<div style={{ flex: 0, textAlign: "center" }}>
            {row.original.firstName}
          </div>*/
          if (!cellInfo.original.hasScans)
          return (
            <div style={{ flex: 0, textAlign: "center" }}>
              {cellInfo.original.qrcode}
            </div>
          );
          return (
            <div style={{ flex: 0, textAlign: "center" }}>
              <NavLink to={`/display/${cellInfo.original._id}`} target="_blank">
                {cellInfo.original.qrcode}
              </NavLink>
            </div>
          );
        },
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.firstName}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.lastName}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Gender",
        accessor: "gender",
        maxWidth: 70,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.gender}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Group",
        accessor: "group",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.group}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i")),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">All</option>
            {arrGroups.map((group, i) => (
              <option key={`option_${i}`} value={group}>
                {group}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: (
          <span>
            Pupil
            <br />
            Premium
          </span>
        ),
        accessor: "PP",
        maxWidth: 80,
        show: country === 'GB',
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.PP ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        Header: <span>Anchor</span>,
        accessor: "anchor",
        maxWidth: 80,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.anchor ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        Header: (
          <span style={{ flex: 0, textAlign: "center", whiteSpace: "normal" }}>
            Scaled Score
          </span>
        ),
        maxWidth: 70,
        accessor: "scaledScore",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {isNaN(row.original.scaledScore) ||
            row.original.scaledScore === null
              ? "*"
              : row.original.scaledScore.toFixed(1)}
          </div>
        ),
        filterable: false
      },
      {
        Header: "Level",
        accessor: "level",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.level}
          </div>
        ),
        filterable: false
      },
      {
        Header: "Infit",
        maxWidth: 70,
        accessor: "infit",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.infit === null || row.original.infit === 0
              ? ""
              : (row.original.infit || 0).toFixed(1)}
          </div>
        ),
        filterable: false
      },
      {
        Header: <span>Local<br />Comparisons</span>,
        maxWidth: 100,
        accessor: "localComparisons",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.localComparisons || 0}
          </div>
        ),
        filterable: false
      },
      {
        Header: <span>Moderated</span>,
        maxWidth: 100,
        accessor: "moderated",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.moderated ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      },
      {
        Header: <span>Mod<br />Comparisons</span>,
        maxWidth: 100,
        accessor: "modComparisons",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.modComparisons || 0}
          </div>
        ),
        filterable: false
      },
      {
        Header: <span>From Task</span>,
        maxWidth: 150,
        accessor: 'taskName',
        filterMethod: (filter, row) => row[filter.id].match(RegExp(filter.value, "i")),
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.taskName || ''}
          </div>
        ),
      },
    ];
    if (!loaded) return <span>Loading...</span>;
    // console.log('state', this.state)
    return (
      <div> 
        <Grid container>
          <Grid item xs={12}>
            <Tooltip title="Decisions for Candidate">
              <Fab
                size="small"
                style={{ margin: "0 5px 5px 0" }}
                color="secondary"
                disabled={selection.length !== 1}
                component={AdapterLink}
                to={`/candidatedecisions/${task}/${selection[0]}`}
              >
                <MenuIcon />
              </Fab>
            </Tooltip>
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{
                float: "right",
                textTransform: "inherit",
                margin: "0 0 5px 5px"
              }}
              disabled={(candidates || []).length === 0 || disabled}
              onClick={() => self.handleDownloadCandidates(candidates)}
            >
              <ArrowDropDownCircle style={{ fontSize: "20px" }} /> Candidate Results
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CheckboxTable defaultPageSize={50}
              data={candidates}
              filterable
              columns={columns}
              minRows={0}
              showPagination={true}
              ref={r => (this.checkboxTable = r)}
              style={{ marginBottom: "20px" }}
              {...checkboxProps}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(CandidatesReporting);
