import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button
} from "@material-ui/core";
// import { FormatQuote } from "@material-ui/icons";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import auth from "./../Auth.js";

class Pricing2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "33px",
                  color: "#3f1f71"
                }}
              >
                Pricing
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item />
            <hr style={{ width: "502px" }} />
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <Typography
              style={{
                // fontFamily: "Source Sans Pro",
                fontSize: "20px",
                color: "#af1280"
              }}
            >
              Save time and reduce the assessment burden with Comparative
              Judgement
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ margin: "30px 0 0 0" }}>
          <Grid container justify="center">
            <Grid
              container
              justify="space-around"
              alignItems="stretch"
              style={{ width: "1024px" }}
            >
              <Grid item style={{ width: "240px", margin: "20px 0 0px 0" }}>
                <Card style={{ height: "45px", backgroundColor: "#dddddd" }}>
                  <CardHeader
                    title={
                      <Typography
                        style={{ fontSize: "23px", marginTop: "-10px" }}
                      >
                        Primary Schools
                      </Typography>
                    }
                  />
                </Card>
              </Grid>
              <Grid item style={{ width: "240px", margin: "20px 0 0px 0" }}>
                <Card style={{ height: "45px", backgroundColor: "#dddddd" }}>
                  <CardHeader
                    title={
                      <Typography
                        style={{ fontSize: "23px", marginTop: "-10px" }}
                      >
                        Secondary Schools
                      </Typography>
                    }
                  />
                </Card>
              </Grid>
              <Grid item style={{ width: "502px", margin: "20px 0 0px 0" }}>
                <Card style={{ height: "45px", backgroundColor: "#dddddd" }}>
                  <CardHeader
                    title={
                      <Typography
                        style={{ fontSize: "23px", marginTop: "-10px" }}
                      >
                        USA Schools
                      </Typography>
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid
              container
              justify="space-around"
              alignItems="stretch"
              style={{ width: "1024px" }}
            >
              <Grid item style={{ width: "240px", margin: "20px 0 20px 0" }}>
                <Card style={{ height: "460px" }}>
                  <CardHeader
                    style={{ backgroundColor: "#af1280" }}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{ fontSize: "18px", textAlign: "center" }}
                      >
                        Assessing Primary Writing
                      </Typography>
                    }
                  />
                  <CardContent style={{ height: '190px' }}>
                    <Typography
                      style={{
                        // fontFamily: "Source Sans Pro",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      Years 1 to 6
                    </Typography>
                    <hr style={{ width: "208px" }} />
                    <Typography
                      style={{
                        // fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        textAlign: "center"
                      }}
                    >
                      Standardised and nationally benchmarked assessment for primary writing
                    </Typography>
                    </CardContent>
                    <CardContent>
                    <hr style={{ width: "208px" }} />
                    <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '28px', fontWeight: 'bold' }}><span>£595</span> <span style={{ fontSize: '19px', fontWeight: 'normal' }}>+VAT</span></div>
                    <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '0.875rem', color: '#0000008a' }}>per annum</div>
                  </CardContent>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        // left: "92px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        // fontFamily: "Source Sans Pro"
                      }}
                      onClick={() => {
                        auth.login("subscribe2?product=APW");
                      }}
                    >
                      Sign up
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/*<Grid item style={{ width: "240px", margin: "20px 0 20px 0" }}>
                <Card style={{ height: "460px" }}>
                  <CardHeader
                    style={{ backgroundColor: "#af1280" }}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{ fontSize: "18px", textAlign: "center" }}
                      >
                        Proof of Progress
                      </Typography>
                    }
                  />
                  <CardContent style={{ height: '190px' }}>
                    <Typography
                      style={{
                        // fontFamily: "Source Sans Pro",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      Years 7 to 9
                    </Typography>
                    <hr style={{ width: "208px" }} />
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        textAlign: "center"
                      }}
                    >
                      Standardised and nationally benchmarked assessment for KS3 English and maths
                    </Typography>
                    </CardContent>
                    <CardContent>
                    <hr style={{ width: "208px" }} />
                      <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '28px', fontWeight: 'bold' }}><span>£2</span> <span style={{ fontSize: '19px', fontWeight: 'normal' }}>+VAT per pupil</span></div>
                      <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '0.875rem', color: '#0000008a' }}>per test</div>
                  </CardContent>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        // left: "92px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      onClick={() => {
                        auth.login("subscribe2?product=PoP1920");
                      }}
                    >
                      Sign up
                    </Button>
                  </CardActions>
                </Card>
              </Grid>*/}

              <Grid item style={{ width: "240px", margin: "20px 0 20px 0" }}>
                <Card style={{ height: "460px" }}>
                  <CardHeader
                    style={{ backgroundColor: "#af1280" }}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{ fontSize: "18px", textAlign: "center" }}
                      >
                        Assessing GCSE English
                      </Typography>
                    }
                  />
                  <CardContent style={{ height: '190px' }}>
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      Years 10 to 11
                    </Typography>
                    <hr style={{ width: "208px" }} />
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        textAlign: "center"
                      }}
                    >
                      Standardised and nationally benchmarked assessment for KS4 English
                    </Typography>
                    </CardContent>
                    <CardContent>
                    <hr style={{ width: "208px" }} />
                    <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '28px', fontWeight: 'bold' }}><span>£595</span> <span style={{ fontSize: '19px', fontWeight: 'normal' }}>+VAT</span></div>
                    <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '0.875rem', color: '#0000008a' }}>per annum</div>
                  </CardContent>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        // left: "92px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      onClick={() => {
                        auth.login("subscribe2?product=AGE");
                      }}
                    >
                      Sign up
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item style={{ width: "240px", margin: "20px 0 20px 0" }}>
                <Card style={{ height: "460px" }}>
                  <CardHeader
                    style={{ backgroundColor: "#3f1f71" }}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{ fontSize: "18px", textAlign: "center" }}
                      >
                        Assessing America's Writing
                      </Typography>
                    }
                  />
                  <CardContent style={{ height: '190px' }}>
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      Grade 3
                    </Typography>
                    <hr style={{ width: "208px" }} />
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        textAlign: "center"
                      }}
                    >
                      Standardised and benchmarked writing assessment for Grade 3 pupils in America
                    </Typography>
                    </CardContent>
                    <CardContent>
                    <hr style={{ width: "208px" }} />
                      <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '28px', fontWeight: 'bold' }}><span>$300</span></div>
                      <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '0.875rem', color: '#0000008a' }}>per annum</div>
                  </CardContent>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        // left: "92px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      onClick={() => {
                        auth.login("subscribe2?product=AAW");
                      }}
                    >
                      Sign up
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item style={{ width: "240px", margin: "20px 0 20px 0" }}>
                <Card style={{ height: "460px" }}>
                  <CardHeader
                    style={{ backgroundColor: "#3f1f71" }}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{ fontSize: "18px", textAlign: "center" }}
                      >
                        Assessing America's Writing
                      </Typography>
                    }
                  />
                  <CardContent style={{ height: '190px' }}>
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      Grade 9
                    </Typography>
                    <hr style={{ width: "208px" }} />
                    <Typography
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        textAlign: "center"
                      }}
                    >
                      Standardised and benchmarked writing assessment for Grade 9 pupils in America
                    </Typography>
                    </CardContent>
                    <CardContent>
                    <hr style={{ width: "208px" }} />
                      <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '28px', fontWeight: 'bold' }}><span>$300</span></div>
                      <div style={{ width: '100%', textAlign: 'center', fontFamily: "Source Sans Pro", fontSize: '0.875rem', color: '#0000008a' }}>per annum</div>
                  </CardContent>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        // left: "92px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      onClick={() => {
                        auth.login("subscribe2?product=AAWS");
                      }}
                    >
                      Sign up
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ margin: "20px 0 30px 0" }}>
        <Grid container justify="center">
            <Grid item style={{ position: 'relative', backgroundColor: '#efefef', width: '1024px', height: '169px' }}>
                <Typography style={{ position: 'absolute', fontFamily: "Source Sans Pro", top: '22px', fontSize: '21px', fontWeight: 'bold', color: '#af1280', textAlign: 'center', width: '1024px' }}>
                Our FREE account sign up
                </Typography>
                <Typography style={{ position: 'absolute', fontFamily: "Source Sans Pro", top: '45px', fontSize: '21px', color: '#af1280', textAlign: 'center', width: '1024px' }}>
                allows you to create an account and subscribe to products through the control panel.
                </Typography>
                <Button
                size="small"
                variant="contained"
                style={{
                    position: 'absolute',
                  left: "449px",
                  top: '90px',
                  width: "145px",
                  height: "40px",
                  backgroundColor: "#3f1f71",
                  color: "#ffffff",
                  textTransform: "inherit",
                  fontFamily: "Source Sans Pro"
                }}
                onClick={() => { auth.login('tasks') }}
                >
                    Sign up
                </Button>
                </Grid>
            </Grid>
            </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                position: "relative",
                width: "1024px",
                height: "165px",
                backgroundImage: "url(/assets/quote-banner-extended.jpg)"
              }}
            >
              <Typography
                style={{
                  position: "absolute",
                  width: "502px",
                  padding: "20px 0 0 0",
                  fontFamily: "Source Sans Pro",
                  fontSize: "23px",
                  color: "#3f1f71",
                  textAlign: "center"
                }}
              >
                The whole process has given us confidence our writing is
                improving, and has given us a clearer understanding of how we are
                doing.
              </Typography>
              <Typography
                style={{
                  position: "absolute",
                  width: "502px",
                  top: "125px",
                  xpadding: "20px 0 0 0",
                  fontFamily: "Source Sans Pro",
                  fontSize: "15px",
                  color: "#af1280",
                  textAlign: "center"
                }}
              >
                Dominic Hughes, Head Teacher, Springwell Junior School
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Pricing2);
