import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button, Hidden, Divider
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import auth from "./../Auth.js";
import PageTitle from "./../components/pageTitle.js";

class AAWMob extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  render() {
    const { classes } = this.props;
    console.log('state', this.state)
    return (
        <Hidden smUp>
      <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
        <PageTitle
          title="Assessing America's Writing"
          img="/assets/girl-red-jumper_banner-mob.jpg"
        />
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ margin: '0 5% 0 5%' }}>
              <Typography
                gutterBottom
                style={{
                  margin: "20px 0 20px 0",
                  // fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  color: "#af1280"
                }}
              >
                Assessing America's Writing (AAW) uses Comparative Judgement to assess children's writing. It comes in two versions, one for Elementary schools (Grade 3) and one for High schools (Grade 9).
              </Typography>
              <Typography
                gutterBottom
                style={{
                  margin: "20px 0 20px 0",
                  // fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  color: "#af1280"
                }}
              >
                If you're a US school, you can take part by uploading your children's writing and then judging it online alongside the writing of children from other schools in America. Your teachers will benefit from seeing their own pupils' writing in the context of other pupils' writing. You'll also receive a standardized score for each pupil. Our Comparative Judgement engine ensures the process is quick and the results you receive are reliable and nationally standardized.
              </Typography>
              <Typography
                gutterBottom
                style={{
                  margin: "20px 0 20px 0",
                  // fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  color: "#af1280"
                }}
              >
                Last year we assessed the writing of 150,000 pupils in the UK using this approach. Assessing America's Writing is our pilot US project.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item style={{ xwidth: "669px", margin: "20px 0 20px 0", padding: '0 5% 0 5%' }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  title={
                    <Typography
                      style={{
                        fontSize: "21px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      Save time and reduce the assessment burden…
                      <br />
                      Comparative Judgement in 4 easy steps!
                    </Typography>
                  }
                />
                <CardContent xstyle={{ position: 'relative', xheight: '325px' }}>
                    <Grid container justify="center">
                        <Grid item>
                        <img
                    src="/assets/step-0.1-icon.svg"
                    alt="Step 1"
                  />
                        </Grid>
                    </Grid>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        1
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;We set the task, so everyone is writing against the same prompt.
                      </span>
                  </CardContent>
                  <Divider />
                  <CardContent>
                  <Grid container justify="center">
                        <Grid item>
                  <img
                    src="/assets/step-1-icon.svg"
                    alt="Step 1"
                  />
                  </Grid>
                  </Grid>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        2
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Upload your pupils’ scripts on our specially barcoded paper
                      </span>
                  </CardContent>
                  <Divider />
                  <CardContent>
                  <Grid container justify="center">
                        <Grid item>
                  <img
                    src="/assets/step-2-icon.svg"
                    alt="Step 1"
                    xstyle={{ position: 'absolute', top: '60px', left: '354px' }}
                  />
                  </Grid>
                  </Grid>
                                
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        3
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Judge writing, comparing two pieces of writing on screen, side-by-side to determine which is best
                      </span>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container justify="center">
                        <Grid item>
                  <img
                    src="/assets/step-3-icon.svg"
                    alt="Step 1"
                    xstyle={{ position: 'absolute', top: '54px', left: '526px' }}
                  />
                  </Grid>
                  </Grid>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        4
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Benchmark attainment and progress across your school, and nationally, with the information and statistics in your school’s individual report
                      </span>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "676px", margin: "20px 0 20px 0", padding: '0 5% 0 5%' }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#af1280" }}
                  title={
                    <Typography
                      className={classes.topBannerHeading}
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        color: "#ffffff",
                        textAlign: "center"
                      }}
                    >
                      Here’s a summary of how it works:
                    </Typography>
                  }
                />
                <CardContent style={{ fontFamily: "Source Sans Pro" }}>
                  <ul>
                    <li style={{ margin: "20px 0 20px 0" }}>
                        There are two assessment windows for Grade 3 or Grade 9, one in January and one in June
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                        Each window involves a specific piece of writing where we provide the stimulus.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                        In each window you judge the work of your pupils as well as the work of pupils from other schools in America.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                        You are never asked to compare your pupils with pupils from another school. Similarly, teachers from other schools will never be asked to compare your pupils with their pupils. Rather, every 5th judgement is a moderation judgement where you compare the work of pupils from other schools.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                        Our judgement process ensures every script is externally standardized and all judging quality monitored in real time.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                        You receive a standardized score for every pupil as well as reports aggregated by school and gender. In June, we will also provide you with a progress measure for every pupil.
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "669px", margin: "20px 0 20px 0", padding: '0 5% 0 5%' }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  xclassName={classes.topBannerBackground}
                  title={
                    <Typography className={classes.topBannerHeading}>
                      What are the benefits of No More Marking: Assessing
                      America's Writing?
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="space-around">
                    <Grid item style={{ width: "300px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Workload
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          You can assess the writing of an entire grade in under an hour
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Accuracy
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Comparative judgement achieves greater levels of reliability for more open tasks than traditional approaches to marking.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Validity
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Holistic judgements of writing help you escape the tick list approach to assessing writing.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Progress measures
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          You can measure the progress Grade 3 or Grade 9 make from January to June.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Standardized results
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Results are based on the performance of pupils and the judgements of teachers from many different schools in America.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    <Grid item style={{ width: "300px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Robust data controls
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                            Every judge’s performance is recorded, and decisions made by inaccurate judges are removed from the results.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Exemplar scripts
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          After each assessment, we provide you with exemplar scripts from pupils across the country, which can be used in lessons or for professional development.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Built-in moderation
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          As you are judging, you are also silently and anonymously moderating the judgements of other teachers.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Assess every script
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          With traditional moderation, most scripts are never seen by anyone other than the class teacher. With us, every script is seen twenty times.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                //position: "relative",
                //width: "1024px",
                //height: "165px",
                backgroundImage: "url(/assets/quote-banner-extended.jpg)"
              }}
            >
              <Typography
                style={{
                  //position: "absolute",
                  //width: "500px",
                  padding: "20px 10% 0 10%",
                  fontSize: "23px",
                  color: "#3f1f71",
                  textAlign: "center"
                }}
              >
                
                  Having fully embraced CJ this year, it has been a revelation both in terms of its uncanny accuracy and significant reduction in workload for us all.
                
              </Typography>
              <Typography
                style={{
                  //position: "absolute",
                  //width: "669px",
                  bottom: "20px",
                  fontSize: "15px",
                  color: "#af1280",
                  textAlign: "center"
                }}
              >
                Themistocles Bakas, Head of Assessment and Technology, Charles Dickens Primary School
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                position: "relative",
                backgroundColor: "#efefef",
                width: "100%",
                //width: "1180px",
                //height: "169px"
              }}
            >
              <Typography
                style={{
                  //position: "absolute",
                  //fontFamily: "Source Sans Pro",
                  top: "22px",
                  fontSize: "21px",
                  color: "#af1280",
                  textAlign: "center",
                  //width: "1180px"
                }}
              >
                Standardized and nationally benchmarked
                <br />
                writing assessment for Grade 3 or Grade 9, just <strong>
                  $300
                </strong>
              </Typography>
              <Button
                size="small"
                variant="contained"
                style={{
                    margin: '10px 25% 10px 25%',
                  //position: "absolute",
                  //left: "517px",
                  //top: "90px",
                  //width: "145px",
                  height: "40px",
                  backgroundColor: "#3f1f71",
                  color: "#ffffff",
                  textTransform: "inherit",
                  //fontFamily: "Source Sans Pro"
                  width: '50%'
                }}
                fullWidth
                onClick={() => {
                  auth.login('subscribe2?product=AAW');
                }}
              >
                Sign up
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Hidden>
    );
  }
}

export default withStyles(styles)(AAWMob);
