import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  Paper,
  Grid,
  Typography,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import { InsertDriveFile } from "@material-ui/icons";
import { List, ListItem } from "@material-ui/core";
// import gql from "graphql-tag";
// import { Query } from "react-apollo";
import AxiosAll from "./../axiosAll";

const _ = require("lodash");

class ReportingResources extends Component {
  state = {
    files: [],
    loaded: false,
  }
  componentDidMount = () => {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/aws/getTaskReports`, { task: task })
    .then(oFiles => {
      this.setState({
        files: oFiles.data,
        loaded: true,
      })
    })
  }
  render() {
    const { files, loaded } = this.state;
    // const { match } = this.props;
    // const task = match.params.task;
    // console.log("match", task);
    // console.log('state', this.state)
    if (!loaded)
      return <span />
    return (
      <div>
      {/*<Query
        query={gql`{ getTaskReports(task:"${task}") { filename url dateCreated } }`}
      >
        {({ loading, data, error, refetch }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error :( {JSON.stringify(error)}</p>;
          const oFiles = data.getTaskReports;
          // console.log("data", data, oFiles);
        return (*/}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6} style={{ paddingRight: "20px" }}>
                  <Paper elevation={4}>
                    <List>
                      {_.map(files, function(oFile, i) {
                        return (
                          <ListItem
                            key={`li_${i}`}
                            button
                            component="a"
                            href={oFile.url}
                            target="_blank" divider
                          >
                            <ListItemIcon>
                              <InsertDriveFile />
                            </ListItemIcon>
                            <ListItemText
                              primary={oFile.filename}
                              secondary={oFile.dateCreated}
                            />
                          </ListItem>
                        );
                      })}
                      {files.length === 0 && (
                        <ListItem>
                          <ListItemText primary="No files found" />
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={4} style={{ padding: "15px" }}>
                    <Typography>
                      Click the links to download the Reports, available only
                      for National Tasks.&nbsp; See{" "}
                      <NavLink to="/calendars">
                        availability of reports dates
                      </NavLink>
                      .
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          {/*});
        }}
      </Query>*/}
      </div>
    );
  }
}

export default withRouter(ReportingResources);
