import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@material-ui/core";
import {
  Grid,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { animateScroll } from "react-scroll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import AxiosAll from "../axiosAll";
// import AxiosCall from "../axiosCall";
// import PageTitle from './../components/pageTitle'
import queryString from "query-string";

const md5 = require("md5");
const _ = require("lodash");
const moment = require("moment");

class ProductCard extends Component {
  render() {
    const {
      shortName,
      name1,
      name2,
      years,
      cost,
      purchases,
      points
    } = this.props;
    return (
      <Card style={{ width: "25%" }}>
        <CardContent>
          <Typography variant="h5" align="center" color="secondary">
            {name1}
          </Typography>
          <Typography variant="h5" align="center" color="secondary">
            {name2}
          </Typography>
          <Typography variant="body2" align="center" paragraph>
            {years}
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ fontSize: "24px" }}
          >
            {cost}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align="center"
            paragraph
          >
            per annum
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{
              marginBottom: "20px",
              textTransform: "inherit"
            }}
            disabled={_.includes(_.map(purchases, "productName"), shortName)}
            onClick={e => {
              this.props.onClick(shortName);
            }}
          >
            {_.includes(_.map(purchases, "productName"), shortName)
              ? `Purchased: ${moment(
                  (
                    _.find(purchases, {
                      productName: shortName
                    }) || {}
                  ).purchaseCreatedAt,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("DD/MM/YY")}`
              : `Select`}
          </Button>
          {_.map(points, (pointItem, j) => (
            <Typography key={`j_${j}`} variant="caption" paragraph>
              &#9632; {pointItem}
            </Typography>
          ))}
        </CardContent>
      </Card>
    );
  }
}

class Subscribe3 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("boot", {
      app_id: "zodtosc9"
    });
  }
  state = {
    loaded: false,
    activeStep: 0,
    couponCode: "",
    product: "",
    disabled: false,
    notInList: false,
    termsCheck: false,
    country: '',
    address1: '',
    address2: '',
    town: '',
    postcode: '',
    addressCountry: '',
  };
  componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    const promUser = AxiosAll.post(`/accounts/getUser`);
    const promPurchases = AxiosAll.post(`/purchases/getPurchases`);
    const promCoupons = AxiosAll.post(`/purchases/getCoupons`);
    const promSchool = AxiosAll.post(`/accounts/getSchool`);
    const promGetProducts = AxiosAll.post(`/purchases/getProducts`);
    Promise.all([
      promUser,
      promPurchases,
      promCoupons,
      promSchool,
      promGetProducts
    ]).then(oResults => {
      console.log("oResults", oResults);
      const oUser = oResults[0].data || {};
      const oPurchases = _.flatten(_.map(oResults[1].data, "purchases"));
      const oCoupons = oResults[2].data;
      const oSchool = oResults[3].data || {};
      const oProducts = oResults[4].data;
      const set = {
        school: oSchool,
        user: oUser,
        oPurchases: oPurchases,
        coupons: oCoupons,
        loaded: true,
        personFirstName: oUser.personFirstName,
        personLastName: oUser.personLastName,
        personJobTitle: oUser.personJobTitle,
        personPhone: oUser.personPhone,
        schoolName: oSchool.schoolName,
        nces: oSchool.nces,
        dfe: oSchool.dfe,
        urn: oSchool.urn,
        address1: oSchool.address1 || '',
        address2: oSchool.address2 || '',
        town: oSchool.town || '',
        postcode: oSchool.postcode || '',
        addressCountry: oSchool.country || '',
        financeName: oSchool.financeName,
        financePhone: oSchool.financePhone,
        financeEmail: oSchool.financeEmail,
        financeJobTitle: oSchool.financeJobTitle,
        purchaseOrder: oSchool.purchaseOrder,
        hearAboutUs: oSchool.hearAboutUs,
        products: oProducts
      };
      if (_.has(qs, "product")) {
        if (!_.includes(_.map(oPurchases, "productName"), qs.product)) {
          set.activeStep = 1;
          set.productName = qs.product;
          set.product = qs.product;
          set.country = _.find(oProducts, { productName: set.productName }).country
        }
      }
      this.setState(set);
    });
  }
  handleSelectPersonalsPage = productName => {
    console.log("handleSelectPersonalsPage", productName);
    const { products } = this.state;
    this.setState({
      activeStep: 1,
      product: productName,
      country: _.find(products, { productName }).country
    });
  };
  handleCompletePurchase = () => {
    const {
      product,
      dfe,
      urn,
      nces,
      stateSchoolId,
      personFirstName,
      personLastName,
      personJobTitle,
      personPhone,
      schoolName,
      outsideUK,
      address1,
      address2,
      town,
      postcode,
      country,
      financeName,
      financePhone,
      financeEmail,
      financeJobTitle,
      purchaseOrder,
      hearAboutUs,
      couponCode,
      addressCountry
    } = this.state;
    // console.log("handleCompletePurchase", product, oProduct, oProduct.id);
    this.setState({ disabled: true });
    AxiosAll.post(`/accounts/completeSubscriptionAll`, {
      product,
      dfe,
      urn,
      nces,
      stateSchoolId,
      personFirstName,
      personLastName,
      personJobTitle,
      personPhone,
      schoolName,
      outsideUK,
      address1,
      address2,
      town,
      postcode,
      country,
      financeName,
      financePhone,
      financeEmail,
      financeJobTitle,
      purchaseOrder,
      hearAboutUs,
      couponCode, addressCountry
    }).then(oResult => {
      console.log("oResult", oResult.data);

      this.setState({
        activeStep: 3,
        disabled: false
      });
    });
  };
  render() {
    const {
      loaded,
      coupons,
      couponCode,
      activeStep,
      products,
      product,
      purchases,
      country,
      personFirstName,
      personJobTitle,
      personLastName,
      personPhone,
      schoolName,
      dfe,
      address1,
      town,
      urn,
      nces,
      address2,
      postcode,
      addressCountry,
      financeName,
      financePhone,
      financeEmail,
      financeJobTitle,
      purchaseOrder,
      hearAboutUs,
      disabled,
      notInList,
      school,
      termsCheck, oPurchases
    } = this.state;
    if (!loaded)
      return (
        <Grid style={{ paddingTop: "146px" }} container>
          <Typography gutterBottom>Loading...</Typography>
        </Grid>
      );
    const isAuthenticated = (localStorage.getItem("email") || "") !== "";
    if (!isAuthenticated)
      return (
        <Grid style={{ paddingTop: "146px" }} container>
          <Typography gutterBottom>
            You must be authenticated before continuing
          </Typography>
        </Grid>
      );
    console.log("state", this.state, _.has(school, "schoolName"));
    let discount = 0;
    if (
      couponCode !== "" &&
      _.indexOf(_.map(coupons, "md5"), md5(couponCode)) > -1
    ) {
      const oDiscount = _.find(coupons, { md5: md5(couponCode) });
      discount = oDiscount.discount;
    }
    const totalCost = (_.find(products, { productName: product }) || {}).cost;
    const totalCostAfterDiscount = totalCost - (discount / 100) * totalCost;
    const oSelProduct = _.find(products, { productName: product }) || {};
    console.log("oSelProduct", oSelProduct);
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "1180px" }}>
              <Card style={{ margin: "10px 0 10px 0" }}>
                <CardHeader
                  title={`Subscribe${
                    product === "" ? "" : ` (${oSelProduct.productNameLong})`
                  }`}
                />
                <Divider />
                <CardContent>
                  <Stepper activeStep={activeStep} style={{ padding: "0" }}>
                    <Step>
                      <StepLabel>Products</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Personals</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Summary</StepLabel>
                    </Step>
                  </Stepper>
                </CardContent>
                <Divider />
                <CardContent>
                  {activeStep === 0 && (
                    <Grid
                      container
                      style={{
                        flexGrow: 1,
                        paddingTop: "30px",
                        height: "830px"
                      }}
                    >
                      <Grid item xs={12}>
                        <Grid container spacing={8} justify="space-evenly">
                          <ProductCard
                            shortName="APW"
                            name1="Assessing Primary"
                            name2="Writing"
                            years="Years 1 - 6"
                            cost="£595 + VAT"
                            purchases={purchases}
                            points={[
                              "6 National Judging Windows",
                              "Get whole-school and nationally benchmarked data",
                              "Save time and support progression & intervention planning"
                            ]}
                            onClick={e => {
                              this.handleSelectPersonalsPage("APW");
                            }}
                          />
                          <ProductCard
                            shortName="AGE"
                            name1="Assessing GCSE"
                            name2="English"
                            years="Years 10 - 11"
                            cost="£595 + VAT"
                            purchases={purchases}
                            points={[
                              "Standardised & nationally benchmarked",
                              "GCSE English Language & English Literature extended assessment & reporting",
                              "4 Language judging windows & 2 Literature judging windows"
                            ]}
                            onClick={e => {
                              this.handleSelectPersonalsPage("AGE");
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "80px" }}>
                        <Grid container spacing={8} justify="space-evenly">
                          <ProductCard
                            shortName="TWR"
                            name1="The Writing"
                            name2="Revolution"
                            years="Grade 1 - 12 (USA only)"
                            cost="-"
                            purchases={purchases}
                            points={[
                              "Only available for the USA schools participating in The Writing Revolution assessments"
                            ]}
                            onClick={e => {
                              this.handleSelectPersonalsPage("TWR");
                            }}
                          />
                          <ProductCard
                            shortName="AAW"
                            name1="Assessing America's"
                            name2="Writing (Elementary)"
                            years="Grade 3 (USA Only)"
                            cost="$300"
                            purchases={purchases}
                            points={[
                              "Only available for USA Schools",
                              "Two assessment windows for Grade 3, one in January and one in June",
                              "You must sign up by December 13th",
                            ]}
                            onClick={e => {
                              this.handleSelectPersonalsPage("AAW");
                            }}
                          />
                          <ProductCard
                            shortName="AAWS"
                            name1="Assessing America's"
                            name2="Writing (High)"
                            years="Grade 9 (USA Only)"
                            cost="$300"
                            purchases={purchases}
                            points={[
                              "Only available for USA Schools",
                              "Two assessment windows for Grade 9, one in January and one in June",
                              "You must sign up by December 13th",
                            ]}
                            onClick={e => {
                              this.handleSelectPersonalsPage("AAWS");
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 1 && (
                    <Grid container style={{ flexGrow: 1 }}>
                      <Formik
                        initialValues={{
                          personFirstName,
                          personJobTitle,
                          personLastName,
                          personPhone,
                          schoolName,
                          dfe,
                          address1,
                          town,
                          urn,
                          nces,
                          address2,
                          postcode,
                          addressCountry,
                          financeName,
                          financePhone,
                          financeEmail,
                          financeJobTitle,
                          purchaseOrder,
                          couponCode,
                          hearAboutUs,
                          country,
                          notInList
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          console.log("values", values);
                          this.setState({
                            activeStep: 2,
                            disabled: false,
                            personFirstName: values.personFirstName,
                            personJobTitle: values.personJobTitle,
                            personLastName: values.personLastName,
                            personPhone: values.personPhone,
                            schoolName: values.schoolName,
                            dfe: values.dfe || '',
                            nces: values.nces || '',
                            address1: values.address1,
                            town: values.town,
                            country: values.country,
                            urn: values.urn || '',
                            address2: values.address2,
                            postcode: values.postcode,
                            financeName: values.financeName,
                            financePhone: values.financePhone,
                            financeEmail: values.financeEmail,
                            financeJobTitle: values.financeJobTitle,
                            purchaseOrder: values.purchaseOrder,
                            couponCode: values.couponCode,
                            hearAboutUs: values.hearAboutUs
                          });
                          animateScroll.scrollToTop();
                        }}
                        validationSchema={Yup.object().shape({
                          personFirstName: Yup.string().required("Required"),
                          personLastName: Yup.string().required("Required"),
                          personJobTitle: Yup.string().required("Required"),
                          personPhone: Yup.string().required("Required"),
                          schoolName: Yup.string().required("Required"),
                          /*dfe: Yup.number().when("country", {
                            is: "GB",
                            then: Yup.number()
                              .moreThan(1000000, "Must be a 7 digit number")
                              .lessThan(9999999, "Must be a 7 digit number")
                              .required('Required')
                          }),
                          urn: Yup.number().when("country", {
                            is: "GB",
                            then: Yup.number().required()
                          }),
                          nces: Yup.number().when("country", {
                            is: "USA",
                            then: Yup.number().required()
                          }),*/
                          address1: Yup.string().required("Required"),
                          address2: Yup.string(),
                          town: Yup.string().required("Required"),
                          postcode: Yup.string(),
                          addressCountry: Yup.string().required("Required"),
                          financeName: Yup.string().required("Required"),
                          financePhone: Yup.string().required("Required"),
                          financeEmail: Yup.string()
                            .email("Must be a valid email address")
                            .required("Required"),
                          financeJobTitle: Yup.string().required("Required")
                        })}
                      >
                        {({
                          isSubmitting,
                          values,
                          errors,
                          dirty,
                          setFieldValue
                        }) => (
                          <Form>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <Typography variant="h6">
                                  Personal Details
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <FormikTextField
                                  label="First Name"
                                  name="personFirstName"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                                <FormikTextField
                                  label="Job Title"
                                  name="personJobTitle"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormikTextField
                                  label="Last Name"
                                  name="personLastName"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                                <FormikTextField
                                  label="Phone"
                                  name="personPhone"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <Typography variant="h6">
                                  School Details
                                </Typography>
                                {!_.has(school, "schoolName") && (
                                  <div>
                                    <Typography
                                      style={
                                        (_.has(errors, "schoolName") && !notInList)
                                          ? {
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              color: "red"
                                            }
                                          : {
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              color: "rgba(0, 0, 0, 0.54)"
                                            }
                                      }
                                    >
                                      Select School (Enter at least 3
                                      characters)
                                    </Typography>
                                    <AsyncSelect
                                      cacheOptions
                                      disabled
                                      loadOptions={(inputValue, callback) => {
                                        if (inputValue.length < 3) {
                                          callback([]);
                                        } else {
                                          console.log("lookup", inputValue, product, country);
                                          AxiosAll.post(
                                            `/noauth/getSchoolNameOptions2`,
                                            {
                                              chars: inputValue,
                                              product: product,
                                              country: country
                                            }
                                          ).then(oRes => {
                                            console.log('getSchoolNameOptions2', oRes.data)
                                            this.setState({
                                              schoolOptions: oRes.data
                                            });
                                            console.log(
                                              "getSchoolNameOptions",
                                              oRes.data
                                            );
                                            callback(oRes.data);
                                          });
                                        }
                                      }}
                                      defaultOptions
                                      onInputChange={newValue => {
                                        console.log("newval", newValue);
                                        this.setState({ inputValue: newValue });
                                      }}
                                      onChange={selRow => {
                                        console.log("selRow", selRow);
                                          setFieldValue(
                                            "schoolName",
                                            selRow.schoolName
                                          );
                                          setFieldValue("dfe", selRow.dfe);
                                          setFieldValue("urn", selRow.urn);
                                          setFieldValue("nces", selRow.nces)
                                          setFieldValue(
                                            "address1",
                                            selRow.address1
                                          );
                                          setFieldValue(
                                            "address2",
                                            selRow.address2
                                          );
                                          setFieldValue("town", selRow.town);
                                          setFieldValue(
                                            "addressCountry",
                                            selRow.country || "UK"
                                          );
                                          setFieldValue(
                                            "postcode",
                                            selRow.postcode
                                          );
                                          this.setState({
                                            notInList: selRow._id === "new"
                                          });
                                          console.log("on change", selRow);
                                      }}
                                    />
                                    {_.has(errors, "schoolName") && !notInList && (
                                      <Typography
                                        style={{
                                          color: "red",
                                          fontSize: "0.75rem",
                                          lineHeight: "1.5"
                                        }}
                                      >
                                        {errors.schoolName}
                                      </Typography>
                                    )}
                                  </div>
                                )}
                                {_.has(school, "schoolName") && (
                                  <div>
                                    <Typography>{school.schoolName}</Typography>
                                  </div>
                                )}
                              </Grid>
                              {notInList && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    marginBottom: "30px"
                                  }}
                                >
                                  <FormikTextField
                                    label="School Name"
                                    name="schoolName"
                                    style={{ marginBottom: "20px" }}
                                    fullWidth
                                  />

                                  <FormikTextField
                                    label="Town"
                                    name="town"
                                    style={{ marginBottom: "20px" }}
                                    fullWidth
                                  />
                                  <FormikTextField
                                    label={
                                      country === "GB" ? "Postcode" : "ZIP Code"
                                    }
                                    name="postcode"
                                    style={{ marginBottom: "20px" }}
                                    fullWidth
                                  />
                                </Grid>
                              )}
                              {notInList && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    marginBottom: "30px"
                                  }}
                                >
                                  <FormikTextField
                                    label="Address 1"
                                    name="address1"
                                    style={{ marginBottom: "20px" }}
                                    fullWidth
                                  />
                                  <FormikTextField
                                    label="Address 2"
                                    name="address2"
                                    style={{ marginBottom: "20px" }}
                                    fullWidth
                                  />
                                <FormikTextField
                                    label="Country"
                                    name="schoolCountry"
                                    fullWidth
                                  />
                                </Grid>
                              )}

                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ marginBottom: "-30px" }}
                                >
                                  Finance Contact Details
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <FormikTextField
                                  label="Name"
                                  name="financeName"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                                <FormikTextField
                                  label="Phone"
                                  name="financePhone"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormikTextField
                                  type="email"
                                  label="Email"
                                  name="financeEmail"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                                <FormikTextField
                                  label="Job Title"
                                  name="financeJobTitle"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ marginBottom: "-30px" }}
                                >
                                  Other
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginBottom: "30px"
                                }}
                              >
                                <FormikTextField
                                  label="Purchase Order No"
                                  name="purchaseOrder"
                                  style={{ marginBottom: "20px" }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormikTextField
                                  label="Coupon Code"
                                  name="couponCode"
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px"
                                }}
                              >
                                <FormikTextField
                                  style={{
                                    marginTop: "-20px",
                                    marginBottom: "20px"
                                  }}
                                  label="Where did you hear about us?"
                                  name="hearAboutUs"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  disabled={disabled || isSubmitting}
                                  style={{ textTransform: "inherit" }}
                                >
                                  Next
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <Grid container style={{ flexGrow: 1 }}>
                      <Grid item xs={12}>
                        <List>
                          <ListItem>
                            <ListItemText primary="Product" />
                            <Typography variant="body2">
                              {oSelProduct.productNameLong}
                            </Typography>
                          </ListItem>
                          {totalCost === totalCostAfterDiscount && (
                            <ListItem>
                              <ListItemText primary="Total Cost" />
                              <Typography
                                variant="body2"
                                style={{ textAlign: "right" }}
                              >
                                Total Cost:{" "}
                                {_.includes(["AAW", "TWR"], product)
                                  ? `$${(totalCost || 0).toFixed(2)}`
                                  : `£${totalCost.toFixed(2)} + VAT`}
                              </Typography>
                            </ListItem>
                          )}
                          {totalCost !== totalCostAfterDiscount && (
                            <ListItem>
                              <ListItemText primary="Total Cost" />
                              <Typography
                                variant="body2"
                                style={{ textAlign: "right" }}
                              >
                                Total Cost:{" "}
                                {_.includes(["AAW", "AAWS", "TWR"], product)
                                  ? `$${(totalCost || 0).toFixed(2)}`
                                  : `£${(totalCost || 0).toFixed(2)} + VAT`}
                                <br />
                                Total Cost after Discount:{" "}
                                {_.includes(["AAW", "AAWS", "TWR"], product) 
                                  ? `$${(totalCost || 0).toFixed(2)}`
                                  : `£${totalCostAfterDiscount.toFixed(
                                      2
                                    )} + VAT`}
                                <br />
                                Discount Code: {couponCode} ({discount}%)
                              </Typography>
                            </ListItem>
                          )}
                          <ListItem>
                            <ListItemText primary="Your Details" />
                            <Typography
                              variant="body2"
                              style={{ textAlign: "right" }}
                            >
                              {personFirstName} {personLastName}
                              <br />
                              {personJobTitle}
                              <br />
                              {personPhone}
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="School Details" />
                            <Typography
                              variant="body2"
                              style={{ textAlign: "right" }}
                            >
                              {schoolName}
                              <br />
                              {oSelProduct.country === "GB" && dfe !== '' && (
                                <span>
                                  {dfe} / {urn}
                                </span>
                              )}
                              {oSelProduct.country === "USA" && nces !== '' && (
                                <span>{nces}</span>
                              )}
                              <br />
                              {address1}
                              <br />
                              {address2}
                              <br />
                              {town}&nbsp;{postcode}
                              <br />
                              {country}
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Finance Details" />
                            <Typography
                              variant="body2"
                              style={{ textAlign: "right" }}
                            >
                              {financeName}
                              <br />
                              {financeEmail}
                              <br />
                              {financePhone}
                              <br />
                              {financeJobTitle}
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={termsCheck}
                                  onChange={e =>
                                    this.setState({ termsCheck: !termsCheck })
                                  }
                                  value="checked"
                                />
                              }
                            />
                            <a
                              href="https://s3-eu-west-1.amazonaws.com/nmm-v2/terms/GDPR+agreement.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              I accept the terms & conditions
                            </a>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body2">
                              By clicking <strong>Complete Purchase</strong> you
                              agree to be invoiced for the selected products.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!termsCheck || disabled}
                              onClick={e => {
                                this.handleCompletePurchase();
                              }}
                              style={{ textTransform: "inherit" }}
                            >
                              Complete Purchase
                            </Button>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 3 && (
                    <Grid container>
                      <Grid item xs={12}>
                        <List>
                          <ListItem>
                            <Typography variant="body2">
                              Thank you for your purchase.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body2">
                              You will shortly be sent a confirmation email with
                              your next steps.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              href="/tasks?tab=1"
                              style={{ textTransform: "inherit" }}
                            >
                              My Tasks
                            </Button>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
                {activeStep === 0 && (
                <CardContent>
                  <Typography variant="body1" align="center" paragraph>
                    Alternatively, if you would like to use the chat support on
                    our site and link your school MIS to the site with Assembly
                    then get in touch at{" "}
                    <a href="mailto:support@nomoremarking.com">
                      support@nomoremarking.com
                    </a>
                    .
                  </Typography>
                  {oPurchases.length > 0 && (
                    <Typography variant="body1" align="center">
                      If you would like to cancel any existing contracts please
                      email <a href="mailto:support@nomoremarking.com">support@nomoremarking.com</a> stating your DfE number. 
                    </Typography>
                  )}
                  {/*<Grid style={{ padding: "40px" }} id="PoPCosts">
                    <Typography variant="h4" gutterBottom>
                      Proof of Progress Costs
                    </Typography>
                  </Grid>
                  <POPCosts />*/}
                </CardContent>
              )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Subscribe3;
