import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
    Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Typography,
  Checkbox, Fab, Tooltip
} from "@material-ui/core";
import { Star, StarBorder, StarHalf, Check } from '@material-ui/icons'
import ImageZoom from "react-medium-image-zoom";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from './../layout/taskMenu2.js'
import ScansMenu from './../layout/scansMenu.js'

const _ = require('lodash')
// const resemble = require('resemblejs')

class ScansDuplicates extends Component {
    state = {
        taskName: '',
        locked: true,
        isModTask: false,
        useCodes: false,
        duplicates: [],
        loaded: false,
        selection: [],
    }
    componentDidMount() {
        const { match } = this.props;
        const task = match.params.task;
        const promTask = AxiosAll.post(`/tasks/getTask`, { task: task })
        const promDuplicates = AxiosAll.post(`/scans/getDuplicates`, { task: task })
        const promMenu = AxiosAll.post(`/scans/getScanMenu`, { task: task })
        Promise.all([promTask, promDuplicates, promMenu]).then(oResult => {
            console.log('*', oResult)
            const oDuplicates = oResult[1].data
            /*const arrProm = _.map(oDuplicates, (oDuplicate, i) => {
                return _.map(oDuplicate.pages, (oPages, j) => {
                    return _.map(oPages.scan, (oScan, k) => {
                        if (k > 0) {
                            return new Promise(function(resolve, reject) {
                                resemble(oPages.scan[k].full_url).compareTo(oPages.scan[k - 1].full_url).onComplete(oRes => {
                                    oDuplicates[i].pages[j].scan[k].diff = oRes.misMatchPercentage
                                    resolve(oRes.misMatchPercentage)
                                })
                            })

                        }
                        else {
                            return Promise.resolve({})
                        }
                    })
                })
            })
            Promise.all(_.flattenDeep(arrProm))
            .then(oMisMatch => {*/
                this.setState({
                    taskName: oResult[0].data.name,
                    locked: oResult[0].data.locked,
                    isModTask: oResult[0].data.isModTask,
                    useCodes: oResult[0].data.useCodes,
                    // candsWithScans: oResult[0].data.candsWithScans,
                    // scansNeedingFix: oResult[0].data.scansNeedingFix,
                    duplicates: oDuplicates,
                    selection: [],
                    loaded: true,
                    pdfFiles: oResult[2].data.pdfFiles,
                    codesToFix: oResult[2].data.codesToFix,
                    candsWithScans: oResult[2].data.candsWithScans,
                    hasDuplicates: oResult[2].data.hasDuplicates,
                    excludedPages: oResult[2].data.excludedPages,
                })
            //})

        })
    }
    render() {
        const { match } = this.props;
        const task = match.params.task;
        const { taskName, locked, isModTask, useCodes, duplicates, selection, loaded, pdfFiles, codesToFix, candsWithScans, hasDuplicates, excludedPages } = this.state
        if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
        if (isModTask) return <Typography gutterBottom>Not available for moderation tasks</Typography>
        console.log('state', this.state)
        return (
            <Grid container style={{ paddingTop: '146px' }}>
                <Grid item xs={4}>
                    <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} useCodes={useCodes} />
                </Grid>
                <Grid item xs={8}>
                    <ScansMenu tab={3} useCodes={useCodes} pdfFiles={pdfFiles} codesToFix={codesToFix} candsWithScans={candsWithScans} isModTask={isModTask} hasDuplicates={hasDuplicates} excludedPages={excludedPages} />
                    {duplicates.length === 0 && <Typography color="secondary" gutterBottom>
                        No duplicates found
                    </Typography>}
                    {duplicates.length > 0 && <Tooltip title="Select / Deselect All"><Fab size="small" color="secondary" style={{ margin: '0 0 10px 0' }} onClick={e => {
                        const checked = _.includes(selection, duplicates[0].pages[0].scan[0]._id)
                        const allIDs = _.flattenDeep(_.map(duplicates, duplicate => {
                            return _.map(duplicate.pages, page => {
                                return _.map(page.scan, '_id')
                            })
                        }))
                        if (!checked) {
                            this.setState({ selection: allIDs })
                        }
                        else {
                            this.setState({ selection: [] })
                        }
                    }}><StarHalf /></Fab></Tooltip>}
                    {duplicates.length > 0 && <Tooltip title="Do Update">
                        <Fab size="small" color="secondary" style={{ margin: '0 0 10px 20px' }} onClick={e => {
                            AxiosAll.post(`/scans/toggleExcludeImgs`, {
                                task: task,
                                selection: selection,
                            })
                            .then(oRes => {
                                    this.setState({ duplicates: oRes.data })
                            })
                        }}>
                            <Check />
                        </Fab>
                    </Tooltip>}
                    <Typography gutterBottom>A solid starred image is excluded from any judging</Typography>
                    <Typography gutterBottom>The figure in the bottom left of each thumbnail indicates the difference with the previous image.</Typography>
                    {duplicates.map((oCode, i) => { 
                        // console.log('oCode', oCode)
                        return (
                            <div key={`i_${i}`}>
                                {oCode.pages.map((oPage, j) => {
                                    const nItems = oPage.scan.length
                                    const thumbWidth = 200 + 12
                                    // console.log('oPage', oPage)
                                    return (
                                        <div key={`dv_${i}_${j}`}>
                                            <hr style={{ marginBottom: '20px'}} />
                                            <Grid item xs={12}>
                                        {/*<Fab size="small" color="secondary" onClick={e => {
                                            console.log('>', oCode, oPage)
                                            console.log('>>', oPage.scan[0]._id, _.map(oPage.scan, '_id'))
                                            const checked = _.includes(selection, oPage.scan[0]._id)
                                            if (!checked) {
                                                this.setState({ selection: _.union(selection, _.map(oPage.scan, '_id')) })
                                            }
                                            else {
                                                this.setState({ selection: _.difference(selection, _.map(oPage.scan, '_id')) })
                                            }
                                        }} style={{ position: 'absolute', margin: '-10px 0 0 0px' }}><Check style={{ fontSize: '16px' }}  /></Fab>*/}
                                        <Typography color="secondary" style={{ margin: '0 0 15px 0'}}>{oCode._id === '' ? `-` : `${oCode._id} ${oPage.qrpage}`}</Typography>
                                        </Grid>
                                        <GridList key={`gl_${i}_${j}`} style={{ flexWrap: "nowrap", backgroundColor: "#F8F8F8", width: `${nItems * thumbWidth}px`, marginBottom: "20px" }} cols={nItems}>
                                            {oPage.scan.map((oScan, k) => {
                                                // console.log('oScan', oScan)
                                                return (
                                                    <GridListTile 
                                                        
                                                        key={`glt_${i}_${j}_${k}`} style={{ height: "315px" }}>
                                                        <ImageZoom image={{
                                                                src:
                                                                oScan.angle === 0
                                                                ? oScan.thumbnail0
                                                                : oScan.thumbnail180,
                                                            alt: `${oPage.qrcode} ${oPage.qrpage}`,
                                                            style: {
                                                                width: "200px",
                                                                height: "282px",
                                                                border: "1px solid #021a40",
                                                                margin: "5px",
                                                                padding: "5px"
                                                            }
                                                            }}
                                                            zoomImage={{
                                                            src: oScan.full_url,
                                                            alt: `*`,
                                                            }}
                                                        />
                                                        <GridListTileBar
                                                            style={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"}} 
                                                            titlePosition="top" actionIcon={<span style={{ margin: '0 7px 0 10px' }}>{!oScan.exclude ? <StarBorder /> : <Star />}</span>} actionPosition="left" title={<small>{oScan.scanFile} (p{oScan.pdfpage})</small>} />
                                                        <GridListTileBar
                                                            style={{ background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"}}
                                                            title={<small>{!oScan.diff ? '' : oScan.diff}</small>} actionIcon={<Checkbox color="primary" checked={_.includes(selection, oScan._id)} onClick={e => {
                                                            console.log('>', e.shiftKey, e.target.checked, oPage, oScan)
                                                            /*if (e.target.checked && e.shiftKey) {
                                                                console.log('**', oPage, oScan)
                                                            }
                                                            else*/ if (e.target.checked) {
                                                                this.setState(prevState => ({ selection: [...prevState.selection, oScan._id] }))
                                                            }
                                                            else {
                                                                this.setState({ selection: _.filter(selection, id => { return (id !== oScan._id) } )})
                                                            }

                                                        }} />} />
                                                    </GridListTile>
                                                )
                                            })}
                                        </GridList>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
              </Grid>
            </Grid>
        )
    }
}

export default withRouter(ScansDuplicates);