import React, { Component } from "react";
import {
  Paper,
  AppBar,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  Select,
  MenuItem,
  Button,
  Fab,
  Grid,
  Divider,
  List,
  Tooltip,
  IconButton
} from "@material-ui/core";
import {
  Check,
  Clear,
  Add,
  Delete,
  KeyboardArrowDown
} from "@material-ui/icons";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PageTitle from "./../components/pageTitle.js";
import AxiosAll from "./../axiosAll";

const _ = require("lodash");
const moment = require("moment");
const FileSaver = require("file-saver");
const oAcYears = [
  {
    value: moment().year(),
    label: `${moment().year() - 1}-${moment().year()}`
  },
  {
    value: moment().year() + 1,
    label: `${moment().year()}-${moment().year() + 1}`
  }
];
const oCols = [
  { field: "dfe", title: "DfE" },
  { field: "identifier", title: "Candidate" },
  { field: "qrcode", title: "QRCode" },
  { field: "firstName", title: "First Name" },
  { field: "lastName", title: "Last Name" },
  { field: "dob", title: "DOB" },
  { field: "gender", title: "Gender" },
  { field: "group", title: "Group" },
  { field: "PP", title: "Pupil Premium" },
  { field: "EAL", title: "EAL/ESL" },
  { field: "yg", title: "YG/Grade" },
  { field: "scaledScore", title: "Scaled Score" },
  { field: "seScaledScore", title: "Scaled Score SE" },
  { field: "trueScore", title: "True Score" },
  { field: "seTrueScore", title: "True Score SE" },
  { field: "infit", title: "Infit" },
  { field: "score", title: "Score" },
  { field: "propScore", title: "Prop Score" },
  { field: "level", title: "Level" },
  { field: "levelValue", title: "Level Value" },
  { field: "link", title: "Link" },
  { field: "localComparisons", title: "Local Comparisons" },
  { field: "modComparisons", title: "Mod Comparisons" },
  { field: "anchor", title: "Anchor" },
  { field: "moderated", title: "Moderated" },
  { field: "taskName", title: "Task Name" },
  { field: "observedScore", title: "Observed Score" },
  { field: "losses", title: "Losses" },
  { field: "anchorScore", title: "Anchor Score" },
  { field: "fromTaskName", title: "From Task" }
];

class Trust extends Component {
  state = {
    loaded: false,
    trusts: [],
    XeroContacts: [],
    XeroInvoices: [],
    contacts: [],
    discountCodes: [],
    taskTypes: [],
    purchases: [],
    tasks: [],
    selected: null,
    tab: 0,
    taskYG: null,
    taskAcYear: moment().year(),
    oYGs: [],
    errorMsg: "",
    emailVerified: false,
    msgEmailVerifySent: false,
  };
  componentDidMount() {
    AxiosAll.post(`/trust/getTrustsForUser`, {}).then(oRes => {
      const trusts = oRes.data;
      if (trusts.emailVerified === false) {
        this.setState({ emailVerified: false, loaded: true });
      } else if (trusts.length === 1) {
        AxiosAll.post(`/trust/getTrust`, { trust: trusts[0]._id, product: trusts[0].product }).then(
          oRes => {
            const oYGs = _.uniq(_.map(oRes.data.tasks, "yearGroup")).sort();
            this.setState({
              loaded: true,
              trust: oRes.data,
              product: trusts[0].product,
              trusts: trusts,
              selected: 0,
              purchases: oRes.data.purchases,
              XeroContacts: oRes.data.XeroContacts,
              XeroInvoices: oRes.data.XeroInvoices,
              contacts: oRes.data.contacts,
              discountCodes: oRes.data.discountCodes,
              taskTypes: oRes.data.oTTs,
              tasks: oRes.data.tasks,
              oYGs: oYGs,
              taskYG: oYGs.length > 0 ? oYGs[0] : null,
              emailVerified: true
            });
          }
        );
      } else {
        this.setState({
          loaded: true,
          trusts: trusts,
          emailVerified: true
        });
      }
    });
  }
  render() {
    const {
      loaded,
      XeroContacts,
      XeroInvoices,
      trust,
      contacts,
      discountCodes,
      taskTypes,
      purchases,
      tasks,
      trusts,
      selected,
      tab,
      taskYG,
      taskAcYear,
      oYGs,
      errorMsg,
      emailVerified, msgEmailVerifySent, product
    } = this.state;
    if (!loaded)
      return (
        <Grid style={{ paddingTop: "146px" }} container>
          <Typography gutterBottom>Loading...</Typography>
        </Grid>
      );
    if (!emailVerified) {
      return (
        <Grid style={{ paddingTop: "146px" }} container>
          <Grid item xs={12} style={{ padding: '15px' }}>
            <Typography gutterBottom>
              Your email account has not been verified, you must verify before
              you can view this page.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: '15px' }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                console.log("send verify email");
                AxiosAll.post(`/accounts/sendVerifyEmail`, {})
                .then(oRes => {
                  console.log('oRes', oRes.data)
                  this.setState({ msgEmailVerifySent: true })
                })
              }}
              disabled={msgEmailVerifySent}
              style={{ textTransform: 'inherit' }}
            >
              Send Verify Email
            </Button>
          </Grid>
          {msgEmailVerifySent && (
            <Grid item xs={12} style={{ padding: '15px' }}>
              <Typography gutterBottom>Verification Email Sent.&nbsp; Please click on the link inside the email to confirm your email address.</Typography>
            </Grid>
          )}
        </Grid>
      );
    }
    const re = taskAcYear
      ? RegExp(`${taskAcYear - 1}-${taskAcYear}`)
      : RegExp(oAcYears[0].label);
    const oFilteredTTs = _.filter(taskTypes, oTT => {
      if (oYGs.length === 0) return false;
      return oTT.yearGroup === (taskYG || oYGs[0].value) && re.test(oTT.name);
    });
    const arrSyllabus = _.map(oFilteredTTs, "_id");
    const oFilteredTasks = _.filter(tasks, oTask => {
      return _.indexOf(arrSyllabus, oTask.syllabus) > -1;
    });
    console.log("state", this.state);
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
        <Grid item xs={12} style={{ padding: "15px" }}>
          <PageTitle content="Trust Summary" />
          {trusts.length === 0 && (
            <Grid container>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <strong>This page is designed for Trust use only.</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Your email address is not currently part of any Trust, you can
                  ask your Trust coordinator to add you.&nbsp; If you have any
                  queries please email{" "}
                  <a href="mailto:support@nomoremarking.com">
                    support@nomoremarking.com
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          )}
          {!trust && trusts.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                <ReactTable
                  data={trusts}
                  columns={[
                    {
                      Header: "Trust Name",
                      accessor: "trustName",
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                          {row.original.trustName}
                        </div>
                      )
                    },
                    {
                      Header: "Product",
                      accessor: "productNameLong",
                      Cell: row => (
                        <div style={{ flex: 0, textAlign: "center" }}>
                          {row.original.productNameLong}
                        </div>
                      )
                    }
                  ]}
                  minRows={0}
                  showPagination={false}
                  getTrProps={(state, rowInfo) => {
                    return {
                      onMouseOver: e => {
                        if (selected !== rowInfo.index) {
                          this.setState({ selected: rowInfo.index });
                        }
                      },
                      onMouseOut: e => {
                        if (selected !== null) {
                          this.setState({ selected: null });
                        }
                      },
                      onClick: e => {
                        console.log("click", trusts[rowInfo.index]);
                        AxiosAll.post(`/trust/getTrust`, {
                          trust: trusts[rowInfo.index]._id,
                          product: product,
                        }).then(oTrust => {
                          const oYGs = _.uniq(
                            _.map(oTrust.data.tasks, "yearGroup")
                          ).sort();
                          this.setState({
                            trust: trusts[rowInfo.index]._id,
                            product: trusts[rowInfo.index].product,
                            XeroContacts: oTrust.data.XeroContacts,
                            XeroInvoices: oTrust.data.XeroInvoices,
                            contacts: oTrust.data.contacts,
                            discountCodes: oTrust.data.discountCodes,
                            taskTypes: oTrust.data.oTTs,
                            purchases: oTrust.data.purchases,
                            tasks: oTrust.data.tasks,
                            oYGs: oYGs,
                            taskYG: oYGs.length > 0 ? oYGs[0] : null
                          });
                        });
                      },
                      style: {
                        background:
                          rowInfo.index === selected ? "#313D4F" : "white",
                        color: rowInfo.index === selected ? "white" : "#313D4F"
                      }
                    };
                  }}
                />
              </Grid>
            </Grid>
          )}
          {!!trust && selected !== null && (
            <Grid container>
              <Grid item xs={12}>
                <Typography color="secondary" gutterBottom>
                  {trusts[selected].trustName} (
                  {trusts[selected].productNameLong})
                </Typography>
                <Paper elevation={4}>
                  <AppBar position="static">
                    <Tabs
                      value={tab}
                      onChange={(e, v) => {
                        this.setState({ tab: v });
                      }}
                    >
                      <Tab label="Schools" />
                      <Tab label="Contacts" />
                      <Tab label="Discount Codes" />
                      <Tab label="Tasks" />
                      <Tab label="Invoicing" />
                    </Tabs>
                  </AppBar>
                  {tab === 0 && (
                    <Grid item xs={12} style={{ padding: "15px" }}>
                      <Typography>
                        Alter DfEs/NCESs on the left to update schools in Trust
                      </Typography>
                      <ReactTable
                        data={purchases}
                        columns={[
                          {
                            Header: "DfE / NCES",
                            accessor: "dfe",
                            width: 130,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.dfe}
                              </div>
                            )
                          },
                          {
                            Header: "Disc. %",
                            accessor: "discount",
                            width: 70,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.discount}
                              </div>
                            )
                          },
                          /*{
                            Header: "Product",
                            accessor: "product",
                            width: 80,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {(row.original.Product || {}).productName ||
                                  "-"}
                              </div>
                            )
                          },*/
                          {
                            Header: "School Name",
                            accessor: "School.schoolName",
                            Cell: row => (
                              <div
                                style={{
                                  flex: 0,
                                  textAlign: "center",
                                  whiteSpace: "normal"
                                }}
                              >
                                {row.original.School.schoolName}
                              </div>
                            )
                          },
                          {
                            Header: "Super User",
                            accessor: "UP.email",
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {_.has(row.original.UP, "email") ? (
                                  <a href={`mailto:${row.original.UP.email}`}>
                                    {row.original.UP.email}
                                  </a>
                                ) : (
                                  <span>-</span>
                                )}
                              </div>
                            )
                          },
                          {
                            Header: "Year Groups",
                            accessor: "ygs",
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {(row.original.ygs || []).join(", ")}
                              </div>
                            )
                          },
                          {
                            Header: (
                              <span>
                                Signed
                                <br />
                                up?
                              </span>
                            ),
                            accessor: "included",
                            width: 60,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.included ? <Check /> : <Clear />}
                              </div>
                            )
                          }
                        ]}
                        minRows={0}
                        showPagination={true}
                        style={{ marginTop: "20px" }}
                      />
                    </Grid>
                  )}
                  {tab === 1 && (
                    <Grid item xs={12} style={{ padding: "15px" }}>
                      <Typography gutterBottom>
                        Users below will be added as school users giving access
                        to all school tasks.
                      </Typography>
                      <Paper elevation={4}>
                        <Typography style={{ padding: "15px 0 0 15px" }}>
                          Add Contact
                        </Typography>
                        {errorMsg !== "" && (
                          <Typography
                            style={{ padding: "15px 0 0 15px" }}
                            color="secondary"
                          >
                            {errorMsg}
                          </Typography>
                        )}
                        <Formik
                          initialValues={{ newEmail: "" }}
                          onSubmit={(
                            values,
                            { setSubmitting, setFieldValue }
                          ) => {
                            console.log("add email submit", values);
                            setSubmitting(true);
                            AxiosAll.post(`/trust/addEmailToTrust`, {
                              trust: trusts[selected]._id,
                              email: values.newEmail
                            }).then(oTrust => {
                              this.setState({
                                contacts: oTrust.data.contacts,
                                purchases: oTrust.data.purchases,
                                tasks: oTrust.data.tasks,
                                taskYG: oYGs.length > 0 ? oYGs[0] : null
                              });
                              setSubmitting(false);
                              setFieldValue("newEmail", "");
                            });
                          }}
                          validationSchema={Yup.object().shape({
                            newEmail: Yup.string()
                              .email("Valid email required")
                              .notOneOf(
                                _.map(contacts, "email"),
                                "Must not be a current contact"
                              )
                              .required("Required")
                          })}
                        >
                          {({ values, errors, isSubmitting }) => (
                            <Form>
                              <FormikTextField
                                name="newEmail"
                                label="Email Address"
                                style={{ margin: "15px", width: "250px" }}
                              />
                              <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ margin: "30px 0 15px 15px" }}
                                disabled={isSubmitting}
                              >
                                <Add />
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Paper>
                      <ReactTable
                        data={contacts}
                        columns={[
                          {
                            Header: "Email",
                            accessor: "email",
                            //width: 240,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                <a href={`mailto:${row.original.email}`}>
                                  {row.original.email}
                                </a>
                              </div>
                            )
                          },
                          {
                            Header: "Name",
                            accessor: "personFirstName",
                            //width: 200,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.personFirstName}{" "}
                                {row.original.personLastName}
                              </div>
                            )
                          },
                          {
                            Header: "Position",
                            accessor: "personJobTitle",
                            //width: 200,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.personJobTitle}
                              </div>
                            )
                          },
                          {
                            Header: "Phone",
                            accessor: "personPhone",
                            //width: 200,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.personPhone}
                              </div>
                            )
                          },
                          {
                            Header: (
                              <span>
                                Has
                                <br />
                                Account?
                              </span>
                            ),
                            accessor: "hasAccount",
                            width: 80,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.hasAccount === false ? (
                                  <Clear />
                                ) : (
                                  <Check />
                                )}
                              </div>
                            )
                          },
                          {
                            Header: "",
                            accessor: "_id",
                            width: 60,
                            Cell: row => (
                              <Fab
                                size="small"
                                color="secondary"
                                disabled={
                                  localStorage.getItem("email") ===
                                    row.original.email ||
                                  _.indexOf(
                                    _.map(
                                      _.map(purchases, "School"),
                                      "superUser"
                                    ),
                                    row.original.user
                                  ) > -1
                                }
                                onClick={() => {
                                  const r = window.confirm(
                                    `Delete Contact ${row.original.email}?`
                                  );
                                  if (!r) return;
                                  console.log("del contact", row.original._id);
                                  AxiosAll.post(`/trust/removeEmailFromTrust`, {
                                    trust: trusts[selected]._id,
                                    email: row.original.email
                                  }).then(oTrust => {
                                    const oYGs = _.uniq(
                                      _.map(oTrust.data.tasks, "yearGroup")
                                    ).sort();
                                    this.setState({
                                      contacts: oTrust.data.contacts,
                                      purchases: oTrust.data.purchases,
                                      tasks: oTrust.data.tasks,
                                      oYGs: oYGs,
                                      taskYG: oYGs.length > 0 ? oYGs[0] : null
                                    });
                                  });
                                }}
                              >
                                <Delete />
                              </Fab>
                            )
                          }
                        ]}
                        minRows={0}
                        showPagination={true}
                        style={{ marginTop: "20px" }}
                      />
                    </Grid>
                  )}
                  {tab === 2 && (
                    <Grid item xs={12} style={{ padding: "15px" }}>
                      <ReactTable
                        data={discountCodes}
                        columns={[
                          {
                            Header: "Code",
                            accessor: "code",
                            width: 200,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.code}
                              </div>
                            )
                          },
                          {
                            Header: "Discount %",
                            accessor: "discount",
                            width: 120,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.discount}
                              </div>
                            )
                          },
                          {
                            Header: "Label",
                            accessor: "label",
                            width: 100,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.label}
                              </div>
                            )
                          }
                        ]}
                        minRows={0}
                        showPagination={true}
                        style={{ marginTop: "20px" }}
                      />
                    </Grid>
                  )}
                  {tab === 3 && (
                    <Grid item xs={12} style={{ padding: "15px" }}>
                      <Grid container>
                        <Grid item xs={5} style={{ padding: "10px" }}>
                          <Select
                            value={taskYG}
                            onChange={(e, v) =>
                              this.setState({ taskYG: v.props.value })
                            }
                            fullWidth
                          >
                            {oYGs.map((v, i) => (
                              <MenuItem
                                key={`yg_${i}`}
                                value={v}
                              >{`Y${v}`}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={5} style={{ padding: "10px" }}>
                          <Select
                            value={taskAcYear}
                            onChange={(e, v) =>
                              this.setState({ taskAcYear: v.props.value })
                            }
                            fullWidth
                          >
                            {_.map(oAcYears, (v, i) => (
                              <MenuItem
                                key={`taskAcYear_${v.value}`}
                                value={v.value}
                              >
                                {v.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1} style={{ padding: "10px" }}>
                          <Tooltip title="Candidates download">
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                AxiosAll.post(`/trust/getCandidateDownload`, {
                                  tasks: _.map(oFilteredTasks, "_id")
                                }).then(oResult => {
                                  const arrCols = _.map(oCols, "field");
                                  //let oResult = JSON.parse(JSON.stringify(oData));
                                  oResult = _.map(oResult.data, function(oRow) {
                                    oRow.PP = oRow.PP ? "Y" : "N";
                                    oRow.EAL = oRow.EAL ? "Y" : "N";
                                    oRow.anchor = oRow.anchor ? "Y" : "N";
                                    oRow.moderated = oRow.moderated ? "Y" : "N";
                                    _.each(arrCols, function(attr) {
                                      if (!_.has(oRow, attr)) oRow[attr] = "*";
                                      if (
                                        _.includes(
                                          ["firstName", "lastName", "group"],
                                          attr
                                        ) &&
                                        _.has(oRow, attr)
                                      )
                                        oRow[attr] = oRow[attr].replace(
                                          /,/,
                                          "."
                                        );
                                    });
                                    oRow.link = `${window.location.origin}/display/${oRow._id}`;
                                    return oRow;
                                  });
                                  oResult = _.map(oResult, function(oRow) {
                                    return _.values(_.pick(oRow, arrCols)).join(
                                      ","
                                    );
                                  });
                                  const arrTitles = _.map(oCols, "title");
                                  oResult.unshift(arrTitles.join(","));
                                  const blob = new Blob([oResult.join("\n")], {
                                    type: "text/plain;charset=utf-8"
                                  });
                                  FileSaver.saveAs(blob, "trustCandidates.csv");
                                });
                              }}
                            >
                              <KeyboardArrowDown />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <ReactTable
                        data={oFilteredTasks}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "name",
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.name}
                              </div>
                            )
                          },
                          {
                            Header: "Candidates",
                            accessor: "_candidates",
                            width: 100,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original._candidates}
                              </div>
                            )
                          },
                          {
                            Header: <span>Generated</span>,
                            accessor: "_genScripts",
                            width: 90,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original._genScripts}
                              </div>
                            )
                          },
                          {
                            Header: <span>Uploaded</span>,
                            accessor: "_scanPages",
                            width: 80,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original._scanPages}
                              </div>
                            )
                          },
                          {
                            Header: "Judges",
                            accessor: "_judges",
                            width: 80,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original._judges}
                              </div>
                            )
                          },
                          {
                            Header: (
                              <span>
                                Judging
                                <br />%
                              </span>
                            ),
                            accessor: "_quota",
                            width: 80,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original._quota === 0
                                  ? "-"
                                  : `${Math.round(
                                      (row.original._judgements /
                                        row.original._quota) *
                                        100
                                    )}%`}
                              </div>
                            )
                          },
                          {
                            Header: " ",
                            accessor: "_id",
                            width: 60,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    AxiosAll.post(
                                      `/trust/getCandidateDownload`,
                                      { tasks: [row.original._id] }
                                    ).then(oResult => {
                                      const arrCols = _.map(oCols, "field");
                                      //let oResult = JSON.parse(JSON.stringify(oData));
                                      oResult = _.map(
                                        _.orderBy(
                                          oResult.data,
                                          ["dfe", "lastName", "firstName"],
                                          ["asc", "asc", "asc"]
                                        ),
                                        function(oRow) {
                                          oRow.PP = oRow.PP ? "Y" : "N";
                                          oRow.EAL = oRow.EAL ? "Y" : "N";
                                          oRow.anchor = oRow.anchor ? "Y" : "N";
                                          oRow.moderated = oRow.moderated
                                            ? "Y"
                                            : "N";
                                          _.each(arrCols, function(attr) {
                                            if (!_.has(oRow, attr))
                                              oRow[attr] = "*";
                                            if (
                                              _.includes(
                                                [
                                                  "firstName",
                                                  "lastName",
                                                  "group"
                                                ],
                                                attr
                                              ) &&
                                              _.has(oRow, attr)
                                            )
                                              oRow[attr] = oRow[attr].replace(
                                                /,/,
                                                "."
                                              );
                                          });
                                          oRow.link = `${window.location.origin}/display/${oRow._id}`;
                                          return oRow;
                                        }
                                      );
                                      oResult = _.map(oResult, function(oRow) {
                                        return _.values(
                                          _.pick(oRow, arrCols)
                                        ).join(",");
                                      });
                                      const arrTitles = _.map(oCols, "title");
                                      oResult.unshift(arrTitles.join(","));
                                      const blob = new Blob(
                                        [oResult.join("\n")],
                                        {
                                          type: "text/plain;charset=utf-8"
                                        }
                                      );
                                      FileSaver.saveAs(
                                        blob,
                                        "schoolCandidates.csv"
                                      );
                                    });
                                  }}
                                >
                                  <KeyboardArrowDown />
                                </IconButton>
                              </div>
                            )
                          }
                          /*{
                    Header: 'Quota',
                    accessor: '_quota',
                    width: 80,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original._quota}
                      </div>
                    )
                  },
                  {
                    Header: 'Judgements',
                    accessor: '_judgements',
                    width: 80,
                    Cell: row => (
                      <div style={{ flex: 0, textAlign: "center" }}>
                        {row.original._judgements}
                      </div>
                    )
                  }*/
                        ]}
                        minRows={0}
                        showPagination={true}
                        style={{ marginTop: "20px" }}
                      />
                    </Grid>
                  )}
                  {tab === 4 && (
                    <Grid item xs={12} style={{ padding: "15px" }}>
                      <Typography>Info from Xero</Typography>
                      <Typography style={{ margin: "20px 0 0 0" }} variant="h6">
                        Contacts
                      </Typography>
                      {XeroContacts.length === 0 && (
                        <Typography>No Contacts</Typography>
                      )}
                      {XeroContacts.map(contact => (
                        <Paper key={`xeroContact_${contact._id}`} elevation={4}>
                          <List
                            key={`contacts_${contact._id}`}
                            subheader={
                              <ListSubheader>
                                {`${contact.FirstName} ${contact.LastName} `} (
                                <a href={`mailto:${contact.EmailAddress}`}>
                                  {contact.EmailAddress}
                                </a>
                                )
                              </ListSubheader>
                            }
                          >
                            <Divider />
                            {contact.Addresses.map((address, j) => (
                              <ListItem key={`add_${contact._id}_${j}`} divider>
                                <ListItemText
                                  primary={address.AddressType}
                                  secondary={_.compact(
                                    _.values(_.omit(address, "AddressType"))
                                  ).join(", ")}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      ))}

                      <Typography style={{ margin: "20px 0 0 0" }} variant="h6">
                        Invoices
                      </Typography>
                      <ReactTable
                        data={XeroInvoices}
                        columns={[
                          {
                            Header: "No",
                            accessor: "InvoiceNumber",
                            width: 90,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.InvoiceNumber}
                              </div>
                            )
                          },
                          {
                            Header: "Raised",
                            accessor: "DateString",
                            width: 110,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.DateString.substring(0, 10)}
                              </div>
                            )
                          },
                          {
                            Header: "Due Date",
                            accessor: "DueDateString",
                            width: 110,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.DueDateString.substring(0, 10)}
                              </div>
                            )
                          },
                          {
                            Header: "Due Amount",
                            accessor: "AmountDue",
                            width: 110,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                £{row.original.AmountDue}
                              </div>
                            )
                          },
                          {
                            Header: "Paid Amount",
                            accessor: "AmountPaid",
                            width: 110,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                £{row.original.AmountPaid}
                              </div>
                            )
                          },
                          {
                            Header: "Status",
                            accessor: "Status",
                            width: 80,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.Status}
                              </div>
                            )
                          },
                          {
                            Header: "Payments",
                            accessor: "Payments",
                            // width: 180,
                            Cell: row => (
                              <div style={{ flex: 0, textAlign: "center" }}>
                                {row.original.Payments.map((payment, i) => (
                                  <div key={`key_${payment.PaymentID}`}>
                                    {`£${payment.Amount} (${moment(
                                      parseFloat(
                                        payment.Date.replace(
                                          "/Date(",
                                          ""
                                        ).replace(")/", "")
                                      )
                                    ).format("YYYY-MM-DD")})`}
                                  </div>
                                ))}
                              </div>
                            )
                          }
                        ]}
                        minRows={0}
                        showPagination={true}
                      />
                    </Grid>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default Trust;
