import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Divider, Typography } from "@material-ui/core";
import { Card, CardContent } from "@material-ui/core";
import PageTitle from "./../components/pageTitle.js";
import "react-table/react-table.css";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from './../layout/taskMenu2.js'

// *** Add files with public access to nmm-v2/syllabus/<i>syllabus id</i>/] ***
const _ = require("lodash");

class TestMaterials extends Component {
  state = {
    files: [],
    taskName: '',
    locked: false,
    isModTask: false,
    loaded: false,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getTask`, { task: task }).then(oTask => {
      console.log('oTask', oTask.data)
      const syllabus = oTask.data.syllabus;
      if (syllabus) {
        AxiosAll.post(`/aws/getSyllabusFiles`, { syllabus: syllabus })
        .then(oFiles => {
          console.log('oFiles', oFiles.data)
          this.setState({
            files: oFiles.data,
            taskName: oTask.data.name,
            locked: oTask.data.locked,
            isModTask: oTask.data.isModTask,
            loaded: true,
          });
        });
      } else {
        this.setState({
          loaded: true,
          taskName: oTask.data.name,
          locked: oTask.data.locked,
          isModTask: oTask.data.isModTask,
        });
      }
    });
  }
  render() {
    const { files, loaded, taskName, locked, isModTask } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    // console.log("files", files);
    return (
      <Grid container style={{ margin: '0 15px 15px 15px', paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} />
        </Grid>
        <Grid item xs={8}>
          <PageTitle content="Test Materials" />
          <Card>
            {_.map(files, function(filename, i) {
              return (
                <div key={`file_${i}`}>
                  <CardContent>
                    <a
                      href={`https://s3-eu-west-1.amazonaws.com/nmm-v2/syllabus/${filename}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {filename.split("/")[1]}
                    </a>
                  </CardContent>
                  <Divider />
                </div>
              );
            })}
            {files.length === 0 && (
              <div>
                <CardContent>No Files available</CardContent>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(TestMaterials);
