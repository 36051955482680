import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Hidden
} from "@material-ui/core";
import CookieConsent from "react-cookie-consent";
import auth from "./../Auth.js";

class HomeMob extends Component {
  render() {
    return (
      <Hidden smUp>
        <CookieConsent
          location="bottom"
          style={{ padding: "0 30px 0 0", justifyContent: "space-evenly" }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
          <Grid
            item
            style={{
              position: "relative",
              backgroundSize: "contain",
              width: "100%",
              height: "150px",
              backgroundImage: "url(/assets/home-banner-hand-pencil-mob.jpg)",
              backgroundRepeat: "no-repeat"
            }}
          >
            <Typography
              style={{
                position: "absolute",
                fontSize: "23px",
                left: "40px",
                top: "20px",
                color: "#af1280"
              }}
            >
              Comparative Judgement
            </Typography>
            <Typography
              style={{
                position: "absolute",
                fontSize: "23px",
                left: "40px",
                top: "60px",
                color: "#3f1f71"
              }}
            >
              Simplifying assessment for schools
            </Typography>
          </Grid>

          <Card
            style={{
              backgroundColor: "#efefef",
              margin: "20px 10% 20px 10%"
            }}
          >
            <CardHeader
              style={{
                backgroundImage:
                  "linear-gradient(to left, #3f1f71 0%, #461e72 35%, #af1280), linear-gradient(to bottom, #3f1f71, #3f1f71)"
              }}
              title={
                <Typography
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    color: "#ffffff"
                  }}
                >
                  The Issue
                </Typography>
              }
            />
            <CardContent>
              <Typography
                gutterBottom
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#af1280",
                  lineHeight: 1.17
                }}
              >
                Traditional marking and moderation are time-consuming and
                inaccurate
              </Typography>
              <Typography
                gutterBottom
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#363636"
                }}
              >
                Marking work accurately is difficult.&nbsp; Moderators
                standardise the marks, but it’s hard to review more than a tiny
                fraction of scripts in a typical moderation session, and even
                agreeing on those is not easy.&nbsp; And the whole process is
                very time-consuming.
              </Typography>
              <Typography
                gutterBottom
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#3f1f71"
                }}
              >
                There is another way...
              </Typography>
            </CardContent>
          </Card>

          <Card style={{
              backgroundColor: "#efefef",
              margin: "20px 10% 20px 10%"
            }}>
            <CardHeader
              style={{
                backgroundImage:
                  "linear-gradient(to left, #3f1f71 0%, #461e72 35%, #af1280), linear-gradient(to bottom, #3f1f71, #3f1f71)"
              }}
              title={
                <Typography
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    color: "#ffffff"
                  }}
                >
                  The Issue
                </Typography>
              }
            />
            <CardContent>
              <Typography
                gutterBottom
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#af1280",
                  lineHeight: 1.17
                }}
              >
                Comparative Judgement assesses open-ended work more reliably
                than traditional marking
              </Typography>
              <Typography
                gutterBottom
                style={{
                  // fontFamily: "Source Sans Pro",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#363636"
                }}
              >
                Comparative judgement is a process where judges compare two
                responses and decide which is better.&nbsp; Following repeated
                comparisons, the resulting data is statistically modelled and
                responses placed on a scale of relative quality.
              </Typography>
              <Typography
                gutterBottom
                style={{
                  // fontFamily: "Source Sans Pro",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#3f1f71"
                }}
              >
                Research has shown the process to be as reliable as double
                marking, but much quicker.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                style={{
                  margin: '0 40px 0 40px',
                  //left: "92px",
                  //width: "145px",
                  height: "40px",
                  backgroundColor: "#3f1f71",
                  color: "#ffffff",
                  textTransform: "inherit"
                  // fontFamily: "Source Sans Pro"
                }}
                fullWidth
                component={NavLink}
                to={`/demo2`}
              >
                Try the Demo
              </Button>
            </CardActions>
          </Card>

          <Card style={{
              backgroundColor: "#efefef",
              margin: "20px 10% 20px 10%"
            }}>
            <CardHeader
              style={{
                backgroundImage:
                  "linear-gradient(to left, #3f1f71 0%, #461e72 35%, #af1280), linear-gradient(to bottom, #3f1f71, #3f1f71)"
              }}
              title={
                <Typography
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    color: "#ffffff"
                  }}
                >
                  The Issue
                </Typography>
              }
            />

            <CardContent>
              <Typography
                gutterBottom
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "19px",
                  fontWeight: "600",
                  color: "#3f1f71",
                  lineHeight: 1.17,
                  letterSpacing: "normal",
                  textAlign: "center"
                }}
              >
                Join the thousands of schools who are already enjoying the
                benefits of judging.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                style={{
                  margin: '0 40px 0 40px',
                  //left: "92px",
                  //width: "145px",
                  height: "40px",
                  backgroundColor: "#3f1f71",
                  color: "#ffffff",
                  textTransform: "inherit",
                  fontFamily: "Source Sans Pro"
                }}
                fullWidth
                onClick={() => {
                  auth.login("subscribe2");
                }}
              >
                Sign up
              </Button>
            </CardActions>
          </Card>

          <Card style={{
              backgroundColor: "#efefef",
              margin: "20px 0 20px 0"
            }}>
              <CardContent>
          <Typography
                  style={{
                    //position: "absolute",
                    //fontFamily: "Source Sans Pro",
                    //top: "42px",
                    fontSize: "21px",
                    color: "#af1280",
                    textAlign: "center",
                    padding: '20px',
                    //width: "1180px"
                  }}
                >
                  Using Comparative Judgement to provide a quicker, more
                  reliable method of assessment
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    //position: "absolute",
                    //left: "517px",
                    //top: "90px",
                    //width: "145px",
                    height: "40px",
                    backgroundColor: "#3f1f71",
                    color: "#ffffff",
                    textTransform: "inherit",
                    //fontFamily: "Source Sans Pro"
                  }}
                  fullWidth
                  onClick={() => {
                    auth.login("subscribe2");
                  }}
                >
                  Sign up
                </Button>
                </CardContent>
          </Card>

        </Grid>
      </Hidden>
    );
  }
}

export default HomeMob;
