import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
AppBar, Tabs, Tab, Badge
  } from "@material-ui/core";
  import PageTitle from "./../components/pageTitle.js";
//  import AxiosAll from "./../axiosAll";

const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
  ));

  class ScansMenu extends Component {
    /*state = {
        useCodes: false,
        pdfFiles: 0,
        codesToFix: 0,
        candsWithScans: 0,
        loaded: false,
    }
    componentDidMount() {
        const { match } = this.props;
        const task = match.params.task;
        AxiosAll.post(`/scans/getScanMenu`, { task: task })
        .then(oRes => {
            console.log('scans menu mount')
            this.setState({
                useCodes: oRes.data.useCodes,
                pdfFiles: oRes.data.pdfFiles,
                codesToFix: oRes.data.codesToFix,
                candsWithScans: oRes.data.candsWithScans,
                loaded: true,
            })
        })
    }*/
    render() {
        const { match, useCodes, pdfFiles, codesToFix, candsWithScans, isModTask, hasDuplicates, excludedPages } = this.props;
        // const { loaded, useCodes, pdfFiles, codesToFix, candsWithScans } = this.state;
        // if (!loaded) return <span />
        const task = match.params.task;
        const { tab } = this.props
        console.log('scans menu props', this.props)
        return (
            <div>
                <PageTitle content="Scans" />
                    <AppBar position="static" style={{ marginBottom: '20px' }}>
                    <Tabs value={tab} onChange={(e, value) => {
                        this.setState({
                            tab: value,
                        })
                    }}>
                        <Tab
                            label={<Badge variant="dot" invisible={pdfFiles === 0} color="secondary" style={{ padding: '6px' }}>Uploads</Badge>}
                            style={{ textTransform: "inherit" }}
                            component={ForwardNavLink} to={`/scansUploads/${task}`}
                        />
                        <Tab
                            label={<Badge variant="dot" invisible={codesToFix === 0} color="secondary" style={{ padding: '6px' }}>Fix Codes</Badge>}
                            style={{ textTransform: "inherit" }}
                            disabled={!useCodes || isModTask}
                            component={ForwardNavLink} to={`/scansFixCodes/${task}`}
                        />
                        <Tab
                            label={<Badge variant="dot" invisible={candsWithScans === 0} color="secondary" style={{ padding: '6px' }}>Thumbnails</Badge>}
                            style={{ textTransform: "inherit" }}
                            disabled={isModTask}
                            component={ForwardNavLink} to={`/scansThumbnails/${task}`}
                        />
                        <Tab
                            label={<Badge variant="dot" invisible={!hasDuplicates} color="secondary" style={{ padding: '6px' }}>Duplicates</Badge>}
                            style={{ textTransform: "inherit" }}
                            disabled={isModTask}
                            component={ForwardNavLink} to={`/scansDuplicates/${task}`}
                        />
                        <Tab
                            label="Included"
                            style={{ textTransform: "inherit" }}
                            disabled={isModTask}
                            component={ForwardNavLink} to={`/scansBlanks/${task}`}
                        />
                        <Tab
                            label={<Badge variant="dot" invisible={!excludedPages} color="secondary" style={{ padding: '6px' }}>Excluded</Badge>}
                            style={{ textTransform: "inherit" }}
                            disabled={isModTask}
                            component={ForwardNavLink} to={`/scansExcluded/${task}`}
                        />
                    </Tabs>
                </AppBar>
            </div>
        )
    }
  }

  export default withRouter(ScansMenu);


