import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { ArrowDropDownCircle } from "@material-ui/icons";
import AxiosAll from "./../axiosAll";
const FileSaver = require("file-saver");

const _ = require("lodash");

class Monitoring extends Component {
  state = {
    data: [],
    loaded: false
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getModMonitoring`, { task: task }).then(oRes => {
      this.setState({
        data: oRes.data,
        loaded: true
      });
    });
  }
  render() {
    const { loaded, data } = this.state;
    if (!loaded) return <span />;
    console.log("state", this.state);
    return (
      <Grid container>
        <Grid item xs={12} style={{ padding: "10px" }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{
              xmargin: "20px 0 0 0",
              textTransform: "inherit",
              float: "right"
            }}
            onClick={() => {
              const oCols = [
                { field: "dfe", title: "DfE" },
                { field: "schoolName", title: "School Name" },
                { field: 'taskName', title: 'Task Name' },
                { field: 'genScripts', title: 'Generated Scripts' },
                { field: "candidatesUploaded", title: "Candidates Uploaded" },
                { field: "candidatesTotal", title: "Total Candidates" },
                { field: "totalComparisons", title: "Judgements Made" },
                { field: "totalQuota", title: "Judgements Quota" }
              ];
              const arrCols = _.map(oCols, "field");
              let oResult = JSON.parse(JSON.stringify(data));
              oResult = _.map(oResult, function(oRow) {
                return {
                  dfe: oRow.dfe,
                  schoolName: (oRow.School || {}).schoolName || "-",
                  taskName: oRow.name,
                  genScripts: oRow.genScripts === 1 ? 'Y' : 'N',
                  candidatesUploaded: oRow.candidateCounts.true || 0,
                  candidatesTotal:
                    (oRow.candidateCounts.true || 0) +
                    (oRow.candidateCounts.false || 0),
                  totalComparisons: oRow.totalComparisons,
                  totalQuota: oRow.totalQuota
                };
              });
              oResult = _.map(oResult, function(oRow) {
                return _.values(_.pick(oRow, arrCols)).join(",");
              });
              const arrTitles = _.map(oCols, "title");
              oResult.unshift(arrTitles.join(","));
              const blob = new Blob([oResult.join("\n")], {
                type: "text/plain;charset=utf-8"
              });
              FileSaver.saveAs(blob, "candidates.csv");
            }}
          >
            <ArrowDropDownCircle /> Download
          </Button>
        </Grid>
        <Grid item xs={12} style={{ padding: "10px" }}>
          <Paper elevation={4}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">&nbsp;</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Percentage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Local Tasks Connected</TableCell>
                  <TableCell align="center">{data.length}</TableCell>
                  <TableCell align="center" />
                </TableRow>
                <TableRow>
                  <TableCell align="center">Generated at least one PDF</TableCell>
                  <TableCell align="center">{_.sum(_.map(data, 'genScripts'))}</TableCell>
                  <TableCell align="center">{Math.round(_.sum(_.map(data, 'genScripts')) / data.length * 100)}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Uploaded at least one PDF</TableCell>
                  <TableCell align="center">
                    {_.compact(_.map(data, "candidateCounts.true")).length}
                  </TableCell>
                  <TableCell align="center">
                    {Math.round(
                      (_.compact(_.map(data, "candidateCounts.true")).length /
                        data.length) *
                        100
                    )}
                    %
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Made at least one Judgement</TableCell>
                  <TableCell align="center">
                    {_.filter(data, oTask => oTask.comparisons > 0).length}
                  </TableCell>
                  <TableCell align="center">
                    {Math.round(
                      (_.filter(data, oTask => oTask.comparisons > 0).length /
                        data.length) *
                        100
                    )}
                    %
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Monitoring);
