import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Avatar,
  Button,
  Select,
  MenuItem,
  Typography
} from "@material-ui/core";
import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import Papa from "papaparse";
import {
  Input,
  InputLabel,
  FormHelperText,
  FormControl
} from "@material-ui/core";
import {
  CloudUpload,
  Help,
  ArrowDropDownCircle,
  Menu,
  Cancel
} from "@material-ui/icons";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";

const _ = require("lodash");
const moment = require("moment");
const arrOptional = ["QRCode", "PP", "EAL"];


class UploadCandidatesCSV extends Component {
  state = {
    dialogOpen: false,
    msg: <span />,
    candidates: [],
    arrFileCols: [],
    Candidate: "",
    QRCode: "",
    First_Name: "",
    Last_Name: "",
    DOB: "",
    Gender: "",
    Group: "",
    PP: "",
    EAL: "",
    Year_Group: "",
    useCodes: false,
    loaded: false,
    disabled: false,
    country: '',
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getTask`, {
      task: task
    }).then(oTask => {
        this.setState({
          loaded: true,
          useCodes: oTask.data.useCodes,
          country: oTask.data.country,
        });
    });
  }
  handleFileChange = event => {
    const self = this;
    const file = event.target.files[0];
    Papa.parse(file, {
      delimiter: true,
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      encoding: 'UTF-8',
      complete: function(oResults, file) {
        console.log("oResults", oResults);
        let arrFileCols = [];
        if (oResults.errors.length) {
          self.setState({
            msg: <Alert type="error" text="Error in file" />
          });
        } else if (oResults.data.length === 0) {
          self.setState({
            msg: <Alert type="error" text="Empty file" />
          });
        } else {
          arrFileCols = _.keys(oResults.data[0]);
          let newState = {
            arrFileCols: arrFileCols,
            dialogOpen: true,
            candidates: oResults.data
          };

          _.each(
            [
              "Candidate",
              "QRCode",
              "First_Name",
              "Last_Name",
              "DOB",
              "Gender",
              "Group",
              "PP",
              "EAL",
              "ESL",
              "Year_Group",
              "Grade",
            ],
            function(column) {
              if (_.includes(arrFileCols, column)) newState[column] = column;
            }
          );
          console.log('newState', newState)
          if (!_.has(newState, 'Year_Group') && newState.Grade !== '') {
            newState.Year_Group = newState.Grade
          }
          if (!_.has(newState, 'EAL') && newState.ESL !== '') {
            newState.EAL = newState.ESL
          }
          // console.log("newState", newState);
          self.setState(newState);
        }
      }
    });
  };
  handleChangeCol = (column, e) => {
    this.setState({ [column]: e.target.value });
  };
  handleColNamesSubmit = () => {
    const self = this;
    const { candidates, country } = this.state;
    const { match } = this.props;
    const task = match.params.task;
    self.setState({ disabled: true })
    console.log("handleColNamesSubmit", this.state, candidates, country);
    let oCandidates = _.map(candidates, function(oCand) {
      const oNewCand = {};
      _.each(
        [
          "Candidate",
          "QRCode",
          "First_Name",
          "Last_Name",
          "DOB",
          "Gender",
          "Group",
          "PP",
          "Year_Group",
          "Grade",
          "EAL",
          "ESL",
        ],
        function(column) {
          oNewCand[column] = oCand[self.state[column]];
        }
      );
      return oNewCand;
    });
    let row = 1;
    let oCandidates2 = [];
    _.each(oCandidates, function(oCand) {
      row++;
      let flag = "N";
      let dob = (oCand.DOB || "").trim();
      let eal = (oCand.EAL || oCand.ESL)
      console.log('oCand', oCand)
      if (_.has(oCand, "PP")) flag = (oCand.PP || "").trim().toUpperCase();
      else if (_.has(oCand, "PPI"))
        flag = (oCand.PPI || "").trim().toUpperCase();
      if (oCand.First_Name !== "" || oCand.Last_Name !== "")
        oCandidates2.push({
          row: row,
          Candidate: (oCand.Candidate || "").trim(),
          firstName: (oCand.First_Name || "").trim(),
          lastName: (oCand.Last_Name || "").trim(),
          dob: moment(dob, "DD/MM/YYYY", true).isValid()
            ? moment(dob, "DD/MM/YYYY").format("YYYY-MM-DD")
            : dob,
          gender: (
            oCand.Gender.replace(/^Male$/i, "M").replace(/^Female$/i, "F") || ""
          )
            .trim()
            .toUpperCase(),
          yg: (_.has(oCand, 'Year_Group') ? parseInt(oCand.Year_Group, 10) : parseInt(oCand.Grade, 10)), // (country === 'GB' ? parseInt(oCand.Year_Group, 10) : parseInt(oCand.Grade, 10)),
          group: (oCand.Group || "").trim(),
          PP: flag.replace(/^Yes$/i, "Y").replace(/^No$/i, "N") === "Y",
          EAL: (eal || '').toUpperCase() === 'Y', // (country === 'GB' ? (oCand.EAL || "").toUpperCase() === "Y" : (oCand.ESL || "").toUpperCase() === "Y"),
          qrcode: oCand.QRCode || ""
        });
    });
    console.log("oCandidates2", oCandidates2);
    const arrValidationInfo = doValidation(oCandidates2, country);
    if (arrValidationInfo.length) {
      self.setState({
        msg: <Alert text={arrValidationInfo.join(",")} type="error" />,
        dialogOpen: false
      });
    } else {
      // console.log("TO ADD", oCandidates2);
      AxiosAll.post(`/candidates/createCandidates`, {
        task: task,
        candidates: oCandidates2
      }).then(oResult => {
        // console.log("*", oCandidates2, task);
        self.setState({
          msg: (
            <Alert
              type="success"
              text="File Successfully uploaded, click on 'View' to see candidates"
            />
          ),
          dialogOpen: false,
          disabled: false,
        });
      });
    }
  };
  getErrorStatus = column => {
    if (_.includes(arrOptional, column)) {
      return false;
    }
    return this.state[column] === "";
  };
  render() {
    const self = this;
    console.log("state", self.state);
    const { dialogOpen, arrFileCols, useCodes, loaded, disabled } = this.state;
    if (!loaded) return <span />;
    let errors = 0;
    // console.log('CSV country', country)
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: "10px" }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <CloudUpload />
                    </Avatar>
                  }
                  title="File Import"
                  subheader="Upload candidate details"
                />
                <Divider />
                <CardContent>
                  {self.state.msg}
                  {!useCodes && (
                    <Typography color="secondary">
                      Only available for with Codes tasks
                    </Typography>
                  )}
                  <FormControl>
                    <InputLabel htmlFor="idUploadCSV">File</InputLabel>
                    <Input
                      id="idUploadCSV"
                      type="file"
                      disabled={!useCodes}
                      onChange={e => {
                        self.handleFileChange(e);
                      }}
                    />
                    <FormHelperText>Select a CSV file</FormHelperText>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Help />
                    </Avatar>
                  }
                  title="Help"
                />
                <Divider />
                <CardContent>Files must be in CSV format</CardContent>
                <Divider />
                <CardContent>
                  File must contain all the columns described in the file format
                  below
                </CardContent>
                <Divider />
                <CardContent>
                  The first row must have header information
                </CardContent>
                <Divider />
                <CardContent>
                  PDFs will be generated in the same order that the file is
                  provided
                </CardContent>
                <Divider />
                {/*<CardContent>
                  Re-uploading will remove the old copy of the data - printed
                  QRs will not be able to be matched back
                </CardContent>*/}
                <Divider />
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/csv_Template.csv"
                    target="_blank"
                    style={{ textTransform: "inherit" }}
                  >
                    <ArrowDropDownCircle /> UK Template File
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/csv_TemplateUSA.csv"
                    target="_blank"
                    style={{ textTransform: "inherit" }}
                  >
                    <ArrowDropDownCircle /> USA Template File
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "20px" }}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Menu />
                </Avatar>
              }
              title="File Format"
            />
            <Divider />
            <CardContent>
              <Table padding="dense" style={{ width: '100%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Information</strong>
                    </TableCell>
                    <TableCell>
                      Candidate
                      <br />
                      Identifier
                    </TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Year Group / Grade</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Pupil Premium</TableCell>
                    <TableCell>English as an Additional Language / English as a Second Language</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Column Title</strong>
                    </TableCell>
                    <TableCell>Candidate</TableCell>
                    <TableCell>First_Name</TableCell>
                    <TableCell>Last_Name</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Year_Group / Grade</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>PP</TableCell>
                    <TableCell>EAL / ESL</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Validation Information</strong>
                    </TableCell>
                    <TableCell>Up to 50 characters (Optional)</TableCell>
                    <TableCell>Up to 50 characters</TableCell>
                    <TableCell>Up to 50 characters</TableCell>
                    <TableCell>yyyy-mm-dd or dd/mm/yyyy</TableCell>
                    <TableCell>M / F</TableCell>
                    <TableCell>0 - 13</TableCell>
                    <TableCell>Up to 50 characters (Optional)</TableCell>
                    <TableCell>Y / N (Optional)</TableCell>
                    <TableCell>Y / N (Optional)</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Dialog open={dialogOpen}>
          <DialogTitle>
            Upload CSV
            <Button
              mini
              variant="fab"
              style={{ float: "right" }}
              onClick={e => {
                self.setState({
                  dialogOpen: false
                });
              }}
            >
              <Cancel />
            </Button>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <p>
              Ensure the corresponding column from the uploaded file is selected
              for each required field
            </p>
            <Table>
              <TableBody>
                <Grid container xspacing={24}>
                  <Grid item xs={12} sm={6}>
                    {_.map([
                        "Candidate",
                        "First_Name",
                        "Gender",
                        "Group",
                        "Year_Group"
                      ],
                      function(column, i) {
                        const error = self.getErrorStatus(column);
                        if (error) errors++;
                        return (
                          <TableRow key={`row_${i}`}>
                            <TableCell
                              style={{ paddingLeft: 0, borderBottom: 0 }}
                            >
                              {column.replace(/Year_Group/, 'Year_Group/Grade')}
                            </TableCell>
                            <TableCell
                              style={{ paddingLeft: 0, borderBottom: 0 }}
                            >
                              <FormControl>
                                <Select
                                  error={error}
                                  value={self.state[column]}
                                  inputProps={{ name: column, id: column }}
                                  fullWidth
                                  onChange={e => {
                                    self.handleChangeCol(column, e);
                                  }}
                                >
                                  {_.map(arrFileCols, function(csvCol, j) {
                                    return (
                                      <MenuItem
                                        key={`mi_${i}_${j}`}
                                        value={csvCol}
                                      >
                                        {csvCol}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {_.map(["QRCode", "Last_Name", "DOB", "PP", "EAL"],
                      function(column, i) {
                        const error = self.getErrorStatus(column);
                        if (error) errors++;
                        return (
                          <TableRow key={`row_${i}`}>
                            <TableCell
                              style={{ paddingLeft: 0, borderBottom: 0 }}
                            >
                              {column.replace(/EAL/, 'EAL/ESL')}
                            </TableCell>
                            <TableCell
                              style={{ paddingLeft: 0, borderBottom: 0 }}
                            >
                              <FormControl
                                required={!_.includes(arrOptional, column)}
                              >
                                <Select
                                  error={error}
                                  value={(self.state[column] || '').replace(
                                    /^$/,
                                    _.includes(arrOptional, column)
                                      ? "None"
                                      : ""
                                  )}
                                  inputProps={{ name: column, id: column }}
                                  fullWidth
                                  onChange={e => {
                                    self.handleChangeCol(column, e);
                                  }}
                                >
                                  {_.map(
                                    _.union(
                                      _.includes(arrOptional, column)
                                        ? ["None"]
                                        : [],
                                      arrFileCols
                                    ),
                                    function(csvCol, j) {
                                      return (
                                        <MenuItem
                                          key={`mi2_${i}_${j}`}
                                          value={csvCol}
                                        >
                                          {csvCol}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={errors > 0 || disabled}
              onClick={e => self.handleColNamesSubmit()}
              style={{ textTransform: 'inherit' }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withRouter(UploadCandidatesCSV);

function doValidation(oCandidates, country) {
  let arrErrors = [];

  // Validate QR Codes
  const arrDuplicates = _(_.map(oCandidates, "qrcode"))
    .groupBy()
    .pickBy(x => x.length > 1)
    .keys()
    .value();
  if (_.compact(arrDuplicates).length > 0)
    arrErrors.push(
      `QR Codes must be unique, duplicates: ${arrDuplicates.join(",")}`
    );
  // console.log("Duplicates", arrDuplicates);
  _.each(oCandidates, function(oCand) {
    if (!!oCand.qrcode) {
      if (!/[A-Z0-9]{6}/.test(oCand.qrcode)) {
        arrErrors.push(`Invalid QR Code: ${oCand.qrcode}`);
      }
    }
  });

  // Look for Blanks
  _.each(["First_Name", "Last_Name", "DOB", "Gender"], function(attr) {
    const oFil = _.filter(oCandidates, function(oCand) {
      return oCand[attr] === "";
    });
    let arrRows = _.map(oFil, "row");
    if (arrRows.length) {
      // console.log(">", attr, oFil, arrRows, oCandidates);
      arrErrors.push(`${attr} cannot be empty Row(s): ${arrRows.join(",")}`);
    }
  });

  // Validate DOB
  let arrRows = [];
  const oNoDobsCands = _.filter(oCandidates, function(o) {
    return o.dob !== "";
  });
  _.each(oNoDobsCands, function(oNoDobsCand, i) {
    if (moment(oNoDobsCand.dob.trim(), "YYYY-MM-DD", true).isValid() === false || moment(oNoDobsCand.dob.trim(), "DD/MM/YYYY", true).isValid())
      arrRows.push(oNoDobsCand.row);
  });
  if (arrRows.length)
    arrErrors.push(
      `Date of Birth is in wrong format, please change to YYYY-MM-DD or DD/MM/YYYY in Row(s): ${arrRows.join(
        ","
      )}`
    );

  // Valid Entries
  const oChecks = [
    { attr: "gender", valid: ["M", "F", ""] },
    { attr: "yg", valid: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] }
  ]
  _.each(oChecks, function(oCheck) {
    const oFil = _.filter(oCandidates, function(oCand) {
      console.log('oCand', oCand, oCheck, _.indexOf(oCheck.valid, oCand[oCheck.attr]))
      return _.indexOf(oCheck.valid, oCand[oCheck.attr]) === -1;
    });
    const arrRows = _.map(oFil, "row");
    if (arrRows.length)
      arrErrors.push(
        `Invalid entries for ${oCheck.attr
          .replace("Flag", "Pupil Premium")
          .replace("yg", "Year Group")} in row(s): ${arrRows.join(",")}`
      );
  });

  return arrErrors;
}
