import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
// import "typeface-roboto";
import {
  Grid,
  Button,
  Divider,
  Chip,
  Tabs,
  Tab,
  AppBar,
  Typography
} from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
// import { styles } from "./../layout/styles.js";
// import { withStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PageTitle from "./../components/pageTitle.js";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";
import queryString from 'query-string'

const _ = require("lodash");
const moment = require("moment");

class MyTasks extends Component {
  constructor(props) {
    super(props);
    if (localStorage.hasOwnProperty("email")) {
      window.Intercom("update", {
        app_id: "zodtosc9",
        email: localStorage.getItem("email"),
        created_at: moment().unix()
      });
    }
  }
  state = {
    selected: null,
    dialogOpen: false,
    tab: 0,

    loaded: false,
    tasks: [],
    purchases: 0,
    assemblyBearer: ""
  };
  componentDidMount() {
    const qs = queryString.parse(this.props.location.search)
    const promTasks = AxiosAll.post(`/tasks/getTasks`);
    const promPurchases = AxiosAll.post("/purchases/getPurchases");
    Promise.all([promTasks, promPurchases]).then(oResults => {
      const oTasks = oResults[0].data;
      const oSchool = oResults[1].data.length ? oResults[1].data[0] : {};
      console.log('componentDidMount', oResults)
      /*if (_.has(oTasks, "error")) {
        localStorage.clear();
        this.props.history.push(`/`);
      }*/
      let set = {
        loaded: true,
        tasks: oTasks,
        purchases: (oSchool.purchases || []).length,
        assemblyBearer: oSchool.assemblyBearer
      }
      if (_.has(qs, 'tab')) {
        if (!isNaN(qs.tab)) {
          set.tab = parseInt(qs.tab, 10)
        }
      }

      this.setState(set);
    });
  }
  handleTabChange = (e, value) => {
    this.setState({ tab: value });
  };
  render() {
    const self = this;
    const {
      selected,
      dialogOpen,
      tab,
      loaded,
      tasks,
      purchases,
      assemblyBearer,
      disabled
    } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    const columns = [
      {
        Header: "Name",
        accessor: "name",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", cursor: 'pointer' }}>
            {row.original.name}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Candidates",
        accessor: "_candidates",
        width: 100,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", cursor: 'pointer' }}>
            {row.original._candidates}
          </div>
        ),
        filterable: false
      },
      {
        Header: "Judges",
        accessor: "_judges",
        width: 100,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", cursor: 'pointer' }}>
            {row.original._judges}
          </div>
        ),
        filterable: false
      },
      /*{
        Header: "Local Judgements",
        accessor: "_localJudgements",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original._localJudgements && row.original._quota
              ? `${row.original._judgements} / ${row.original._quota}`
              : "-"}
          </div>
        ),
        filterable: false
      },*/
      {
        Header: "Reliability",
        accessor: "mle",
        width: 100,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", cursor: 'pointer' }}>
            {row.original.mle
              ? row.original.mle.toFixed(2)
              : "-"}
          </div>
        ),
        filterable: false
      },
      {
        Header: "Tags",
        accessor: "_id",
        Cell: row => {
          return (
            <span>
              {row.original.yearGroup && (
                <Chip
                  label={`Y/G${row.original.yearGroup}`}
                  style={{ backgroundColor: row.original.ygColour, cursor: 'pointer' }}
                />
              )}
              {row.original.acYear && (
                <Chip
                  label={row.original.acYear}
                  style={{ backgroundColor: row.original.acYearColour, cursor: 'pointer' }}
                />
              )}
              {row.original.productName && (
                <Chip
                  label={row.original.productName}
                  style={{ backgroundColor: row.original.productColour, cursor: 'pointer' }}
                />
              )}
            </span>
          );
        },
        filterable: false,
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        width: 120,
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center", cursor: 'pointer' }}>
            {row.original.createdAt.split(' ')[0]}
          </div>
        ),
        filterable: false
      }
    ];
    let oFilteredTasks = tasks || [];
    const currentWeek = parseInt(moment().format("ww"), 10);
    console.log('currentWeek', currentWeek)
    const thisAcYear = moment().month() > 6 ? `${moment().year()}-${moment().year() + 1}` : `${moment().year() - 1}-${moment().year()}`
    console.log('this acYear', thisAcYear)
    switch (tab) {
      case 0:
        oFilteredTasks = _.filter(oFilteredTasks, function(o) {
          return !!o.yearGroup && _.includes(o.activeWeeks || [], currentWeek) && o.name.indexOf(thisAcYear) > -1;
        });
        break;
      case 1:
        oFilteredTasks = _.filter(oFilteredTasks, function(o) {
          return !!o.yearGroup && (!_.includes(o.activeWeeks || [], currentWeek) || o.name.indexOf(thisAcYear) === -1);
        });
        break;
      case 2:
        oFilteredTasks = _.filter(oFilteredTasks, function(o) {
          return !o.yearGroup;
        });
        break;
      default:
        break;
    }
    console.log('state', this.state)
    return (
      <Grid container style={{ paddingTop: '146px' }}>
        <Grid item xs={12} style={{ margin: "20px" }}>
          <PageTitle content="My Tasks" />
          <Grid item xs={12} style={{ margin: "20px" }}>
            {purchases > 0 && (assemblyBearer || "") === "" && (
              <Alert
                text={
                  <span>
                    You are currently not connected to
                    Assembly&nbsp;&nbsp;&nbsp;
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "inherit" }}
                      onClick={e => {
                        self.setState({ dialogOpen: true });
                      }}
                    >
                      More
                    </Button>
                  </span>
                }
                type="error"
              />
            )}
            {purchases === 0 && (
              <Alert
                text={
                  <span>
                    You are currently not subscribed to any products, click{" "}
                    <NavLink to="/subscribe2" style={{ color: "inherit" }}>
                      Subscribe
                    </NavLink>{" "}
                    to continue
                  </span>
                }
                type="info"
              />
            )}
            <div style={{ flexGrow: 1, margin: "15px 0px 15px 0px" }}>
              <AppBar position="static">
                <Tabs value={tab} onChange={this.handleTabChange}>
                  <Tab
                    label="Current National Tasks"
                    style={{ textTransform: "inherit" }}
                  />
                  <Tab
                    label="Other National Tasks"
                    style={{ textTransform: "inherit" }}
                  />
                  <Tab
                    label="Custom Tasks"
                    style={{ textTransform: "inherit" }}
                  />
                  <Tab label="All Tasks" style={{ textTransform: "inherit" }} />
                  <Typography
                    style={{ flex: 1 }}
                  />
                  <Tab
                    label="Create a Custom Task"
                    style={{ textTransform: "inherit" }}
                    disabled={disabled}
                    onClick={e => {
                      self.setState({ disabled: true });
                      // AxiosTasks.post('/createTask')
                      AxiosAll.post(`/tasks/createTask`).then(oNewTask => {
                        this.props.history.push(
                          `/settings2/${oNewTask.data._id}`
                        );
                      });
                    }}
                  />
                </Tabs>
              </AppBar>
            </div>
            <ReactTable defaultPageSize={50}
              data={oFilteredTasks}
              filterable
              columns={columns}
              minRows={0}
              showPagination={true}
              style={{ margin: '0 0 15px 0' }}
              getTrProps={(state, rowInfo) => {
                return {
                  onMouseOver: e => {
                    if (selected !== rowInfo.index) {
                      self.setState({ selected: rowInfo.index });
                    }
                  },
                  onMouseOut: e => {
                    if (selected !== null) {
                      self.setState({ selected: null });
                    }
                  },
                  onClick: e => {
                    this.props.history.push(
                      `/candidates/${oFilteredTasks[rowInfo.index]._id}`, { taskName: 'xx'}
                    );
                  },
                  style: {
                    background:
                      rowInfo.index === selected ? "#313D4F" : "white",
                    color: rowInfo.index === selected ? "white" : "#313D4F"
                  }
                };
              }}
            />
          </Grid>
        </Grid>
        <Dialog open={dialogOpen}>
          <DialogTitle>
            Connect to Assembly
            <Button
              mini
              variant="fab"
              style={{ float: "right", marginTop: "-12px" }}
              onClick={e => {
                self.setState({ dialogOpen: false });
              }}
            >
              <Cancel />
            </Button>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <p>
              To automatically populate No More Marking with the students from
              your school, we'll first need someone from your IT team to connect
              your MIS to the Assembly Platform.&nbsp; They will need to:
            </p>
            <ul>
              <li>
                <a
                  href="https://auth.assembly.education/schools/sign_up"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign the school up to the Assembly Platform
                </a>{" "}
                with invite code: <strong>nomoremarking2017</strong>
              </li>
              <li>
                Configure the connection to your school's{" "}
                <a
                  href="http://help.assembly.education/article/29-installing-the-assembly-connector-sims"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIMS
                </a>
                ,{" "}
                <a
                  href="http://help.assembly.education/article/36-how-to-install-the-assembly-connector-cmis"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facility CMIS
                </a>
                ,{" "}
                <a
                  href="http://help.assembly.education/article/65-connecting-bromcom-to-assembly"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bromcom
                </a>{" "}
                or{" "}
                <a
                  href="http://help.assembly.education/article/66-connecting-scholarpack-to-assembly"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ScholarPack
                </a>{" "}
                by following the appropriate guide on the{" "}
                <a
                  href="http://help.assembly.education/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Assembly help site
                </a>
              </li>
              <li>
                Finally{" "}
                <a
                  href="http://help.assembly.education/article/41-how-to-authorise-an-app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  authorise the No More Marking app
                </a>{" "}
                on the Assembly Platform
              </li>
            </ul>
            <p>
              Please{" "}
              <a
                href="https://assembly.education/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact Assembly
              </a>{" "}
              for support regarding connecting to Assembly.
            </p>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

export default (withRouter(MyTasks));
