import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  CardContent,
  Button,
  IconButton, Tooltip, Dialog, DialogContent
} from "@material-ui/core";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import PageTitle from "./../components/pageTitle.js";
import { VideoLabel } from "@material-ui/icons";

class Events2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  state = {
    isOpen: false,
    video: null,
  }
  render() {
    const { isOpen, video } = this.state
    const { classes } = this.props;
    return (
      <Grid
        container
        style={{ flexGrow: 1, margin: "0 0 20px 0", paddingTop: "146px" }}
      >
        <PageTitle
          title="Training Events"
          img="/assets/training-events-banner.jpg"
        />

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Grid container justify="center">
            <Grid
              container
              justify="center"
              alignItems="stretch"
              style={{ width: "1180px" }}
            >
              <Grid item style={{ width: "328px", margin: "0 10px 0 10px" }}>
                <Card style={{ backgroundColor: "#efefef" }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography className={classes.topBannerHeading}>
                        Knowledge Base
                      </Typography>
                    }
                  />
                  <CardMedia
                    image="/assets/knowledge-base_2.jpg"
                    style={{ width: "328px", height: "155px" }}
                  />
                  <CardContent style={{ height: "340px" }}>
                    <Typography
                      gutterBottom
                      style={{
                        // fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
                      You don't need any formal training to get started with
                      comparative judgement!&nbsp; If you want to set up a free
                      account or take part in one of our paid-for national
                      projects, you can use our{" "}
                      <a
                        href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Knowledge Base
                      </a>{" "}
                      to find out everything you need to know.&nbsp; You can
                      also email our helpdesk,{" "}
                      <a href="mailto:support@nomoremarking.com">
                        support@nomoremarking.com
                      </a>
                      , if you get stuck.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "92px",
                        width: "145px",
                        height: "40px",
                        bottom: "20px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit"
                        // fontFamily: "Source Sans Pro"
                      }}
                      href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354"
                      target="_blank"
                    >
                      Knowledge Base
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xclassName={classes.Rectangle}
                style={{ width: "328px", margin: "0 10px 0 10px" }}
              >
                <Card style={{ backgroundColor: "#efefef" }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{}}
                      >
                        Free Webinars
                      </Typography>
                    }
                  />
                  <CardMedia
                    image="/assets/webinar.jpg"
                    style={{ width: "328px", height: "155px" }}
                  />
                  <CardContent style={{ height: "340px" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
                      The webinars are suitable if you want to use the free
                      account or if you want to subscribe to a national
                      project.{/*&nbsp; We offer two different webinar sessions:*/}
                    </Typography>
                    <Grid justify="space-between" container>
        <Grid item style={{ maxWidth: '75%'}}>
                    <Typography
                      gutterBottom
                      style={{
                        // fontFamily: "Source Sans Pro",
                        fontSize: "18px",
                        // fontWeight: "600",
                        color: "#af1280"
                      }}
                    >
                       Introduction to Comparative Judgement (1 hour)
                    </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="View most recent video"><IconButton size="small" href="https://www.notion.so/nmm/An-Introduction-to-Comparative-Judgement-Primary-July-1st-2019-80555513e7aa4daf844bf39146826d8d" target="_blank"><VideoLabel /></IconButton></Tooltip>
                    {/*<Tooltip title="View most recent video"><IconButton size="small" onClick={() => this.setState({ isOpen: true, video: '345507824'})}><VideoLabel /></IconButton></Tooltip>*/}
                    </Grid>
                    </Grid>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
                      Judge some essays, see the live results, and learn more
                      about how national projects work.
                    </Typography>
                    <Grid justify="space-between" container>
                    <Grid item style={{ maxWidth: '75%'}}>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "18px",
                        color: "#af1280"
                      }}
                    >
                      Getting started with No More Marking (1 hour)
                    </Typography>
                    </Grid>
                    <Grid item style={{ maxWidth: '75%'}}>
                    <Tooltip title="View most recent video"><IconButton size="small" href="https://www.notion.so/No-More-Marking-website-basic-intro-mini-video-2ac60477d28e478e95a45d5f5da6bd60" target="_blank"><VideoLabel  /></IconButton></Tooltip>
                    </Grid>
                    </Grid>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        color: "#363636"
                      }}
                    >
                      This takes you through the steps of setting up your task, taking part in national judging and getting your results.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "92px",
                        width: "145px",
                        height: "40px",
                        bottom: "20px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      href="https://www.notion.so/nmm/Upcoming-events-and-training-opportunities-c7dcae3a2e414caba34929f35df77070"
                      target="_blank"
                    >
                      See here for dates
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xclassName={classes.Rectangle}
                style={{ width: "328px", margin: "0 10px 0 10px" }}
              >
                <Card style={{ backgroundColor: "#efefef" }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{}}
                      >
                        Primary Writing Hubs
                      </Typography>
                    }
                  />
                  <CardMedia
                    image="/assets/writting-hubs.jpg"
                    style={{ width: "328px", height: "155px" }}
                  />
                  <CardContent style={{ height: "340px" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
                      One of the main uses of comparative judgement is to assess
                      primary writing.&nbsp; In our Primary Writing Hubs, we
                      explore primary writing in more depth, look at examples of
                      writing drawn from across our 500+ participating schools,
                      and look at best practice and evidence-based strategies
                      for improving writing.&nbsp; These are designed for more
                      experienced users of comparative judgement, but beginners
                      with an interest in primary writing will also find them
                      useful.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
                      <a
                        href="https://www.notion.so/nmm/Upcoming-events-and-training-opportunities-c7dcae3a2e414caba34929f35df77070"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See here for dates
                      </a>{" "}
                      or if you are an APW subscriber,{" "}
                      <a href="mailto:support@nomoremarking.com">contact us</a>{" "}
                      for more information.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "92px",
                        width: "145px",
                        height: "40px",
                        bottom: "20px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      href="https://www.notion.so/nmm/Upcoming-events-and-training-opportunities-c7dcae3a2e414caba34929f35df77070"
                      target="_blank"
                    >
                      See here for dates
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={isOpen} onClose={() => this.setState({ isOpen: false })} fullWidth>
                <DialogContent>
            <iframe title="Judging" src={`https://player.vimeo.com/video/${video}?title=0&byline=0&portrait=0`} width="560px" height="420px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </DialogContent>
            </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(Events2);
