import React, { Component } from "react";
import { Grid, SnackbarContent } from "@material-ui/core";
import { Error, CheckCircle, Info } from "@material-ui/icons";

const _ = require("lodash");

class Alert extends Component {
  render() {
    const { text, type } = this.props;
    const oTypes = [
      {
        type: "error",
        backgroundColor: '#af1280', // "#C92C36",
        icon: <Error style={{ fontSize: "20px", marginRight: "10px" }} />
      },
      {
        type: "success",
        backgroundColor: "#4FA244",
        icon: <CheckCircle style={{ fontSize: "20px", marginRight: "10px" }} />
      },
      {
        type: "info",
        backgroundColor: "#3D71D2",
        icon: <Info style={{ fontSize: "20px", marginRight: "10px" }} />
      }
    ];

    const oType = _.find(oTypes, { type: type });
    if (!oType) return <p>Type not found</p>;
    return (
      <Grid item xs={12}>
        <Grid container justify="center" style={{ margin: "5px 0 5px 0" }}>
          <SnackbarContent
            style={{ backgroundColor: oType.backgroundColor }}
            message={
              <span style={{ display: "flex", alignItems: "center" }}>
                {oType.icon}
                {text}
              </span>
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default Alert;
