import React, { Component } from "react";
import {
  Grid,
  AppBar,
  Paper,
  Button,
  Typography,
  Tabs,
  Tab,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Webcam from "react-webcam";
import * as jsPDF from "jspdf";
import { SizeMe } from "react-sizeme";
import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import request from "superagent";

const _ = require("lodash");

class Uploads extends Component {
  state = {
    tab: 0,
    task: "",
    yourEmail: "",
    showSection: 0,
    loaded: false,
    capturedImage: "",
    capturedImageAR: 1,
    disabled: false,
    error: ""
  };
  componentDidMount() {
    const { task } = this.props.match.params;
    this.setState({
      task: task || "",
      loaded: true
    });
  }
  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = () => {
    const self = this;
    const imageSrc = this.webcam.getScreenshot();
    console.log("imageSrc", imageSrc);
    var i = new Image();
    i.onload = function() {
      console.log("ar", i.width, i.height);
      self.setState({
        capturedImage: imageSrc,
        capturedImageAR: i.width / i.height
      });
    };
    i.src = imageSrc;
  };
  handleFileChange = (event) => {
    const { task, yourEmail, disabled } = this.state;
    const file = event.target.files[0]
    if (file.size > 20 * 1024 * 1024) {
      this.setState({
        error: `File ${file.name} is too large (max. file size is 20MBs)`,
        disabled: false,
      });
    } else if (!/.jpg$/i.test(file.name)) {
      this.setState({
          error: `File ${file.name} is not a jpg file`,
          disabled: false,
      });
    } else {
      this.setState({ disabled: disabled, loaded: false })
      request
        .post(`https://api.cloudinary.com/v1_1/no-more-marking-ltd/image/upload`)
        .field("upload_preset", "tqgw3b0n")
        .field("file", file)
        .field("multiple", false)
        .end((error, oResponse) => {
          console.log('oResponse', oResponse)
          const {
            public_id,
            format,
          } = oResponse.body;
          if (!_.includes(['jpg'], format)) {
            this.setState({ error: `Invalid file type (${format})`, disabled: false, loaded: false })
          }
          else {
            console.log('post', { public_id: public_id, task: task, user: yourEmail })
            AxiosAll.post(`/scans/uploadDirectImg`, {
              public_id: public_id, task: task, user: yourEmail,
            })
            .then(oRes => {
              console.log('oRes', oRes)
              this.setState({
                error: oRes.data.error,
                disabled: false,
                showSection: 2,
                loaded: true
              })
              console.log('oRes', oRes)
            })
          }
        })
    }
  }
  render() {
    console.log(">", this.state, this.props);
    const {
      task,
      tab,
      showSection,
      loaded,
      capturedImage,
      capturedImageAR,
      yourEmail,
      disabled,
      error
    } = this.state;
    if (!loaded)
      return (
        <Typography color="primary" style={{ margin: "20px" }}>
          Loading ...
        </Typography>
      );
    return (
      <Grid container>
        <Grid item md={6} style={{ padding: '15px', width: '100%' }}>
          <Card>
            <CardContent>
              <Typography gutterBottom>
                Use this page to upload or take a photo of your response.
              </Typography>
            </CardContent>
            <Divider />
            <List>
              {task !== "" && (
                <ListItem>
                  <ListItemText primary="Task ID" secondary={task} />
                </ListItem>
              )}
              {yourEmail !== "" && (
                <ListItem>
                  <ListItemText primary="Your Email" secondary={yourEmail} />
                </ListItem>
              )}
            </List>
          </Card>
        </Grid>
        <Grid item md={6} style={{ padding: '15px', width: '100%' }}>
          {showSection === 0 && (
            <Card>
              <CardContent>
                <Typography gutterBottom>Please enter your details</Typography>
                <Formik
                  initialValues={{
                    task: task,
                    yourEmail: ""
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("submitted", values);
                    this.setState({
                      task: values.task,
                      yourEmail: values.yourEmail,
                      showSection: 1
                    });
                  }}
                  validationSchema={Yup.object().shape({
                    task: Yup.string()
                      .min(36)
                      .max(36)
                      .required("Required"),
                    yourEmail: Yup.string()
                      .email("Valid email required")
                      .required("Required")
                  })}
                >
                  {({ isSubmitting, values, errors, dirty }) => (
                    <Form>
                      <FormikTextField
                        name="task"
                        label="Task Identifier"
                        helperText="Enter the identifier provided by your task leader"
                        fullWidth
                      />
                      <FormikTextField
                        type="email"
                        name="yourEmail"
                        label="Your Email"
                        fullWidth
                      />
                      <Button
                        size="mini"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || disabled}
                        style={{ marginTop: "20px", textTransform: "inherit" }}
                      >
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          )}
          {showSection === 1 && (
            <Paper>
              <AppBar position="static" style={{ backgroundColor: "#2e3e4f" }}>
                <Tabs
                  value={tab}
                  onChange={(e, tab) => {
                    this.setState({ tab: tab });
                  }}
                >
                  <Tab label="Photo" />
                  <Tab label="Upload" />
                </Tabs>
              </AppBar>
              {tab === 0 && (
                <SizeMe>
                  {({ size }) => {
                    console.log("size", size);
                    if (capturedImage !== "")
                      return (
                        <Grid item xs={12} style={{ padding: "15px" }}>
                          <Typography gutterBottom>
                            Do you want to use this image?
                          </Typography>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ margin: "10px", textTransform: "inherit" }}
                            disabled={disabled}
                            onClick={e => {
                              var doc = new jsPDF({ orientation: "portrait" });
                              doc.addImage(
                                capturedImage,
                                "JPEG",
                                10,
                                10,
                                190,
                                190 / capturedImageAR
                              );
                              // doc.save('xx.pdf')
                              const imgData = doc.output("dataurl");
                              console.log("imgData", imgData.length, imgData);
                              this.setState({
                                disabled: true,
                                loaded: false
                              });
                              AxiosAll.post(`/scans/uploadDirect`, {
                                imgData: imgData.replace(
                                  ";filename=generated.pdf",
                                  ""
                                ),
                                task: task,
                                user: yourEmail
                              }).then(oRes => {
                                console.log("oRes", oRes);
                                if (_.has(oRes.data, "error")) {
                                  this.setState({
                                    disabled: false,
                                    showSection: 2,
                                    error: oRes.data.error,
                                    loaded: true
                                  });
                                } else {
                                  this.setState({
                                    disabled: false,
                                    showSection: 2,
                                    res: oRes.data,
                                    loaded: true
                                  });
                                }
                              });
                            }}
                          >
                            Use Image
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ margin: "10px", textTransform: "inherit" }}
                            disabled={disabled}
                            onClick={e => {
                              this.setState({
                                capturedImage: ""
                              });
                            }}
                          >
                            Take another Image
                          </Button>
                          <img
                            src={capturedImage}
                            style={{ width: "100%" }}
                            alt="Captured"
                          />
                        </Grid>
                      );
                    return (
                      <Grid item xs={12} style={{ padding: "15px" }}>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                              marginBottom: "20px",
                              textTransform: "inherit"
                            }}
                            onClick={this.capture}
                          >
                            <AddAPhoto />
                            &nbsp; Capture
                          </Button>
                        </div>
                        <Webcam
                          width={size.width - 20}
                          ref={this.setRef}
                          screenshotFormat="image/jpeg"
                        />
                      </Grid>
                    );
                  }}
                </SizeMe>
              )}
              {tab === 1 && (
                <Grid item xs={12} style={{ padding: "15px" }}>
                <Typography>Select a file to upload, file must be in JPEG format and less than 20MBs.</Typography>
                <FormControl style={{ marginTop: '20px' }}>
                    <input
                        id="idUploadPDFs"
                        type="file"
                        disabled={disabled}
                        onChange={event =>
                          this.handleFileChange(event)
                        }
                        multiple
                    />
                    <FormHelperText>{error}</FormHelperText>
                </FormControl>
                </Grid>
              )}
            </Paper>
          )}
          {showSection === 2 && (
            <Card>
              {/*<CardHeader title="xx" subheader="yy" />
              <Divider />*/}
              <List>
                {error !== "" && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography color="secondary">
                          Error Processing Upload
                        </Typography>
                      }
                      secondary={
                        <Typography color="secondary">{error}</Typography>
                      }
                    />
                  </ListItem>
                )}
                {error === "" && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography color="primary">
                          Your file has been successfully processed
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Uploads);
