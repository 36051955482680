import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
// import "typeface-roboto";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PageTitle from "./../components/pageTitle.js";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from './../layout/taskMenu2.js'

class JudgeDecisions extends Component {
  state = {
    oJudge: {},
    decisions: [],
    loaded: false
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const judge = match.params.judge;
    const promJudge = AxiosAll.post(`/judges/getJudge`, { judge: judge });
    const promDecs = AxiosAll.post(`/judges/getJudgeDecisions`, {
      judge: judge
    });
    const promTask = AxiosAll.post(`/tasks/getTask`, {
      task: task,
    });
    Promise.all([promJudge, promDecs, promTask]).then(oResult => {
      // console.log("oResult", oResult);
      this.setState({
        oJudge: oResult[0].data,
        decisions: oResult[1].data,
        taskName: oResult[2].data.name,
        locked: oResult[2].data.locked,
        isModTask: oResult[2].data.isModTask,
        loaded: true,
      });
    });
  }
  render() {
    const { match } = this.props;
    const judge = match.params.judge;
    const columns = [
      {
        Header: "Chosen",
        accessor: "chosenName",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            <NavLink to={`/display/${row.original.chosen}`} target="_blank">
              {row.original.chosenName}
            </NavLink>
          </div>
        )
      },
      {
        Header: "Chosen Score",
        accessor: "chosenScore",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.chosenScore
              ? row.original.chosenScore.toFixed(1)
              : "-"}
          </div>
        )
      },
      {
        Header: "Not Chosen",
        accessor: "notChosenName",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            <NavLink to={`/display/${row.original.notChosen}`} target="_blank">
              {row.original.notChosenName}
            </NavLink>
          </div>
        )
      },
      {
        Header: "Not Chosen Score",
        accessor: "notChosenScore",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.notChosenScore || row.original.notChosenScore === 0
              ? row.original.notChosenScore.toFixed(1)
              : "-"}
          </div>
        )
      },
      {
        Header: "Time Taken",
        accessor: "timeTaken",
        Cell: row => (
          <div
            style={{ flex: 0, textAlign: "center" }}
          >{`${(row.original.timeTaken / 1000).toFixed(1)}s`}</div>
        )
      },
      {
        Header: "Probability",
        accessor: "probability",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {(row.original.probability * 100).toFixed(1)}%
          </div>
        )
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.createdAt}
          </div>
        )
      }
    ];
    const { oJudge, decisions, loaded, taskName, locked, isModTask } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    return (
      <Grid container style={{ margin: '0 15px 15px 15px', paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} />
        </Grid>
        <Grid item xs={8} style={{ marginTop: "15px" }}>
          <PageTitle content={`Judge Decisions`} />
          <Typography style={{ marginLeft: "15px" }}>
            Decisions for{" "}
            <NavLink to={`/display/${judge}`} target="_blank">{`${
              oJudge.email
            }`}</NavLink>
          </Typography>
          <ReactTable defaultPageSize={50}
            data={decisions}
            columns={columns}
            minRows={0}
            showPagination={true}
            style={{ margin: "15px" }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default JudgeDecisions;
