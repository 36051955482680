export const styles = {
  topBannerNav: {
    position: "relative",
    width: "1180px",
    height: "111px",
    backgroundImage:
      "linear-gradient(to left, var(--purple), #461e72 69%, var(--magenta)), linear-gradient(to bottom, var(--magenta), var(--magenta))"
    // borderStyle: 'solid',
  },
  bannerLogo: {
    position: "absolute",
    left: "78px",
    top: "31px"
  },
  ComparativeJudgemen: {
    position: "absolute",
    // opacity: 0.6,
    left: "596px",
    top: "46px",
    // width: '235px',
    height: "19px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#3f1f71"
  },
  bannerMedium: {
    position: "absolute",
    left: "859px",
    top: "39px",
    padding: "inherit"
  },
  bannerTwitter: {
    position: "absolute",
    left: "909px",
    top: "39px",
    padding: "inherit"
  },
  SignUp: {
    position: "absolute",
    left: "958px",
    top: "46px",
    width: "49px",
    height: "20px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#3f1f71"
  },
  Rectangle: {
    position: "absolute",
    left: "1022px",
    top: "40px",
    width: "80px",
    height: "37px",
    borderRadius: "5px",
    backgroundColor: "#dddddd",
    textTransform: "inherit"
  },
  topMenuAppBar: {
    backgroundColor: "#3f1f71",
    height: "35px"
  },
  menuButton: {
    fontFamily: "Source Sans Pro",
    marginTop: "-40px",
    lineHeight: "inherit",
    height: "20px",
    color: "#ffffff",
    textTransform: "inherit",
    alignItems: "normal"
  },
  username: {
    textTransform: "inherit",
    position: "absolute",
    textAlign: "right",
    left: "0",
    top: "85px",
    width: "1170px",
    color: "#ffffff"
  },
  footer: {
    position: "relative",
    width: "1180px",
    height: "152px",
    backgroundColor: "#3f1f71"
    // borderStyle: 'solid',
  },
  footerC1R1: {
    position: "absolute",
    left: "78px",
    top: "62px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  footerC1R2: {
    position: "absolute",
    left: "78px",
    top: "127px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  footerLink: {
    position: "absolute",
    // height: '18px',
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    textTransform: "inherit",
    minWidth: "auto"
  },
  deskBlurred: {
    position: "relative",
    width: "1180px",
    height: "286px",
    backgroundImage: "url(/assets/home-banner-hand-pencil.jpg)"
  },
  topBannerBackground: {
    backgroundImage:
      "linear-gradient(to left, #3f1f71 0%, #461e72 35%, #af1280), linear-gradient(to bottom, #3f1f71, #3f1f71)"
  },
  topBannerHeading: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  }
};
