import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Divider } from "@material-ui/core";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Chip
} from "@material-ui/core";
import { List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import { Lock, LockOpen, SubdirectoryArrowRight } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Settings as SettingsIcon, Help } from "@material-ui/icons";
import AxiosAll from "./../axiosAll";
require("es6-promise").polyfill();
const _ = require("lodash");
const moment = require("moment");

const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

class TaskMenu2 extends Component {
  constructor(props) {
    super(props);
    if (localStorage.hasOwnProperty("email")) {
      window.Intercom("update", {
        app_id: "zodtosc9",
        email: localStorage.getItem("email"),
        created_at: moment().unix()
      });
    }
  }
  state = {
    activeStep: -1,
    steps: [
      {
        label: "1. Set up task",
        sections: [
          {
            id: "tm_candidates",
            text: (
              <span>a.&nbsp; Populate, generate, download & print coded sheets</span>
            ),
            page: "candidates",
            help: 'https://www.notion.so/nmm/Setting-up-a-task-d983f4ad14534794b09fdf9bf87e561b',
          },
          {
            id: "tm_materials",
            text: <span>b.&nbsp; Download national assessment documents</span>,
            page: "testMaterials",
            help: 'https://www.notion.so/nmm/Details-of-the-tasks-to-be-completed-f0b8b4385193402cb1e5a95716a61dd8',
          },
          {
            id: "tm_scans",
            text: <span>c.&nbsp; Scan completed assessments & upload scans</span>,
            page: "scansUploads",
            help: 'https://www.notion.so/nmm/Scanning-and-uploading-your-Coded-answer-sheets-9e3474d24d0340b19a5463bc01f8f476',
          }
        ]
      },
      {
        label: "2. Judge",
        sections: [
          {
            id: "tm_judges",
            text: <span>a.&nbsp; Run judging session</span>,
            page: "judges",
            help: "https://www.notion.so/nmm/Judging-12328f42a36b4006932b9d1eaf180f1a"
          }
        ]
      },
      {
        label: "3. Get your results",
        sections: [
          {
            id: "tm_results",
            text: <span>a.&nbsp; Check results</span>,
            page: "reporting",
            help: "https://www.notion.so/nmm/Getting-your-results-c89188cf2130472eb4bb4c549097d64a"
          }
        ]
      }
    ],
    disabled: false,
    loaded: false,
  };
  handleStepClick = event => {
    this.setState({
      activeStep: parseInt(event.currentTarget.id.replace("s_", ""), 10)
    });
  };
  render() {
    const self = this;
    let {
      activeStep,
      steps,
      disabled,
    } = this.state;
    const { location, taskName, locked, isModTask } = this.props;
    // console.log('*', taskName, locked, isModTask)
    const page = location.pathname.split("/")[1];
    const task = location.pathname.split("/")[2];
    // if (!loaded) return <span />;
    const oSyllabus = {};
    if (activeStep === -1)
      _.each(steps, function(oStep, i) {
        if (_.indexOf(_.map(oStep.sections, "page"), page) > -1) {
          activeStep = i;
        }
      });
    return (
      <div>
        <div style={{ marginTop: "20px" }} />
        {/*<div style={{ float: "left", width: "25%" }}>*/}
          <Paper
            elevation={4}
            style={{ margin: "0 20px 20px 0", backgroundColor: "#F5F5F5" }}
          >
            <Grid container>
              <Grid item xs={12}>
                {isModTask && <Tooltip title="Update local tasks">
                  <IconButton
                    mini="true"
                    variant="raised"
                    color="primary"
                    disabled={disabled}
                    style={{ float: "right" }}
                    onClick={e => {
                      this.setState({ disabled: true });
                      AxiosAll.post(`/tasks/getLocalTasks`, { task: task }).then(oRes => {
                        console.log('getLocalTasks', oRes)
                        const arrProms = _.map(oRes.data, async (task) => {
                          return AxiosAll.post(`/tasks/refreshTask`, { task: task })
                        })
                        Promise.all(arrProms)
                        .then(oRes => {
                          AxiosAll.post(`/aws/genModCandsFile`, { task: task })
                          .then(oRes2 => {
                            this.setState({ disabled: false });
                            window.location.reload(false);
                          })
                        })
                      })
                    }}
                  >
                    <SubdirectoryArrowRight />
                  </IconButton>
                </Tooltip>}
                <Tooltip title="Update candidate and judge scores">
                  <IconButton
                    id="tm_refresh"
                    mini="true"
                    variant="raised"
                    color="primary"
                    disabled={disabled}
                    style={{ float: "right" }}
                    onClick={e => {
                      this.setState({ disabled: true });
                      AxiosAll.post(`/tasks/refreshTask`, { task: task }).then(
                        oResults => {
                          this.setState({ disabled: false });
                          window.location.reload(false);
                        }
                      );
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Update task settings, script generation settings, set task owners & define levels">
                  <IconButton
                    id="tm_Settings"
                    color={page === "settings" ? "secondary" : "primary"}
                    variant="raised"
                    disabled={disabled}
                    style={{ float: "right" }}
                    component={AdapterLink}
                    to={`/settings2/${task}`}
                    onClick={self.handleSettingsClick}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={locked ? `Locked task` : `Unlocked task`}>
                  {locked ? <Lock style={{ padding: '12px', float: 'right' }} /> : <LockOpen style={{ padding: '12px', float: 'right' }} />}
                </Tooltip>
              </Grid>
              <Grid item xs={12} style={{ padding: "0 20px 10px 20px" }}>
                {oSyllabus.productName && (
                  <Chip
                    label={oSyllabus.productName}
                    style={{
                      backgroundColor: oSyllabus.productColour,
                      margin: "0 2px 0 2px"
                    }}
                  />
                )}
                {oSyllabus.yearGroup && (
                  <Chip
                    label={`Year ${oSyllabus.yearGroup}`}
                    style={{
                      backgroundColor: oSyllabus.ygColour,
                      margin: "0 2px 0 2px"
                    }}
                  />
                )}
                {oSyllabus.yearGroup && (
                  <Chip
                    label={oSyllabus.acYear}
                    style={{
                      backgroundColor: oSyllabus.acYearColour,
                      margin: "0 2px 0 2px"
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="secondary"
                  gutterBottom
                  style={{ paddingLeft: "20px", paddingBottom: "0px" }}
                >
                  {taskName}
                </Typography>
              </Grid>
            </Grid>

            {steps.map(function(oStep, key1) {
              return (
                <div key={`dv_${key1}`}>
                  <Divider />
                  <Typography
                    variant="subtitle2"
                    style={{ margin: "15px 15px 0px 20px" }}
                  >
                    {oStep.label}
                  </Typography>
                  <List>
                    {oStep.sections.map(function(oSection, key2) {
                      return (
                        <ListItem key={`key_${key1}_${key2}`} dense button component={AdapterLink} to={`/${oSection.page}/${task}`} style={{ paddingLeft: '30px' }} >
                          <ListItemText primary={<Typography color={page === oSection.page ? 'secondary' : 'primary' }>{oSection.text}</Typography>} />
                          <ListItemSecondaryAction>
                            <IconButton href={oSection.help} target="_blank">
                              <Help />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
              );
            })}
          </Paper>
        {/*</div>*/}
      </div>
    );
  }
}

export default withRouter(TaskMenu2);
