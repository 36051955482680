import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Paper,
  LinearProgress,
  TextField,
  Tooltip,
  Divider,
  Badge,
  Typography,
} from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { Cancel, PersonOutline } from "@material-ui/icons";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";
// import AxiosCall from './../axiosCall'


const _ = require("lodash");
const moment = require("moment");

class Judging extends Component {
  constructor(props) {
    super(props);
    window.Intercom("shutdown");
  }
  state = {
    openDialog: false,
    openCommentsDialog: false,
    dialogTitle: '',
    dialogText: '',
    dialogType: '',
    leftCandidate: "",
    rightCandidate: "",
    leftURLs: [],
    rightURLs: [],
    timeTakenStart: 0,
    medianTimeTaken: 0,
    disabled: false,
    judgeQuestion: '',
    judgeInfo: '',
    loaded: false,
    quota: -1,
    comparisons: -1,
    isExternal: false,
    curTask: '',
    lastDecision: null,
    fixedTask: false,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const judge = match.params.judge;
    AxiosAll.post(`/judging/initJudgingCheck`, { task: task, judge: judge })
    //AxiosCall.post(`/judging_initJudgingCheck`, { task: task, judge: judge })
    .then(oResultInit => {
      console.log('oResultInit', oResultInit.data)
      const fixedTask = oResultInit.data.fixedTask || false
      if (_.has(oResultInit.data, 'errorMsg')) {
        this.setState({
          openDialog: true,
          dialogTitle: 'Error',
          dialogText: `Error: ${oResultInit.data.errorMsg}`,
          loaded: true,
          fixedTask: fixedTask,
        })
      }
      else {
        // console.log('<init', task, judge)
        AxiosAll.post(`/judging/recordJudgementAndGetNextPair`, { task: task, judge: judge, isExternal: false })
        //AxiosCall.post(`/judging_recordJudgementAndGetNextPair`, { task: task, judge: judge, isExternal: false, createdAt: moment().format('YYY-MM-DD HH:mm:ss'), fixedTask: fixedTask })
        .then(oResult => {
          console.log('init>', oResult.data)
          if (_.has(oResult.data, 'errorMsg')) {
            this.setState({
              openDialog: true,
              dialogTitle: 'Judging',
              dialogText: oResult.data.errorMsg,
              dialogType: 'error',
              loaded: true,
              fixedTask: fixedTask,
            })
          }
          else {
            this.setState({
              openDialog: true,
              dialogTitle: 'Start Judging',
              dialogText: `You have completed ${oResult.data.comparisons} judgements with ${oResult.data.quota - oResult.data.comparisons} remaining`,
              dialogType: 'intro',
              judgeQuestion: oResultInit.data.judgeQuestion,
              judgeInfo: (oResultInit.data.judgeInfo || ''),
              loaded: true,
              comparisons: oResult.data.comparisons,
              quota: oResult.data.quota,
              leftCandidate: oResult.data.left._id,
              rightCandidate: oResult.data.right._id,
              leftURLs: oResult.data.left.scans,
              rightURLs: oResult.data.right.scans,
              isExternal: oResult.data.isExternal,
              curTask: oResult.data.task,
              fixedTask: fixedTask,
            })
          }
        })
      }
    })
  }
  handleSelectCandidate = isLeft => {
    if (this.state.disabled) return;
    const {
      timeTakenStart,
      leftCandidate,
      rightCandidate, isExternal, curTask, fixedTask
    } = this.state;
    const { match } = this.props;
    const task = match.params.task;
    const judge = match.params.judge;
    const self = this;

    this.setState({ leftURLs: [], rightURLs: [], disabled: true });
    const timeTaken = moment().valueOf() - timeTakenStart;
    const chosen = isLeft ? leftCandidate : rightCandidate;
    const notChosen = isLeft ? rightCandidate : leftCandidate;

    AxiosAll.post(`/judging/recordJudgementAndGetNextPair`, {
    //AxiosCall.post(`/judging_recordJudgementAndGetNextPair`, {
      task: curTask,
      judge: judge,
      chosen: chosen,
      notChosen: notChosen,
      timeTaken: timeTaken,
      isLeft: isLeft,
      isExternal: isExternal,
      excludeTask: (isExternal ? task : 'x'),
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      fixedTask: fixedTask,
    }).then(oNextPair => {
      console.log("oNextPair", oNextPair.data);
      if (_.has(oNextPair.data, "errorMsg")) {
        // console.log("Error");
        this.setState(prevState => ({
          openDialog: true,
          dialogTitle: 'Judging Complete',
          dialogText: oNextPair.data.errorMsg,
          dialogType: 'error',
          comparisons: prevState.comparisons + 1,
        }));
      } else {
        this.setState(prevState => ({
          leftCandidate: oNextPair.data.left._id,
          rightCandidate: oNextPair.data.right._id,
          leftURLs: oNextPair.data.left.scans,
          rightURLs: oNextPair.data.right.scans,
          timeTakenStart: moment().valueOf(),
          comparisons: oNextPair.data.comparisons,
          medianTimeTaken: oNextPair.data.medianTimeTaken,
          quota: oNextPair.data.quota,
          isExternal: oNextPair.data.isExternal,
          curTask: oNextPair.data.task,
          lastDecision: oNextPair.data.lastDecision,
        }));
        setTimeout(function() {
          self.setState({ disabled: false });
        }, 500);
      }
    });
  };
  render() {
    const self = this;
    const {
      openCommentsDialog,
      openDialog,
      dialogTitle,
      dialogText,
      dialogType,
      leftCandidate,
      rightCandidate,
      leftURLs,
      rightURLs,
      medianTimeTaken,
      disabled,
      loaded,
      judgeQuestion, judgeInfo, quota, comparisons, lastDecision, fixedTask
    } = this.state;
    if (!loaded) return <span />;
    const { match } = this.props;
    const task = match.params.task;
    const judge = match.params.judge;
    const progress = Math.round(comparisons / quota * 100)
    console.log('state', this.state)
    // cloudinaryCore.responsive()
    return (
      <Grid container style={{ flexGrow: 1 }}>
        <Grid item xs={12} style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <Grid container justify="space-around">
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              style={{ flex: 4, textTransform: 'inherit' }}
              onClick={e => {
                self.handleSelectCandidate(true);
              }}
            >
              Left
            </Button>
            <Button
              style={{
                flex: 2,
                color: "black",
                backgroundColor: "white",
                opacity: 1
              }}
              disabled
            >{`${(medianTimeTaken / 1000 || 0).toFixed(1)} s`}</Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ textTransform: "inherit", flex: 8 }}
              onClick={e => {
                this.setState({ openCommentsDialog: true });
              }}
            >
              {judgeQuestion}
            </Button>
            <Tooltip title="Undo Last Judgement"><Button variant="contained" color="primary" style={{ flex: 1, textTransform: 'inherit' }} disabled={!lastDecision || fixedTask} onClick={() => {
              console.log('undo')
              AxiosAll.post(`/judging/undoRecordJudgement`, {
              //AxiosCall.post(`/judging_undoRecordJudgement`, {
                decision: lastDecision,
                judge: judge,
              }).then(oNextPair => {
                console.log("oNextPair", oNextPair.data);
                if (_.has(oNextPair.data, "error")) {
                  // console.log("Error");
                  this.setState(prevState => ({
                    openDialog: true,
                    dialogTitle: 'Judging Complete',
                    dialogText: oNextPair.data.error,
                    dialogType: 'error',
                    comparisons: prevState.comparisons + 1,
                  }));
                } else {
                  this.setState(prevState => ({
                    leftCandidate: oNextPair.data.left._id,
                    rightCandidate: oNextPair.data.right._id,
                    leftURLs: oNextPair.data.left.scans,
                    rightURLs: oNextPair.data.right.scans,
                    timeTakenStart: moment().valueOf(),
                    comparisons: oNextPair.data.comparisons,
                    medianTimeTaken: oNextPair.data.medianTimeTaken,
                    quota: oNextPair.data.quota,
                    isExternal: oNextPair.data.isExternal,
                    curTask: oNextPair.data.task,
                    lastDecision: null,
                  }));
                  setTimeout(function() {
                    self.setState({ disabled: false });
                  }, 500);
                }
              })
            }}>Undo</Button></Tooltip>
            <Button
              style={{
                flex: 2,
                color: "black",
                backgroundColor: "white",
                opacity: 1
              }}
            >
              <Tooltip title="Judge judgements remaining">
                <Badge
                  badgeContent={<span>{quota - comparisons}</span>}
                  color="secondary"
                  style={{ top: "8px", right: "-3px", marginRight: "20px" }}
                >
                  <PersonOutline />
                </Badge>
              </Tooltip>
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              style={{ flex: 4, textTransform: 'inherit' }}
              onClick={e => {
                self.handleSelectCandidate(false);
              }}
            >
              Right
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={progress}
          />
        </Grid>
        <Grid container>
        <Grid item xs={6}>
          {leftURLs.length > 0 && (
            <Paper
              component={NavLink}
              to={`/display/${leftCandidate}`}
              target="_blank"
              id="idLeftPaper"
              elevation={4}
              style={{ padding: "5px" }}
            >
              {_.map(leftURLs, function(url, i) {
                return (
                  <img
                    key={`imgL_${i}`}
                    src={url}
                    style={{ maxWidth: "100%" }}
                    alt="Left"
                  />
                );
              })}
            </Paper>
          )}
        </Grid>
        <Grid item xs={6}>
          {rightURLs.length > 0 && (
            <Paper
              component={NavLink}
              to={`/display/${rightCandidate}`}
              target="_blank"
              id="idRightPaper"
              elevation={4}
              style={{ padding: "5px" }}
            >
              {_.map(rightURLs, function(url, i) {
                return (
                  <img
                    key={`imgR_${i}`}
                    src={url}
                    style={{ maxWidth: "100%" }}
                    alt="Right"
                  />
                );
              })}
            </Paper>
          )}
        </Grid>
        </Grid>
        <Dialog
          maxWidth="md"
          fullWidth
          open={openCommentsDialog}
          onClose={self.handleCommentsClose}
        >
          <DialogTitle>
            Feedback Form
            <Button
              mini
              variant="fab"
              style={{ float: "right" }}
              onClick={e => {
                this.setState({ openCommentsDialog: false });
              }}
            >
              <Cancel />
            </Button>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <TextField
                id="idComments"
                label="Comments"
                defaultValue=""
                fullWidth
                autoFocus
                multiline
                rows="4"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "inherit" }}
              disabled={disabled}
              onClick={e => {
                self.setState({ disabled: true })
                AxiosAll.post(`/judging/createComment`, {
                //AxiosCall.post(`/judging_createComment`, {
                  task: task,
                  judge: judge,
                  leftCandidate: leftCandidate,
                  rightCandidate: rightCandidate,
                  comments: document.getElementById("idComments").value,
                  createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                }).then(oComment => {
                  self.setState({
                    openCommentsDialog: false,
                    disabled: false,
                  });
                });
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialog} onEnter={o => {}}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <Alert text={dialogText} type="info" />
              {judgeInfo !=='' && <Typography gutterBottom style={{ margin: '15px 0 0 0'}} color="secondary">{judgeInfo}</Typography>}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            {dialogType === 'intro' && <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={e => {
                this.setState({
                  openDialog: false,
                  timeTakenStart: moment().valueOf(),
                });
              }}
              style={{ textTransform: "inherit" }}
              disabled={disabled}
            >
              Start Judging
            </Button>}
            {_.includes(['error', 'finish'], dialogType) && <Button
              size="small"
              variant="contained"
              color="primary"
              component={NavLink}
              to="/"
              style={{ textTransform: "inherit" }}
            >
              Home
            </Button>}
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

export default withRouter(Judging);
