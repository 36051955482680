import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Typography,
  IconButton,
} from "@material-ui/core";
import { SizeMe } from "react-sizeme";
import ImageZoom from "react-medium-image-zoom";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from "./../layout/taskMenu2.js";
import ScansMenu from "./../layout/scansMenu.js";
import { ArrowBack, FileCopy, Star, StarBorder } from "@material-ui/icons";
import ReactTable from "react-table";
import "react-table/react-table.css";

const _ = require("lodash");

class ScansThumbnails extends Component {
  state = {
    loaded: false,
    scans: [],
    candidates: [],
    taskName: "",
    locked: true,
    isModTask: false,
    useCodes: false,
    candsWithScans: 0,
    disabled: false,
    scansNeedingFix: 0,
    limit: 5000,
    hasDuplicates: false,
  };
  componentDidMount() {
    const { match } = this.props;
    const { limit } = this.state;
    const task = match.params.task;
    const promGetCands = AxiosAll.post(`/candidates/getCandidatesForTask`, {
      task: task
    });
    const promScans = AxiosAll.post(`/scans/getScannedPagesForTask`, {
      task: task
    });
    const promTask = AxiosAll.post(`/tasks/getTask`, { task: task });
    const promThumbnails = AxiosAll.post(`/scans/getThumbnails`, {
      task: task,
      limit: limit
    });
    const promMenu = AxiosAll.post(`/scans/getScanMenu`, { task: task });
    Promise.all([
      promGetCands,
      promScans,
      promTask,
      promThumbnails,
      promMenu
    ]).then(oResult => {
      const oCands = oResult[0].data;
      // const oScans = oResult[1].data;
      console.log("oTask", oResult[2].data, oResult[3].data);
      this.setState({
        candidates: oCands,
        // scans: _.orderBy(oScans, ["qrcode", "qrpage"]),
        scans: oResult[3].data,
        taskName: oResult[2].data.name,
        locked: oResult[2].data.locked,
        isModTask: oResult[2].data.isModTask,
        useCodes: oResult[2].data.useCodes,
        pdfFiles: oResult[4].data.pdfFiles,
        codesToFix: oResult[4].data.codesToFix,
        candsWithScans: oResult[4].data.candsWithScans,
        hasDuplicates: oResult[4].data.hasDuplicates,
        excludedPages: oResult[4].data.excludedPages,
        loaded: true
      });
    });
  }
  render() {
    const { match } = this.props;
    const task = match.params.task;
    const {
      loaded,
      scans,
      candidates,
      taskName,
      locked,
      isModTask,
      useCodes,
      limit,
      pdfFiles,
      codesToFix,
      candsWithScans, hasDuplicates, excludedPages
    } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    if (isModTask) return <Typography gutterBottom>Not available for moderation tasks</Typography>
    // if (scans.length === 0) return <span>No pages uploaded</span>;
    // const oScanGroups = _.groupBy(scans, "qrcode");
    // console.log('oScanGroups', oScanGroups)
    // console.log('state', this.state)
    // const n = _.keys(oScanGroups).length
    // const nCandsWithScans = _.filter(candidates, { hasScans: true }).length;
    console.log("state", this.state);
    const arrValidQRs = _.map(candidates, 'qrcode')
    const columns = [
      {
      Header: 'Thumbnails',
      accessor: "_id",
      filterMethod: (filter, row) => row[filter.id].match(RegExp(filter.value, "i")),
      Cell: row => {
        const oScan = row.original
        return (
          <SizeMe key={`qr_${oScan._id}`}>
          {({ size }) => {
            const thumbWidth = 200 + 12;
            const nItems = parseInt(size.width / thumbWidth, 10);
            const oCand = _.find(candidates, { qrcode: oScan._id });
            return (
              <div>
                <Typography color="secondary">
                  {oCand
                    ? `${oCand.firstName} ${oCand.lastName} (${oScan._id})`
                    : oScan._id || "No QR Code"}
                </Typography>
                <GridList
                  key={`gl_${oScan._id}`}
                  style={{
                    flexWrap: "nowrap",
                    backgroundColor: "#F8F8F8",
                    width: `${nItems * thumbWidth}px`,
                    marginBottom: "20px"
                  }}
                  cols={nItems}
                >
                  {oScan.pages.map((oPage, i) => {
                    const backgroundStyle1 = (_.includes(arrValidQRs, oScan._id) ?  { background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"} : { background: "linear-gradient(to bottom, rgba(178,0,0,0.7) 0%, rgba(178,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" })
                    const backgroundStyle2 = (_.includes(arrValidQRs, oScan._id) ?  { background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"} : { background: "linear-gradient(to top, rgba(178,0,0,0.7) 0%, rgba(178,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" })
                    const exclude = (_.compact(_.map(oPage.scans, 'exclude')).length > 0)
                    return (
                      <GridListTile
                        key={`${oScan._id}_${oPage.qrpage}`}
                        style={{ height: "315px" }}
                      >
                        <ImageZoom
                          image={{
                            src:
                              oPage.scans[0].angle === 0
                                ? oPage.scans[0].thumbnail0
                                : oPage.scans[0].thumbnail180,
                            alt: `${oScan.qrcode} ${oPage.qrpage}`,
                            style: {
                              width: "200px",
                              height: "282px",
                              border: "1px solid #021a40",
                              margin: "5px",
                              padding: "5px"
                            }
                          }}
                          zoomImage={{
                            src: oPage.scans[0].full_url,
                            alt: `${oPage.scans.qrcode} ${
                              oPage.scans.qrpage
                            }`
                          }}
                        />
                        <GridListTileBar
                          actionPosition="right"
                          titlePosition="top"
                          style={backgroundStyle1}
                          title={<IconButton onClick={e => {
                            AxiosAll.post(`/scans/toggleExcludeImgs`, {
                              task: task,
                              selection: _.map(oPage.scans, '_id')
                            })
                            .then(oRes => {
                              AxiosAll.post(`/scans/getThumbnails`, { task: task, limit: limit })
                              .then(oData => {
                                this.setState({ scans: oData.data })
                              })
                            })
                          }}>
                            {exclude ? <Star /> : <StarBorder />}
                          </IconButton>}
                          actionIcon={
                            <IconButton
                              onClick={() => {
                                console.log("onclick", oScan, i, oPage);
                                const oRPage = oScan.pages[i];
                                const oLPage = oScan.pages[i - 1];
                                console.log(
                                  "onclick",
                                  oScan,
                                  i,
                                  oPage,
                                  oLPage,
                                  oRPage
                                );
                                AxiosAll.post(`/scans/swapPages`, {
                                  task: task,
                                  qrcode: oScan._id,
                                  lPage: oScan.pages[i - 1].qrpage,
                                  rPage: oScan.pages[i].qrpage,
                                  limit: limit
                                }).then(oRes => {
                                  this.setState({
                                    scans: oRes.data
                                  });
                                });
                              }}
                            >
                              {i > 0 && oPage.qrpage !== 0 && <ArrowBack />}
                            </IconButton>
                          }
                        />
                        <GridListTileBar
                          title={
                            <small>
                              {oPage.qrpage === 0 ? "" : `P${oPage.qrpage}`}
                            </small>
                          }
                          style={backgroundStyle2}
                          actionIcon={oPage.n > 1 && <IconButton disabled><FileCopy /></IconButton>}
                        />
                      </GridListTile>
                    );
                  })}
                </GridList>
              </div>
            );
          }}
        </SizeMe>
        )
      },
    },
    ]
    return (
      <Grid container style={{ margin: "0 15px 15px 15px", paddingRight: "35px", paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2
            taskName={taskName}
            locked={locked}
            isModTask={isModTask}
            useCodes={useCodes}
          />
        </Grid>
        <Grid item xs={8}>
          <ScansMenu
            tab={2}
            useCodes={useCodes}
            pdfFiles={pdfFiles}
            codesToFix={codesToFix}
            candsWithScans={candsWithScans}
            hasDuplicates={hasDuplicates}
            excludedPages={excludedPages} 
          />
          <Typography gutterBottom>
            Click on image for an image zoom.
            &nbsp; In cases of multiple sheets, click on the top left arrow button of a sheet to change the page ordering.
            &nbsp; The copy icon in the bottom right hand corner of the image indicates a duplicate.
            &nbsp; A red border indicates an invalid QR code.
            &nbsp; A solid starred image is excluded from any judging.
          </Typography>
          {scans.length === 0 && (
            <Typography color="secondary" gutterBottom>
              No Scans uploaded
            </Typography>
          )}
          <ReactTable defaultPageSize={50}
            data={scans}
            columns={columns}
            filterable
            minRows={0}
          />
          {/*scans.map((oScan, i) => (
            <SizeMe key={`qr_${oScan._id}`}>
              {({ size }) => {
                const thumbWidth = 200 + 12;
                const nItems = parseInt(size.width / thumbWidth, 10);
                const oCand = _.find(candidates, { qrcode: oScan._id });
                return (
                  <div>
                    <Typography color="secondary">
                      {oCand
                        ? `${oCand.firstName} ${oCand.lastName} (${oScan._id})`
                        : oScan._id || "No QR Code"}
                    </Typography>
                    <GridList
                      key={`gl_${oScan._id}`}
                      style={{
                        flexWrap: "nowrap",
                        backgroundColor: "#F8F8F8",
                        width: `${nItems * thumbWidth}px`,
                        marginBottom: "20px"
                      }}
                      cols={nItems}
                    >
                      {oScan.pages.map((oPage, i) => {
                        return (
                          <GridListTile
                            key={`${oScan._id}_${oPage.qrpage}`}
                            style={{ height: "315px" }}
                          >
                            <ImageZoom
                              image={{
                                src:
                                  oPage.scans[0].angle === 0
                                    ? oPage.scans[0].thumbnail0
                                    : oPage.scans[0].thumbnail180,
                                alt: `${oScan.qrcode} ${oPage.qrpage}`,
                                style: {
                                  width: "200px",
                                  height: "282px",
                                  border: "1px solid #021a40",
                                  margin: "5px",
                                  padding: "5px"
                                }
                              }}
                              zoomImage={{
                                src: oPage.scans[0].full_url,
                                alt: `${oPage.scans.qrcode} ${
                                  oPage.scans.qrpage
                                }`
                              }}
                            />
                            <GridListTileBar
                              actionPosition="left"
                              titlePosition="top"
                              style={{
                                background:
                                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                              }}
                              actionIcon={
                                <IconButton
                                  onClick={() => {
                                    console.log("onclick", oScan, i, oPage);
                                    const oRPage = oScan.pages[i];
                                    const oLPage = oScan.pages[i - 1];
                                    console.log(
                                      "onclick",
                                      oScan,
                                      i,
                                      oPage,
                                      oLPage,
                                      oRPage
                                    );
                                    AxiosAll.post(`/scans/swapPages`, {
                                      task: task,
                                      qrcode: oScan._id,
                                      lPage: oScan.pages[i - 1].qrpage,
                                      rPage: oScan.pages[i].qrpage,
                                      limit: limit
                                    }).then(oRes => {
                                      this.setState({
                                        scans: oRes.data
                                      });
                                    });
                                  }}
                                >
                                  {i > 0 && oPage.qrpage !== 0 && <ArrowBack />}
                                </IconButton>
                              }
                            />
                            <GridListTileBar
                              title={
                                <small>
                                  {oPage.qrpage === 0 ? "" : `P${oPage.qrpage}`}
                                </small>
                              }
                              style={{
                                background:
                                  "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                              }}
                            />
                          </GridListTile>
                        );
                      })}
                    </GridList>
                  </div>
                );
              }}
            </SizeMe>
          ))}
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ textTransform: "inherit" }}
            disabled={limit >= nCandsWithScans}
            onClick={e => {
              const newLimit = Math.min(limit + 50, nCandsWithScans);
              AxiosAll.post(`/scans/getThumbnails`, {
                task: task,
                limit: newLimit
              }).then(oRes => {
                this.setState({
                  limit: newLimit,
                  scans: oRes.data
                });
              });
            }}
          >
            Load More
          </Button>*/}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ScansThumbnails);
