import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { List, ListItem, ListItemText } from "@material-ui/core";
import {
  Grid,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { animateScroll } from "react-scroll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
// import POPCosts from "./../marketing/popCosts.js";
import AxiosAll from "./../axiosAll";
// import AxiosCall from "./../axiosCall";
// import PageTitle from './../components/pageTitle'
import queryString from 'query-string'

const md5 = require("md5");
const _ = require("lodash");
const moment = require("moment");

const oProducts = [
  {
    id: "eb61043a-d0da-4945-84ff-509cbb900dd7",
    productName: "APW",
    productNameLong: 'Assessing Primary Writing',
    terms: "AssessingPrimaryWriting",
    cost: 595,
    country: 'GB',
  },
  {
    id: "db625fcc-2a7e-4e96-9f31-04d9e2ece8b5",
    productName: "PoP",
    productNameLong: 'PoP',
    terms: "PoPTests",
    cost: 0,
    country: 'GB',
  },
  {
    id: "7a126a26-e20a-421f-96d1-1069b11764c9",
    productName: "PoP1920",
    productNameLong: 'PoP1920',
    terms: "PoPTests",
    cost: 0,
    country: 'GB',
  },
  {
    id: "dfe2ef7d-94ed-4118-8a86-409fd4105377",
    productName: "AGE",
    productNameLong: 'Assessing GCSE English',
    terms: "AssessingGCSEEnglish",
    cost: 595,
    country: 'GB',
  },
  {
    id: '84f96fca-3b7c-4142-aa7a-5c98ec691e51',
    productName: 'TWR',
    productNameLong: 'The Writing Revolution',
    terms: '',
    cost: 0,
    country: 'USA',
  },
  {
    id: '21e11c49-2417-45cb-ab29-6f0df05466b1',
    productName: 'AAW',
    productNameLong: "Assessing America's Writing - Elementary",
    terms: '',
    cost: 300,
    country: 'USA',
  },
  {
    id: '3b029bba-85c2-46db-a5bb-c58ece6d794e',
    productName: 'AAWS',
    productNameLong: "Assessing America's Writing - High",
    terms: '',
    cost: 300,
    country: 'USA',
  },
];

class Subscribe2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("boot", {
      app_id: "zodtosc9"
    });
  }
  state = {
    activeStep: 0,
    product: "",
    termsCheck: false,
    hearAboutUs: "",
    purchaseOrder: "",
    couponCode: "",
    mathsY7: 0,
    englishY7: 0,
    mathsY8: 0,
    englishY8: 0,
    mathsY9: 0,
    englishY9: 0,
    Sept_Maths_7: 0,
    Sept_English_7: 0,
    June_Maths_7: 0,
    June_English_7: 0,
    Sept_Maths_8: 0,
    Sept_English_8: 0,
    June_Maths_8: 0,
    June_English_8: 0,
    Sept_Maths_9: 0,
    Sept_English_9: 0,
    June_Maths_9: 0,
    June_English_9: 0,
    msg: <span />,
    oPurchases: [],
    loaded: false,
    coupons: [],
    school: {},
    user: {},
    disabled: false,
    errorMsg: <span />,
    nces: '',
    dfe: "",
    urn: "",
    schoolName2: '',
    schoolOptions: [],
  };
  componentDidMount() {
    const qs = queryString.parse(this.props.location.search)
    console.log('qs', qs)
    const promUser = AxiosAll.post(`/accounts/getUser`);
    const promPurchases = AxiosAll.post(`/purchases/getPurchases`);
    const promCoupons = AxiosAll.post(`/purchases/getCoupons`);
    const promSchool = AxiosAll.post(`/accounts/getSchool`);
    Promise.all([promUser, promPurchases, promCoupons, promSchool]).then(
      oResult => {
        // console.log("componentDidMount", oResult);
        const oUser = oResult[0].data || {};
        const oPurchases = _.flatten(_.map(oResult[1].data, "purchases"));
        console.log("oPurchases", _.map(oPurchases, 'productName'), _.includes(_.map(oPurchases, 'productName'), qs.product), qs.product);
        const oCoupons = oResult[2].data;
        const oSchool = oResult[3].data || {};
        if (_.has(oSchool, "error")) {
          localStorage.clear();
          this.props.history.push(`/`);
        }
        const oSch = oSchool;
        console.log("oSch", oSch);
        const set = {
          school: oSchool,
          user: oUser,
          oPurchases: oPurchases,
          coupons: oCoupons,
          loaded: true,
          personFirstName: oUser.personFirstName,
          personLastName: oUser.personLastName,
          personJobTitle: oUser.personJobTitle,
          personPhone: oUser.personPhone,
          fixedDfE: oSch.fixedDfE,
          outsideUK: false,
          schoolName: oSch.schoolName,
          nces: oSch.nces,
          stateSchoolId: oSch.stateSchoolId,
          dfe: oSch.dfe,
          urn: oSch.urn,
          address1: oSch.address1,
          address2: oSch.address2 || "",
          town: oSch.town,
          postcode: oSch.postcode,
          country: oSch.country,
          financeName: oSch.financeName,
          financePhone: oSch.financePhone,
          financeEmail: oSch.financeEmail,
          financeJobTitle: oSch.financeJobTitle,
          purchaseOrder: oSch.purchaseOrder,
          hearAboutUs: oSch.hearAboutUs
        };
        if (_.has(qs, 'product')) {
          if (!_.includes(_.map(oPurchases, 'productName'), qs.product)) {
            set.activeStep = 1
            set.productName = qs.product
            set.product = qs.product
          }
        }
        this.setState(set)
      }
    );
  }
  handleSelectPersonalsPage = productName => {
    this.setState({
      activeStep: 1,
      product: productName,
      country: _.find(oProducts, { productName }).country,
    });
  };
  handleCompletePurchase = () => {
    const {
      product,
      dfe,
      urn,
      nces,
      stateSchoolId,
      personFirstName,
      personLastName,
      personJobTitle,
      personPhone,
      schoolName,
      schoolName2,
      outsideUK,
      address1,
      address2,
      town,
      postcode,
      country,
      financeName,
      financePhone,
      financeEmail,
      financeJobTitle,
      purchaseOrder,
      hearAboutUs,
      englishY7,
      englishY8,
      englishY9,
      mathsY7,
      mathsY8,
      mathsY9,
      Sept_Maths_7,
      Sept_English_7,
      June_Maths_7,
      June_English_7,
      Sept_Maths_8,
      Sept_English_8,
      June_Maths_8,
      June_English_8,
      Sept_Maths_9,
      Sept_English_9,
      June_Maths_9,
      June_English_9,
      couponCode,
    } = this.state;
    const oProduct = _.find(oProducts, { productName: product });
    // console.log("handleCompletePurchase", product, oProduct, oProduct.id);
    const self = this;
    this.setState({ disabled: true });
    AxiosAll.post(`/accounts/completeSubscription`, {
      product,
      dfe,
      urn,
      nces,
      stateSchoolId,
      personFirstName,
      personLastName,
      personJobTitle,
      personPhone,
      schoolName: schoolName2,
      outsideUK,
      address1,
      address2,
      town,
      postcode,
      country,
      financeName,
      financePhone,
      financeEmail,
      financeJobTitle,
      purchaseOrder,
      hearAboutUs,
      englishY7,
      englishY8,
      englishY9,
      mathsY7,
      mathsY8,
      mathsY9,
      Sept_Maths_7,
      Sept_English_7,
      June_Maths_7,
      June_English_7,
      Sept_Maths_8,
      Sept_English_8,
      June_Maths_8,
      June_English_8,
      Sept_Maths_9,
      Sept_English_9,
      June_Maths_9,
      June_English_9,
      couponCode
    }).then(oResult => {
      const newDfE = oResult.data.dfe
      const newURN = oResult.data.urn
      console.log("oResult", oResult.data, newDfE, newURN);

      // console.log("oProduct", oProduct.data);
      AxiosAll.post(`/purchases/getProduct`, {
        product: product
      }).then(oProducts => {
        // console.log("getProducts", oProducts);
        const oProduct = oProducts.data;
        AxiosAll.post(`/utils/sendEmail`, {
          to: localStorage.getItem("email"),
          bcc: ['help@assembly.education', 'support@nomoremarking.com', 'neil@nomoremarking.com'],
          subject: `No More Marking Subscription: ${product}`,
          text: oProduct.emailContent
            .join("\n")
            .replace(/PERSON_NAME/, `${personFirstName} ${personLastName}`),
          html: ""
        });
        console.log('ASSEMBLY', oProduct, (oProduct.country === 'GB'))
        if (oProduct.country === 'GB') {
          AxiosAll.post(`/utils/sendEmail`, {
            to: 'help@assembly.education',
            subject: `No More Marking Subscription: ${product}`,
            text: [
              `Details:`,
              `${personFirstName} ${personLastName}`,
              `${personJobTitle} ${personPhone}`,
              ``,
              `${newDfE} / ${newURN}`,
              schoolName,
              `${address1} ${address2} ${town} ${postcode || ''} ${country}`,
              ``,
              `Product: ${product}`,
            ].join('\n'),
            html: '',
          })
        }
      });

      AxiosAll.post(`/utils/findMissingTasks`, {
        dfe: (oProduct.country === 'GB' ? newDfE : nces),
        product: oProduct.id,
      }).then(oResult => {
        console.log("findMissingTasks", `|${newDfE}|`, `|${nces}|`, oProduct, oResult);
      });

      console.log('updateMailChimp', newDfE, nces, schoolName, oProduct.country, (oProduct.country === 'GB' ? newDfE : parseFloat(nces)))
      AxiosAll.post(`/utils/updateMailChimp`, {
        dfe: (oProduct.country === 'GB' ? newDfE : parseFloat(nces))
      }).then(oResult => {
        // console.log("updateMailChimp", oResult);
      });

      console.log('Intercom')
      AxiosAll.post(`/utils/createIntercomEvent`, {
        eventName: "Registration",
        metadata: {}
      }).then(oResult => {
        // console.log("createIntercomEvent", oResult);
      });
      AxiosAll.post(`/utils/createIntercomCompany`, {
        dfe: newDfE,
        schoolName: schoolName
      }).then(oResult => {
        // console.log("createIntercomCompany", oResult);
      });

      /*const arrProducts = _.map(oProducts, "productName").join(",");
      // console.log("arrProducts", arrProducts);
      AxiosAll.post(`/utils/updateMailChimp`, {
        emailHashed: md5(localStorage.getItem("email").toLowerCase()),
        email_address: localStorage.getItem("email").toLowerCase(),
        status: "subscribed",
        merge_fields: {
          PRODUCTS: arrProducts,
          REG_PRODS: arrProducts
        }
      }).then(oResult => {
        // console.log(">", oResult);
      });*/

      self.setState({
        activeStep: 4,
        disabled: false
      });
    });
  };
  render() {
    const self = this;
    const {
      loaded,
      activeStep,
      oPurchases,
      product,
      disabled,
      errorMsg,
      termsCheck,
      coupons,
      schoolName2
    } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    const isAuthenticated = (localStorage.getItem("email") || "") !== "";
    if (!isAuthenticated) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>You must be authenticated before continuing</Typography></Grid>;
    const {
      personFirstName,
      personJobTitle,
      personLastName,
      personPhone,
      schoolName,
      nces,
      outsideUK,
      dfe,
      urn,
      address1,
      town,
      country,
      address2,
      postcode,
      financeName,
      financePhone,
      financeEmail,
      financeJobTitle,
      purchaseOrder,
      couponCode,
      hearAboutUs,
      fixedDfE
    } = this.state;
    const {
      mathsY7,
      englishY7,
      mathsY8,
      englishY8,
      mathsY9,
      englishY9,
      Sept_Maths_7,
      Sept_English_7,
      June_Maths_7,
      June_English_7,
      Sept_Maths_8,
      Sept_English_8,
      June_Maths_8,
      June_English_8,
      Sept_Maths_9,
      Sept_English_9,
      June_Maths_9,
      June_English_9,
    } = this.state;
    // const { dialogOpen, dialogTitle, dialogContent } = this.state;
    let discount = 0;
    if (
      couponCode !== "" &&
      _.indexOf(_.map(coupons, "md5"), md5(couponCode)) > -1
    ) {
      const oDiscount = _.find(coupons, { md5: md5(couponCode) });
      discount = oDiscount.discount;
    }
    let totalCost = 0;
    if (product === "PoP1920") {
      /*_.each(["Maths", "English"], function(subject) {
        _.each([7, 8, 9], function(yg) {
          const candidates = parseInt(
            self.state[`${subject.toLowerCase()}Y${yg}`],
            10
          );
          if (candidates > 0) totalCost += 50 + candidates * (yg === 7 ? 4 : 2);
        });
      });*/
      _.each([7, 8, 9], yg => {
        _.each(['Sept', 'June'], month => {
          _.each(['Maths', 'English'], subject => {
            const candidates = parseInt(self.state[`${month}_${subject}_${yg}`], 10)
            if (candidates > 0) totalCost += candidates * 2
          })
        })
      })
    } else {
      totalCost = (_.find(oProducts, { productName: product }) || {}).cost;
    }
    const totalCostAfterDiscount = totalCost - (discount / 100) * totalCost;
    const oSelProduct = (_.find(oProducts, { productName: product }) || {})
    console.log("state", this.state, oSelProduct);
    // console.log("oPurchases", oPurchases);
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: '146px' }}>
        {/* <PageTitle title="Subscribe" />*/}
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "1180px" }}>
            <Card style={{ xmaxWidth: "75%", margin: "10px 0 10px 0" }}>
              <CardHeader title={`Subscribe${product === '' ? '' : ` (${oSelProduct.productNameLong})`}`} />
              <Divider />
              <CardContent>
                <Stepper activeStep={activeStep} style={{ padding: "0" }}>
                  <Step>
                    <StepLabel>Products</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Personals</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Options</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Summary</StepLabel>
                  </Step>
                </Stepper>
              </CardContent>
              <Divider />
              <CardContent>
                {activeStep === 0 && (
                  <Grid container style={{ flexGrow: 1, paddingTop: '30px', height: '790px' }}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={8}
                        alignItems="row"
                        justify="space-evenly"
                      >
                        <Card style={{ width: "25%" }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              color="secondary"
                            >
                              Assessing Primary
                              <br />
                              Writing
                            </Typography>
                            <Typography
                              variant="body2"
                              align="center"
                              paragraph
                            >
                              Years 1 - 6
                            </Typography>
                            <Typography variant="subtitle2" align="center" style={{ fontSize: '24px' }}>
                              £595 + VAT
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              align="center"
                              paragraph
                            >
                              per annum
                            </Typography>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                marginBottom: "20px",
                                textTransform: "inherit"
                              }}
                              disabled={_.includes(
                                _.map(oPurchases, "productName"),
                                "APW"
                              )}
                              onClick={e => {
                                self.handleSelectPersonalsPage("APW");
                              }}
                            >
                              {_.includes(
                                _.map(oPurchases, "productName"),
                                "APW"
                              )
                                ? `Purchased: ${moment(
                                    (
                                      _.find(oPurchases, {
                                        productName: "APW"
                                      }) || {}
                                    ).purchaseCreatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YY")}`
                                : `Select`}
                            </Button>
                            <Typography variant="caption" paragraph>
                              &#9632; 6 National Judging Windows
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; Get whole-school and nationally
                              benchmarked data
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; Save time and support progression &
                              intervention planning
                            </Typography>
                          </CardContent>
                        </Card>
                        {/*<Card style={{ width: "20%" }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              color="secondary"
                            >
                              Proof of Progress
                              <br />
                              2019-2020
                            </Typography>
                            <Typography
                              variant="body2"
                              align="center"
                              paragraph
                            >
                              Years 7 - 9
                            </Typography>
                            <Typography variant="subtitle2" align="center" style={{ fontSize: '24px' }}>
                              £2 + VAT
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              align="center"
                              paragraph
                            >
                              per candidate per assessment
                            </Typography>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                marginBottom: "20px",
                                textTransform: "inherit"
                              }}
                              disabled={_.includes(
                                _.map(oPurchases, "productName"),
                                "PoP1920"
                              )}
                              onClick={e => {
                                self.handleSelectPersonalsPage("PoP1920");
                              }}
                            >
                              {_.includes(
                                _.map(oPurchases, "productName"),
                                "PoP1920"
                              )
                                ? `Purchased: ${moment(
                                    (
                                      _.find(oPurchases, {
                                        productName: "PoP1920"
                                      }) || {}
                                    ).purchaseCreatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YY")}`
                                : `Select`}
                            </Button>
                            <Typography variant="caption">
                              &#9632; Available for Maths & English
                            </Typography>
                            <Typography variant="caption">
                              &#9632; Provides whole-school & nationally
                              benchmarked data
                            </Typography>
                            <Typography variant="caption">
                              &#9632; Saves time & supports progression &
                              intervention planning
                            </Typography>
                          </CardContent>
                        </Card>*/}
                        <Card style={{ width: "25%" }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              color="secondary"
                            >
                              Assessing
                              <br />
                              GCSE English
                            </Typography>
                            <Typography
                              variant="body2"
                              align="center"
                              paragraph
                            >
                              Years 10 - 11
                            </Typography>
                            <Typography variant="subtitle2" align="center" style={{ fontSize: '24px' }}>
                              £ 595 + VAT
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              align="center"
                              paragraph
                            >
                              per annum
                            </Typography>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                marginBottom: "20px",
                                textTransform: "inherit"
                              }}
                              disabled={_.includes(
                                _.map(oPurchases, "productName"),
                                "AGE"
                              )}
                              onClick={e => {
                                self.handleSelectPersonalsPage("AGE");
                              }}
                            >
                              {_.includes(
                                _.map(oPurchases, "productName"),
                                "AGE"
                              )
                                ? `Purchased: ${moment(
                                    (
                                      _.find(oPurchases, {
                                        productName: "AGE"
                                      }) || {}
                                    ).purchaseCreatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YY")}`
                                : `Select`}
                            </Button>
                            <Typography variant="caption" paragraph>
                              &#9632; Standardised & nationally benchmarked
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; GCSE English Language & English Literature
                              extended assessment & reporting
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; 4 Language judging windows & 2 Literature
                              judging windows
                            </Typography>
                          </CardContent>
                        </Card>

                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '80px' }}>
                      <Grid
                        container
                        spacing={8}
                        alignItems="row"
                        justify="space-evenly"
                      >

                        <Card style={{ width: "25%" }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              color="secondary"
                            >
                              The Writing
                              <br />
                              Revolution
                            </Typography>
                            <Typography
                              variant="body2"
                              align="center"
                              paragraph
                            >
                              Grade 1 - 12 (USA only)
                            </Typography>
                            <Typography variant="subtitle2" align="center" style={{ fontSize: '24px' }}>
                              &nbsp;
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              align="center"
                              paragraph
                            >
                              &nbsp;
                            </Typography>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                marginBottom: "20px",
                                textTransform: "inherit"
                              }}
                              disabled={_.includes(
                                _.map(oPurchases, "productName"),
                                "TWR"
                              )}
                              onClick={e => {
                                self.handleSelectPersonalsPage("TWR");
                              }}
                            >
                              {_.includes(
                                _.map(oPurchases, "productName"),
                                "TWR"
                              )
                                ? `Purchased: ${moment(
                                    (
                                      _.find(oPurchases, {
                                        productName: "TWR"
                                      }) || {}
                                    ).purchaseCreatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YY")}`
                                : `Select`}
                            </Button>
                            <Typography variant="caption" paragraph>
                              &#9632; Only available for the USA schools participating in <a href="https://www.thewritingrevolution.org/" target="_blank" rel="noopener noreferrer">The Writing Revolution</a> assessments
                            </Typography>

                          </CardContent>
                        </Card>
                        <Card style={{ width: "25%" }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              color="secondary"
                            >
                              Assessing America's
                              <br />
                              Writing (Elementary)
                            </Typography>
                            <Typography
                              variant="body2"
                              align="center"
                              paragraph
                            >
                              Grade 3 (USA Only)
                            </Typography>
                            <Typography variant="subtitle2" align="center" style={{ fontSize: '24px' }}>
                              $300
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              align="center"
                              paragraph
                            >
                              per annum
                            </Typography>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                marginBottom: "20px",
                                textTransform: "inherit"
                              }}
                              disabled={_.includes(
                                _.map(oPurchases, "productName"),
                                "AAW"
                              )}
                              onClick={e => {
                                self.handleSelectPersonalsPage("AAW");
                              }}
                            >
                              {_.includes(
                                _.map(oPurchases, "productName"),
                                "AAW"
                              )
                                ? `Purchased: ${moment(
                                    (
                                      _.find(oPurchases, {
                                        productName: "AAW"
                                      }) || {}
                                    ).purchaseCreatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YY")}`
                                : `Select`}
                            </Button>
                            <Typography variant="caption" paragraph>
                              &#9632; Only available for USA Schools
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; Two assessment windows for Grade 3, one in January and one in June
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; You must sign up by December 13th
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card style={{ width: "25%" }}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              align="center"
                              color="secondary"
                            >
                              Assessing America's
                              <br />
                              Writing (High)
                            </Typography>
                            <Typography
                              variant="body2"
                              align="center"
                              paragraph
                            >
                              Grade 9 (USA Only)
                            </Typography>
                            <Typography variant="subtitle2" align="center" style={{ fontSize: '24px' }}>
                              $300
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              align="center"
                              paragraph
                            >
                              per annum
                            </Typography>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                marginBottom: "20px",
                                textTransform: "inherit"
                              }}
                              disabled={_.includes(
                                _.map(oPurchases, "productName"),
                                "AAWS"
                              )}
                              onClick={e => {
                                self.handleSelectPersonalsPage("AAWS");
                              }}
                            >
                              {_.includes(
                                _.map(oPurchases, "productName"),
                                "AAWS"
                              )
                                ? `Purchased: ${moment(
                                    (
                                      _.find(oPurchases, {
                                        productName: "AAWS"
                                      }) || {}
                                    ).purchaseCreatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD/MM/YY")}`
                                : `Select`}
                            </Button>
                            <Typography variant="caption" paragraph>
                              &#9632; Only available for USA Schools
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; Two assessment windows for Grade 9, one in January and one in June
                            </Typography>
                            <Typography variant="caption" paragraph>
                              &#9632; You must sign up by December 13th
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {activeStep === 1 && oSelProduct.country === 'GB' && (
                  <Grid container style={{ flexGrow: 1 }}>
                    <Formik
                      initialValues={{
                        personFirstName,
                        personJobTitle,
                        personLastName,
                        personPhone,
                        schoolName,
                        outsideUK,
                        dfe,
                        address1,
                        town,
                        country,
                        urn,
                        address2,
                        postcode,
                        financeName,
                        financePhone,
                        financeEmail,
                        financeJobTitle,
                        purchaseOrder,
                        couponCode,
                        hearAboutUs
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        console.log("submit", values, fixedDfE, outsideUK);
                        self.setState({ disabled: true });
                        /*AxiosAll.post(`/accounts/subscriptionCheckDfEURN`, {
                          dfe: parseInt(values.dfe, 10),
                          urn: parseInt(values.urn, 10),
                          outsideUK: values.outsideUK,
                        }).then(oResult => {
                          // console.log("subscriptionCheckDfEURN", oResult);
                          if (oResult.data.body === "") {*/
                            self.setState({
                              activeStep: product === "PoP1920" ? 2 : 3,
                              msg: <span />,
                              disabled: false,
                              personFirstName: values.personFirstName,
                              personJobTitle: values.personJobTitle,
                              personLastName: values.personLastName,
                              personPhone: values.personPhone,
                              schoolName: values.schoolName2,
                              outsideUK: values.outsideUK,
                              dfe: values.dfe,
                              address1: values.address1,
                              town: values.town,
                              country: values.country,
                              urn: values.urn,
                              address2: values.address2,
                              postcode: values.postcode,
                              financeName: values.financeName,
                              financePhone: values.financePhone,
                              financeEmail: values.financeEmail,
                              financeJobTitle: values.financeJobTitle,
                              purchaseOrder: values.purchaseOrder,
                              couponCode: values.couponCode,
                              hearAboutUs: values.hearAboutUs
                            });
                            animateScroll.scrollToTop();
                          /*} else {
                            // console.log("*", oResult, values.couponCode);
                            self.setState({
                              errorMsg: (
                                <Typography color="secondary">
                                  {oResult.data.body}
                                </Typography>
                              ),
                              disabled: false,
                              couponCode: values.couponCode
                            });
                            animateScroll.scrollToTop();
                          }
                          setSubmitting(false);
                        });*/
                      }}
                      validationSchema={Yup.object().shape({
                        personFirstName: Yup.string().required("Required"),
                        personLastName: Yup.string().required("Required"),
                        personJobTitle: Yup.string().required("Required"),
                        personPhone: Yup.string().required("Required"),
                        schoolName2: Yup.string().required("Required"),
                        //outsideUK: Yup.boolean(),
                        //dfe: Yup.number().when('outsideUK', { is: false, then: Yup.number().moreThan(1000000, 'Must be a 7 digit number').lessThan(9999999, 'Must be a 7 digit number').required() }),
                        //urn: Yup.number().when('outsideUK', { is: false, then: Yup.number().required() }),
                        //address1: Yup.string().required("Required"),
                        //address2: Yup.string(),
                        //town: Yup.string().required("Required"),
                        //postcode: Yup.string(),
                        //country: Yup.string().required("Required"),
                        financeName: Yup.string().required("Required"),
                        financePhone: Yup.string().required("Required"),
                        financeEmail: Yup.string().email('Must be a valid email address').required("Required"),
                        financeJobTitle: Yup.string().required("Required")
                      })}
                    >
                      {({ isSubmitting, values, errors, dirty, setFieldValue }) => (
                        <Form>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography
                                variant="h6"
                                xstyle={{ marginBottom: "-30px" }}
                              >
                                Personal Details
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="First Name"
                                name="personFirstName"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Job Title"
                                name="personJobTitle"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikTextField
                                label="Last Name"
                                name="personLastName"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Phone"
                                name="personPhone"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography variant="h6">
                                School Details
                              </Typography>
                              {/*<Typography>
                                For schools without a DfE & URN number please
                                contact{" "}
                                <a href="mailto:support@nomoremarking.com">
                                  support@nomoremarking.com
                                </a>
                                .
                              </Typography>
                              {errorMsg}
                              <FormikTextField
                                label="School Name"
                                name="schoolName"
                                fullWidth
                                style={{ marginBottom: "20px" }}
                              />
                              <FormikSelectField label="School outside UK?" name="outsideUK" fullWidth disabled={fixedDfE} options={[
                                { label: "No", value: false },
                                { label: "Yes", value: true },
                              ]} onChange={e => {
                                this.setState({
                                  outsideUK: e.target.value,
                                })
                                console.log('change', e.target.value, this.state)
                              }} />*/}
                              <Typography style={_.has(errors, 'schoolName2') ? { fontSize: '12px', fontWeight: '400', color: 'red' } : { fontSize: '12px', fontWeight: '400', color: 'rgba(0, 0, 0, 0.54)' }}>Select School (Enter at least 3 characters)</Typography>
                              <AsyncSelect
                                cacheOptions
                                loadOptions={(inputValue, callback) => {
                                  if (inputValue.length < 3) {
                                    callback([])
                                  }
                                  else {
                                    console.log('lookup', inputValue)
                                    //AxiosCall.post(`/accounts_getSchoolNameOptions`, { chars: inputValue, product: product, country: country })
                                    AxiosAll.post(`/noauth/getSchoolNameOptions`, { chars: inputValue, product: product, country: country })
                                    .then(oRes => {
                                      this.setState({ schoolOptions: oRes.data })
                                      console.log('getSchoolNameOptions', oRes.data)
                                      callback(oRes.data)
                                    })
                                    
                                  }
                                }}
                                defaultOptions
                                onInputChange={(newValue) => {
                                  console.log('newval', newValue)
                                  this.setState({ inputValue: newValue })
                                }}
                                onChange={(selRow) => {
                                  setFieldValue('schoolName2', selRow.schoolName)
                                  //setFieldValue('nces', selRow.NCESSchoolID)
                                  setFieldValue('dfe', selRow.dfe)
                                  setFieldValue('urn', selRow.urn)
                                  // setFieldValue('stateSchoolId', 'x')
                                  setFieldValue('address1', selRow.address1)
                                  setFieldValue('address2', selRow.address2)
                                  setFieldValue('town', selRow.town)
                                  setFieldValue('country', 'GB')
                                  setFieldValue('postcode', selRow.postcode)
                                  this.setState({
                                    schoolName: selRow.SchoolName,
                                    schoolName2: selRow.SchoolName,
                                    nces: selRow.NCESSchoolID,
                                    dfe: selRow.NCESSchoolID,
                                    stateSchoolId: 'x',
                                    address1: selRow.StreetAddress,
                                    town: selRow.City,
                                    country: 'GB',
                                    urn: -1,
                                    address2: selRow.State,
                                    postcode: selRow.ZIP,
                                  })
                                  console.log('on change', selRow)
                                }}
                              />
                              {_.has(errors, 'schoolName2') && <Typography style={{ color: 'red', fontSize: '0.75rem', lineHeight: '1.5' }}>{errors.schoolName2}</Typography>}
                              <Typography variant="caption">Can't find your school?  Please email us at <a href="mailto:support@nomoremarking.com">support@nomoremarking.com</a>.</Typography>
                            </Grid>
                            {/*<Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              {!outsideUK && <FormikTextField
                                label="DfE Number"
                                type="number"
                                name="dfe"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                                disabled={fixedDfE}
                                helperText="7 digit number expected"
                              />}
                              <FormikTextField
                                label="Address 1"
                                name="address1"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Town"
                                name="town"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Country"
                                name="country"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {!outsideUK && <FormikTextField
                                label="URN"
                                type="number"
                                name="urn"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                                disabled={fixedDfE}
                                helperText="6 digit number expected"
                              />}
                              <FormikTextField
                                label="Address 2"
                                name="address2"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Postcode"
                                name="postcode"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>*/}
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ marginBottom: "-30px" }}
                              >
                                Finance Contact Details
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="Name"
                                name="financeName"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Phone"
                                name="financePhone"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikTextField
                                type="email"
                                label="Email"
                                name="financeEmail"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Job Title"
                                name="financeJobTitle"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ marginBottom: "-30px" }}
                              >
                                Other
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="Purchase Order No"
                                name="purchaseOrder"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikTextField
                                label="Coupon Code"
                                name="couponCode"
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px"
                              }}
                            >
                              <FormikTextField
                                style={{
                                  marginTop: "-20px",
                                  marginBottom: "20px"
                                }}
                                label="Where did you hear about us?"
                                name="hearAboutUs"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={disabled || isSubmitting}
                                style={{ textTransform: "inherit" }}
                              >
                                Next
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                )}

                {activeStep === 1 && oSelProduct.country === 'USA' && (
                  <Grid container style={{ flexGrow: 1 }}>
                    <Formik
                      initialValues={{
                        personFirstName,
                        personJobTitle,
                        personLastName,
                        personPhone,
                        //schoolName,
                        schoolName2,
                        //nces,
                        //stateSchoolId,
                        //address1,
                        //town,
                        //country,
                        //urn,
                        //address2,
                        //postcode,
                        financeName,
                        financePhone,
                        financeEmail,
                        financeJobTitle,
                        purchaseOrder,
                        couponCode,
                        hearAboutUs
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        console.log("submit", values, fixedDfE, outsideUK);
                        self.setState({ disabled: true });
                        /*AxiosAll.post(`/accounts/subscriptionCheckNCES`, {
                          nces: parseInt(values.nces, 10),
                          stateSchoolId: values.stateSchoolId,
                        }).then(oResult => {
                          console.log("subscriptionCheckNCES", oResult);
                          if (oResult.data.body === "") {*/
                            self.setState({
                              activeStep: 3,
                              msg: <span />,
                              disabled: false,
                              personFirstName: values.personFirstName,
                              personJobTitle: values.personJobTitle,
                              personLastName: values.personLastName,
                              personPhone: values.personPhone,
                              schoolName: values.schoolName,
                              nces: values.nces,
                              stateSchoolId: values.stateSchoolId,
                              address1: values.address1,
                              town: values.town,
                              country: values.country,
                              // urn: values.urn,
                              address2: values.address2,
                              postcode: values.postcode,
                              financeName: values.financeName,
                              financePhone: values.financePhone,
                              financeEmail: values.financeEmail,
                              financeJobTitle: values.financeJobTitle,
                              purchaseOrder: values.purchaseOrder,
                              couponCode: values.couponCode,
                              hearAboutUs: values.hearAboutUs
                            });
                            animateScroll.scrollToTop();
                          /*} else {
                            // console.log("*", oResult, values.couponCode);
                            self.setState({
                              errorMsg: (
                                <Typography color="secondary">
                                  {oResult.data.body}
                                </Typography>
                              ),
                              disabled: false,
                              couponCode: values.couponCode
                            });
                            animateScroll.scrollToTop();
                          }*/
                          setSubmitting(false);
                        //});
                      }}
                      validationSchema={Yup.object().shape({
                        personFirstName: Yup.string().required("Required"),
                        personLastName: Yup.string().required("Required"),
                        personJobTitle: Yup.string().required("Required"),
                        personPhone: Yup.string().required("Required"),
                        //schoolName: Yup.string().required("Required"),
                        schoolName2: Yup.string().required("Required"),
                        //nces: Yup.number().min(9999999999, '11-12 digits expected').max(999999999999, '11-12 digits expected').required('Required, can be found at https://nces.ed.gov/ccd/schoolsearch'),
                        //stateSchoolId: Yup.string(),
                        //address1: Yup.string().required("Required"),
                        //address2: Yup.string(),
                        //town: Yup.string().required("Required"),
                        //postcode: Yup.string(),
                        financeName: Yup.string().required("Required"),
                        financePhone: Yup.string().required("Required"),
                        financeEmail: Yup.string().email('Must be a valid email address').required("Required"),
                        financeJobTitle: Yup.string().required("Required")
                      })}
                    >
                      {({ isSubmitting, values, errors, dirty, setFieldValue }) => (
                        <Form>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography
                                variant="h6"
                              >
                                Personal Details
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="First Name"
                                name="personFirstName"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Job Title"
                                name="personJobTitle"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikTextField
                                label="Last Name"
                                name="personLastName"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Phone"
                                name="personPhone"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography variant="h6">
                                School Details
                              </Typography>
                              {errorMsg}
                              {/*<FormikTextField
                                label="School Name"
                                name="schoolName"
                                fullWidth
                                style={{ marginBottom: "20px" }}
                              />*/}
                              <Typography style={_.has(errors, 'schoolName2') ? { fontSize: '12px', fontWeight: '400', color: 'red' } : { fontSize: '12px', fontWeight: '400', color: 'rgba(0, 0, 0, 0.54)' }}>Select School (Enter at least 3 characters)</Typography>
                              <AsyncSelect
                                cacheOptions
                                loadOptions={(inputValue, callback) => {
                                  if (inputValue.length < 3) {
                                    callback([])
                                  }
                                  else {
                                    console.log('lookup', inputValue)
                                    // AxiosCall.post(`/accounts_getSchoolNameOptions`, { chars: inputValue, product: product, country: country })
                                    AxiosAll.post(`/noauth/getSchoolNameOptions`, { chars: inputValue, product: product, country: country })
                                    .then(oRes => {
                                      this.setState({ schoolOptions: oRes.data })
                                      console.log('getSchoolNameOptions', oRes.data)
                                      callback(oRes.data)
                                    })
                                    
                                  }
                                }}
                                defaultOptions
                                onInputChange={(newValue) => {
                                  console.log('newval', newValue)
                                  this.setState({ inputValue: newValue })
                                }}
                                onChange={(selRow) => {
                                  setFieldValue('schoolName2', selRow.SchoolName)
                                  setFieldValue('nces', selRow.NCESSchoolID)
                                  setFieldValue('dfe', selRow.NCESSchoolID)
                                  setFieldValue('stateSchoolId', 'x')
                                  setFieldValue('address1', selRow.StreetAddress)
                                  setFieldValue('town', selRow.City)
                                  setFieldValue('country', 'USA')
                                  setFieldValue('urn', -1)
                                  setFieldValue('address2', selRow.State)
                                  setFieldValue('postcode', selRow.ZIP)
                                  this.setState({
                                    schoolName: selRow.SchoolName,
                                    schoolName2: selRow.SchoolName,
                                    nces: selRow.NCESSchoolID,
                                    dfe: selRow.NCESSchoolID,
                                    stateSchoolId: 'x',
                                    address1: selRow.StreetAddress,
                                    town: selRow.City,
                                    country: 'USA',
                                    urn: -1,
                                    address2: selRow.State,
                                    postcode: selRow.ZIP,
                                  })
                                  console.log('on change', selRow)
                                }}
                              />
                              {_.has(errors, 'schoolName2') && <Typography style={{ color: 'red', fontSize: '0.75rem', lineHeight: '1.5' }}>{errors.schoolName2}</Typography>}
                              <Typography variant="caption">Can't find your school?  Please email us at <a href="mailto:support@nomoremarking.com">support@nomoremarking.com</a>.</Typography>
                            </Grid>
                            {/*<Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                xmarginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="NCES ID"
                                type="number"
                                name="nces"
                                style={{ marginBottom: '20px' }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Address 1"
                                name="address1"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Town"
                                name="town"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>*/}
                            {/*<Grid item xs={12} sm={6}>
                              <FormikTextField
                                label="State School ID"
                                name="stateSchoolId"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Address 2"
                                name="address2"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Zip"
                                name="postcode"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>*/}
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ marginBottom: "-30px" }}
                              >
                                Finance Contact Details
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="Name"
                                name="financeName"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Phone"
                                name="financePhone"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikTextField
                                type="email"
                                label="Email"
                                name="financeEmail"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                              <FormikTextField
                                label="Job Title"
                                name="financeJobTitle"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ marginBottom: "-30px" }}
                              >
                                Other
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginBottom: "30px"
                              }}
                            >
                              <FormikTextField
                                label="Purchase Order No"
                                name="purchaseOrder"
                                style={{ marginBottom: "20px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikTextField
                                label="Coupon Code"
                                name="couponCode"
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px"
                              }}
                            >
                              <FormikTextField
                                style={{
                                  marginTop: "-20px",
                                  marginBottom: "20px"
                                }}
                                label="Where did you hear about us?"
                                name="hearAboutUs"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={disabled || isSubmitting}
                                style={{ textTransform: "inherit" }}
                              >
                                Next
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                )}

                {activeStep === 2 && (
                  <Grid container style={{ flexGrow: 1 }}>
                    <Formik
                      initialValues={{
                        mathsY7,
                        englishY7,
                        mathsY8,
                        englishY8,
                        mathsY9,
                        englishY9,
                        Sept_Maths_7,
                        Sept_English_7,
                        June_Maths_7,
                        June_English_7,
                        Sept_Maths_8,
                        Sept_English_8,
                        June_Maths_8,
                        June_English_8,
                        Sept_Maths_9,
                        Sept_English_9,
                        June_Maths_9,
                        June_English_9,
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        /*const sumPoPPupils =
                          parseInt(values.mathsY7, 10) +
                          parseInt(values.englishY7, 10) +
                          parseInt(values.mathsY8, 10) +
                          parseInt(values.englishY8, 10) +
                          parseInt(values.mathsY9, 10) +
                          parseInt(values.englishY9, 10);*/
                        let sumPoPPupils = 0
                        _.each([7, 8, 9], yg => {
                          _.each(['Sept', 'June'], month => {
                            _.each(['Maths', 'English'], subject => {
                              sumPoPPupils += parseInt(values[`${month}_${subject}_${yg}`], 10)
                            })
                          })
                        })
                        if (sumPoPPupils === 0) {
                          self.setState({
                            errorMsg: (
                              <ListItemText
                                primary={<Typography color="secondary">At least one cohort must be selected</Typography>}
                              />
                            )
                          });
                        } else {
                          let oState = values;
                          oState.activeStep = 3;
                          self.setState(oState);
                        }
                      }}
                      validationSchema={Yup.object().shape({
                        /*mathsY7: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less"),
                        englishY7: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less"),
                        mathsY8: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less"),
                        englishY8: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less"),
                        mathsY9: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less"),
                        englishY9: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less"),*/
                          Sept_Maths_7: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          Sept_English_7: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          June_Maths_7: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          June_English_7: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          Sept_Maths_8: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          Sept_English_8: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          June_Maths_8: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          June_English_8: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          Sept_Maths_9: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          Sept_English_9: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          June_Maths_9: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                          June_English_9: Yup.number()
                          .moreThan(-1, "Must be 0 or greater")
                          .lessThan(1001, "Must be 1000 or less").required('Required'),
                      })}
                    >
                      {({ isSubmitting, values, errors, dirty }) => (
                        <Form>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="PoP options: select cohorts and candidate numbers"
                                secondary="Candidate numbers must be between 0 and 1000"
                              />
                            </ListItem>
                            <ListItem>{errorMsg}</ListItem>
                            <ListItem>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell />
                                    <TableCell colSpan={2} align="center">September</TableCell>
                                    <TableCell colSpan={2} align="center">June</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell />
                                    <TableCell align="center">Maths</TableCell>
                                    <TableCell align="center">English</TableCell>
                                    <TableCell align="center">Maths</TableCell>
                                    <TableCell align="center">English</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {[7, 8, 9].map(yg => (
                                    <TableRow key={`tr_${yg}`}>
                                      <TableCell align="center">Year {yg}</TableCell>
                                      {[{ month: 'Sept', subject: 'Maths' }, { month: 'Sept', subject: 'English' }, { month: 'June', subject: 'Maths' }, { month: 'June', subject: 'English' }].map(oCohort => (
                                          <TableCell key={`tc_${yg}_${oCohort.month}_${oCohort.subject}`}>
                                            <FormikTextField type="number" name={`${oCohort.month}_${oCohort.subject}_${yg}`} style={{ width: '80px' }} />
                                          </TableCell>
                                        ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </ListItem>
                            {/*<ListItem>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell />
                                    <TableCell>Maths</TableCell>
                                    <TableCell>English</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Year 7</TableCell>
                                    <TableCell>
                                      <FormikTextField
                                        type="number"
                                        name="mathsY7"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <FormikTextField
                                        type="number"
                                        name="englishY7"
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Year 8</TableCell>
                                    <TableCell>
                                      <FormikTextField
                                        type="number"
                                        name="mathsY8"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <FormikTextField
                                        type="number"
                                        name="englishY8"
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Year 9</TableCell>
                                    <TableCell>
                                      <FormikTextField
                                        type="number"
                                        name="mathsY9"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <FormikTextField
                                        type="number"
                                        name="englishY9"
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </ListItem>*/}
                          </List>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={disabled}
                              style={{ textTransform: "inherit" }}
                            >
                              Next
                            </Button>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                )}

                {activeStep === 3 && (
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <ListItemText primary="Product" />
                          <Typography variant="body2">{product}</Typography>
                        </ListItem>
                        {totalCost === totalCostAfterDiscount && (
                          <ListItem>
                            <ListItemText primary="Total Cost" />
                            <Typography
                              variant="body2"
                              style={{ textAlign: "right" }}
                            >
                              Total Cost: {_.includes(['AAW', 'TWR'], product) ? `$${(totalCost || 0).toFixed(2)}` : `£${totalCost.toFixed(2)} + VAT`}
                            </Typography>
                          </ListItem>
                        )}
                        {totalCost !== totalCostAfterDiscount && (
                          <ListItem>
                            <ListItemText primary="Total Cost" />
                            <Typography
                              variant="body2"
                              style={{ textAlign: "right" }}
                            >
                              Total Cost: {_.includes(['AAW', 'TWR'], product) ? `$${(totalCost || 0).toFixed(2)}` : `£${(totalCost || 0).toFixed(2)} + VAT`}
                              <br />
                              Total Cost after Discount: {_.includes(['AAW', 'TWR'],product) > -1 ? `$${(totalCost || 0).toFixed(2)}` : `£${totalCostAfterDiscount.toFixed(2)} + VAT`}
                              <br />
                              Discount Code: {couponCode} ({discount}%)
                            </Typography>
                          </ListItem>
                        )}
                        {product === "PoP1920" && (
                          <ListItem>
                            <ListItemText primary="Candidates" />
                            <Typography
                              variant="body2"
                              style={{ textAlign: "right" }}
                            >
                              {['Sept', 'June'].map(month => (
                                <div key={`div_${month}`}>
                                {["Maths", "English"].map(subject => (
                                  <div key={`div_${month}_${subject}`}>
                                  {[7, 8, 9].map(yg => (
                                    <div key={`div_${month}_${subject}_${yg}`}>
                                      {self.state[`${month}_${subject}_${yg}`] > 0 && <span>
                                        {month} {subject} Y{yg}: {self.state[`${month}_${subject}_${yg}`]}
                                      </span>}
                                    </div>
                                  ))}
                                  </div>
                                ))}
                                </div>
                              ))}
                              {["Maths", "English"].map(subject => {
                                return [7, 8, 9].map(yg => {
                                  if (
                                    parseInt(
                                      self.state[
                                        `${subject.toLowerCase()}Y${yg}`
                                      ],
                                      10
                                    ) === 0
                                  )
                                    return <span />;
                                  return (
                                    <span
                                      key={`pop_${subject.toLowerCase()}_${yg}`}
                                    >
                                      {subject} Y{yg}:{" "}
                                      {
                                        self.state[
                                          `${subject.toLowerCase()}Y${yg}`
                                        ]
                                      }
                                      <br />
                                    </span>
                                  );
                                });
                              })}
                            </Typography>
                          </ListItem>
                        )}
                        <ListItem>
                          <ListItemText primary="Your Details" />
                          <Typography
                            variant="body2"
                            style={{ textAlign: "right" }}
                          >
                            {personFirstName} {personLastName}
                            <br />
                            {personJobTitle}
                            <br />
                            {personPhone}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="School Details" />
                          <Typography
                            variant="body2"
                            style={{ textAlign: "right" }}
                          >
                            {schoolName}
                            <br />
                            {oSelProduct.country === 'GB' && outsideUK && <span>Outside UK</span>}
                            {oSelProduct.country === 'GB' && !outsideUK && <span>{dfe} / {urn}</span>}
                            {oSelProduct.country === 'USA' && <span>{nces}</span>}
                            <br />
                            {address1}
                            <br />
                            {address2}
                            <br />
                            {town}&nbsp;{postcode}
                            <br />
                            {country}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Finance Details" />
                          <Typography
                            variant="body2"
                            style={{ textAlign: "right" }}
                          >
                            {financeName}
                            <br />
                            {financeEmail}
                            <br />
                            {financePhone}
                            <br />
                            {financeJobTitle}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={termsCheck}
                                onChange={e =>
                                  self.setState({ termsCheck: !termsCheck })
                                }
                                value="checked"
                              />
                            }
                          />
                          <a
                            href="https://s3-eu-west-1.amazonaws.com/nmm-v2/terms/GDPR+agreement.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            I accept the terms & conditions
                          </a>
                        </ListItem>
                        <ListItem>
                          <Typography variant="body2">
                            By clicking <strong>Complete Purchase</strong> you
                            agree to be invoiced for the selected products.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={!termsCheck || disabled}
                            onClick={e => {
                              self.handleCompletePurchase();
                            }}
                            style={{ textTransform: "inherit" }}
                          >
                            Complete Purchase
                          </Button>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                )}

                {activeStep === 4 && (
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <Typography variant="body2">
                            Thank you for your purchase.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography variant="body2">
                            You will shortly be sent a confirmation email with
                            your next steps.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Button variant="contained" color="primary" size="small" href="/tasks?tab=1" style={{ textTransform: 'inherit' }}>My Tasks</Button>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
              {activeStep === 0 && (
                <CardContent>
                  <Typography variant="body1" align="center" paragraph>
                    Alternatively, if you would like to use the chat support on
                    our site and link your school MIS to the site with Assembly
                    then get in touch at{" "}
                    <a href="mailto:support@nomoremarking.com">
                      support@nomoremarking.com
                    </a>
                    .
                  </Typography>
                  {oPurchases.length > 0 && (
                    <Typography variant="body1" align="center">
                      If you would like to cancel any existing contracts please
                      email <a href="mailto:support@nomoremarking.com">support@nomoremarking.com</a> stating your DfE number. 
                    </Typography>
                  )}
                  {/*<Grid style={{ padding: "40px" }} id="PoPCosts">
                    <Typography variant="h4" gutterBottom>
                      Proof of Progress Costs
                    </Typography>
                  </Grid>
                  <POPCosts />*/}
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
        </Grid>
        </Grid>
    );
  }
}

export default withRouter(Subscribe2);
