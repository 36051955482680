import React, { Component } from "react";
import { Grid, Typography, Button, TextField } from "@material-ui/core";
import { Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
// import PageTitle from "./../components/pageTitle.js";
import FlipMove from "react-flip-move";
import AxiosAll from "./../axiosAll";
import PageTitle from './../components/pageTitle.js'

const _ = require("lodash");
// const cloneTask = "9edf41ae-b68c-42aa-9e47-8df687acb0a5";

class Demo2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  state = {
    activeStep: 0,
    colours: [
      { score: 1, colour: "rgba(63, 31, 113, 0.2)" },
      { score: 2, colour: "rgba(63, 31, 113, 0.3)" },
      { score: 3, colour: "rgba(63, 31, 113, 0.5)" },
      { score: 4, colour: "rgba(63, 31, 113, 0.6)" },
      { score: 5, colour: "rgba(63, 31, 113, 0.7)" },
      { score: 6, colour: "rgba(63, 31, 113, 0.8)" },
      { score: 7, colour: "rgba(63, 31, 113, 0.9)" },
      { score: 8, colour: "rgba(63, 31, 113, 1)" }
    ],
    current: 0,
    value: "",
    shuffled: [],
    // cloneTask: "9edf41ae-b68c-42aa-9e47-8df687acb0a5",
    judge: "",
    left: "",
    right: "",
    leftCandidate: "",
    rightCandidate: "",
    score: 0,
    selected: 0,
    disabled: false,
    loaded: false,
    oColours: [],
    task: "",
    newtask: "",
    decision: "",
    choice: ""
  };
  componentDidMount() {
    const { colours } = this.state;

    AxiosAll.post(`/tasks/cloneTaskAndCandidates`).then(oResult => {
      // console.log("oRes", oResult);
      const newtask = oResult.data.task;
      const judge = oResult.data.judge;
      // console.log('newTask', newtask, 'judge', judge)
      /*AxiosAll.post(`/judging/initialiseTaskJudging`, {
        task: newtask
      }).then(oResult => {*/
      // console.log("oRes2", oResult);
      AxiosAll.post(`/candidates/getCandidatesForTask`, {
        task: newtask
      }).then(oCandidates => {
        // console.log('getCandidatesForTask', oCandidates)
        let oColours = oCandidates.data;
        oColours = _.map(oColours, function(oColour) {
          oColour.colour = (
            _.find(colours, {
              score: parseInt(oColour.qrcode.replace("AAAAA", ""), 10)
            }) || {}
          ).colour;
          oColour.score = parseInt(oColour.qrcode.replace("AAAAA", ""), 10);
          return oColour;
        });
        oColours = _.orderBy(oColours, ["trueScore", "asc"]);
        // console.log("oCandidates", oCandidates.data, oColours);
        this.setState({
          oColours: _.shuffle(oColours),
          task: newtask,
          judge: judge,
          loaded: true,
          newtask: newtask
        });
      });
      //});
    });
  }
  handleMarkingStep = e => {
    this.setState({
      activeStep: 1,
      shuffled: _.shuffle(this.state.colours)
    });
  };
  handleMarkEntered = e => {
    const self = this;
    const { shuffled, current } = this.state;
    this.setState({ value: e.key })
    if (e.key.search(/[1-8]/) === 0) {
      shuffled[current].response = parseInt(e.key, 10);
      setTimeout(() => {
        if (current < 7) {
          self.setState({
            shuffled: shuffled,
            current: current + 1,
            value: ""
          });
        } else {
          self.setState({
            shuffled: shuffled,
            activeStep: 2
          });
        }
      }, 200);

    } else {

    }
  };
  handleStartJudging = e => {
    const { task, judge } = this.state;
    this.setState({ disabled: false });
    AxiosAll.post(`/judging/recordJudgementAndGetNextPair`, {
      task: task,
      judge: judge,
      isExternal: false
    }).then(oNextPair => {
      // console.log("oNextPair", oNextPair);
      this.setState({
        left: oNextPair.data.left.qrcode,
        right: oNextPair.data.right.qrcode,
        leftCandidate: oNextPair.data.left._id,
        rightCandidate: oNextPair.data.right._id,
        activeStep: 3,
        disabled: false,
        decision: oNextPair.data.decision
      });
    });
  };
  handleSelectSide = (side, perfectScale) => {
    if (perfectScale) return;
    const {
      task,
      judge,
      left,
      right,
      leftCandidate,
      rightCandidate,
      newtask,
      colours,
      // oColours,
    } = this.state;
    this.setState({ disabled: true });
    let chosen;
    let notChosen;
    let chosenQR;
    let notChosenQR;
    if (side === "Left") {
      chosen = leftCandidate;
      notChosen = rightCandidate;
      chosenQR = left;
      notChosenQR = right;
    } else {
      chosen = rightCandidate;
      notChosen = leftCandidate;
      chosenQR = right;
      notChosenQR = left;
    }
    // console.log('record Judge', task, judge, chosen, notChosen)
    AxiosAll.post(`/judging/recordJudgementAndGetNextPair`, {
      task: task,
      judge: judge,
      chosen: chosen,
      notChosen: notChosen,
      timeTaken: 0,
      isLeft: false,
      isExternal: false
    }).then(oNextPair => {
      // console.log("oNextPair", oNextPair, newtask);
      AxiosAll.post(`/tasks/miniRefreshTask`, {
        task: newtask
      }).then(oRefresh => {
        console.log('oRefresh', oRefresh.data)
        AxiosAll.post(`/candidates/getCandidatesForTask`, {
          task: newtask
        }).then(oCandidates => {
          /*console.log('>Get Scores', oCandidates.data)
          let oUpdatedColours = _.map(oColours, oColour => {
            console.log('=', oColour.qrcode)
            oColour.trueScore = _.find(oCandidates.data, { qrcode: oColour.qrcode }).trueScore
            return oColour
          })*/

          let oColours = oCandidates.data;
          oColours = _.map(oColours, function(oColour) {
            oColour.colour = (
              _.find(colours, {
                score: parseInt(oColour.qrcode.replace("AAAAA", ""), 10)
              }) || {}
            ).colour;
            oColour.score = parseInt(oColour.qrcode.replace("AAAAA", ""), 10);
            return oColour;
          });
          oColours = _.orderBy(oColours, ["trueScore", "asc"]);
          this.setState(prevState => ({
            left: oNextPair.data.left.qrcode,
            right: oNextPair.data.right.qrcode,
            leftCandidate: oNextPair.data.left._id,
            rightCandidate: oNextPair.data.right._id,
            score:
              chosenQR > notChosenQR ? prevState.score + 1 : prevState.score,
            selected: prevState.selected + 1,
            oColours: oColours,
            disabled: false,
            decision: oNextPair.data.decision
          }));
        });
      });
    });
  };
  render() {
    const self = this;
    const {
      activeStep,
      colours,
      shuffled,
      current,
      value,
      left,
      right,
      score,
      selected,
      disabled,
      loaded,
      oColours
    } = this.state;
    const remaining = _.filter(shuffled, function(o) {
      return !_.has(o, "response");
    });
    const marked = _.filter(shuffled, function(o) {
      return _.has(o, "response");
    });
    console.log("state", this.state);
    if (!loaded) return <span />;
    const perfectScale = _.every(_.map(oColours, "qrcode"), function(
      value,
      index,
      array
    ) {
      return index === 0 || String(array[index - 1]) <= String(value);
    });
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: '146px' }}>
        <PageTitle title="Comparative Judgement Demo" img="/assets/compare-apples.jpg" />
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                position: "relative",
                width: "1180px"
              }}
            >
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '20px', color: '#af1280' }} gutterBottom>
                      Why Comparative Judgement Works and Marking Does Not Work
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      Marking does not work when it involves any degree of human
                      judgement. This is due to a simple principle.
                    </Typography>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      "There is no absolute judgement. All judgements are
                      comparisons of one thing with another". (Human Judgement:
                      The Eye of the Beholder by Donald Laming, p.9.)
                    </Typography>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      Laming has shown that at best our judgements are ordinal.
                      We can place things in an order, but scarcely more than
                      this. Ask two people to apply a mark scheme and you will
                      most likely get different marks. Ask people to place two
                      scripts in order, and you will get more consistency.
                    </Typography>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px', color: '#af1280' }} gutterBottom>
                      This demonstration illustrates this principle. Each number
                      is associated with a colour. Memorise the number along
                      with its colour.
                    </Typography>
                    <Table
                      style={{
                        width: "auto",
                        marginTop: "-50px",
                        marginBottom: "20px"
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {colours.map(function(oColour) {
                            return (
                              <TableCell key={`col1_${oColour.score}`}>
                                &nbsp;
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {colours.map(function(oColour) {
                            return (
                              <TableCell
                                key={`col2_${oColour.score}`}
                                style={{ textAlign: "center" }}
                              >
                                <Button
                                  key={`btn1_${oColour.score}`}
                                  style={{
                                    backgroundColor: oColour.colour,
                                    minWidth: "50px"
                                  }}
                                >
                                  &nbsp;
                                </Button>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          {colours.map(function(oColour) {
                            return (
                              <TableCell
                                key={`col3_${oColour.score}`}
                                style={{ fontFamily: 'Source Sans Pro', textAlign: "center" }}
                              >
                                {oColour.score}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={self.handleMarkingStep}
                      disabled={disabled}
                      style={{
                      width: "145px",
                      height: "40px",
                      backgroundColor: "#3f1f71",
                      color: "#ffffff",
                      textTransform: "inherit",
                      fontFamily: "Source Sans Pro"
                    }}
                    >
                      Start Test
                    </Button>
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '20px', color: '#af1280' }} gutterBottom>
                      Marking
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      Give each colour a darkness mark from 1 to 8 (light to
                      dark):
                    </Typography>
                    <Button
                      style={
                        remaining.length
                          ? {
                              backgroundColor: shuffled[current].colour,
                              minWidth: "50px"
                            }
                          : {}
                      }
                    >
                      &nbsp;
                    </Button>
                    <br />
                    <TextField
                      id="markResponse"
                      label="Mark (1-8)"
                      margin="normal"
                      onKeyPress={self.handleMarkEntered}
                      autoFocus
                      value={value}
                      style={{ fontFamily: 'Source Sans Pro' }}
                    />
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      Marking Score:{" "}
                      {_.reduce(
                        marked,
                        function(sum, o) {
                          if (o.response === o.score) return sum + 1;
                          return sum;
                        },
                        0
                      )}{" "}
                      out of {marked.length}
                    </Typography>
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '20px', color: '#af1280' }} gutterBottom>
                      Score
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      Marking Score:{" "}
                      {_.reduce(
                        marked,
                        function(sum, o) {
                          if (o.response === o.score) return sum + 1;
                          return sum;
                        },
                        0
                      )}{" "}
                      out of {marked.length}
                    </Typography>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      So we have now established that you cannot make absolute
                      judgements! Let us now see if you can make ordinal
                      judgements.
                    </Typography>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        self.handleStartJudging(e);
                      }}
                      style={{ textTransform: "inherit", fontFamily: "Source Sans Pro" }}
                      disabled={disabled}
                    >
                      Start Judging
                    </Button>
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '20px', color: '#af1280' }} gutterBottom>
                      Judging
                    </Typography>
                  </StepLabel>

                  <StepContent>
                    {(left || "") !== "" && (right || "") !== "" && (
                      <Table
                        style={{
                          width: "auto",
                          marginTop: "0px",
                          marginBottom: "20px"
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan="2">
                              <Typography style={{ fontFamily: "Source Sans Pro" }}><strong>Click on the darker shade ...</strong></Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ textAlign: "center" }}>
                              <Button
                                style={{
                                  backgroundColor: _.find(oColours, {
                                    qrcode: left
                                  }).colour,
                                  minWidth: "50px"
                                }}
                                onClick={e => {
                                  self.handleSelectSide("Left", perfectScale);
                                }}
                                disabled={disabled}
                              >
                                &nbsp;
                              </Button>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <Button
                                style={{
                                  backgroundColor: _.find(oColours, {
                                    qrcode: right
                                  }).colour,
                                  minWidth: "50px"
                                }}
                                onClick={e => {
                                  self.handleSelectSide("Right", perfectScale);
                                }}
                                disabled={disabled}
                              >
                                &nbsp;
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    )}
                    {(left || "") === "" && (right || "") === "" && (
                      <div>
                        <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>No more pairs available</Typography>
                      </div>
                    )}

                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      Judging Score: {score} out of {selected}
                    </Typography>
                    {perfectScale && (
                      <Typography
                      style={{ fontFamily: 'Source Sans Pro', fontSize: '16px', color: '#af1280' }}
                        gutterBottom
                      >
                        And now the scale is perfect
                      </Typography>
                    )}

                    <Typography style={{ fontFamily: 'Source Sans Pro', fontSize: '16px' }} gutterBottom>
                      ... and see how the scale sorts itself out ...
                    </Typography>

                    <FlipMove>
                      {oColours.map(function(oColour) {
                        return (
                          <Button
                            key={`btn1_${oColour.score}`}
                            style={{
                              backgroundColor: oColour.colour,
                              minWidth: "50px",
                              marginRight: "30px"
                            }}
                          >
                            &nbsp;
                          </Button>
                        );
                      })}
                    </FlipMove>
                  </StepContent>
                </Step>
              </Stepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Demo2;
