import React, { Component } from "react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import history from "./history";
// import Home from "./marketing/home.js";
import Home2 from "./marketing/home2.js";
// import TopMenu from "./layout/topMenu.js";
import TopMenu2 from "./layout/topMenu2.js";
// import Footer from "./layout/footer.js";
import Footer2 from "./layout/footer2.js";
import Pricing2 from './marketing/pricing2.js'
//import APW from "./marketing/apw.js";
import APW2 from "./marketing/apw2.js";
import AAW from "./marketing/aaw.js";
//import POP from "./marketing/pop.js";
// import POP2 from "./marketing/pop2.js";
//import AGE from "./marketing/age.js";
import AGE2 from "./marketing/age2.js";
import Events from "./marketing/events.js";
import Events2 from "./marketing/events2.js";
// import Demo from "./marketing/demo.js";
import Demo2 from "./marketing/demo2.js";
import Calendars2 from "./marketing/calendars2.js";
//import AboutUs from "./marketing/aboutUs.js";
import AboutUs2 from "./marketing/aboutUs2.js";
import MyTasks from "./tasks/tasks.js";
import Settings2 from "./settings/settings2.js";
import Candidates from "./candidates/candidates.js";
import Display from "./display/display.js";
import ScansUploads from './scans/scansUploads.js'
import ScansFixCodes from './scans/scansFixCodes.js'
import ScansThumbnails from './scans/scansThumbnails.js'
import ScansDuplicates from './scans/scansDuplicates.js';
import ScansBlanks from './scans/scansBlanks.js';
import ScansExcluded from './scans/scansExcluded.js'
// import Scans2 from "./scans/scans2.js";
import Thumbnails from "./scans/thumbnails.js";
import TestMaterials from "./candidates/testMaterials.js";
import Judges from "./judges/judges.js";
import CandidateDecisions from "./decisions/candidate.js";
import JudgeDecisions from "./decisions/judge.js";
import JudgingSignup from "./judging/signup.js";
import Judging from "./judging/judging.js";
import Reporting from "./reporting/reporting.js";
import Subscribe3 from "./subscribe/subscribe3.js";
import Subscribe2 from "./subscribe/subscribe2.js";
import AccountSummary from "./accounts/summary.js";
import Uploads from './uploads/uploads.js';
import Trust from "./trusts/trust.js";
import OAuthRedirect from "./assembly/oauthRedirect.js";
import nmmTheme from "./nmmTheme.js";
import Callback from "./Callback.js";
import auth from "./Auth";

class App extends Component {
  async componentDidMount() {
    if (window.location.pathname === "/callback") return;
    try {
      await auth.silentAuth();
      this.forceUpdate();
    } catch (err) {
      // console.log('Login required')
      if (err.error === "login_required") return;
    }
  }
  render() {
    const curPath = this.props.location.pathname.replace(/^\//, '')
    // const useNewLayout = /^$|^demo2|^products|^events2|^calendars2|^aboutUs2|^pricing/.test(curPath)
    const marketingPages = /^$|^demo2|^products|^events2|^calendars2|^aboutUs2|^pricing/.test(curPath)
    return (
      <MuiThemeProvider theme={nmmTheme}>
        <Router history={history}>
          <div>
            {/* !useNewLayout && <TopMenu /> */}
            <TopMenu2 marketingPages={marketingPages} />
            {/*<TaskMenu2 />*/}
            <Switch>
              {/*<Route exact path="/" component={Home} />*/}
              <Route exact path="/" component={() => <Home2 />} />
              <Route exact path="/pricing" component={() => <Pricing2 />} />
              {/*<Route exact path="/products/apw" component={APW} />*/}
              <Route exact path="/products/apw2" component={() => <APW2 />} />
              <Route exact path="/products/aaw" component={() => <AAW />} />
              {/*<Route exact path="/products/pop" component={POP} />*/}
              {/*<Route exact path="/products/pop2" component={POP2} />*/}
              {/*<Route exact path="/products/age" component={AGE} />*/}
              <Route exact path="/products/age2" component={() => <AGE2 />} />
              <Route exact path="/events" component={() => <Events />} />
              <Route exact path="/events2" component={() => <Events2 />} />
              {/*<Route exact path="/demo" component={() => <Demo />} />*/}
              <Route exact path="/demo2" component={Demo2} />
              <Route path="/calendars" component={() => <Calendars2 />} />
              <Route path="/calendars2" component={() => <Calendars2 />} />
              {/*<Route exact path="/aboutUs" component={AboutUs} />*/}
              <Route exact path="/aboutUs2" component={() => <AboutUs2 />} />
              {/*<Route exact path="/subscribe" component={() => <Subscribe />} />*/}
              <Route exact path="/subscribeOld" component={Subscribe2} />
              <Route exact path="/subscribe2" component={Subscribe3} />
              <Route exact path="/tasks" render={() => <MyTasks /> } />
              <Route exact path="/settings2/:task" component={Settings2} />
              <Route exact path="/candidates/:task" component={Candidates} />
              <Route exact path="/display/:candidate" component={() => <Display />} />
              {/*<Route exact path="/scans/:task" component={Scans2} />*/}
              <Route exact path="/scansUploads/:task" component={() => <ScansUploads />} />
              <Route exact path="/scansFixCodes/:task" component={() => <ScansFixCodes />} />
              <Route exact path="/scansThumbnails/:task" component={() => <ScansThumbnails />} />
              <Route exact path="/scansDuplicates/:task" component={() => <ScansDuplicates />} />
              <Route exact path="/scansBlanks/:task" component={() => <ScansBlanks />} />
              <Route exact path="/scansExcluded/:task" component={() => <ScansExcluded />} />
              <Route exact path="/thumbnails/:task" render={() => <Thumbnails /> } />
              <Route
                exact
                path="/testMaterials/:task"
                component={TestMaterials}
              />
              <Route exact path="/judges/:task" component={Judges} />
              <Route
                exact
                path="/candidatedecisions/:task/:candidate"
                component={() => <CandidateDecisions />}
              />
              <Route
                exact
                path="/judgedecisions/:task/:judge"
                component={() => <JudgeDecisions />}
              />
              <Route
                exact
                path="/judging/signup/:task"
                component={() => <JudgingSignup />}
              />
              <Route exact path="/judging/:task/:judge" component={() => <Judging />} />
              <Route
                exact
                path="/judging/:task/:judge/:modCode"
                component={() => <Judging />}
              />
              <Route exact path="/reporting/:task" component={Reporting} />
              <Route path="/accountSummary" component={() => <AccountSummary />} />
              <Route path="/trust" component={Trust} />
              <Route path="/OAuthRedirect" component={() => <OAuthRedirect />} />
              <Route path="/callback" component={() => <Callback />} />
              <Route path="/Uploads/:task?" component={() => <Uploads />} />
            </Switch>
            {/*!useNewLayout && <Footer />*/}
            <Footer2 marketingPages={() => <marketingPages />} />
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
