import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button
} from "@material-ui/core";
// import { ExpandMore } from "@material-ui/icons";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import PageTitle from './../components/pageTitle.js'

class AboutUs2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: '146px', margin: '0 0 20px 0' }}>
        <PageTitle title="About us" img="/assets/girl-red-jumper_banner.jpg" />
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Grid container justify="center">
            <Grid container justify="center" xalignItems="stretch" style={{ width: "1180px" }}>
              <Grid
                item
                style={{ width: "328px", padding: '0 10px 0 10px' }}              
              >
                <Card style={{ backgroundColor: '#efefef' }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography className={classes.topBannerHeading}>
                        Daisy Christodoulou
                      </Typography>
                    }
                  />
                  <CardContent xstyle={{ height: '420px'}}>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}>
Daisy Christodoulou is Director of Education.&nbsp; You can read more in this <a href="https://www.thetimes.co.uk/article/data-underload-jm6vczc89xr" target="_blank" rel="noopener noreferrer">Sunday Times</a> article or this <a href="https://www.theguardian.com/education/2014/nov/25/daisy-christodoulou-seven-myths-education-profile" target="_blank" rel="noopener noreferrer">profile in the Guardian</a>.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}>
Daisy has written two books: <a href="https://www.amazon.co.uk/Making-Good-Progress-Assessment-Learning/dp/0198413602/ref=sr_1_1?s=books&amp;ie=UTF8&amp;qid=1477201669&amp;sr=1-1" target="_blank" rel="noopener noreferrer">Making Good Progress? The future of Assessment for Learning (Oxford University Press, 2017)</a> and <a href="https://www.amazon.co.uk/Seven-Myths-About-Education-Christodoulou-ebook/dp/B00IYPHIIU/ref=sr_1_1?s=books&amp;ie=UTF8&amp;qid=1395072415&amp;sr=1-1" target="_blank" rel="noopener noreferrer">Seven Myths about Education (Routledge, 2014)</a>.

                    </Typography>
                  </CardContent>
                  <CardActions>
                  <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "72px",
                        width: "145px",
                        height: "40px",
                        bottom: '20px',
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        visibility: 'hidden',
                      }}
                      href=""
                      target="_blank"
                    >
                      Citations
                    </Button>
                  </CardActions>
                </Card>
                <Card style={{ marginTop: '20px', backgroundColor: '#efefef' }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography className={classes.topBannerHeading}>
                        Dr Patrick Barmby
                      </Typography>
                    }
                  />
                  <CardContent xstyle={{ height: '600px'}}>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}>
Patrick Barmby is the Head of Research for No More Marking and joined the organisation in July 2017. Prior to moving to No More Marking, Patrick was working in South Africa as a Senior Lecturer in mathematics education at the University of the Witwatersrand, Johannesburg. He also worked for 13 years at Durham University in the UK, and in both institutions, he worked closely with trainee teachers and also carried out research mainly in primary mathematics. His research interests include the notion of understanding in mathematics, the use of visual representations in teaching mathematics and the use of eye tracking technology in mathematics education research. In the past, he has also published on developing attitude towards science measures and on the teaching of problem solving. Patrick also taught for two years in a rural secondary school in Kenya.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}>
Patrick is the co-author of two text books for teachers: Primary Mathematics: Teaching for Understanding; and Understanding and Enriching Problem Solving in Primary Mathematics.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "72px",
                        width: "145px",
                        height: "40px",
                        bottom: '20px',
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      href="https://scholar.google.co.uk/citations?user=tTeWbd8AAAAJ&hl=en"
                      target="_blank"
                    >
                      Citations
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xclassName={classes.Rectangle}
                style={{ width: "328px", padding: '0 10px 0 10px' }}
              >
                <Card style={{ backgroundColor: '#efefef' }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{}}
                      >
                        Dr Christopher Wheadon
                      </Typography>
                    }
                  />
                  <CardContent xstyle={{ height: '420px'}}>
                  <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
Dr Chris Wheadon is a psychometrician, and the founder of No MoreMarking Ltd, which seeks through the website nomoremarking.com to popularise the use of Comparative Judgement approaches in Assessment. His specialist area is Item Response Theory which he explored through his PhD, 'An Item Response Theory Approach to the Maintenance of Standards in Public Examinations in England.'
                        </Typography>
                        <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
In the last several years Chris has worked as a senior advisor on many projects of national significance in England for the examinations regulator in England, Ofqual, including the design of the National Reference Test and the re-design of GCSE Maths. Prior to this he was the senior psychometrician at the examination board AQA.
                        </Typography>

                 </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "72px",
                        width: "145px",
                        height: "40px",
                        bottom: '20px',
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      href="https://scholar.google.co.uk/citations?user=9vuQ8coAAAAJ&hl=en"
                      target="_blank"
                    >
                      Citations
                    </Button>
                  </CardActions>
                </Card>
                <Card style={{ marginTop: '20px', backgroundColor: '#efefef' }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{}}
                      >
                        Mr Brian Henderson
                      </Typography>
                    }
                  />
                  <CardContent xstyle={{ height: '600px'}}>
                  <Typography
                      gutterBottom
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
Brian Henderson is Chief Technology Officer for No More Marking and is responsible for the websites along with the database.
                        </Typography>
                        <Typography
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
Brian has been an author on a number of peer reviewed journal articles on assessment.&nbsp; With a background in Mathematics and Computing he has become skilled with both Databases and Web Application architecture and has developed a great interest in emerging technologies such as Cloud Development.
                        </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "72px",
                        width: "145px",
                        height: "40px",
                        bottom: '20px',
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                      }}
                      href="https://scholar.google.co.uk/citations?user=Z6rlLekAAAAJ&hl=en"
                      target="_blank"
                    >
                      Citations
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xclassName={classes.Rectangle}
                style={{ width: "328px", padding: '0 10px 0 10px' }}
              >
                <Card style={{ backgroundColor: '#efefef' }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{}}
                      >
                        Dr Ian Jones
                      </Typography>
                    }
                  />
                  <CardContent xstyle={{ height: '420px'}}>
                    <Typography
                      gutterBottom
                      style={{
                        // fontFamily: "Source Sans Pro",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
The senior scientific advisor is Dr Ian Jones, a lecturer and former Royal Society Shuttleworth Education Research Fellow based in the Mathematics Education Centre at Loughborough University.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "72px",
                        width: "145px",
                        height: "40px",
                        bottom: '20px',
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        fontFamily: "Source Sans Pro"
                      }}
                      href="https://scholar.google.co.uk/citations?user=QjDB5fAAAAAJ&hl=en"
                      target="_blank"
                    >
                      Citations
                    </Button>
                  </CardActions>
                </Card>
                <Card style={{ marginTop: '20px', backgroundColor: '#efefef' }}>
                  <CardHeader
                    className={classes.topBannerBackground}
                    title={
                      <Typography
                        className={classes.topBannerHeading}
                        style={{}}
                      >
                        Mr Neil Defty
                      </Typography>
                    }
                  />
                  <CardContent xstyle={{ height: '600px'}}>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
Neil is No More Marking’s Head of Customer Relations.&nbsp; He is responsible for ensuring our schools and Trusts are well supported on a day-to-day basis as well as strategically, and also provides training materials for users and delivers presentations on comparative judgement.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
Neil graduated from Newcastle University with a first class Honours Degree in Maths including Statistics.&nbsp; Neil also has an MSc in Mathematical Techniques and their Applications.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#363636"
                      }}
                    >
After some work in psychology and child dyslexia research, Neil worked for Durham University’s Centre for Evaluation and Monitoring (CEM) for 25 years.&nbsp; During this time he built and managed Yellis – the UK’s first secondary school assessment and monitoring system with over 1,000 schools participating.&nbsp; In later years, Neil also managed CEM’s MidYIS system and its 11+ Entrance Testing services, and led the organisation to its successful certification in Customer Service Excellence in 2016.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        left: "72px",
                        width: "145px",
                        height: "40px",
                        backgroundColor: "#3f1f71",
                        color: "#ffffff",
                        textTransform: "inherit",
                        bottom: '20px',
                      }}
                      href="https://scholar.google.co.uk/citations?user=4x5Ld_EAAAAJ"
                      target="_blank"
                    >
                      Citations
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    )
  }
}

export default withStyles(styles)(AboutUs2);
