import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { Grid, IconButton, Typography, Button, Slider } from "@material-ui/core";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from "./../layout/taskMenu2.js";
import { RotateLeft } from "@material-ui/icons";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
// import queryString from "query-string";
import ScansMenu from "./../layout/scansMenu.js";
import * as Yup from "yup";
import ImageZoom from "react-medium-image-zoom";
// import { Slider } from "@material-ui/lab";

const _ = require("lodash");

class ScansFixCodes extends Component {
  state = {
    loaded: false,
    scans: [],
    candidates: [],
    selection: [],
    selectAll: false,
    taskName: "",
    locked: true,
    isModTask: false,
    useCodes: false,
    disabled: false,
    candsWithScans: 0,
    codesToFix: 0,
    pdfFiles: 0,
    x: 0,
    y: 0.85,
    w: 0.5,
    h: 0.1,
    sx: 0,
    sy: 85,
    updateDisabled: true,
    hasDuplicates: false,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const promScans = AxiosAll.post(`/scans/getScannedPagesForTask`, {
      task: task
    });
    const promCands = AxiosAll.post(`/candidates/getCandidatesForTask`, {
      task: task
    });
    const promTask = AxiosAll.post(`/tasks/getTask`, { task: task });
    const promMenu = AxiosAll.post(`/scans/getScanMenu`, { task: task });
    Promise.all([promScans, promCands, promTask, promMenu]).then(oResults => {
      this.setState({
        scans: oResults[0].data,
        candidates: oResults[1].data,
        loaded: true,
        taskName: oResults[2].data.name,
        locked: oResults[2].data.locked,
        isModTask: oResults[2].data.isModTask,
        useCodes: oResults[2].data.useCodes,
        pdfFiles: oResults[3].data.pdfFiles,
        codesToFix: oResults[3].data.codesToFix,
        candsWithScans: oResults[3].data.candsWithScans,
        hasDuplicates: oResults[3].data.hasDuplicates,
      });
    });
  }
  render() {
    const self = this;
    const { match } = this.props;
    const task = match.params.task;
    const { loaded, candidates, disabled, updateDisabled } = this.state;
    const {
      taskName,
      locked,
      isModTask,
      useCodes,
      codesToFix,
      candsWithScans,
      pdfFiles,
      sx,
      sy,
      x,
      y,
      w,
      h,
      hasDuplicates, excludedPages
    } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    if (isModTask) return <Typography gutterBottom>Not available for moderation tasks</Typography>
    const arrCandQRs = _.map(candidates, "qrcode");
    // const arrScanQRs = _.compact(_.uniq(_.map(scans, "qrcode")));
    // const arrScanOnlyQRs = _.difference(arrScanQRs, arrCandQRs);
    console.log("arrCandQRs", arrCandQRs);
    const scans = _.map(this.state.scans, oScan => {
      oScan.valid = _.includes(arrCandQRs, oScan.qrcode);
      return oScan;
    });
    console.log("scans", scans, _.filter(scans, { valid: false }).length);

    const columns = [
      {
        Header: "Thumbnail",
        accessor: "_id",
        width: 220,
        sortable: false,
        Cell: cellInfo => {
          const rotated = cellInfo.original.full_url.match(/a_180/);
          console.log("cellInfo", cellInfo);
          return (
            <ImageZoom
              image={{
                src:
                  rotated === 0
                    ? cellInfo.original.thumbnail180
                    : cellInfo.original.thumbnail0,
                alt: `${cellInfo.original.qrcode} ${cellInfo.original.qrpage}`,
                style: {
                  width: "200px",
                  height: "282px",
                  border: "1px solid #021a40",
                  margin: "5px",
                  padding: "5px"
                }
              }}
              zoomImage={{
                src: cellInfo.original.full_url,
                alt: `${cellInfo.original.qrcode} ${cellInfo.original.qrpage}`
              }}
            />
          );
          // return <img src={`https://res.cloudinary.com/no-more-marking-ltd/image/upload/e_contrast:80/${rotated ? 'a_180' : 'a_0'}/dn_100,pg_${cellInfo.original.pdfpage},q_auto,w_150,h_211/${cellInfo.original.publicId}.jpg`} alt="*" />
        },
        filterable: false
      },
      {
        Header: "Zoom",
        accessor: "_id",
        sortable: false,
        Cell: cellInfo => {
          const rotated = cellInfo.original.full_url.match(/a_180/);
          return (
            <img
              src={`https://res.cloudinary.com/no-more-marking-ltd/image/upload/e_contrast:80/${
                rotated ? "a_180" : "a_0"
              }/pg_${
                cellInfo.original.pdfpage
              },q_auto,w_${w},h_${h},x_${x},y_${y},c_crop/${
                cellInfo.original.publicId
              }.jpg`}
              alt="*"
            />
          );
        },
        filterable: false
      },
      {
        Header: "Rotate",
        filterable: false,
        width: 60,
        sortable: false,
        Cell: cellInfo => {
          return (
            <IconButton
              size="small"
              color="primary"
              disabled={disabled}
              onClick={e => {
                self.setState({ disabled: true });
                const oImg = cellInfo.original;
                console.log("rotate1", oImg);
                const full_url = oImg.full_url.match(/a_180/)
                  ? oImg.full_url.replace(/a_180/, "a_0")
                  : oImg.full_url.replace(/a_0/, "a_180");
                const url = oImg.url.match(/a_180/)
                  ? oImg.url.replace(/a_180/, "a_0")
                  : oImg.url.replace(/a_0/, "a_180");
                console.log("rotate2", {
                  scanFile: oImg._id,
                  full_url: full_url,
                  url: url,
                  task: task
                });
                AxiosAll.post(`/scans/updateScansPage`, {
                  scanFile: oImg._id,
                  full_url: full_url,
                  url: url,
                  task: task
                }).then(oResults => {
                  // console.log("rotate", oResults.data);
                  AxiosAll.post(`/scans/getScannedPagesForTask`, {
                    task: task
                  }).then(oResults => {
                    self.setState({
                      scans: oResults.data,
                      disabled: false
                    });
                  });
                });
              }}
            >
              <RotateLeft />
            </IconButton>
          );
        }
      },
      {
        Header: "Code",
        accessor: "qrcode",
        width: 120,
        Cell: cellInfo => {
          return (
            <Formik
              key={`formik_${cellInfo.original._id}`}
              initialValues={{
                // code: `${cellInfo.original.qrcode}${cellInfo.original.qrpage}`,
                code: `${cellInfo.original.qrcode}${
                  cellInfo.original.qrpage === 0 ||
                  cellInfo.original.qrcode === ""
                    ? ""
                    : cellInfo.original.qrpage
                }`
              }}
              onSubmit={(values, { setSubmitting }) => {
                console.log("onSubmit", values.code, cellInfo.original);
                AxiosAll.post(`/scans/updateScansPage`, {
                  scanFile: cellInfo.original._id,
                  qrcode: values.code.substring(0, 6).toUpperCase(),
                  qrpage: parseInt(values.code[6], 10),
                  task: task
                }).then(oRes1 => {
                  AxiosAll.post(`/scans/getScannedPagesForTask`, {
                    task: task
                  }).then(oResults => {
                    self.setState({
                      scans: oResults.data
                    });
                  });
                });
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .matches(
                    /^[A-Z0-9]{6}\d$/,
                    <span>
                      Must be in
                      <br />
                      AAAAAAD format
                    </span>
                  )
                  .matches(
                    new RegExp(
                      "^" +
                        _.map(arrCandQRs, qr => {
                          return qr.substring(0, 6);
                        }).join("|"),
                      "i"
                    ),
                    "Invalid QR"
                  )
              })}
            >
              {({ isSubmitting, values, errors, dirty, handleSubmit }) => (
                <Form key={`frm_${cellInfo.original._id}`}>
                  <FormikTextField
                    key={`txt_${cellInfo.original._id}`}
                    name="code"
                    label="Code"
                    fullWidth
                    onBlur={e => {
                      console.log("this", this);
                      handleSubmit();
                    }}
                  />
                </Form>
              )}
            </Formik>
          );
        },
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Valid?",
        accessor: "valid",
        width: 100,
        filterMethod: (filter, row) => {
          if (filter.value === "true" && row.valid) return true;
          if (filter.value === "false" && !row.valid) return true;
          return false;
          // return (filter.value === 'true' && row.valid)
          /*if (filter.value === "true") {
                    return row[filter.id] = true;
                  }
                  return row[filter.id] = false*/
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "false"}
          >
            <option value="false">Invalid</option>
            <option value="true">Valid</option>
          </select>
        ),
        Cell: cellInfo => {
          return (
            <div style={{ flex: 0, textAlign: "center" }}>
              {cellInfo.original.valid ? "Y" : "N"}
            </div>
          );
        }
      }
    ];
    console.log("state", this.state);
    return (
      <Grid container style={{ margin: "15px", paddingRight: "35px", paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2
            taskName={taskName}
            locked={locked}
            isModTask={isModTask}
            useCodes={useCodes}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <ScansMenu
              tab={1}
              useCodes={useCodes}
              pdfFiles={pdfFiles}
              codesToFix={codesToFix}
              candsWithScans={candsWithScans}
              hasDuplicates={hasDuplicates}
              excludedPages={excludedPages} 
            />
          </Grid>
          <Grid container style={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <Typography gutterBottom>Alter Zoom position:</Typography>
              <Grid container justify="space-around">
                <Grid item xs={4}>
                  <Typography
                    style={{ padding: "0 0 0 5px" }}
                    gutterBottom
                  >{`Horizontal: ${sx}%`}</Typography>
                  <Slider
                    value={sx}
                    min={0}
                    max={50}
                    style={{ margin: "5px 10px 20px 10px" }}
                    onChange={(e, v) => {
                      this.setState({
                        sx: parseInt(v, 10),
                        updateDisabled: false
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    style={{ padding: "0 0 0 5px" }}
                    gutterBottom
                  >{`Vertical: ${sy}%`}</Typography>
                  <Slider
                    value={sy}
                    min={0}
                    max={85}
                    style={{ margin: "5px 10px 20px 10px" }}
                    onChange={(e, v) => {
                      this.setState({
                        sy: parseInt(v, 10),
                        updateDisabled: false
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="raised"
                    color="primary"
                    style={{ textTransform: "inherit" }}
                    disabled={updateDisabled}
                    onClick={e => {
                      this.setState({
                        x: sx / 100,
                        y: sy / 100,
                        updateDisabled: true
                      });
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ReactTable defaultPageSize={50}
                data={scans}
                filterable
                defaultFiltered={[{ id: "valid", value: "false" }]}
                columns={columns}
                minRows={0}
                showPagination={true}
                style={{ marginBottom: "20px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ScansFixCodes);
