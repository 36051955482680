import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import AxiosAll from "./../axiosAll";

const _ = require("lodash");

class OAuthRedirect extends Component {
  componentDidMount() {
    const params = queryString.parse(window.location.search);
    const uri = `${window.location.origin}/OAuthRedirect`;
    console.log("uri", uri);
    console.log("params", params);
    AxiosAll.post(`/assembly/authoriseSchool`, {
      code: params.code,
      uri: uri,
      state: params.state,
      scope:
        "students.basic+students.gender+students.pp+students.dob+students.mis_id+students.dates+students.upn+teaching_groups+assessments.national+assessments.write:optional+students.eal"
    }).then(oResult => {
      console.log("oResult", oResult);
      this.props.history.push(`/tasks`);
    });
  }
  render() {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    if (!_.has(params, "code") || !_.has(params, "state"))
      return <p>State and/or Code not found in Query String</p>;
    // console.log("OAuthRedirect A", params);

    return <div />;
  }
}

export default withRouter(OAuthRedirect);
