import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";

class PageTitle extends Component {
  render() {
    const { content, title, img } = this.props;
    if (content) {
      return (
        <Typography
          gutterBottom
          variant="h4"
          color="secondary"
          style={{ textAlign: "center" }}
        >
          {content}
        </Typography>
      );
    }
    return (
      <Grid container style={{ flexGrow: 1 }}>
        <Hidden smUp>
          <Grid container justify="center">
            <Grid item style={{
                position: "relative",
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "140px",
                backgroundImage: `url(${img || "/assets/girl-red-jumper_banner.jpg"})`,
            }}>
              <Grid
                item
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: "0",
                  height: "55px",
                  //mixBlendMode: "multiply",
                  backgroundImage:
                    "linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.49))"
                }}
              >
              <Typography
                style={{
                  position: "absolute",
                  left: "40px",
                  top: "10px",
                  fontFamily: "Source Sans Pro",
                  fontSize: "23px",
                  color: "#ffffff"
                }}
              >
                {title}
              </Typography>

            </Grid>
              </Grid>
            </Grid>
        </Hidden>
        <Hidden xsDown>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                position: "relative",
                width: "1180px",
                height: "286px",
                backgroundImage: `url(${img || "/assets/girl-red-jumper_banner.jpg"})`, // "url(/assets/girl-red-jumper_banner.jpg)"
              }}
            >
              <Grid
                item
                style={{
                  position: "absolute",
                  width: "1180px",
                  bottom: "0",
                  height: "115px",
                  mixBlendMode: "multiply",
                  backgroundImage:
                    "linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.49))"
                }}
              />
              <Typography
                style={{
                  position: "absolute",
                  left: "252px",
                  top: "213px",
                  fontFamily: "Source Sans Pro",
                  fontSize: "33px",
                  color: "#ffffff"
                }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Hidden>
        </Grid>
    );
  }
}

export default PageTitle;
