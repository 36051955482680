import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  Divider,
  Avatar,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { School } from "@material-ui/icons";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";
const uuidv4 = require("uuid/v4");

const _ = require("lodash");
const moment = require("moment");

class PopulateAssembly extends Component {
  state = {
    selected: null,
    yearGroup: "-1",
    teachingGroup: "Reg_Group",
    disabled: false,
    loaded: false,
    bearer: "",
    ygs: [],
    msg: <span />,
    purchases: 0,
    expired: false,
    csrf: ""
  };
  componentDidMount() {
    const csrf = uuidv4();
    localStorage.setItem("csrf", csrf);
    AxiosAll.post(`/purchases/getPurchases`).then(oSchool => {
      console.log("get purchases", oSchool.data);
      if (oSchool.data.length === 0)
        this.setState({
          msg: <Alert text="Not associated with any school" type="error" />,
          loaded: true
        });
      else if (oSchool.data.length > 1)
        this.setState({
          msg: (
            <Alert text="Can only be associated with one school" type="error" />
          ),
          loaded: true
        });
      else {
        let assemblyBearer = oSchool.data[0].assemblyBearer;
        const assemblyRefreshToken = oSchool.data[0].assemblyRefreshToken;
        const assemblyExpires = oSchool.data[0].assemblyExpires;
        const expired =
          moment().valueOf() >
          moment(assemblyExpires, "YYYY-MM-DD HH:mm:ss").valueOf();
        // console.log("expired", expired);
        // console.log("<", assemblyExpires, assemblyRefreshToken);
        if (!assemblyExpires) {
          // console.log("does not exists");
          this.setState({
            loaded: true
          });
        } else if (expired) {
          AxiosAll.post(`/assembly/refreshAssembly`, {
            refreshToken: assemblyRefreshToken
          }).then(oRefresh => {
            console.log("oRefresh", oRefresh);
            assemblyBearer = oRefresh.data.access_token;
            AxiosAll.post(`/assembly/getYearGroups`, {
              bearer: assemblyBearer
            }).then(oYGs => {
              console.log("oYGs", oYGs);
              this.setState({
                bearer: assemblyBearer,
                loaded: true,
                ygs: oYGs.data.data,
                purchases: oSchool.data[0].purchases.length,
                expired: expired,
                csrf: csrf
              });
            });
          });
        } else {
          // console.log("B getYearGroups", assemblyBearer);
          AxiosAll.post(`/assembly/getYearGroups`, {
            bearer: assemblyBearer
          }).then(oYGs => {
            // console.log("oYGs", oYGs);
            this.setState({
              bearer: assemblyBearer,
              loaded: true,
              purchases: oSchool.data[0].purchases.length,
              ygs: oYGs.data.data
            });
          });
        }
      }
    });
  }
  handleYearGroupChange = event => {
    this.setState({ yearGroup: event.currentTarget.value });
  };
  handleTeachingGroupChange = event => {
    this.setState({ teachingGroup: event.currentTarget.value });
  };
  render() {
    const oTeachingGroups = [
      { value: "Reg_Group", label: "Registration Group" },
      { value: "MAT", label: "Mathematics" },
      { value: "ENG", label: "English" },
    ];
    const {
      yearGroup,
      disabled,
      loaded,
      ygs,
      msg,
      bearer,
      purchases,
      csrf
    } = this.state;
    const { match } = this.props;
    const task = match.params.task;
    if (!loaded) return <span>Loading...</span>;
    console.log("bearer", bearer);
    // console.log(">", window.location.hostname);
    if (!bearer) {
      const assemblyClientId = "BDA4086B1010E8408EEF";
      const origin =
        window.location.hostname === "localhost"
          ? "https://www.nomoremarking.com"
          : window.location.origin;
      const assemblyLink = `https://platform.assembly.education/oauth/authorize?redirect_uri=${origin}/OAuthRedirect&client_id=${assemblyClientId}&scope=students.basic+students.gender+students.pp+students.dob+students.mis_id+students.dates+students.upn+teaching_groups+assessments.national+assessments.write:optional&state=${csrf}`;
      return (
        <div>
          <Typography gutterBottom>
            To automatically populate No More Marking with the students from your school, we’ll firstly need someone from your IT team to connect your MIS to the Assembly Platform.
          </Typography>
          <Typography gutterBottom>
            You will need the invite code: <strong>nomoremarking2017</strong>
          </Typography>
          <Typography>
            Then follow these steps:
          </Typography>
          <ul>
            <li>Follow the appropriate guide on the <a href="http://help.assembly.education" target="_blank" rel="noopener noreferrer">Assembly help site</a> for your MIS</li>
            <li><a href="http://help.assembly.education/article/41-how-to-authorise-an-app" target="_blank" rel="noopener noreferrer">Authorise the No More Marking App</a></li>
            <li>After authorising the No More Marking app, please click the Assembly Connect button below to complete the connection</li>
          </ul>
          <Button
            mini
            variant="contained"
            color="primary"
            href={assemblyLink}
            style={{ textTransform: "inherit", marginBottom: '20px' }}
          >
            Assembly Connect
          </Button>
          <Typography gutterBottom>
            If you have any problems with this setting up process, please <a href="https://assembly.education/contact" target="_blank" rel="noopener noreferrer">contact Assembly</a> directly for support regarding connecting to Assembly.
          </Typography>
          <Typography gutterBottom>
            <i>Please note that this connection via Assembly is only available for paid subscribers to No More Marking.</i>
          </Typography>


          {/*<Alert text="Not Connected to Assembly" type="error" />
          <Typography gutterBottom>
            To automatically populate No More Marking with the students from
            your school, we’ll first need someone from your IT team to connect
            your MIS to the Assembly Platform.&nbsp; They will need to:
          </Typography>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <a
                href="https://auth.assembly.education/schools/sign_up"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign the school up to the Assembly Platform
              </a>{" "}
              with invite code: <strong>nomoremarking2017</strong>
            </li>
            <li style={{ listStyleType: "disc" }}>
              Configure the connection to your school’s{" "}
              <a
                href="http://help.assembly.education/article/29-installing-the-assembly-connector-sims"
                target="_blank"
                rel="noopener noreferrer"
              >
                SIMS
              </a>
              ,{" "}
              <a
                href="http://help.assembly.education/article/36-how-to-install-the-assembly-connector-cmis"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facility CMIS
              </a>
              ,{" "}
              <a
                href="http://help.assembly.education/article/65-connecting-bromcom-to-assembly"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bromcom
              </a>{" "}
              or{" "}
              <a
                href="http://help.assembly.education/article/66-connecting-scholarpack-to-assembly"
                target="_blank"
                rel="noopener noreferrer"
              >
                ScholarPack
              </a>{" "}
              by following the appropriate guide on the{" "}
              <a
                href="http://help.assembly.education/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Assembly help site
              </a>
            </li>
            <li style={{ listStyleType: "disc" }}>
              Finally{" "}
              <a
                href="http://help.assembly.education/article/41-how-to-authorise-an-app"
                target="_blank"
                rel="noopener noreferrer"
              >
                authorise the No More Marking app
              </a>{" "}
              on the Assembly Platform
            </li>
          </ul>
          <Typography gutterBottom>
            Please{" "}
            <a
              href="https://assembly.education/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact Assembly
            </a>{" "}
            for support regarding connecting to Assembly.
          </Typography>
          <Typography gutterBottom>Only available for paid options.</Typography>
          <Typography gutterBottom>
            School must be signed up to{" "}
            <a
              href="http://assembly.education/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Assembly
            </a>{" "}
            and connected their account.
          </Typography>
          <Button
            mini
            variant="contained"
            color="primary"
            href={assemblyLink}
            style={{ textTransform: "inherit" }}
          >
            Assembly Connect
          </Button>*/}
        </div>
      );
    }
    // console.log("purchases", purchases);
    if (purchases === 0)
      return <Alert text="Only available for paying customers" type="error" />;
    return (
      <Grid container>
        {msg}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: "15px" }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <School />
                    </Avatar>
                  }
                  title="Select Cohort"
                />
                <Divider />
                <CardContent>
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <InputLabel htmlFor="idYearGroup">Year Group</InputLabel>
                    <Select
                      native
                      defaultValue={yearGroup}
                      inputProps={{ id: "idYearGroup" }}
                      onChange={this.handleYearGroupChange}
                      autoFocus
                    >
                      <option value="-1" selected={yearGroup === -1}>
                        Please Select
                      </option>
                      {ygs.map(oYG => {
                        return (
                          <option key={`option_${oYG.code}`} value={oYG.code}>
                            {oYG.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="idTeachingGroup">
                      Teaching Group
                    </InputLabel>
                    <Select
                      native
                      defaultValue={"Reg_Group"}
                      inputProps={{ id: "idTeachingGroup" }}
                    >
                      {oTeachingGroups.map(oTG => {
                        return (
                          <option key={`tg_${oTG.value}`} value={oTG.value}>
                            {oTG.label}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={yearGroup === "-1" || disabled}
                    style={{ textTransform: "inherit" }}
                    onClick={e => {
                      const yg = document.getElementById("idYearGroup").value;
                      const tg = document.getElementById("idTeachingGroup")
                        .value;
                      const tgLabel = _.find(oTeachingGroups, { value: tg })
                        .label;
                      const c = window.confirm(
                        `Add Candidates from Year Group: "${yg}" and Group:"${tgLabel}?"`
                      );
                      if (!c) return;
                      this.setState({ disabled: true });
                      AxiosAll.post(`/assembly/populateCandidates`, {
                        bearer: bearer,
                        year_code: yg,
                        subject_code: tg,
                        task: task
                      }).then(oResult => {
                        const oCandidates = oResult.data;
                        // console.log("populateCandidates", oCandidates);
                        AxiosAll.post(`/candidates/createCandidates`, {
                          task: task,
                          candidates: oCandidates
                        }).then(oResult => {
                          this.props.history.push(`/candidates/${task}`);
                        });
                      });
                    }}
                  >
                    Do Update
                  </Button>
                </CardActions>
                <Divider />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Divider />
                <Divider />
                <CardContent>
                  <Typography component="p">
                    For teaching groups we automatically map the most commonly
                    occurring English and Maths codes and, from experience, this
                    covers around 90% of use cases.&nbsp; For those schools that
                    use a non-standard mapping{" "}
                    <a
                      href="http://help.assembly.education/article/30-how-to-map-your-subjects-on-the-assembly-platform-sims"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this guide
                    </a>{" "}
                    talks them through how they can locate it on SIMS and enter
                    it on the Platform.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(PopulateAssembly);
