import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import auth from "./Auth";
// import Axios from "axios";
import AxiosAll from "./axiosAll";

class Callback extends Component {
  async componentDidMount() {
    await auth.handleAuthentication();
    //Axios.post(`https://dev-api.beta.nomoremarking.com/utils/updateLastLogin`, { email: localStorage.getItem('email') })
    AxiosAll.post(`/utils/updateLastLogin`, {
      email: localStorage.getItem('email'),
    })
    .then(oRes => {
      console.log('oRes', oRes)
      const redirect = localStorage.getItem("redirect") || "";
      if (redirect === "") this.props.history.push("/tasks");
      else this.props.history.push(`/${redirect}`);
    })
  }

  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white"
    };

    return (
      <div style={style}>
        <p>Loading ...</p>
      </div>
    );
  }
}

export default withRouter(Callback);
