import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Hidden } from "@material-ui/core";
// import { ArrowDropDown } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { styles } from "./../layout/styles.js";


class FooterMob extends Component {
  render() {
    const { location } = this.props;
    if (RegExp("judging|callback|generateScripts|callback", "i").test(location.pathname)) return <span />;
    return (
        <Hidden smUp>
      <Grid container style={{ flexGrow: 1, margin: '30px 0 0 0', backgroundColor: '#3f1f71' }}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ position: 'relative', height: '210px', width: '100%', backgroundColor: '#3f1f71'}}>
              <Typography style={{ position: 'absolute', left: '78px', top: '18px', width: '128px', height: '54px', fontSize: '14px', color: '#ffffff' }}>
                <strong>No More Marking Ltd</strong>
                <br />
                26 Bridgemere Drive
                <br />
                Durham DH1 5FG
              </Typography>
              <Typography style={{ position: 'absolute', left: '78px', top: '103px', width: '275px', height: '15px', fontSize: '12px', color: '#ffffff' }}>
                Copyright &copy; 2019 No More Marking All Rights Reserved
              </Typography>
              <Typography style={{ position: 'absolute', left: '78px', top: '127px', width: '275px', height: '15px', fontSize: '12px', color: '#ffffff' }}>
                Site by <a href="https://onward-creative.com" target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>Onward Design</a>
              </Typography>

              {/*<Typography style={{ position: 'absolute', left: '426px', top: '18px', width: '128px', height: '18px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                Products
              </Typography>
              <NavLink to={`/products/apw2`}><Typography style={{ position: 'absolute', left: '426px', top: '46px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Primary Writing
              </Typography></NavLink>
              <NavLink to={`/products/age2`}><Typography style={{ position: 'absolute', left: '426px', top: '75px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Assessing GCSE English
              </Typography></NavLink>
              <NavLink to={`/products/aaw`}><Typography style={{ position: 'absolute', left: '426px', top: '103px', width: '197px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Assessing America's Writing
              </Typography></NavLink>

              <NavLink to={`/demo2`}><Typography style={{ position: 'absolute', left: '624px', top: '18px', width: '128px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Demo
              </Typography></NavLink>
              <NavLink to={`/pricing`}><Typography style={{ position: 'absolute', left: '624px', top: '46px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Pricing
              </Typography></NavLink>
              <NavLink to={`/calendars2`}><Typography style={{ position: 'absolute', left: '624px', top: '75px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Calendar
              </Typography></NavLink>
              <NavLink to={`/events2`}><Typography style={{ position: 'absolute', left: '624px', top: '103px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Training Events
              </Typography></NavLink>

              <a href="https://blog.nomoremarking.com" target="_blank" rel="noopener noreferrer"><Typography style={{ position: 'absolute', left: '774px', top: '18px', width: '128px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Blog
              </Typography></a>
              <a href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354" target="_blank" rel="noopener noreferrer"><Typography style={{ position: 'absolute', left: '774px', top: '46px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Support
              </Typography></a>
              <NavLink to={`/aboutUs2`}><Typography style={{ position: 'absolute', left: '774px', top: '75px', width: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                About us
    </Typography></NavLink>*/}
              <a href="https://s3-eu-west-1.amazonaws.com/nmm-v2/terms/GDPR+agreement.pdf" target="_blank" rel="noopener noreferrer"><Typography style={{ position: 'absolute', left: '78px', top: '160px', xwidth: '137px', height: '18px', fontSize: '14px', color: '#ffffff' }}>
                Terms & Conditions
              </Typography></a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Hidden>
    );
  }
}

export default withStyles(styles)(withRouter(FooterMob));
