import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  Grid,
  AppBar,
  Typography,
  Button,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Hidden
} from "@material-ui/core";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import auth from "./../Auth.js";
import TopMenuMob from "./topMenuMob";

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

class TopMenu2 extends Component {
  state = {
    anchorProduct: null
  };
  constructor(props) {
    super(props);
    window.Intercom("boot", {
      app_id: "zodtosc9"
    });
  }
  handleProductMenu = event => {
    this.setState({ anchorProduct: event.currentTarget });
  };
  handleProductClose = () => {
    this.setState({ anchorProduct: null });
  };
  render() {
    const { location, classes, marketingPages } = this.props;
    if (RegExp("judging|callback", "i").test(location.pathname))
      return <span />;
    const { anchorProduct } = this.state;
    const openProduct = Boolean(anchorProduct);
    const isAuthenticated = (localStorage.getItem("email") || "") !== "";
    return (
      <div>
        <TopMenuMob />
        <Hidden xsDown>
          <Grid
            container
            style={
              marketingPages
                ? {
                    flexGrow: 1,
                    position: "fixed",
                    zIndex: 10,
                    width: "100%",
                    backgroundColor: "#ffffff"
                  }
                : {
                    flexGrow: 1,
                    position: "fixed",
                    zIndex: 10,
                    width: "100%",
                    backgroundColor: "#3f1f71"
                  }
            }
          >
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item style={styles.topBannerNav}>
                  <NavLink to={`/`}>
                    <img
                      src={
                        marketingPages
                          ? "/assets/coloured-logo.svg"
                          : "/assets/logo-white.svg"
                      }
                      style={{
                        position: "absolute",
                        top: "30px",
                        left: "40px"
                      }}
                      alt="No More Marking"
                    />
                  </NavLink>
                  <Typography style={styles.ComparativeJudgemen}>
                    Comparative Judgement for Schools
                  </Typography>
                  {marketingPages && (
                    <IconButton
                      style={styles.bannerMedium}
                      target="_blank"
                      href={`https://blog.nomoremarking.com`}
                    >
                      <img src="/assets/medium-icon.svg" alt="Medium" />
                    </IconButton>
                  )}
                  {marketingPages && (
                    <IconButton
                      style={styles.bannerTwitter}
                      target="_blank"
                      href={`https://twitter.com/nmmarking`}
                    >
                      <img src="/assets/twitter-icon.svg" alt="Twitter" />
                    </IconButton>
                  )}
                  {!isAuthenticated && (
                    <Button
                      variant="text"
                      style={{
                        position: "absolute",
                        left: "958px",
                        top: "40px",
                        width: "49px",
                        height: "37px",
                        fontSize: "15px",
                        color: "#3f1f71",
                        textTransform: "inherit"
                      }}
                      onClick={() => {
                        auth.login("subscribe2");
                      }}
                    >
                      Sign up
                    </Button>
                  )}
                  {!isAuthenticated && (
                    <Button
                      className={classes.Rectangle}
                      onClick={() => {
                        auth.login("tasks");
                      }}
                    >
                      Login
                    </Button>
                  )}
                  {isAuthenticated && (
                    <Button
                      style={{
                        position: "absolute",
                        fontSize: "15px",
                        left: "1022px",
                        top: "40px",
                        width: "80px",
                        height: "37px",
                        backgroundColor: "#dddddd",
                        textTransform: "inherit"
                      }}
                      onClick={() => {
                        auth.logout();
                      }}
                    >
                      Logout
                    </Button>
                  )}
                  {isAuthenticated && (
                    <Typography variant="caption" style={styles.username}>
                      {localStorage.getItem("email") || "-"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AppBar
                position="relative"
                style={
                  marketingPages
                    ? { backgroundColor: "#3f1f71", height: "35px" }
                    : { backgroundColor: "#af1280", height: "35px" }
                }
              >
                <Grid container justify="center">
                  <Grid
                    item
                    style={
                      marketingPages ? { width: "1180px" } : { width: "100%" }
                    }
                  >
                    <Toolbar>
                      <Button
                        style={{
                          marginTop: "-40px",
                          lineHeight: "inherit",
                          height: "20px",
                          color: "#ffffff",
                          textTransform: "inherit",
                          alignItems: "normal"
                        }}
                        component={ForwardNavLink}
                        to="/demo2"
                      >
                        Demo
                      </Button>
                      <Button
                        style={{
                          marginTop: "-40px",
                          lineHeight: "inherit",
                          height: "20px",
                          color: "#ffffff",
                          textTransform: "inherit",
                          alignItems: "normal"
                        }}
                        aria-owns={openProduct ? "menuProduct" : null}
                        aria-haspopup="true"
                        onClick={this.handleProductMenu}
                      >
                        Products{" "}
                        <ArrowDropDown style={{ fontSize: "inherit" }} />
                      </Button>
                      <Menu
                        id="menuProduct"
                        anchorEl={anchorProduct}
                        open={openProduct}
                        onClose={this.handleProductClose}
                      >
                        <MenuItem
                          id="tm_apw"
                          onClick={this.handleProductClose}
                          component={ForwardNavLink}
                          to="/products/apw2"
                          style={{
                            fontFamily: "Source Sans Pro",
                            padding: "5px 10px 5px 10px"
                          }}
                        >
                          Assessing Primary Writing
                        </MenuItem>
                        {/*<MenuItem
                id="tm_pop"
                onClick={this.handleProductClose}
                component={NavLink}
                to="/products/pop2"
                style={{ fontFamily: 'Source Sans Pro', padding: '5px 10px 5px 10px' }}
              >
                Proof of Progress
              </MenuItem>*/}
                        <MenuItem
                          id="tm_age"
                          onClick={this.handleProductClose}
                          component={ForwardNavLink}
                          to="/products/age2"
                          style={{
                            fontFamily: "Source Sans Pro",
                            padding: "5px 10px 5px 10px"
                          }}
                        >
                          Assessing GCSE English
                        </MenuItem>
                        <MenuItem
                          id="tm_apw"
                          onClick={this.handleProductClose}
                          component={ForwardNavLink}
                          to="/products/aaw"
                          style={{
                            fontFamily: "Source Sans Pro",
                            padding: "5px 10px 5px 10px"
                          }}
                        >
                          Assessing America's Writing
                        </MenuItem>
                      </Menu>
                      <Button
                        style={styles.menuButton}
                        component={ForwardNavLink}
                        to="/events2"
                      >
                        Training Events
                      </Button>
                      <Button
                        style={styles.menuButton}
                        component={ForwardNavLink}
                        to={`/calendars2`}
                      >
                        Calendars
                      </Button>
                      <Button
                        style={styles.menuButton}
                        component={ForwardNavLink}
                        to={`/aboutUs2`}
                      >
                        About us
                      </Button>
                      {isAuthenticated && (
                        <Button
                          style={styles.menuButton}
                          component={ForwardNavLink}
                          to={`/accountSummary`}
                        >
                          Account Summary
                        </Button>
                      )}
                      {isAuthenticated && (
                        <Button
                          style={styles.menuButton}
                          component={ForwardNavLink}
                          to={`/subscribe2`}
                        >
                          Subscribe
                        </Button>
                      )}
                      {isAuthenticated && (
                        <Button
                          style={styles.menuButton}
                          component={ForwardNavLink}
                          to={`/trust`}
                        >
                          Trust
                        </Button>
                      )}
                      {isAuthenticated && (
                        <Button
                          style={styles.menuButton}
                          component={ForwardNavLink}
                          to={`/tasks`}
                        >
                          My Tasks
                        </Button>
                      )}
                      <Typography style={{ flex: 1 }} />
                      <Button
                        style={styles.menuButton}
                        component={ForwardNavLink}
                        to={`/pricing`}
                      >
                        Pricing
                      </Button>
                      <Button
                        style={styles.menuButton}
                        href={`https://blog.nomoremarking.com`}
                        target="_blank"
                      >
                        Blog
                      </Button>
                      <Button
                        style={styles.menuButton}
                        href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354"
                        target="_blank"
                      >
                        Support
                      </Button>
                    </Toolbar>
                  </Grid>
                </Grid>
              </AppBar>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(TopMenu2));
