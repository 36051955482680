import { createMuiTheme } from '@material-ui/core/styles';

const nmmTheme = createMuiTheme({
  typography: {
    fontFamily: 'Source Sans Pro', //'Fira Sans',
  },
  palette: {
    primary: {
      main: '#3f1f71', // '#2E3E4F',
    },
    secondary: {
      main: '#af1280', // '#E64D43',
    }
  },
});

export default nmmTheme;
