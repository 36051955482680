import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Typography
} from "@material-ui/core";
import PageTitle from "./../components/pageTitle.js";
import queryString from "query-string";
import * as yup from "yup";

const oProducts = [
  { productShort: "APW", productLong: "Assessing Primary Writing" },
  // { productShort: 'PoP1920', productLong: 'Proof of Progress' },
  { productShort: "AGE", productLong: "Assessing GCSE English" },
  { productShort: "TWR", productLong: "The Writing Revolution" },
  { productShort: "AAW", productLong: "Assessing America's Writing" }
];
const arrAcYears = [2020];

const _ = require("lodash");

class Calendars2 extends Component {
  state = {
    product: 0,
    acYear: 0,
    loaded: false
  };
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    const schema = yup.object().shape({
      acYear: yup
        .number()
        .required()
        .positive()
        .integer(),
      product: yup
        .string()
        .matches(RegExp(_.map(oProducts, "productShort").join("|")))
        .required()
    });
    console.log("qs", qs, _.map(oProducts, "productShort").join("|"));
    schema.isValid(qs).then(isValid => {
      if (isValid) {
        this.setState({
          acYear: _.indexOf(arrAcYears, parseInt(qs.acYear, 10)),
          product: _.indexOf(_.map(oProducts, "productShort"), qs.product),
          loaded: true
        });
      } else {
        this.setState({
          loaded: true
        });
      }
    });
  }
  render() {
    const { product, acYear, loaded } = this.state;
    console.log("state", this.state);
    if (!loaded) return <span />;
    return (
      <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
        <PageTitle title="Calendars" img="/assets/girl-red-jumper_banner.jpg" />
        <Grid item xs={12}>
          <Grid item xs={12} style={{ margin: "15px 0px 15px 0px" }}>
            <AppBar position="static">
              <Tabs
                value={product}
                onChange={(e, value) => {
                  this.setState({ product: value });
                }}
                style={{ padding: "0 70px 0 70px" }}
              >
                {oProducts.map((oProduct, i) => (
                  <Tab
                    key={`prod_${i}`}
                    label={oProduct.productLong}
                    style={{ textTransform: "inherit" }}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>

          <Grid item xs={12} style={{ margin: "15px 0px 15px 0px" }}>
            <AppBar position="static">
              <Tabs
                value={acYear}
                onChange={(e, value) => {
                  this.setState({ acYear: value });
                }}
                style={{ padding: "0 70px 0 70px" }}
              >
                {arrAcYears.map(acYear => (
                  <Tab
                    key={`acYear_${acYear}`}
                    label={`${acYear - 1}-${acYear}`}
                    style={{ textTransform: "inherit" }}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item style={{ width: "1180px" }}>
                {/*product === 0 && acYear === 0 && <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
            <Paper elevation={4} >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Genre</TableCell>
                    <TableCell>Stimulus Released</TableCell>
                    <TableCell>Writing Window</TableCell>
                    <TableCell>Judging Window</TableCell>
                    <TableCell>Results Published</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Year 3 Oct</TableCell>
                    <TableCell>Fiction</TableCell>
                    <TableCell>08/10/2018</TableCell>
                    <TableCell>15/10/2018 - 19/10/2018</TableCell>
                    <TableCell>05/11/2018 - 09/11/2018</TableCell>
                    <TableCell>16/11/2018</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 5 Nov</TableCell>
                    <TableCell>Non Fiction</TableCell>
                    <TableCell>05/11/2018</TableCell>
                    <TableCell>12/11/2018 - 16/11/2018</TableCell>
                    <TableCell>26/11/2018 - 30/11/2018</TableCell>
                    <TableCell>07/12/2018</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 1 Jan</TableCell>
                    <TableCell>Fiction</TableCell>
                    <TableCell>07/01/2019</TableCell>
                    <TableCell>14/01/2019 - 18/01/2019</TableCell>
                    <TableCell>28/01/2019 - 01/02/2019</TableCell>
                    <TableCell>08/02/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 4 Jan</TableCell>
                    <TableCell>Non Fiction</TableCell>
                    <TableCell>14/01/2019</TableCell>
                    <TableCell>21/01/2019 - 25/01/2019</TableCell>
                    <TableCell>04/02/2019 - 08/02/2019</TableCell>
                    <TableCell>15/02/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 2 Mar</TableCell>
                    <TableCell>Fiction</TableCell>
                    <TableCell>11/02/2019</TableCell>
                    <TableCell>25/02/2019 - 01/03/2019</TableCell>
                    <TableCell>11/03/2019 - 15/03/2019</TableCell>
                    <TableCell>22/03/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 6 Mar</TableCell>
                    <TableCell>Fiction</TableCell>
                    <TableCell>25/02/2019</TableCell>
                    <TableCell>04/03/2019 - 08/03/2019</TableCell>
                    <TableCell>18/03/2019 - 22/03/2019</TableCell>
                    <TableCell>29/03/2019</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            </Grid>*/}

                {/*product === 1 && acYear === 0 && <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
            <Paper elevation={4}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Genre</TableCell>
                    <TableCell>Writing Window</TableCell>
                    <TableCell>Judging Window</TableCell>
                    <TableCell>Results Published</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Year 7 Sept Maths</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>17/09/2018 - 19/10/2018</TableCell>
                    <TableCell>22/10/2018 - 09/11/2018</TableCell>
                    <TableCell>03/12/2018</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 7 Sept English</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>17/09/2018 - 19/10/2018</TableCell>
                    <TableCell>22/10/2018 - 09/11/2018</TableCell>
                    <TableCell>03/12/2018</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 7 June English</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>13/05/2019 - 21/06/2019</TableCell>
                    <TableCell>24/06/2019 - 12/07/2019</TableCell>
                    <TableCell>26/08/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 7 June Maths</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>13/05/2019 - 21/06/2019</TableCell>
                    <TableCell>24/06/2019 - 12/07/2019</TableCell>
                    <TableCell>26/08/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 8 June English</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>13/05/2019 - 21/06/2019</TableCell>
                    <TableCell>24/06/2019 - 12/07/2019</TableCell>
                    <TableCell>26/08/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 8 June Maths</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>13/05/2019 - 21/06/2019</TableCell>
                    <TableCell>24/06/2019 - 12/07/2019</TableCell>
                    <TableCell>26/08/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 9 June English</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>13/05/2019 - 21/06/2019</TableCell>
                    <TableCell>24/06/2019 - 12/07/2019</TableCell>
                    <TableCell>26/08/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year 9 June Maths</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>13/05/2019 - 21/06/2019</TableCell>
                    <TableCell>24/06/2019 - 12/07/2019</TableCell>
                    <TableCell>26/08/2019</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            </Grid>*/}

                {/*product === 1 && acYear === 0 && <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
            <Paper elevation={4}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell valign="top">
                      <p>
                        <b>Task</b>
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Exam board and question</b>
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Writing window</b>
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Judging window</b>
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        <b>Results published</b>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell rowSpan="2">
                      <p>Task 1</p>
                    </TableCell>
                    <TableCell>
                      <p>AQA Eng Lang: June 2017 Paper 1 Q5 (Writing) </p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>24/09/2018 - 28/09/2018</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>08/10/2018 - 19/10/2018</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>26/10/2018</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <p>
                        Edexcel Eng Lang: June 2017 Paper 1 Section B (Writing)
                      </p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell rowSpan="2">
                      <p>Task 2</p>
                    </TableCell>
                    <TableCell>
                      <p>AQA Eng Lang: June 2017 Paper 1 Q4 (Reading)</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>2/11/2018 - 16/11/2018</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>26/11/2018 - 07/12/2018</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>14/12/2018</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <p>Edexcel Eng Lang: June 2017 Paper 1 Q4 (Reading)</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell rowSpan="2">
                      <p>Task 3</p>
                    </TableCell>
                    <TableCell>
                      <p>AQA Eng Lang: June 2017 Paper 2 Q5 (Writing)</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>14/01/2019 - 18/01/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>28/01/2019 - 08/02/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>15/02/2019</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <p>
                        Edexcel Eng Lang: June 2017 Paper 2 Section B (Writing)
                      </p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell rowSpan="2">
                      <p>Task 4</p>
                    </TableCell>
                    <TableCell>
                      <p>AQA Eng Lang: June 2017 Paper 2 Q4 (Reading)</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>04/03/2019 - 08/03/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>18/03/2019 - 29/03/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>05/04/2019</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <p>
                        Edexcel Eng Lang: June 2017 Paper 2 Q7 (both
                        parts)(Reading)
                      </p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell rowSpan="2">
                      <p>Task 5</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        AQA Eng Lit: June 2017 Paper 2 Section C Question 1
                        (unseen poetry)
                      </p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>29/04/2019 - 03/05/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>13/05/2019 - 24/05/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>31/05/2019</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <p>
                        Edexcel Eng Lit: June 2017 Paper 2 Section B2 (unseen
                        poetry)
                      </p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell rowSpan="2">
                      <p>Task 6</p>
                    </TableCell>
                    <TableCell>
                      <p>
                        AQA Eng Lit: June 2017 Paper 1 Section A (Shakespeare)
                      </p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>10/06/2019 - 14/06/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>24/06/2019 - 05/07/2019</p>
                    </TableCell>
                    <TableCell rowSpan="2">
                      <p>12/07/2019</p>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <p>
                        Edexcel Eng Lit: June 2017 Paper 1 Section A (both
                        parts) (Shakespeare)
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>*/}

                {/*product === 2 && acYear === 0 && <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
            <Typography color="secondary" gutterBottom>Not available for this year</Typography>
          </Grid>*/}

                {product === 2 && acYear === 0 && (
                  <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
                    <Typography color="secondary" gutterBottom>
                      New York State
                    </Typography>
                    <Paper elevation={4} style={{ marginBottom: "30px" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <p>
                                <b>Window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Writing window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Judging window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Results published</b>
                              </p>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>September</TableCell>
                            <TableCell>23-Sep-2019 - 27-Sep-2019</TableCell>
                            <TableCell>07-Oct-2019 - 18-Oct-2019</TableCell>
                            <TableCell>25-Oct-2019</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>January</TableCell>
                            <TableCell>13-Jan-2020 - 17-Jan-2020</TableCell>
                            <TableCell>27-Jan-2020 - 07-Feb-2020</TableCell>
                            <TableCell>14-Feb-2020</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>June</TableCell>
                            <TableCell>01-Jun-2020 - 05-Jun-2020</TableCell>
                            <TableCell>15-Jun-2020 - 26-Jun-2020</TableCell>
                            <TableCell>03-Jul-2020</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                    <Typography color="secondary" gutterBottom>
                      Louisiana State
                    </Typography>
                    <Paper elevation={4}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <p>
                                <b>Window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Writing window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Judging window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Results published</b>
                              </p>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>September</TableCell>
                            <TableCell>19-Aug-2019 - 23-Aug-2019</TableCell>
                            <TableCell>02-Sep-2019 - 13-Sep-2019</TableCell>
                            <TableCell>25-Oct-2019</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>January</TableCell>
                            <TableCell>09-Dec-2019 - 13-Dec-2019</TableCell>
                            <TableCell>06-Jan-2020 - 17-Jan-2020</TableCell>
                            <TableCell>14-Feb-2020</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>June</TableCell>
                            <TableCell>04-May-2020 - 08-May-2020</TableCell>
                            <TableCell>18-May-2020 - 29-May-2020</TableCell>
                            <TableCell>03-Jul-2020</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                )}

                {product === 0 && acYear === 0 && (
                  <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
                    <Paper elevation={4}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Genre</TableCell>
                            <TableCell>Stimulus Released</TableCell>
                            <TableCell>Writing Window</TableCell>
                            <TableCell>Judging Window</TableCell>
                            <TableCell>Results Published</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Year 3 Oct</TableCell>
                            <TableCell>Fiction</TableCell>
                            <TableCell>07/10/2019</TableCell>
                            <TableCell>14/10/2019 - 18/10/2019</TableCell>
                            <TableCell>04/11/2019 - 08/11/2019</TableCell>
                            <TableCell>15/11/2019</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Year 5 Nov</TableCell>
                            <TableCell>Non Fiction</TableCell>
                            <TableCell>04/11/2019</TableCell>
                            <TableCell>11/11/2019 - 15/11/2019</TableCell>
                            <TableCell>25/11/2019 - 29/11/2019</TableCell>
                            <TableCell>06/12/2019</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Year 1 Jan</TableCell>
                            <TableCell>Fiction</TableCell>
                            <TableCell>06/01/2020</TableCell>
                            <TableCell>13/01/2020 - 17/01/2020</TableCell>
                            <TableCell>27/01/2020 - 31/01/2020</TableCell>
                            <TableCell>07/02/2020</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Year 4 Jan</TableCell>
                            <TableCell>Non Fiction</TableCell>
                            <TableCell>13/01/2020</TableCell>
                            <TableCell>20/01/2020 - 24/01/2020</TableCell>
                            <TableCell>03/02/2020 - 07/02/2020</TableCell>
                            <TableCell>14/02/2020</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Year 2 Mar</TableCell>
                            <TableCell>Fiction</TableCell>
                            <TableCell>03/02/2020</TableCell>
                            <TableCell>24/02/2020 - 28/02/2020</TableCell>
                            <TableCell>09/03/2020 - 13/03/2020</TableCell>
                            <TableCell>20/03/2020</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Year 6 Mar</TableCell>
                            <TableCell>Fiction</TableCell>
                            <TableCell>24/02/2020</TableCell>
                            <TableCell>02/03/2020 - 06/03/2020</TableCell>
                            <TableCell>16/03/2020 - 20/03/2020</TableCell>
                            <TableCell>27/03/2020</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                )}

                {/*product === 1 && acYear === 1 && <Grid item xs={12} style={{ margin: "40px" }}>
          <Paper elevation={4}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Writing Window</TableCell>
                    <TableCell>Judging Window</TableCell>
                    <TableCell>Results Published</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>September</TableCell>
                    <TableCell>16/09/2019 - 18/10/2019</TableCell>
                    <TableCell>21/10/2019 - 08/11/2019</TableCell>
                    <TableCell>01/12/2019</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>June</TableCell>
                    <TableCell>11/05/2020 - 19/06/2020</TableCell>
                    <TableCell>22/06/2020 - 10/07/2020</TableCell>
                    <TableCell>24/08/2020</TableCell>
                  </TableRow>
                  </TableBody>
                  </Table>
                  </Paper>
          </Grid>*/}

                {product === 1 && acYear === 0 && (
                  <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
                    <Paper elevation={4}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell valign="top">
                              <p>
                                <b>Task</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Exam board and question</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Writing window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Judging window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Results published</b>
                              </p>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <p>Task 1</p>
                            </TableCell>
                            <TableCell>
                              <p>
                                AQA Eng Lang: June 2018 Paper 1 Q5 (Writing){" "}
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>23/09/2019 - 27/09/2019</p>
                            </TableCell>
                            <TableCell>
                              <p>07/10/2019 - 18/10/2019</p>
                            </TableCell>
                            <TableCell>
                              <p>25/10/2019</p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <p>Task 2</p>
                            </TableCell>
                            <TableCell>
                              <p>
                                AQA Eng Lang: June 2018 Paper 1 Q4 (Reading)
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>11/11/2019 - 15/11/2019</p>
                            </TableCell>
                            <TableCell>
                              <p>25/11/2019 - 06/12/2019</p>
                            </TableCell>
                            <TableCell>
                              <p>13/12/2019</p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <p>Task 3</p>
                            </TableCell>
                            <TableCell>
                              <p>
                                AQA Eng Lang: June 2018 Paper 2 Q5 (Writing)
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>13/01/2020 - 17/01/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>27/01/2020 - 07/02/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>14/02/2020</p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <p>Task 4</p>
                            </TableCell>
                            <TableCell>
                              <p>
                                AQA Eng Lang: June 2018 Paper 2 Q4 (Reading)
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>02/03/2020 - 06/03/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>16/03/2020 - 27/03/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>03/04/2020</p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <p>Task 5</p>
                            </TableCell>
                            <TableCell>
                              <p>
                                AQA Eng Lit: June 2018 Paper 2 Section C
                                Question 1 (unseen poetry)
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>27/04/2020 - 01/05/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>11/05/2020 - 22/05/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>29/05/2020</p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <p>Task 6</p>
                            </TableCell>
                            <TableCell>
                              <p>
                                AQA Eng Lit: June 2018 Paper 1 Section A
                                (Shakespeare)
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>08/06/2020 - 12/06/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>22/06/2020 - 03/07/2020</p>
                            </TableCell>
                            <TableCell>
                              <p>10/07/2020</p>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                )}

                {/*product === 3 && acYear === 0 && <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
            <Typography color="secondary" gutterBottom>Not available for this year</Typography>
          </Grid>*/}

                {product === 3 && acYear === 0 && (
                  <Grid item xs={12} style={{ margin: "40px 0 0 0" }}>
                    <Typography color="secondary" gutterBottom>Grades 3 & 9</Typography>
                    <Paper elevation={4}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <p>
                                <b>Window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Writing window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Judging window</b>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p>
                                <b>Results published</b>
                              </p>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>January</TableCell>
                            <TableCell>13-Jan-2020 - 17-Jan-2020</TableCell>
                            <TableCell>27-Jan-2020 - 31-Jan-2020</TableCell>
                            <TableCell>14-Feb-2020</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>June</TableCell>
                            <TableCell>01-Jun-2020 - 05-Jun-2020</TableCell>
                            <TableCell>15-Jun-2020 - 19-Jun-2020</TableCell>
                            <TableCell>03-Jul-2020</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Calendars2);
