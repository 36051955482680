import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import PageTitle from "./../components/pageTitle.js";
// import AxiosAll from "./../axiosAll";
/*import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from "@material-ui/core";*/
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
//import { Event } from "@material-ui/icons";

class Events extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  render() {
    return (
      <Grid container justify="space-around" alignItems="stretch">
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <PageTitle content="Training Events" />
        </Grid>
      <Grid item md={4} style={{ padding: "40px" }}>
        <Card>
          <CardHeader title="Knowledge Base" />
          <Divider />
          <CardContent>
            <Typography>You don't need any formal training to get started with comparative judgement!&nbsp; If you want to set up a free account or take part in one of our paid-for national projects, you can use our <a href="https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354" target="_blank" rel="noopener noreferrer">Knowledge Base</a> to find out everything you need to know.&nbsp; You can also email our helpdesk, <a href="mailto:support@nomoremarking.com">support@nomoremarking.com</a>, if you get stuck.</Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item md={4} style={{ padding: "40px" }}>
        <Card>
          <CardHeader title="Free Webinars" />
          <Divider />
          <CardContent>
            <Typography gutterBottom>The webinars are suitable if you want to use the free account or if you want to subscribe to a national project.&nbsp; We offer two different webinar sessions:</Typography>
            <Typography color="secondary">Introduction to Comparative Judgement (1 hour)</Typography>
            <Typography gutterBottom>Judge some essays, see the live results, and learn more about how national projects work.</Typography>
            <Typography color="secondary">Using NoMoreMarking.com (30 minutes)</Typography>
            <Typography gutterBottom>This walks you through the process of setting up a judging task on nomoremarking.com.</Typography>
            <Typography><a href="https://www.notion.so/nmm/Upcoming-events-and-training-opportunities-c7dcae3a2e414caba34929f35df77070" target="_blank" rel="noopener noreferrer">See here for dates</a></Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item md={4} style={{ padding: "40px" }}>
        <Card>
          <CardHeader title="Primary Writing Hubs" />
          <Divider />
          <CardContent>
            <Typography gutterBottom>One of the main uses of comparative judgement is to assess primary writing.&nbsp; In our Primary Writing Hubs, we explore primary writing in more depth, look at examples of writing drawn from across our 500+ participating schools, and look at best practice and evidence-based strategies for improving writing.&nbsp; These are designed for more experienced users of comparative judgement, but beginners with an interest in primary writing will also find them useful.</Typography>
            <Typography><a href="https://www.notion.so/nmm/Upcoming-events-and-training-opportunities-c7dcae3a2e414caba34929f35df77070" target="_blank" rel="noopener noreferrer">See here for dates</a> or if you are an APW subscriber, <a href="mailto:support@nomoremarking.com">contact us</a> for more information.</Typography>

          </CardContent>
        </Card>
        </Grid>
      </Grid>
    )
  }
}

/*const moment = require("moment");
class Events extends Component {
  state = {
    data: [],
    loaded: false
  };
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  componentDidMount() {
    AxiosAll.post("/marketing/getEvents")
      .then(oData => {
        // console.log("oData", oData);
        this.setState({
          data: oData.data,
          loaded: true
        });
      })
      .catch(oErr => {
        // console.log("oErr", oErr);
      });
  }
  render() {
    const { data, loaded } = this.state;
    if (!loaded) return <span>Loading...</span>;
    // console.log("data", data);
    return (
      <Grid container>
        <Grid item xs={12} style={{ margin: "40px" }}>
          <PageTitle content="Events" />
          <Grid container>
            <Paper elevation={4} style={{ width: '100%' }}>
              <List style={{ paddingBottom: 0 }}>
                {data.map(oEvent => (
                  <ListItem
                    button
                    component="a"
                    target="_blank"
                    key={`li_${oEvent._id}`}
                    href={oEvent.link}
                    divider
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Event />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<strong>{oEvent.title}</strong>}
                      secondary={
                        <React.Fragment>
                          <Typography gutterBottom>
                            {oEvent.description}
                          </Typography>
                          <Typography gutterBottom>
                            <i>
                              {moment(
                                oEvent.startDate,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("Do MMMM YYYY HH:mm")}
                            </i>
                          </Typography>
                          <Typography>
                            <strong>{oEvent.location}</strong>
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
                {data.length === 0 && <ListItem>
                  <ListItemText primary="No upcoming training events at the moment, check back soon for more" />
                </ListItem>}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}*/

export default Events;
