import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Avatar, Card, CardHeader, CardMedia, Divider, Slider } from "@material-ui/core";
import { Filter } from "@material-ui/icons"
import AxiosAll from "./../axiosAll";
// import { Slider } from "@material-ui/lab";
import { SizeMe } from "react-sizeme";

import { XYPlot, XAxis, YAxis, HeatmapSeries, Hint } from "react-vis";

const _ = require("lodash");

class DotPlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredNode: null,
      loaded: false,
      candidates: [],
      msg: <Typography gutterBottom>&nbsp;</Typography>,
      binWidth: 20,
      value: 10,
      selCand: null,
    };
  }

  incrementBinWidth = () => {
    this.setState(state => ({ state: state.binWidth++ }));
    this.setState(state => ({
      state: (state.candidates = densityBin(state.candidates, state.binWidth))
    }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.setState(state => ({ state: (state.binWidth = state.value) }));
    this.setState(state => ({
      state: (state.candidates = densityBin(state.candidates, state.binWidth))
    }));
  };

  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;

    AxiosAll.post(`/candidates/getCandidatesForTask`, {
      task: task
    }).then(oResult => {
      this.setState({
        loaded: true,
        candidates: densityBin(oResult.data)
      });
    });
  }
  render() {
    const { loaded, candidates, hoveredNode, binWidth, value, selCand } = this.state;
    if (!loaded) return <span />;
    console.log("state", this.state);
    console.log(
      "y",
      _.min(_.map(candidates, "y")),
      _.max(_.map(candidates, "y"))
    );
    /*
    const yAxisScale = d3.scale.linear()
    .domain([0, 59])
    .range([height, 0])
    .nice();
    */
    console.log(
      "cands",
      _.map(candidates, candidate =>
        _.pick(candidate, [
          "x",
          "y",
          "size",
          "bin",
          "infit",
          "scaledScore",
          "seScaledScore",
          "color"
        ])
      )
    );
    return (
      <SizeMe>
      {({ size }) => {
        const leftWidth = 4 / 12 * size.width // - 100
        const leftHeight = leftWidth * 1.414
        return (
      <Grid container>
        <Grid item xs={4}>
        <div>
          <Typography id="label">Bin Width: {parseInt(binWidth, 10)} %</Typography>
          <div style={{ margin: '0 50px 0 50x' }}>
            <Slider
              value={value}
              min={1}
              aria-labelledby="label"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <XYPlot
          xType="linear"
          yType="ordinal"
          margin={50}
          width={leftWidth}
          height={leftHeight}
        >
          <XAxis orientation="top" title='Scaled Score'/>
          <YAxis />
          <HeatmapSeries
            colorRange={[
              "#a6cee3",
              "#1f78b4",
              "#b2df8a",
              "#33a02c",
              "#fb9a99",
              "#e31a1c",
              "#fdbf6f",
              "#ff7f00",
              "#cab2d6",
              "#6a3d9a",
              "#ffff99",
              "#b15928"
            ]}
            style={{
              stroke: "white",
              strokeWidth: "2px",
              rectStyle: {
                rx: 10,
                ry: 10
              }
            }}
            onValueMouseOver={d => this.setState({ hoveredNode: d })}
            onValueClick={(datapoint, event) => {
              const oSelCand = _.find(candidates, { _id: datapoint._id })
              console.log('click', datapoint, candidates, oSelCand)
              this.setState({ selCand: oSelCand })
            }}
            className="heatmap-series-example"
            data={candidates}
          />
          {hoveredNode && (
            <Hint
              xType="literal"
              yType="literal"
              getX={d => d.x}
              getY={d => d.y}
              value={{
                score: hoveredNode.scaledScore,
                firstname: hoveredNode.firstName,
                lastName: hoveredNode.lastName,
                level: hoveredNode.level
              }}
            />
          )}
        </XYPlot>
        </Grid>
        <Grid item xs={8} style={{ padding: '0 20px 0 20px' }}>
          {!!selCand && <Card >
            <CardHeader avatar={<Avatar><Filter /></Avatar>} title={`${selCand.firstName} ${selCand.lastName}`} subheader={`${selCand.qrcode} / ${selCand.level}`} />
            <Divider />
            <CardMedia>
              {selCand.scans.map(scan => <img src={scan} alt="*" style={{ width: '100%' }} /> )}
            </CardMedia>
          </Card>}
        </Grid>
      </Grid>
        )}}
      </SizeMe>
    );
  }
}

export default withRouter(DotPlots);

/*function bubbles(x) {
  _.map(x, o => {
    o.x = o.bin;
    o.y = o.infit;
    o.size = o.seScaledScore;
  });
  return x;
}*/

function densityBin(x, binp = null) {
  let results;
  if (x.length === 0) return [];

  // use scaled scores
  const ts = _.map(x, "scaledScore");
  if (!binp) binp = 10;
  const binwidth = (_.max(ts) - _.min(ts)) * (binp / 100);
  // binwidth = 50;
  console.log("binwidth: ", binwidth);
  // sort by ts
  x = _.sortBy(x, "scaledScore");

  let cbin = 0; // current bin ID
  let binend = -Infinity; // End position of current bin (scan left to right)

  results = _.map(x, (o, i) => {
    console.log(o, i);
    o.scaledScore = _.round(o.scaledScore, 2);
    o.color = o.levelValue;
    if (o.scaledScore >= binend) {
      binend = o.scaledScore + binwidth;
      //console.log('new bin end:', binend);
      cbin++;
    }
    o.bin = cbin;
    o.size = 1;
    return o;
  });

  let lastBin = 0;
  let newBin;
  let binRank = 1;
  _.map(results, o => {
    const binos = _.filter(results, ["bin", o.bin]);
    // console.log(binos);
    const ts = _.map(binos, "scaledScore");
    o.x = (_.max(ts) + _.min(ts)) / 2;
    newBin = binos[0].bin;
    if (newBin === lastBin) {
      binRank++;
    } else {
      binRank = 1;
      lastBin = newBin;
    }
    o.y = binRank;
  });

  console.log(results);

  return results;
}
