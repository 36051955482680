import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { Grid, Button, Avatar, Divider, Typography } from "@material-ui/core";
import { School } from "@material-ui/icons";
import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";

class JudgingSignup extends Component {
  state = {
    disabled: false,
    taskName: "",
    judgeQuestion: "",
    judgeInfo: '',
    loaded: false,
    msg: <span />,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getTask`, { task: task }).then(oResult => {
      console.log('getTask', oResult.data)
      this.setState({
        taskName: oResult.data.name,
        judgeQuestion: oResult.data.judgeQuestion,
        judgeInfo: (oResult.data.judgeInfo || ''),
        loaded: true
      });
    });
  }
  render() {
    const { disabled, taskName, judgeQuestion, judgeInfo, loaded, msg } = this.state;
    const { match } = this.props;
    const task = match.params.task;
    if (!loaded) return <span />;
    console.log('state', this.state)
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values, { setSubmitting }) => {
                // console.log('signup', values, values.email)
                this.setState({ disabled: true, msg: <Typography color="secondary" gutterBottom>Initialising...</Typography> });
                AxiosAll.post(`/judges/createJudge`, {
                  task: task,
                  email: values.email,
                  domain: ""
                }).then(oResult => {
                  // console.log('>', oResult)
                  const judge = oResult.data.judge;
                  const url = `${
                    window.location.origin
                  }/judging/${task}/${judge}`;
                  AxiosAll.post(`/utils/sendEmail`, {
                    to: values.email,
                    subject: "No More Marking - Added as a Judge",
                    text: [
                      `Dear ${values.email}\n`,
                      `You have now been registered as a judge for the task: ${taskName}.\n`,
                      `Your direct web link is: ${url}\n`,
                      `Thank you!\n\n\n`
                    ].join("\n"),
                    html: ""
                  }).then(oEmailResult => {
                    this.props.history.push(`/judging/${task}/${judge}`);
                  });
                });
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email address")
                  .required("Required")
              })}
            >
              {({ isSubmitting, values, errors, dirty }) => (
                <Form>
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar>
                          <School />
                        </Avatar>
                      }
                      title="Judge Signup"
                      subheader={
                        <span>
                          Complete the form below to become a judge on the{" "}
                          <strong>"{taskName}"</strong> Task.&nbsp; The question
                          is <strong>"{judgeQuestion}"</strong>.
                        </span>
                      }
                    />
                    <Divider />
                    <CardContent>
                      {judgeInfo !== '' && <Typography gutterBottom>{judgeInfo}</Typography>}
                      {msg}
                      <FormikTextField
                        autoFocus
                        name="email"
                        label="Email Address"
                        fullWidth
                      />
                    </CardContent>
                    <CardActions>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={disabled || isSubmitting}
                        style={{ textTransform: "inherit" }}
                      >
                        Submit
                      </Button>
                    </CardActions>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(JudgingSignup);
