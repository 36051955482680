import auth0 from 'auth0-js';
const _ = require('lodash');

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: 'nmm.eu.auth0.com',
      clientID: '232Gp1LKDOuxs5w2Uhuerguu4eFoZ4cu',
      redirectUri: `${window.location.origin}/callback`,
      audience: 'https://nmm.eu.auth0.com/userinfo',
      responseType: 'token id_token',
      scope: 'openid email'
    });

    this.authFlag = 'isLoggedIn';
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login(redirect) {
    localStorage.setItem('redirect', redirect)
    this.auth0.authorize();
  }

  getIdToken() {
    return this.idToken;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    })
  }

  setSession(authResult) {
    this.idToken = authResult.idToken;
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem(this.authFlag, JSON.stringify(true));
    localStorage.setItem('email', authResult.idTokenPayload.email);
    localStorage.setItem('isAdmin', (_.indexOf(['brian@nomoremarking.com', 'patrick@nomoremarking.com', 'neil@nomoremarking.com', 'chris@nomoremarking.com'], authResult.idTokenPayload.email) > -1));
    localStorage.setItem('token', authResult.idToken);

  }

  logout() {
    localStorage.setItem(this.authFlag, JSON.stringify(false));
    localStorage.removeItem('email');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('token');
    this.auth0.logout({
      returnTo: window.location.origin,
      clientID: '232Gp1LKDOuxs5w2Uhuerguu4eFoZ4cu',
    });
  }

  silentAuth() {
    if(this.isAuthenticated()) {
      return new Promise((resolve, reject) => {
        this.auth0.checkSession({}, (err, authResult) => {
          if (err) {
            localStorage.removeItem(this.authFlag);
            return reject(err);
          }
          this.setSession(authResult);
          resolve();
        });
      });
    }
  }

  isAuthenticated() {
    // Check whether the current time is past the token's expiry time
    //return new Date().getTime() < this.expiresAt;
    return JSON.parse(localStorage.getItem(this.authFlag));
  }
}

const auth = new Auth();

export default auth;