import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Tabs, Tab, Paper, Typography } from "@material-ui/core";
import "react-table/react-table.css";
import PopulateAssembly from "./assembly.js";
import UploadCandidatesCSV from "./uploadCandidatesCSV.js";
import UploadCandidatesCSV2 from "./uploadCandidatesCSV2.js";
import Anchors2 from "./anchors2.js";
import PageTitle from "./../components/pageTitle.js";
import ViewCandidates from "./viewCandidates.js";
// import "typeface-roboto";
import TaskMenu2 from './../layout/taskMenu2.js'
import AxiosAll from "./../axiosAll";

class Candidates extends Component {
  state = {
    tab: 2,
    loaded: false,
    locked: false,
    isModTask: false,
    taskName: '',
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getTask`, {
      task: task,
    })
    .then(oResult => {
      // console.log('oResult', oResult.data)
      this.setState({
        taskName: oResult.data.name,
        locked: oResult.data.locked,
        isModTask: oResult.data.isModTask,
        loaded: true,
      })
    })
  }
  handleTabChange = (event, tab) => {
    // console.log("handleTabChange", tab);
    this.setState({ tab });
  };
  render() {
    const { tab, locked, isModTask, taskName, loaded } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    return (
      <Grid container style={{ margin: '0 15px 15px 15px', paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} />
        </Grid>
        <Grid item xs={8}>
        <PageTitle content="Candidates" />
        <Paper elevation={4}>
          <Tabs value={tab} onChange={this.handleTabChange}>
            <Tab label="Assembly Populate" />
            <Tab label="CSV Upload" />
            <Tab label="View" />
            <Tab label="Anchors" />
          </Tabs>
          <Paper elevation={4} style={{ padding: "20px" }}>
            {tab === 0 && (
              <div>
                <PopulateAssembly />
              </div>
            )}
            {tab === 1 && (
              <div>
                <UploadCandidatesCSV2
                  changeTab={e => {
                    this.handleTabChange(e, 2);
                  }}
                />
              </div>
            )}
            {tab === 2 && (
              <div>
                <ViewCandidates
                  changeTab0={e => {
                    this.handleTabChange(e, 0);
                  }}
                  changeTab1={e => {
                    this.handleTabChange(e, 1);
                  }}
                />
              </div>
            )}
            {tab === 3 && (
              <div>
                <Anchors2 />
              </div>
            )}
          </Paper>
        </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Candidates);
