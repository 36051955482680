import React, { Component } from "react";
import {
  Grid,
  Divider,
  Avatar,
  Typography,
  Button,
  Fab,
} from "@material-ui/core";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {
  CheckCircle,
  Delete,
  Person,
  PersonPin,
  SupervisorAccount,
  School
} from "@material-ui/icons";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import PageTitle from "./../components/pageTitle.js";
import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";

const CheckboxTable = checkboxHOC(ReactTable);
const _ = require("lodash");

class AccountSummary extends Component {
  state = {
    selection: [],
    selectAll: false,
    msg: <span />,
    addEmailHelper: <span />,
    existingUsersHelper: <span />,
    oSchool: {},
    oUsers: [],
    loaded: false,
    noSchool: false
  };
  componentDidMount() {
    // console.log(">>componentDidMount");
    const promAccount = AxiosAll.post(`/accounts/getSchoolUsers`);
    const promSchool = AxiosAll.post(`/accounts/getSchool`);
    Promise.all([promAccount, promSchool]).then(oResults => {
      console.log('oRes', oResults)
      const oUsers = oResults[0];
      const oSchool = oResults[1].data;
      if (!_.has(oSchool, "dfe")) {
        // console.log("User part of 0 or more than 1 school");
        this.setState({
          loaded: true,
          noSchool: true,
          oSchool: {}
        });
      } else {
        this.setState({
          oSchool: oSchool,
          oUsers: oUsers.data,
          loaded: true
        });
      }
    });
  }
  handleSectionClick = event => {
    this.setState({
      section: event.currentTarget.id
    });
  };
  handleSectionCheckbox = event => {
    const step = event.currentTarget.id.split("_")[1];
    const section = event.currentTarget.id.split("_")[2];
    const oSteps = this.state.steps;
    oSteps[step].sections[section].checked = !oSteps[step].sections[section]
      .checked;
    this.setState({
      steps: oSteps
    });
  };
  toggleSelection = (key, shift, row) => {
    key = key.replace('select-', '')
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  render() {
    // const self = this;
    const {
      selection,
      selectAll,
      addEmailHelper,
      existingUsersHelper,
      oSchool,
      oUsers,
      loaded,
      noSchool
    } = this.state;
    const { toggleSelection, toggleAll, isSelected } = this;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };
    const columns = [
      {
        Header: "Email",
        accessor: "email",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.email}
          </div>
        )
      },
      {
        Header: "Super User",
        accessor: "superUser",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.superUser ? <CheckCircle /> : <span />}
          </div>
        )
      },
    ];

    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    let oUsers2 = _.map(oUsers, function(oUser) {
      return {
        _id: oUser.user,
        email: oUser.email,
        superUser: oUser.user === oSchool.superUser,
        accountExists: true,
      };
    });
    oUsers2 = _.filter(oUsers2, function(oUser) { return !_.includes(oSchool.accountsToCreate, oUser.email) })
    /*_.each((oSchool.accountsToCreate || []), function(email, i) {
      oUsers2.push({
        _id: i,
        email: email,
        superUser: false,
        accountExists: false,
      })
    })*/
    if (_.has(oSchool, "error")) {
      return <div>{existingUsersHelper}</div>;
    }
    console.log('state', oUsers2, this.state)
    if (noSchool)
      return (
        <Grid container style={{ paddingTop: '146px' }}>
          <Grid item xs={12} style={{ margin: "20px" }}>
            <PageTitle content="Account Summary" />
          </Grid>
          <Grid item xs={12} style={{ margin: "20px" }}>
            <Typography variant="h6">
              <strong>
                You are not currently connected to any school.&nbsp; To connect
                your account:
              </strong>
            </Typography>
            <ul>
              <li>
                If your school has an existing subscription please ask your
                existing Admin to add you as a user
              </li>
              <li>Use the Subscribe option to create a new subscription.</li>
            </ul>
          </Grid>
        </Grid>
      );
    const oInvite = _.map(oSchool.accountsToCreate, email => {
      return {
        email: email,
        accountSetup: _.includes(_.map(oUsers, 'email'), email)
      }
    })
    console.log('oInvite', oInvite)
    return (
      <Grid container style={{ paddingTop: '146px' }}>
        <Grid item xs={12} style={{ margin: "20px" }}>
          <PageTitle content="Account Summary" />
        </Grid>
        <Grid
          container
          justify="space-around"
          alignItems="stretch"
          xstyle={{ margin: '0 20px 0 20px' }}
        >
          <Typography gutterBottom style={{ width: '75%' }}>
            Use this option to add additional task owners within your
                    school for the national tasks.&nbsp; If the new user does not
                    currently have an account with No More Marking they will be
                    emailed with sign up instructions.&nbsp; Administration access
                    allows you to see sensitive information on the performance
                    of teachers.&nbsp; Judges do not need administrative access.
          </Typography>
          <Card>
            <CardHeader avatar={<Avatar><School /></Avatar>} title={oSchool._id === -1 ? "Account not currently associated with any school" : `${oSchool.dfe || "-"} / ${oSchool.urn || "-"}`} subheader={oSchool.schoolName} />
          </Card>
        </Grid>
        <Grid item xs={12} style={{ margin: "20px" }}>
          <Grid container>
            {/*<Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="body2">
                    Use this option to add additional task owners within your
                    school for the national tasks. If the new user does not
                    currently have an account with No More Marking they will be
                    emailed with sign up instructions. Administration access
                    allows you to see sensitive information on the performance
                    of teachers. Judges do not need administrative access.
                  </Typography>
                </CardContent>
                <Divider />
                <CardContent>
                  <Typography gutterBottom variant="headline" component="h2">
                    School
                  </Typography>
                  <Typography component="p">
                    {oSchool._id === -1
                      ? "Account not currently associated with any school"
                      : `${oSchool.dfe || "-"} / ${oSchool.urn || "-"}`}
                  </Typography>
                  <Typography component="p">{oSchool.schoolName}</Typography>
                </CardContent>
              </Card>
            </Grid>*/}
            <Grid
              item
              xs={6}
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Person />
                    </Avatar>
                  }
                  title="Add User"
                  // subheader="User must have an account setup already"
                />
                <Divider />
                <CardContent>
                  <Typography gutterBottom>
                    Administration access allows you to see sensitive
                    information on the performance of teachers.&nbsp; Judges do
                    not need administrative access.&nbsp; Are you sure you wish
                    to proceed?
                  </Typography>
                  <Typography gutterBottom>If the user does not currently have an account they will be sent an invitation email.</Typography>
                  <Formik
            initialValues={{
              addEmail: '',
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log("submitted", values);
              AxiosAll.post(`/accounts/addSchoolUser`, {
                email: values.addEmail,
              }).then(result => {
                /*const promAccount = AxiosAll.post(`/accounts/getSchoolUsers`);
                const promSchool = AxiosAll.post(`/accounts/getSchool`);
                Promise.all([promAccount, promSchool]).then(oResults => {
                  console.log('oRes', oResults)
                  const oUsers = oResults[0];
                  const oSchool = oResults[1].data;
                  resetForm({ addEmail: '' })
                  setSubmitting(false)
                  if (!_.has(oSchool, "dfe")) {
                    // console.log("User part of 0 or more than 1 school");
                    this.setState({
                      noSchool: true,
                      oSchool: {}
                    });
                  } else {
                    this.setState({
                      oSchool: oSchool,
                      oUsers: oUsers.data,
                      addEmailHelper: <Typography color="secondary">{result.data.body}</Typography>,
                    });
                  }
                });*/

                // console.log("result", result);
                // if ( result.data.body === "Email address added" ) {
                  AxiosAll.post(
                    `/accounts/getSchoolUsers`
                  ).then(oResult => {
                    // console.log("oNewUsers", oResult);
                    this.setState({
                      addEmailHelper: <Typography color="secondary" gutterBottom>{result.data.body}</Typography>,
                      existingUsersHelper: <span />,
                      oUsers: oResult.data
                    });
                  });
                  resetForm({ addEmail: '' })
                  setSubmitting(false)
                /*} else {
                  this.setState({
                    addEmailHelper: <Typography color="secondary">{result.data.body}</Typography>
                  });
                }*/
              });
            }}
            validationSchema={Yup.object().shape({
              addEmail: Yup.string().email('Must be a valid email').required('Required')
            })}
          >
            {({ isSubmitting, values, errors, dirty }) => (
              <div>
                {addEmailHelper}
                <Form>
                  <FormikTextField name="addEmail" label="Add Email" fullWidth />
                  <Button size="small" type="submit" variant="contained" color="primary" style={{ textTransform: 'inherit', marginTop: '15px' }} disabled={isSubmitting}>Add User</Button>
                </Form>
              </div>
            )}
          </Formik>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <SupervisorAccount />
                    </Avatar>
                  }
                  title="Existing Users"
                />
                <Divider />
                <CardContent>
                  <Typography gutterBottom>Set a Super User (main contact) for a task by selecting them
                  and clicking on <PersonPin /></Typography>
                  {existingUsersHelper}
                  <Fab
                    style={{ margin: "15px 5px 5px 0" }}
                    size="small"
                    color="secondary"
                    disabled={selection.length === 0}
                    onClick={e => {
                      const r = window.confirm(
                        `Remove User(s)\nAre you sure?\n`
                      );
                      if (!r) return;
                      AxiosAll.post(`/accounts/deleteSchoolUser`, {
                        users: selection
                      }).then(response => {
                        // console.log("response", response);
                        if (response.data.body !== "") {
                          this.setState({
                            existingUsersHelper: <Typography color="secondary">{response.data.body}</Typography>,
                            addEmailHelper: <span />,
                          });
                        } else {
                          this.setState({
                            oUsers: _.filter(oUsers, function(o) {
                              return _.indexOf(selection, o.user) === -1;
                            }),
                            existingUsersHelper: <Typography color="secondary">User(s) removed</Typography>, // <Alert text="User Removed" type="success" />,
                            addEmailHelper: <span />,
                            selection: [],
                            selectAll: false
                          });
                        }
                      });
                    }}
                  >
                    <Delete />
                  </Fab>
                  <Fab
                    style={{ margin: "15px 5px 5px 0" }}
                    size="small"
                    color="secondary"
                    disabled={selection.length !== 1 || _.filter(oUsers2, oUser => { return (_.includes(selection, oUser._id) && oUser.accountExists === true) }).length === 0}
                    onClick={e => {
                      AxiosAll.post(`/accounts/setSuperUser`, {
                        user: selection[0]
                      }).then(oResult => {
                        const oNewSchool = oSchool;
                        oNewSchool.superUser = selection[0];
                        this.setState({
                          superUser: oNewSchool,
                          msg: <Typography color="secondary">Successfully set Super User</Typography>,
                        });
                      });
                    }}
                  >
                    <PersonPin />
                  </Fab>
                  {/*<Fab
                    style={{ margin: "15px 5px 5px 0" }}
                    size="small"
                    color="secondary"
                    disabled={selection.length !== 1 || _.filter(oUsers2, oUser => { return (_.includes(selection, oUser._id) && oUser.accountExists === true) }).length > 0}
                    onClick={e => {
                      console.log('add', selection, oUsers2, _.find(oUsers2, { _id: selection[0] }))
                    }}
                  >
                    <Add />
                  </Fab>*/}
                  <CheckboxTable defaultPageSize={50}
                    data={oUsers2}
                    columns={columns}
                    minRows={0}
                    showPagination={false}
                    ref={r => (this.checkboxTable = r)}
                    style={{ marginBottom: "20px" }}
                    {...checkboxProps}
                  />
                </CardContent>
              </Card>
            </Grid>
            {/*<Grid item xs={4} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <SupervisorAccount />
                    </Avatar>
                  }
                  title="Invited Users"
                />
                <Divider />
                <CardContent>
                  <Typography>Not set up account yet</Typography>
                  {_.filter(oInvite, { accountSetup: false }).length === 0 && <Typography>-</Typography>}
                  {_.filter(oInvite, { accountSetup: false }).map((oRow, i) => {
                    return <Chip key={`notSetup_${i}`} label={oRow.email} />
                  })}
                </CardContent>
                <Divider />
                <CardContent>
                  <Typography>Set up account</Typography>
                  {_.filter(oInvite, { accountSetup: true }).length === 0 && <Typography>-</Typography>}
                  {_.filter(oInvite, { accountSetup: true }).map((oRow, i) => {
                    return <Chip key={`setup_${i}`} label={oRow.email} />
                  })}
                </CardContent>
                <CardContent>
                  <Button color="primary" variant="contained" size="small" style={{ textTransform: 'inherit' }} onClick={e => {
                    console.log('add users', _.filter(oInvite, { accountSetup: true }))
                    const arrProm = _.map(_.filter(oInvite, { accountSetup: true }), oRow => {
                      return AxiosAll.post(`/accounts/addSchoolUser`, { email: oRow.email })
                    })
                    Promise.all(arrProm)
                    .then(oResult => {
                      const promAccount = AxiosAll.post(`/accounts/getSchoolUsers`);
                      const promSchool = AxiosAll.post(`/accounts/getSchool`);
                      Promise.all([promAccount, promSchool]).then(oResults => {
                        console.log('oRes', oResults)
                        const oUsers = oResults[0];
                        const oSchool = oResults[1].data;
                        if (!_.has(oSchool, "dfe")) {
                          // console.log("User part of 0 or more than 1 school");
                          this.setState({
                            noSchool: true,
                            oSchool: {}
                          });
                        } else {
                          this.setState({
                            oSchool: oSchool,
                            oUsers: oUsers.data,
                          });
                        }
                      });
                    })
                  }}>Add User(s)</Button>
                </CardContent>
              </Card>
          </Grid>*/}
        </Grid>
      </Grid>
      </Grid>
    );
  }
}

export default AccountSummary;
