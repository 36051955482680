import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Divider, Avatar } from "@material-ui/core";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import AxiosAll from "./../axiosAll";

class Display extends Component {
  state = {
    scans: [],
    qrcode: "",
    loaded: false
  };
  componentDidMount() {
    const { match } = this.props;
    const candidate = match.params.candidate;

    AxiosAll.post(`/candidates/getCandidate/`, { candidate: candidate }).then(
      oCand => {
        console.log("oCand", oCand.data);
        // const task = oCand.data.task;
        this.setState({
          qrcode: oCand.data.qrcode || '',
          scans: oCand.data.scans,
          loaded: true
        });
        // console.log("oCand", oCand, task);
      }
    );
  }
  render() {
    const { scans, qrcode, loaded } = this.state;
    if (!loaded) return <span />;
    console.log("state", this.state);
    return (
      <div style={{ paddingTop: '146px' }}>
        <Grid container style={{ margin: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Person />
                    </Avatar>
                  }
                  title={`QR Code: ${(qrcode || '').trim().replace(/^$/, "-")}`}
                />
                <Divider />
                {scans.map((scan, i) => {
                  // console.log("oScan", scan);
                  return (
                    <CardContent key={`img_${i}`}>
                      <img
                        src={scan}
                        alt="*"
                        style={{ maxWidth: "100%" }}
                      />
                    </CardContent>
                  );
                })}
                {scans.length === 0 && (
                  <CardContent>No Scans for Candidate</CardContent>
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Display);
