import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Divider,
  Button,
  Badge, Fab
} from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { Delete, Check, Close } from "@material-ui/icons";
import Papa from "papaparse";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import * as yup from 'yup';
// import "typeface-roboto";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";

const CheckboxTable = checkboxHOC(ReactTable);
const _ = require("lodash");
// const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class FixedJudgements extends Component {
  state = {
    msg: <span />,
    disabled: false,
    selection: [],
    selectAll: false,
    judgesToAdd: [],
    judges: [],
    candidates: [],
    csvContents: [],
    fixed: [],
    oTask: {},
    loaded: false
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    const promJudges = AxiosAll.post(`/judges/getJudgesForTask/`, {
      task: task
    });
    const promGetCands = AxiosAll.post(`/candidates/getCandidatesForTask`, {
      task: task
    });
    const promFixed = AxiosAll.post(`/tasks/getFixedJudgementsForTask`, {
      task: task
    });
    const promTask = AxiosAll.post(`/tasks/getTask`, { task: task });
    Promise.all([promJudges, promGetCands, promFixed, promTask]).then(
      oResults => {
        // console.log("oResults", oResults);
        this.setState({
          judges: oResults[0].data,
          candidates: oResults[1].data,
          fixed: oResults[2].data,
          oTask: oResults[3].data,
          loaded: true
        });
      }
    );
  }
  toggleSelection = (key, shift, row) => {
    key = key.replace('select-', '')
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  handleFileChange = e => {
    const self = this;
    const { judges, candidates } = this.state;
    const file = e.target.files[0];
    this.setState({ disabled: true });
    Papa.parse(file, {
      delimiter: true,
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      complete: function(oResults, file) {
        const schema = yup.array().of(yup.object().shape({
          ID: yup.number('ID Must be numeric').required('ID Required'),
          Judge: yup.string().email('Judge must be a valid email address').required('Judge email required'),
          Left: yup.mixed().oneOf(_.map(candidates, "qrcode"),'Left must be an existing candidate code').required('Left required'),
          Right: yup.mixed().oneOf(_.map(candidates, "qrcode"),'Right must be an existing candidate code').required('Left required'),
        }))
        schema.validate(oResults.data)
        .then(x => {
          console.log('x', x)
          const arrExistingJudges = _.uniq(_.map(judges, "email"));
          const arrCSVJudges = _.uniq(
            _.map(oResults.data, function(o) {
              return o.Judge.toLowerCase();
            })
          );
          const arrJudgesToAdd = _.difference(arrCSVJudges, arrExistingJudges);
          self.setState({
            judgesToAdd: arrJudgesToAdd,
            disabled: false,
            csvContents: _.map(oResults.data, function(o) {
              o.id = parseInt(o.ID, 10)
              o.ID = parseInt(o.ID, 10)
              return o;
            })
          });
        })
        .catch(oErr => {
          console.log('Error', oErr)

          self.setState({
            msg: <Alert text={`${oErr.errors[0]} (Value: ${oErr.params.value})`} type="error" />
            // msg: <Alert text={`${oErr.errors[0]} Value:${oErr.params.value} Row:${oErr.path.match(/^\[.*?(\d+).*\]/)[0]}`} type="error" />
          })
        })

        /*if (
          _.intersection(oResults.meta.fields, ["ID", "Judge", "Left", "Right"])
            .length !== 4
        ) {
          self.setState({
            msg: (
              <Alert
                text={`Invalid Column Headings in the CSV file`}
                type="error"
              />
            )
          });
          return;
        }
        const arrExistingJudges = _.uniq(_.map(judges, "email"));
        const arrCSVJudges = _.uniq(
          _.map(oResults.data, function(o) {
            return o.Judge.toLowerCase();
          })
        );
        const arrJudgesToAdd = _.difference(arrCSVJudges, arrExistingJudges);
        const arrInvalidJudgeEmails = _.filter(arrJudgesToAdd, function(email) {
          // console.log("*", email, !reEmail.test(email));
          return !reEmail.test(email);
        });
        const arrCSVCandidatesL = _.uniq(
          _.map(oResults.data, function(o) {
            return o.Left.toUpperCase();
          })
        );
        const arrCSVCandidatesR = _.uniq(
          _.map(oResults.data, function(o) {
            return o.Right.toUpperCase();
          })
        );
        const arrCSVCandidates = _.flatten([
          arrCSVCandidatesL,
          arrCSVCandidatesR
        ]);
        const arrExistingCandidates = _.map(candidates, "qrcode");
        const arrInvalidCands = _.difference(
          arrCSVCandidates,
          arrExistingCandidates
        );
        if (arrInvalidCands.length) {
          self.setState({
            msg: (
              <Alert
                text={`Invalid Candidates: ${arrInvalidCands.join(",")}`}
                type="error"
              />
            )
          });
        } else if (arrInvalidJudgeEmails.length) {
          self.setState({
            msg: <Alert text={`Invalid Judge Emails`} type="error" />
          });
        } else {
          self.setState({
            judgesToAdd: arrJudgesToAdd,
            disabled: false,
            csvContents: _.map(oResults.data, function(o) {
              o.id = o.ID;
              return o;
            })
          });
        }*/
      }
    });
  };
  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const {
      fixed,
      loaded,
      selection,
      selectAll,
      msg,
      judgesToAdd,
      disabled,
      oTask,
      csvContents,
      judges,
      candidates
    } = this.state;
    if (!loaded) return <span />;
    const { match } = this.props;
    const task = match.params.task;
    const columns = [
      {
        Header: "ID",
        accessor: "row",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>{row.original.row}</div>
        ),
        filterable: false
      },
      {
        Header: "Judge",
        accessor: "judgeEmail",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {(_.find(judges, { _id: row.original.judge }) || {}).email}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Left Candidate",
        accessor: "leftQR",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {(_.find(candidates, { _id: row.original.leftCand }) || {}).qrcode}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Right Candidate",
        accessor: "rightQR",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {(_.find(candidates, { _id: row.original.rightCand }) || {}).qrcode}
          </div>
        ),
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: "Completed",
        accessor: "completed",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.completed ? <Check /> : <Close />}
          </div>
        ),
        filterable: false
      }
    ];
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };
    console.log("state", this.state);
    return (
      <Grid container>
        <Grid item xs={6}>
          <Card style={{ marginRight: "15px" }}>
            <CardHeader title="Upload Fixed Judgements" />
            <Divider />
            <CardContent>
              {msg}
              <FormControl>
                <InputLabel htmlFor="idUploadCSV">File</InputLabel>
                <Input
                  id="idUploadCSV"
                  type="file"
                  onChange={e => {
                    this.handleFileChange(e);
                  }}
                />
                <FormHelperText>Select a CSV file</FormHelperText>
              </FormControl>
            </CardContent>
            <CardActions>
              <Badge badgeContent={judgesToAdd.length} color="secondary">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={disabled || judgesToAdd.length === 0}
                  style={{ textTransform: 'inherit' }}
                  onClick={e => {
                    this.setState({ disabled: true });
                    const arrProm = _.map(judgesToAdd, function(judgeEmail) {
                      AxiosAll.post(`/utils/sendEmail`, {
                        to: judgeEmail,
                        subject: "No More Marking - Added as Owner",
                        text: [
                          `Dear Sir/Madam\n`,
                          `You have been added as an owner to the task: "${
                            oTask.name
                          }".\n\n`,
                          `Best Wishes,`
                        ],
                        html: ""
                      });
                      return AxiosAll.post(`/judges/createJudge`, {
                        task: task,
                        email: judgeEmail,
                        domain: window.location.origin
                      });
                    });
                    Promise.all(arrProm).then(oResult => {
                      AxiosAll.post(`/judges/getJudgesForTask`, {
                        task: task
                      }).then(oJudges => {
                        this.setState({
                          disabled: false,
                          judgesToAdd: [],
                          judges: oJudges.data
                        });
                      });
                    });
                  }}
                >
                  Email Judges
                </Button>
              </Badge>
              <Badge badgeContent={csvContents.length} color="secondary">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={
                    disabled ||
                    judgesToAdd.length > 0 ||
                    csvContents.length === 0
                  }
                  onClick={e => {
                    this.setState({ disabled: true });
                    AxiosAll.post(`/tasks/createFixedJudgements`, {
                      task: task,
                      CSVContents: JSON.stringify(csvContents)
                    }).then(oResults => {
                      AxiosAll.post(`/tasks/getFixedJudgementsForTask`, {
                        task: task
                      }).then(oFixed => {
                        this.setState({
                          fixed: oFixed.data,
                          csvContents: [],
                          disabled: false
                        });
                      });
                    });
                  }}
                  style={{ textTransform: 'inherit' }}
                >
                  Add Fixed Judgements
                </Button>
              </Badge>
            </CardActions>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <i>Column Title</i>
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Judge</TableCell>
                  <TableCell>Left</TableCell>
                  <TableCell>Right</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <i>Content</i>
                  </TableCell>
                  <TableCell>Row Identifier</TableCell>
                  <TableCell>Email address</TableCell>
                  <TableCell>Left QR Code</TableCell>
                  <TableCell>Right QR Code</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Fab
            size="small"
            style={{ margin: "0 5px 5px 0", textTransform: 'inherit' }}
            color="secondary"
            disabled={selection.length === 0}
            onClick={e => {
              const r = window.confirm(
                `Delete Fixed Judgements(s)?\nAre you sure?`
              );
              if (!r) return;
              AxiosAll.post(`/tasks/deleteFixedJudgements`, {
                task: task,
                ids: selection
              }).then(oResult => {
                // console.log('deleteFixedJudgements', oResult)
                AxiosAll.post(`/tasks/getFixedJudgementsForTask`, {
                  task: task
                }).then(oFixed => {
                  // console.log('getFixedJudgementsForTask', oFixed.data)
                  this.setState({
                    fixed: oFixed.data,
                    csvContents: [],
                    disabled: false,
                    selection: [],
                    selectAll: false,
                  });
                });
              });
            }}
          >
            <Delete />
          </Fab>

          <CheckboxTable defaultPageSize={50}
            data={fixed}
            filterable
            columns={columns}
            minRows={0}
            showPagination={false}
            ref={r => (this.checkboxTable = r)}
            style={{ marginBottom: "20px" }}
            {...checkboxProps}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(FixedJudgements);
