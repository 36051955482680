import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Paper, Tabs, Tab, Grid, Typography } from "@material-ui/core";
import PageTitle from "./../components/pageTitle.js";
// import "typeface-roboto";
import CandidatesReporting from "./candidates.js";
import TaskLevels from "./levels.js";
import DotPlots from './dotPlots.js'
import ReportingResources from './resources.js';
import TaskMenu2 from './../layout/taskMenu2.js'
import AxiosAll from "./../axiosAll";
import Monitoring from "./monitoring.js";

class Reporting extends Component {
  state = {
    tab: 0,
    group: "All Candidates",
    category: "School",
    taskName: '',
    locked: false,
    isModTask: false,
    country: '',
    loaded: false,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getTask`, {
      task: task,
    })
    .then(oResult => {
      // console.log('oResult', oResult.data)
      this.setState({
        taskName: oResult.data.name,
        locked: oResult.data.locked,
        isModTask: oResult.data.isModTask,
        loaded: true,
        country: oResult.data.country,
      })
    })
  }
  handleChange = (event, tab) => {
    this.setState({ tab });
  };
  render() {
    const { tab, taskName, locked, isModTask, country, loaded } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    return (
      <Grid container style={{ margin: '0 15px 15px 15px', paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} />
        </Grid>
        <Grid item xs={8}>
        <Grid item xs={12}>
          <PageTitle content="Reporting" />
          <Paper elevation={4}>
            <Tabs value={tab} onChange={this.handleChange}
          variant="scrollable"
          scrollButtons="auto">
              <Tab label="Candidates" />
              <Tab label="Dot Plots" />
              {/*<Tab label="Analytics" disabled />
              <Tab label="Summary" disabled />
              <Tab label="Scale" disabled />*/}
              <Tab label="Levels" />
              <Tab label="Reports" />
              <Tab label="Monitoring" disabled={!isModTask} />
            </Tabs>
            <Paper elevation={4} style={{ padding: "20px" }}>
              {tab === 0 && (
                <div>
                  <CandidatesReporting country={country} />
                </div>
              )}
              {tab ===1 && <div>
                <DotPlots />
              </div>}
              {/*tab === 1 && <div>
                  <Analytics onClick={(e, category) => { self.handleCategorySelect(e, category) } } />
                </div>*/}
              {/*tab === 2 && <div>
                  <Summary category={category} />
                </div>*/}
              {/*tab === 3 && <div>
                  <Scale category={category} disabled />
                </div>*/}
              {tab === 2 && (
                <div>
                  <TaskLevels locked={locked} />
                </div>
              )}
              {tab === 3 && <div>
                  <ReportingResources />
                </div>}
                {tab === 4 && <div>
                  <Monitoring />
                </div>}
            </Paper>
          </Paper>
        </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Reporting);
