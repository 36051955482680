import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Paper, Hidden
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import auth from "./../Auth.js";
import PageTitle from "./../components/pageTitle.js";
import APWMob from './apwMob'

const _ = require("lodash");

const oGallery = [
  { subject: 'Y2', name: '1', pages: 2, percentile: 99.8, level: 'GDS', writingAge: 0, scaledScore: 598.9 },
  { subject: 'Y2', name: '2', pages: 2, percentile: 95.4, level: 'GDS', writingAge: 0, scaledScore: 546.8 },
  { subject: 'Y2', name: '3', pages: 1, percentile: 90.8, level: 'GDS', writingAge: 0, scaledScore: 535.9 },
  { subject: 'Y2', name: '4', pages: 2, percentile: 88.1, level: 'GDS', writingAge: 0, scaledScore: 530.4 },
  { subject: 'Y2', name: '5', pages: 2, percentile: 83.0, level: 'EXS', writingAge: 0, scaledScore: 521.4 },
  { subject: 'Y2', name: '6', pages: 1, percentile: 74.9, level: 'EXS', writingAge: 0, scaledScore: 509.4 },
  { subject: 'Y2', name: '7', pages: 1, percentile: 63.0, level: 'EXS', writingAge: 0, scaledScore: 497.4 },
  { subject: 'Y2', name: '8', pages: 1, percentile: 50.2, level: 'EXS', writingAge: 0, scaledScore: 483.7 },
  { subject: 'Y2', name: '9', pages: 1, percentile: 43.8, level: 'EXS', writingAge: 0, scaledScore: 474.8 },
  { subject: 'Y2', name: '10', pages: 1, percentile: 37.3, level: 'EXS', writingAge: 0, scaledScore: 466.5 },
  { subject: 'Y2', name: '11', pages: 1, percentile: 27.0, level: 'WTS', writingAge: 0, scaledScore: 454 },
  { subject: 'Y2', name: '12', pages: 1, percentile: 17.4, level: 'WTS', writingAge: 0, scaledScore: 435.4 },
  { subject: 'Y2', name: '13', pages: 1, percentile: 9.4, level: 'WTS', writingAge: 0, scaledScore: 410.6 },
  { subject: 'Y2', name: '14', pages: 1, percentile: 3.4, level: 'WTS', writingAge: 0, scaledScore: 381.5 },
  { subject: 'Y2', name: '15', pages: 1, percentile: 0.6, level: 'WTS', writingAge: 0, scaledScore: 353.3 },
  { subject: 'Y6', name: '1', pages: 2, percentile: 99.9, level: 'GDS', writingAge: 0, scaledScore: 637.9 },
  { subject: 'Y6', name: '2', pages: 2, percentile: 95.8, level: 'GDS', writingAge: 0, scaledScore: 595.4 },
  { subject: 'Y6', name: '3', pages: 2, percentile: 92.7, level: 'GDS', writingAge: 0, scaledScore: 588 },
  { subject: 'Y6', name: '4', pages: 1, percentile: 87.8, level: 'GDS', writingAge: 0, scaledScore: 580.8 },
  { subject: 'Y6', name: '5', pages: 2, percentile: 82.0, level: 'EXS', writingAge: 0, scaledScore: 574.0 },
  { subject: 'Y6', name: '6', pages: 1, percentile: 75.5, level: 'EXS', writingAge: 0, scaledScore: 567.1 },
  { subject: 'Y6', name: '7', pages: 1, percentile: 65.7, level: 'EXS', writingAge: 0, scaledScore: 560.1 },
  { subject: 'Y6', name: '8', pages: 1, percentile: 57.6, level: 'EXS', writingAge: 0, scaledScore: 553.3 },
  { subject: 'Y6', name: '9', pages: 1, percentile: 47.2, level: 'EXS', writingAge: 0, scaledScore: 546.1 },
  { subject: 'Y6', name: '10', pages: 1, percentile: 39.0, level: 'EXS', writingAge: 0, scaledScore: 539.3 },
  { subject: 'Y6', name: '11', pages: 1, percentile: 31.8, level: 'EXS', writingAge: 0, scaledScore: 532.1 },
  { subject: 'Y6', name: '12', pages: 2, percentile: 23.0, level: 'WTS', writingAge: 0, scaledScore: 525.0 },
  { subject: 'Y6', name: '13', pages: 2, percentile: 17.2, level: 'WTS', writingAge: 0, scaledScore: 518.1 },
  { subject: 'Y6', name: '14', pages: 1, percentile: 11.8, level: 'WTS', writingAge: 0, scaledScore: 511.4 },
  { subject: 'Y6', name: '15', pages: 1, percentile: 3.6, level: 'WTS', writingAge: 0, scaledScore: 475.4 },
]

class APW2 extends Component {
  constructor(props) {
    super(props);
    window.Intercom("update", {
      app_id: "zodtosc9"
    });
  }
  state = {
    btnGallery: "Y2",
    img: oGallery[0],
  };
  render() {
    const { classes } = this.props;
    const { btnGallery, img } = this.state;
    const oImages = _.filter(oGallery, { subject: btnGallery });
    const selImage = _.find(oGallery, { _id: img }) || {};
    console.log('state', this.state)
    return (
      <div>
        <APWMob />
        <Hidden xsDown>
      <Grid container style={{ flexGrow: 1, paddingTop: "146px" }}>
        <PageTitle
          title="Assessing Primary Writing"
          img="/assets/girl-red-jumper_banner.jpg"
        />
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "669px" }}>
              <Typography
                gutterBottom
                style={{
                  margin: "20px 0 20px 0",
                  fontFamily: "Source Sans Pro",
                  fontSize: "18px",
                  color: "#af1280"
                }}
              >
                Assessing Primary Writing makes it easy for you to standardise your assessment of writing with other schools. Taking part in six assessment windows over the year, one per year group, you will get to judge the writing of your pupils alongside the writing of pupils from all over the country. Judging the work online, your teachers will benefit from seeing their own pupils' work in the context of other pupils' writing. Our Comparative Judgement engine ensures that the process is quick while the results you receive are reliable and nationally standardised with a scaled score, a writing age, and a grade.
              </Typography>
              {/*<Typography
                gutterBottom
                style={{
                  margin: "20px 0 20px 0",
                  fontFamily: "Source Sans Pro",
                  fontSize: "15px",
                  color: "#0"
                }}
              >
                When you judge with other schools you get a good sense of the
                range of work we see nationally. Our video shows you a selection
                of work from the Year 1 project in 2018, which involved 2,286
                teachers from 173 schools judging the writing of 8,668 pupils.
              </Typography>*/}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "669px", margin: "20px 0 20px 0" }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  title={
                    <Typography
                      style={{
                        fontSize: "21px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      Save time and reduce the assessment burden…
                      <br />
                      Comparative Judgement in 4 easy steps!
                    </Typography>
                  }
                />
                <CardContent style={{ position: 'relative', height: '325px' }}>
                  <img
                    src="/assets/step-0.1-icon.svg"
                    alt="Step 1"
                    style={{ position: 'absolute', top: '55px', left: '23px' }}
                  />
                  <div style={{ position: 'absolute', top: '173px', left: '23px', width: '133px' }}>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        1
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;We set the task, so everyone is writing against the same prompt.
                      </span>
                  </div>
                  <img
                    src="/assets/step-1-icon.svg"
                    alt="Step 1"
                    style={{ position: 'absolute', top: '20px', left: '176px' }}
                  />
                  <div style={{ position: 'absolute', top: '173px', left: '176px', width: '152px' }}>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        2
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Upload your pupils’ scripts on our specially barcoded paper
                      </span>
                  </div>
                  <img
                    src="/assets/step-2-icon.svg"
                    alt="Step 1"
                    style={{ position: 'absolute', top: '60px', left: '354px' }}
                  />
                                    <div style={{ position: 'absolute', top: '173px', left: '354px', width: '152px' }}>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        3
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Judge writing, comparing two pieces of writing on screen, side-by-side to determine which is best
                      </span>
                  </div>
                  <img
                    src="/assets/step-3-icon.svg"
                    alt="Step 1"
                    style={{ position: 'absolute', top: '54px', left: '526px' }}
                  />
                                                      <div style={{ position: 'absolute', top: '173px', left: '526px', width: '139px' }}>
                    <span
                        style={{
                          fontFamily: "Source Sans Pro script=all rev=1",
                          fontSize: "34px",
                          color: "#363636"
                        }}
                      >
                        4
                      </span>
                      <span style={{ fontSize: "15px", color: "#363636" }}>
                        &nbsp;Benchmark attainment and progress across your school, and nationally, with the information and statistics in your <a
                          href="/reports/apw-demo-feedback.pdf"
                          target="_blank"
                        >
                          school’s individual report
                        </a>
                      </span>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "676px", margin: "20px 0 20px 0" }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#af1280" }}
                  title={
                    <Typography
                      className={classes.topBannerHeading}
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontSize: "21px",
                        color: "#ffffff",
                        textAlign: "center"
                      }}
                    >
                      Here’s a summary of how it works:
                    </Typography>
                  }
                />
                <CardContent style={{ fontFamily: "Source Sans Pro" }}>
                  <ul>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      There are six assessment windows per year, one for every year group.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      Each window involves a specific piece of writing where we provide the stimulus.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      In each window you judge the work of your pupils as well as the work of pupils across the country.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You are never asked to compare your pupils with pupils from another school. Similarly, teachers from other schools will never be asked to compare your pupils with their pupils. Rather, every 5th judgement is a moderation judgement where you compare the work of pupils from other schools.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      Our judgement process ensures every script is externally standardised and all judging quality monitored in real time.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You can carry out your own internal judging sessions using our anchor scripts to monitor pupil progress.
                    </li>
                    <li style={{ margin: "20px 0 20px 0" }}>
                      You receive marks, writing ages and levels for every pupil as well as reports aggregated by school, gender and pupil premium status.
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "669px" }}>
              <iframe
                title="vidAPW"
                src="https://player.vimeo.com/video/256771651"
                width="669"
                height="372"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              />
            </Grid>
          </Grid>
        </Grid>*/}

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "669px", margin: "20px 0 20px 0" }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  title={
                    <Typography className={classes.topBannerHeading}>
                      What are the benefits of No More Marking: Assessing
                      Primary Writing?
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="space-around">
                    <Grid item style={{ width: "300px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Workload
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          A group of teachers can assess the writing of an
                          entire year group within an hour.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Accuracy
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Comparative judgement achieves greater levels of reliability for more open tasks than traditional approaches to marking.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Validity
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Holistic judgements of writing help you escape the
                          tick list approach to assessing writing.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Progress measures
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          You can measure the progress of cohorts accurately
                          across the entire Primary phase of education.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Comparisons between years
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          You can compare the attainment and progress of
                          different year groups, allowing you to see when your
                          pupils make most progress and the impact of any
                          interventions.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    <Grid item style={{ width: "300px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Nationally-standardised results
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Results are based on the performance of thousands of
                          pupils and the judgements of hundreds of teachers from
                          across the country.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Robust data controls
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          Every judge’s performance is recorded, and decisions
                          made by inaccurate judges are removed from the
                          results.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Exemplar scripts
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          After each assessment, we provide you with exemplar
                          scripts from pupils across the country, which can be
                          used in lessons or for CPD.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Built-in moderation
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          As you are judging, you are also silently and
                          anonymously moderating the judgements of other
                          teachers.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Typography
                            style={{
                              fontFamily: "Source Sans Pro",
                              fontSize: "19px",
                              color: "#af1280"
                            }}
                          >
                            Assess every script
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "15px",
                            color: "#0"
                          }}
                        >
                          With traditional moderation, most scripts are never
                          seen by anyone other than the class teacher. With us,
                          every script is seen twenty times.
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item style={{ width: "676px" }}>
              <Card>
                <CardHeader
                  style={{ backgroundColor: "#3f1f71" }}
                  title={
                    <Typography className={classes.topBannerHeading}>
                      Gallery
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      style={{ width: "100%", padding: "0px 10px 0px 0px" }}
                    >
                      {[2, 6].map(yg => (
                        <Button
                          key={`yg_${yg}`}
                          style={
                            btnGallery === `Y${yg}`
                              ? {
                                  fontSize: "17px",
                                  width: "100px",
                                  justifyContent: "left",
                                  color: "#af1280",
                                  textTransform: 'inherit',
                                }
                              : {
                                  fontSize: "17px",
                                  width: "100px",
                                  justifyContent: "left",
                                  textTransform: 'inherit',
                                }
                          }
                          onClick={e => {
                            console.log("YG", yg);
                            this.setState({ btnGallery: `Y${yg}` });
                          }}
                        >
                          Year {yg}
                        </Button>
                      ))}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container>
                    <Grid item xs={3}>
                      <div style={{ height: "677px", overflowY: "scroll" }}>
                        {oImages.map((oImage, i) => (
                          <Paper
                            key={`paper_${i}`}
                            elevation={4}
                            style={{ margin: "0 20px 10px 10px" }}
                            onClick={e => {
                              console.log("*", oImage);
                              this.setState({ img: oImage });
                            }}
                          >
                            <img
                              // src={`/gallery/${oImage.filename}`}
                              src={`https://nmm-v2.s3-eu-west-1.amazonaws.com/gallery/APW/${oImage.subject}/${oImage.name}.1.jpg`}
                              alt={oImage.filename}
                              style={
                                selImage.filename === oImage.filename
                                  ? {
                                      width: "130px",
                                      height: "183px",
                                      backgroundColor: "#af1280",
                                      opacity: 0.34
                                    }
                                  : { width: "130px", height: "183px" }
                              }
                            />
                          </Paper>
                        ))}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                    <div style={{ height: "677px", overflowY: "scroll" }}>
                      {!img && (
                        
                        <Typography style={{ color: "#af1280" }}>
                          Select an Image
                        </Typography>
                      )}
                      {img && (
                        <Paper elevation={4}>
                          <Typography
                            style={{
                              padding: "10px 10px 10px 10px",
                              color: "#af1280"
                            }}
                          >
                            Percentile: {img.percentile}&nbsp;&nbsp;&nbsp;Level: {img.level}&nbsp;&nbsp;&nbsp;Scaled Score: {img.scaledScore}
                          </Typography>
                          {_.range(1, img.pages + 1).map(page => (
                          <img
                            key={`img_${page}`}
                            // src={`/gallery/${selImage.filename}`}
                            src={`https://nmm-v2.s3-eu-west-1.amazonaws.com/gallery/APW/${img.subject}/${img.name}.${page}.jpg`}
                            alt={selImage.name}
                            style={{ width: "480px", height: "677px" }}
                          />
                          ))}

                        </Paper>
                      )}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Grid container justify="center">
            <Grid
              item
              style={{
                position: "relative",
                width: "1024px",
                height: "165px",
                backgroundImage: "url(/assets/quote-banner-extended.jpg)"
              }}
            >
              <Typography
                style={{
                  position: "absolute",
                  width: "500px",
                  padding: "20px 0 0 90px",
                  fontSize: "23px",
                  color: "#3f1f71",
                  textAlign: "center"
                }}
              >
                
                  Having fully embraced CJ this year, it has been a revelation both in terms of its uncanny accuracy and significant reduction in workload for us all.
                
              </Typography>
              <Typography
                style={{
                  position: "absolute",
                  width: "669px",
                  bottom: "20px",
                  fontSize: "15px",
                  color: "#af1280",
                  textAlign: "center"
                }}
              >
                Themistocles Bakas, Head of Assessment and Technology, Charles Dickens Primary School
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Grid container justify="center" xstyle={{ width: "1180px" }}>
            <Grid
              item
              style={{
                position: "relative",
                backgroundColor: "#efefef",
                width: "1180px",
                height: "169px"
              }}
            >
              <Typography
                style={{
                  position: "absolute",
                  fontFamily: "Source Sans Pro",
                  top: "22px",
                  fontSize: "21px",
                  color: "#af1280",
                  textAlign: "center",
                  width: "1180px"
                }}
              >
                Standardised and nationally benchmarked
                <br />
                writing assessment for Years 1 to 6, just <strong>
                  £595
                </strong>{" "}
                + VAT per annum
              </Typography>
              <Button
                size="small"
                variant="contained"
                style={{
                  position: "absolute",
                  left: "517px",
                  top: "90px",
                  width: "145px",
                  height: "40px",
                  backgroundColor: "#3f1f71",
                  color: "#ffffff",
                  textTransform: "inherit",
                  fontFamily: "Source Sans Pro"
                }}
                onClick={() => {
                  auth.login('subscribe2?product=APW');
                }}
              >
                Sign up
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(APW2);
