import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Grid, AppBar, Typography, Toolbar, Menu, MenuItem, IconButton, Hidden, Divider } from "@material-ui/core";
import { styles } from "./../layout/styles.js";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import auth from "./../Auth.js";

class TopMenuMob extends Component {
    state = {
        anchorEl: null,
    }
  constructor(props) {
    super(props);
    window.Intercom("boot", {
      app_id: "zodtosc9"
    });
  }
  handleProductMenu = event => {
    this.setState({ anchorProduct: event.currentTarget });
  };
  handleProductClose = () => {
    this.setState({ anchorProduct: null });
  };
  render() {
    // const { location, classes, marketingPages } = this.props
    const { anchorEl } = this.state;
    // const openProduct = Boolean(anchorProduct);
    const isAuthenticated = (localStorage.getItem("email") || "") !== "";
    return (
        <Hidden smUp>
                  <Grid container style={{ flexGrow: 1, position: 'fixed', zIndex: 10, width: '100%', backgroundColor: '#ffffff' }}>
        <Grid item xs={12}>
          <Grid container justify="space-evenly" style={{ padding: '20px' }}>
            <Grid item>
              <NavLink to={`/`}><img src="/assets/coloured-logo.svg" alt="No More Marking" /></NavLink>
              </Grid>
              <Grid item>
              <Typography style={{
                  //position: 'absolute',
                  //left: '300px',
                  //top: '30px',
                  fontSize: '15px',
                  textAlign: 'right',
                  color: '#3f1f71',
              }}>
                Comparative Judgement<br />for Schools
              </Typography>
              </Grid>
              </Grid>
              </Grid>

            <AppBar position="relative" style={{ xmarginTop: '90px' }}>
                <Toolbar>
                    <IconButton edge="start"  onClick={(e) => {
                        this.setState({ anchorEl: e.currentTarget })
                    }}><MenuIcon style={{ color: 'white' }} /></IconButton>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => {
                        this.setState({ anchorEl: null })
                    }}>
                        {!isAuthenticated && (<MenuItem onClick={() => { this.setState({ anchorEl: null }); auth.login('tasks'); }}>Login</MenuItem>)}
                        {isAuthenticated && (<MenuItem onClick={() => { auth.logout(); }}>Logout</MenuItem>)}
                        <Divider />
                        <MenuItem onClick={() => { this.setState({ anchorEl: null })}} component={NavLink} to="/products/apw2">Assessing Primary Writing</MenuItem>
                        <MenuItem onClick={() => { this.setState({ anchorEl: null })}} component={NavLink} to="/products/age2">Assessing GCSE English</MenuItem>
                        <MenuItem onClick={() => { this.setState({ anchorEl: null })}} component={NavLink} to="/products/aaw">Assessing America's Writing</MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { window.open(`https://blog.nomoremarking.com`)}}>Blog</MenuItem>
                        <MenuItem onClick={() => { window.open(`https://www.notion.so/nmm/No-More-Marking-Help-Articles-b888a56b5b43481db37cea702df25354`)}}>Support</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            </Grid>
        </Hidden>
    );
  }
}

export default withStyles(styles)(withRouter(TopMenuMob));
