import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import {
    Grid,
    Typography,
    FormControl,
    FormHelperText,
    Tooltip, Fab,
} from "@material-ui/core";
import { Delete, CheckCircle } from "@material-ui/icons";
import request from "superagent";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from './../layout/taskMenu2.js'
import ScansMenu from './../layout/scansMenu.js'
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";

const _ = require("lodash");
const CheckboxTable = checkboxHOC(ReactTable);

class ScansUploads extends Component {
    state = {
        disabled: false,
        processing: [],
        msg: <span />,
        selection: [],
        selectAll: false,
        taskName: '',
        locked: true,
        isModTask: false,
        useCodes: false,
        candsWithScans: 0,
        scansNeedingFix: 0,
        nonDeletableFiles: [],
        pdfFiles: 0,
        codesToFix: 0,
        hasDuplicates: false,
        excludedPages: false,
    }
    componentDidMount() {
        const { match } = this.props;
        const task = match.params.task;
        const promSumm = AxiosAll.post(`/scans/getScanSummary`, { task: task });
        const promDepend = AxiosAll.post(`/scans/getScansDependentTasks`, {
          task: task
        });
        const promCorrectTask = AxiosAll.post(`/scans/uploadedToCorrectTask`, {
          task: task
        });
        const promTask = AxiosAll.post(`/tasks/getTask`, { task: task })
        const promNonDel = AxiosAll.post(`/scans/getNonDeletableFiles`, { task: task })
        const promMenu = AxiosAll.post(`/scans/getScanMenu`, { task: task })
        Promise.all([promSumm, promDepend, promCorrectTask, promTask, promNonDel, promMenu]).then(oResult => {
          // console.log("getScanSummary", oResult);
          this.setState({
            invalid: oResult[0].data.invalid,
            scanFileDetails: oResult[0].data.scanFileDetails,
            valid: oResult[0].data.valid,
            loaded: true,
            useCodes: oResult[0].data.task.useCodes,
            owners: oResult[0].data.task.owners,
            arrFiles: _.map(oResult[0].data.scanFileDetails, "scanFilename"),
            //nonDeletableFiles: _.map(oResult[0].data.scanFileDetails, "scanFilename"), // oResult[1].data,
            correctTask: oResult[2].data,
            taskName: oResult[3].data.name,
            locked: oResult[3].data.locked,
            isModTask: oResult[3].data.isModTask,
            // candsWithScans: oResult[3].data.candsWithScans,
            scansNeedingFix: (oResult[3].data.scansNeedingFix || 0),
            nonDeletableFiles: oResult[4].data,
            pdfFiles: oResult[5].data.pdfFiles,
            codesToFix: oResult[5].data.codesToFix,
            candsWithScans: oResult[5].data.candsWithScans,
            hasDuplicates: oResult[5].data.hasDuplicates,
            excludedPages: oResult[5].data.excludedPages,
          });
        });
    }
    toggleSelection = (key, shift, row) => {
      key = key.replace('select-', '')
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(key);
      if (keyIndex >= 0) {
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        selection.push(key);
      }
      this.setState({ selection });
    };
    toggleAll = () => {
      const selectAll = this.state.selectAll ? false : true;
      const selection = [];
      if (selectAll) {
        const wrappedInstance = this.checkboxTable.getWrappedInstance();
        const currentRecords = wrappedInstance.getResolvedState().sortedData;
        currentRecords.forEach(item => {
          selection.push(item._original._id);
        });
      }
      this.setState({ selectAll, selection });
    };
    isSelected = key => {
      return this.state.selection.includes(key);
    };
    handleFileChange = (event, useCodes) => {
        const self = this;
        const { match } = this.props;
        const task = match.params.task;
        const { owners, arrFiles } = this.state;
        self.setState({
          msg: <Typography variant="body2" color="secondary">Uploading, please do not navigate away from this page while uploading ...</Typography>, // <Alert type="info" text="Uploading..." />,
          processing: [],
          processed: [],
          disabled: true,
          activeStep: 1,
          files: event.target.files.length
        });
        _.each(event.target.files, function(file) {
          // console.log("file", file, arrFiles);
          if (_.indexOf(arrFiles, file.name) > -1) {
            self.setState({
              msg: <Typography variant="body2" color="secondary">{`File ${file.name}`}</Typography>, // <Alert type="error" text={`File ${file.name} already exists`} />,
              disabled: false
            });
          } else if (file.size > 20 * 1024 * 1024) {
            self.setState({
              msg: <Typography variant="body2" color="secondary">{`File ${file.name} is too large (max. file size is 20MBs)`}</Typography>,
              disabled: false
            });
          } else if (!/.pdf$/i.test(file.name)) {
            self.setState({
                msg: <Typography variant="body2" color="secondary">{`File ${file.name} is not a pdf file`}</Typography>,
                disabled: false
            });
          } else {
            const url = `https://api.cloudinary.com/v1_1/no-more-marking-ltd/image/upload`;
            request
              .post(url)
              .field("upload_preset", "tqgw3b0n")
              .field("file", file)
              .field("multiple", true)
              .end((error, oResponse) => {
                // console.log("Done Uploading", error, oResponse, file.name);
                if (error) {
                  // console.log("Error: ", file.name, error);
                  self.setState(prevState => ({
                    msg: <Typography variant="body2" color="secondary">{`${error} for file ${file.name}`}</Typography>,
                    processing: _.difference(prevState.processing, [file.name])
                  }));
                } else {
                  self.setState(prevState => ({
                    processing: [...prevState.processing, file.name],
                    disabled: false
                  }));
                  const {
                    pages,
                    public_id,
                    format,
                    bytes,
                    secure_url
                  } = oResponse.body;
                  console.log("Done2", pages, public_id, format, bytes, secure_url);
                  if (pages > 300) {
                    self.setState({
                      msg: <Typography variant="body2" color="secondary">Maximum 300 pages per PDF</Typography>, // <Alert type="error" text={`Maximum 300 pages per PDF`} />,
                      processing: [],
                      disabled: false,
                    })
                  }
                  else if (useCodes) {
                    processPDFFile(task, public_id, pages, file.name, owners)
                    .then(oResult => {
                      AxiosAll.post(`/scans/uploadedToCorrectTask`, {
                        task: task
                      }).then(oCorrectTask => {
                        console.log('uploadedToCorrectTask', oCorrectTask)
                        AxiosAll.post(`/scans/updateMisreadCodes`, {
                          task: task
                        }).then(oResult => {
                          AxiosAll.post(`/scans/getScanSummary`, {
                            task: task
                          }).then(oResult => {
                            const arrSomeProcessing = _.difference(
                              self.state.processing,
                              [file.name]
                            );
                            if (
                              _.difference(self.state.processing, [file.name])
                                .length === 0
                            ) {
                              AxiosAll.post(`/scans/updateMisreadCodes`, {
                                task: task
                              }).then(oResult => {
                                  AxiosAll.post(`/scans/getScanMenu`, { task: task })
                                  .then(oRes => {
                                    self.setState(prevState => ({
                                      activeStep: 2,
                                      msg: arrSomeProcessing.length ? (
                                        prevState.msg
                                      ) : (
                                        <Typography color="secondary">File(s) uploaded</Typography>
                                      ),
                                      pdfFiles: oRes.data.pdfFiles,
                                      codesToFix: oRes.data.codesToFix,
                                      candsWithScans: oRes.data.candsWithScans,
                                        hasDuplicates: oRes.data.hasDuplicates,
                                    }));
                                  })
                              });
                            }
                            self.setState(prevState => ({
                              invalid: oResult.data.invalid,
                              scanFileDetails: oResult.data.scanFileDetails,
                              valid: oResult.data.valid,
                              processing: _.difference(prevState.processing, [
                                file.name
                              ]),
                              processed: [...prevState.processed, file.name],
                              arrFiles: _.map(
                                oResult.data.scanFileDetails,
                                "scanFilename"
                              ),
                              correctTask: oCorrectTask.data
                            }));
                          });
                        });
                      });
                    })
                  } else {
                    // No Codes
                    AxiosAll.post(`/scans/scansProcessPDFNoCodes2`, {
                      task: task,
                      public_id: public_id,
                      pages: pages,
                      scanFile: file.name,
                      owners: owners
                    }).then(oResult => {
                      AxiosAll.post(`/scans/getScanSummary`, { task: task }).then(
                        oResult => {
                          const arrSomeProcessing = _.difference(
                            self.state.processing,
                            [file.name]
                          );
    
                          if (
                            _.difference(self.state.processing, [file.name])
                              .length === 0
                          ) {
                            console.log('Uploads complete?', task)
                            AxiosAll.post(`/scans/attachScansToCandidates`, { task: task })
                            .then(oResult => {
                              self.setState(prevState => ({
                                activeStep: 0,
                                msg: arrSomeProcessing.length ? (
                                  prevState.msg
                                ) : (
                                  <Typography color="secondary">File(s) uploaded</Typography>
                                )
                              }));
                              if (arrSomeProcessing.length === 0) {
                                document.getElementById('idUploadPDFs').value = ''
                              }
                            })
                          }
                          self.setState(prevState => ({
                            invalid: oResult.data.invalid,
                            scanFileDetails: oResult.data.scanFileDetails,
                            valid: oResult.data.valid,
                            processing: _.difference(prevState.processing, [
                              file.name
                            ]),
                            processed: [...prevState.processed, file.name]
                          }));
                        }
                      );
                    });
                  }
                }
              });
          }
        });
      };
    render() {
      const self = this;
      const { match } = this.props;
      const task = match.params.task;
        const { loaded, processing, disabled, msg, selectAll, selection, scanFileDetails, nonDeletableFiles, codesToFix, candsWithScans, arrFiles, hasDuplicates, excludedPages } = this.state;
        const { taskName, locked, isModTask, useCodes, correctTask } = this.state; 
        const { toggleSelection, toggleAll, isSelected } = this;
        // const { nonDeletableFiles } = this.props;
        if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
        const scanFileDetails2 = _.map(scanFileDetails, function(o, i) {
          return {
            _id: o._id,
            scanFilename: o.scanFilename,
            createdAt: o.createdAt,
            pages: o.pages,
            processed: _.compact(_.map(o.pages, "full_url")).length,
            status: o.status,
            publicId: o.publicId
          };
        });
        const columns = [
          {
            Header: "Filename",
            accessor: "scanFilename",
            Cell: row => (
              <div style={{ flex: 0, textAlign: "center" }}>
                {/*<a
                  href={`https://res.cloudinary.com/no-more-marking-ltd/image/upload/${
                    row.original.publicId
                  }.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.original.scanFilename}
                </a>*/}
                <a
                  href={`https://res.cloudinary.com/no-more-marking-ltd/images/${row.original.publicId}/${row.original.scanFilename}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.original.scanFilename}
                </a>
              </div>
            )
          },
          {
            Header: "Pages",
            accessor: "pages",
            maxWidth: 100,
            Cell: row => (
              <div style={{ flex: 0, textAlign: "center" }}>
                {row.original.pages.length}
              </div>
            )
          },
          {
            Header: (
              <span>
                Pages
                <br />
                Processed
              </span>
            ),
            maxWidth: 100,
            accessor: "processed",
            Cell: row => (
              <div style={{ flex: 0, textAlign: "center" }}>
                {row.original.processed}
              </div>
            )
          },
          {
            Header: "Uploaded At",
            accessor: "createdAt",
            Cell: row => (
              <div style={{ flex: 0, textAlign: "center" }}>
                {row.original.createdAt}
              </div>
            )
          }
        ];
        const checkboxProps = {
          selectAll,
          isSelected,
          toggleSelection,
          toggleAll,
          selectType: "checkbox"
        };
        console.log('state', this.state, scanFileDetails2)
        return (
          <Grid container style={{ margin: '0 15px 15px 15px', paddingRight: '35px', paddingTop: '146px' }}>
          <Grid item xs={4}>
              <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} useCodes={useCodes} />
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <ScansMenu tab={0} useCodes={useCodes} pdfFiles={arrFiles.length} codesToFix={codesToFix} candsWithScans={candsWithScans} isModTask={isModTask} hasDuplicates={hasDuplicates} excludedPages={excludedPages} />
                <Typography color="secondary">Pre-generated assessment sheets: {useCodes && <CheckCircle color="primary" style={{ fontSize: '20px' }} />}</Typography>
                <Typography gutterBottom>
                  If you are using our coded sheets, you can scan in class batches to a single pdf.&nbsp; There is a limit of 20MBs maximum file size & 300 maximum pages for each pdf.&nbsp; The system will read the code on the pages and assign them to the correct pupil.
                </Typography>
                <Typography color="secondary">General pdfs: {!useCodes && <CheckCircle color="primary" style={{ fontSize: '20px' }} />}</Typography>
                <Typography gutterBottom>
                  If you aren't using our coded sheets you need to scan every piece of work to a separate pdf and upload each separately.&nbsp; The system will use the file name of the pdf as the id for the piece of work.
                </Typography>
                <Typography gutterBottom>To change the pdf format click on Settings and select the General tab and update the Use Codes option</Typography>

                <Typography gutterBottom>Please ensure no pupil names,
                  addresses or other confidential information is visible
                  above the <strong>"Don’t write below here"</strong> text.
                </Typography>
                  <Typography gutterBottom>
                    <strong>Uploaded pdf files and optimised images for reviewing and judging will be automatically removed after one year.</strong>
                </Typography>
                {correctTask.length > 0 && <Typography color="secondary" gutterBottom>
                  Possible upload to wrong task?&nbsp; Was the PDF originally generated for {_.map(correctTask, 'taskName').join(', ')}
                </Typography>}
                <FormControl style={{ marginTop: '20px' }}>
                    <input
                        id="idUploadPDFs"
                        type="file"
                        disabled={processing.length || disabled || isModTask}
                        onChange={event =>
                        this.handleFileChange(event, useCodes)
                        }
                        multiple
                    />
                    <FormHelperText>{msg}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                <Tooltip title="Delete PDF File(s)">
          <Fab
            size="small"
            style={{ margin: "15px 0 5px 0" }}
            color="secondary"
            disabled={disabled || selection.length === 0 || isModTask}
            onClick={e => {
              const oFiltered = _(scanFileDetails2)
                .keyBy("_id")
                .at(selection)
                .value();
              const arrAffectedDependents = _.intersection(
                _.map(oFiltered, "scanFilename"),
                nonDeletableFiles
              );
              console.log('del', oFiltered, _.map(oFiltered, "scanFilename"), nonDeletableFiles)
              if (arrAffectedDependents.length) {
                window.alert(
                  `Cannot delete file(s): ${arrAffectedDependents.join(
                    ", "
                  )}.  File(s) are used in Anchor sets or moderation tasks`
                );
                return;
              }
              const r = window.confirm(`Delete PDF File(s)?\nAre you sure?`);
              if (!r) return;
              self.setState({
                disabled: true,
                processing: [],
                processed: [],
                msg: <Typography color="secondary">Deleting...</Typography> , // <Alert type="info" text="Deleting..." />
              });
              console.log('selection', _.map(oFiltered, "scanFilename"))
              AxiosAll.post(`/scans/deletePDFFiles`, {
                task: task,
                scanFiles: _.map(oFiltered, "scanFilename"),
              })
              .then(obj => {

              /*const arrPromises = _.map(selection, function(_id) {
                const oRow = _.find(scanFileDetails2, { _id: _id });
                // console.log("oRow", oRow);
                return new Promise(function(resolve, reject) {
                  AxiosAll.post(`/scans/deletePDFFile`, {
                    task: task,
                    scanFile: oRow.scanFilename
                  }).then(oTask => {
                    AxiosAll.post(`/scans/uploadedToCorrectTask`, {
                      task: task
                    }).then(oRes2 => {
                      console.log('uploadedToCorrectTask', oRes2.data)
                      resolve(oTask.data);
                    });
                  });
                });
              });
              Promise.all(arrPromises).then(obj => {*/
                AxiosAll.post(`/tasks/getTask`, { task: task })
                .then(oTask => {
                  console.log('update task obj', oTask.data)
                  console.log('>to remove', scanFileDetails, selection, _.filter(scanFileDetails, function(o) { return (!_.includes(selection, o._id)) }))
                  self.setState({
                    scanFileDetails: _.filter(scanFileDetails, function(o) { console.log('=', selection, o._id, !_.includes(selection, o._id)); return (!_.includes(selection, o._id)) }),
                    selectAll: false,
                    selection: [],
                    disabled: false,
                    msg: <Typography color="secondary">File(s) removed</Typography> , // <Alert type="success" text="File(s) removed" />
                    candsWithScans: oTask.data.candsWithScans,
                    scansNeedingFix: (oTask.data.scansNeedingFix || 0),
                  });
                })
              });
            }}
          >
            <Delete />
          </Fab>
        </Tooltip>
                <CheckboxTable defaultPageSize={50}
                  data={scanFileDetails2}
                  columns={columns}
                  minRows={0}
                  showPagination={true}
                  ref={r => (this.checkboxTable = r)}
                  style={{ marginBottom: "20px", width: "100%" }}
                  {...checkboxProps}
                />
            </Grid>
            </Grid>
            </Grid>
        )
    }
}

export default withRouter(ScansUploads);

async function processPDFFile(task, public_id, pages, scanFile, owners) {
    console.log('processPDF2', scanFile, public_id, pages, owners)
    let oPageData = _.map(_.range(1, pages + 1), function(page) {
        return {
            found: false,
            page: page,
            heightPer: 1,
            text: '',
            taskName: '',
            candidateDetails: '',
        }
    })
    let angle = 0;
    let yHeight = 0.8;
    const arrProm0 = _.map(_.range(1, pages + 1), async function(page) {
        let options = { page: page, quality: 'auto', format: 'jpg', transformation: [{ effect: 'blackwhite' }, { effect: 'blur:50' }, { effect: 'contrast:50' }, { angle: angle, x: 0, y: yHeight, height: 0.999, crop: 'crop' }] };
        return new Promise(function(resolve1, reject1) {
            setTimeout(function() {
              AxiosAll.post(`/scans/pdfProcess`, { publicId: public_id, options: { type: 'upload', timeout: 600000, eager: options } })
              .then(oResult => {
                let oBasicPage = oResult.data
              //cloudinaryExplicit(public_id, { type: 'upload', timeout: 600000, eager: options })
              //.then(oBasicPage => {
                  AxiosAll.post(`/aws/detectText`, { uri: oBasicPage.eager[0].secure_url, yHeight: 0.999})
                  .then(oResult => {
                    const oDetects = oResult.data
                  //awsFunctions.detectText(oBasicPage.eager[0].secure_url, 0.999)
                  //.then(oDetects => {
                      const candDetails = oDetects.candDetails;
                      const code = oDetects.code;
                      const top = oDetects.top;
                      if (code !== '') {
                          // oPageData[page - 1].bytes = oBasicPage.bytes
                          oPageData[page - 1].found = true;
                          oPageData[page - 1].angle = angle;
                          oPageData[page - 1].heightPer = yHeight + (1 - yHeight) * top;
                          oPageData[page - 1].text = code;
                          oPageData[page - 1].candDetails = candDetails;
                          const options = [
                              { page: page, density: 100, quality: 'auto', fetch_format: 'auto', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                              { page: page, crop: 'crop', y: 0, height: oPageData[page - 1].heightPer, aspect_ratio: 1.414, density: 100, quality: 'auto', fetch_format: 'auto', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                              { page: page, crop: 'fill', width: 200, height: 282, density: 50, fetch_format: 'auto', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                          ];
                          AxiosAll.post(`/scans/pdfProcess`, { publicId: public_id, options: { type: 'upload', timeout: 600000, eager: options } })
                          .then(oResult => {
                            let oImages = oResult.data
                          //cloudinaryExplicit(public_id, { type: 'upload', timeout: 600000, eager: options })
                          //.then(oImages => {
                              oPageData[page - 1].size = oImages.eager[0].bytes
                              oPageData[page - 1].full_url = oImages.eager[0].secure_url;
                              oPageData[page - 1].url = oImages.eager[1].secure_url;
                              oPageData[page - 1].thumbnail0 = oImages.eager[2].secure_url;
                              resolve1(oPageData[page - 1]);
                          })
                          .catch(oErr => {
                              console.log('gen urls', oErr, page)
                              reject1(oErr)
                          })
                      }
                      else {
                          const options = [
                              { page: page, density: 100, quality: 'auto', fetch_format: 'auto', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                              { page: page, crop: 'crop', y: 0, height: yHeight, aspect_ratio: 1.414, density: 100, quality: 'auto', fetch_format: 'auto', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                              { page: page, crop: 'fill', width: 200, height: 282, density: 50, fetch_format: 'auto', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                          ];
                          AxiosAll.post(`/scans/pdfProcess`, { publicId: public_id, options: { type: 'upload', timeout: 600000, eager: options } })
                          .then(oResult => {
                            let oImages = oResult.data
                            console.log('oImages', oImages)
                          // cloudinaryExplicit(public_id, { type: 'upload', timeout: 600000, eager: options })
                          // .then(oImages => {
                              oPageData[page - 1].size = oImages.eager[0].bytes
                              oPageData[page - 1].full_url = oImages.eager[0].secure_url;
                              oPageData[page - 1].url = oImages.eager[1].secure_url;
                              oPageData[page - 1].ocr_url0 = oBasicPage.eager[0].secure_url;
                              oPageData[page - 1].thumbnail0 = oImages.eager[2].secure_url;
                              resolve1(oPageData[page - 1]);
                          })
                          .catch(oErr => {
                              console.log('eager error', oErr, options)
                              reject1(oErr)
                          })
                      }
                  })
                  .catch(oErr => {
                      console.log('detectText Err', oErr)
                  })
              })
              .catch(oErr => {
                  console.log('cloud error', oErr)
              })
            }, page * 50)
        })
    })
    await Promise.all(arrProm0)
  
    // console.log('TRY A 180', oPageData)
    /*angle = 180;
    const oRemainingPageData = _.filter(oPageData, function(oPage) { return (!oPage.found) });
    const promPages180 = _.map(oRemainingPageData, async function(oPage, i) {
        const page = oPage.page;
        let options = { page: page, quality: 'auto', format: 'jpg', transformation: [{ effect: 'blackwhite' }, { effect: 'blur:50' }, { effect: 'contrast:50' }, { angle: angle, x: 0, y: yHeight, height: 0.999, crop: 'crop' }] };
        return new Promise(function(resolve1, reject1) {
            setTimeout(function() {
            AxiosAll.post(`/scans/pdfProcess`, { publicId: public_id, options: { type: 'upload', timeout: 600000, eager: options } })
            .then(oResult => {
              let oBasicPage = oResult.data
            //cloudinaryExplicit(public_id, { type: 'upload', timeout: 600000, eager: options })
            //.then(oBasicPage => {
                AxiosAll.post(`/aws/detectText`, { uri: oBasicPage.eager[0].secure_url, yHeight: 0.999})
                .then(oResult => {
                  const oDetects = oResult.data
                //awsFunctions.detectText(oBasicPage.eager[0].secure_url, 0.999)
                //.then(oDetects => {
                    const candDetails = oDetects.candDetails;
                    const code = oDetects.code;
                    const top = oDetects.top;
                    if (code !== '') {
                        // oPageData[page - 1].bytes = oBasicPage.bytes
                        oPageData[page - 1].found = true;
                        oPageData[page - 1].angle = angle;
                        oPageData[page - 1].heightPer = yHeight + (1 - yHeight) * top;
                        oPageData[page - 1].text = code;
                        oPageData[oPage.page - 1].candDetails = candDetails;
                        const options = [
                            { page: page, density: 150, quality: 'auto', format: 'jpg', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                            { page: page, crop: 'crop', y: 0, height: oPageData[oPage.page - 1].heightPer, aspect_ratio: 1.414, density: 150, quality: 'auto', format: 'jpg', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                            { page: page, crop: 'fill', width: 200, height: 282, density: 50, format: 'jpg', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                        ];
                        AxiosAll.post(`/scans/pdfProcess`, { publicId: public_id, options: { type: 'upload', timeout: 600000, eager: options } })
                        .then(oResult => {
                          let oImages = oResult.data
                        //cloudinaryExplicit(public_id, { type: 'upload', timeout: 600000, eager: options })
                        //.then(oImages => {
                            oPageData[page - 1].size = oImages.eager[0].bytes
                            oPageData[page - 1].full_url = oImages.eager[0].secure_url;
                            oPageData[page - 1].url = oImages.eager[1].secure_url;
                            oPageData[page - 1].ocr_url180 = oBasicPage.eager[0].secure_url;
                            oPageData[page - 1].thumbnail180 = oImages.eager[2].secure_url;
                            resolve1(oPageData[page - 1]);
                        })
                        .catch(oErr => {
                            console.log('gen urls', oErr, oPage)
                        })
                    }
                    else {
                        // console.log('Not Found', oPage, `|${code}|`)
                        // oPageData[page - 1].bytes = oBasicPage.bytes
                        oPageData[page - 1].angle = 0;
                        oPageData[page - 1].heightPer = yHeight;
                        oPageData[page - 1].text = '';
                        oPageData[page - 1].candDetails = candDetails;
                        const options = [
                            { page: page, crop: 'crop', y: 0, height: oPageData[page - 1].heightPer, aspect_ratio: 1.414, density: 150, quality: 'auto', format: 'jpg', transformation: [{ effect: 'contrast:80' }, { angle: 0 }] },
                            { page: page, crop: 'crop', y: 0, height: 0, aspect_ratio: 1.414, density: 150, quality: 'auto', format: 'jpg', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                            { page: page, crop: 'fill', width: 200, height: 282, density: 50, format: 'jpg', transformation: [{ effect: 'contrast:80' }, { angle: angle }] },
                        ];
                        AxiosAll.post(`/scans/pdfProcess`, { publicId: public_id, options: { type: 'upload', timeout: 600000, eager: options } })
                        .then(oResult => {
                          let oImages = oResult.data
                        //cloudinaryExplicit(public_id, { type: 'upload', timeout: 600000, eager: options })
                        //.then(oImages => {
                            oPageData[page - 1].size = oImages.eager[0].bytes
                            oPageData[page - 1].url = oImages.eager[0].secure_url;
                            oPageData[page - 1].ocr_url180 = oBasicPage.eager[0].secure_url;
                            oPageData[page - 1].thumbnail180 = oImages.eager[2].secure_url;
                            resolve1(oPageData[page - 1]);
                        })
                        .catch(oErr => {
                          console.log('gen urls', oErr, oPage)
                        })
                    }
                })
                .catch(oErr => {
                    console.log('detect error', oErr)
                    reject1(oErr)
                })
            })
            .catch(oErr => {
                console.log('cloudinary explicit Error', oErr)
                reject1(oErr)
            })
            }, page * 50)
        })
    });
  
    await Promise.all(promPages180)*/
    
    const oScansToAdd = _.map(oPageData, function(oRow, i) {
        return {
            task: [task],
            owners: owners,
            scanFile: scanFile,
            pdfpage: i + 1,
            qrcode: (oRow.text === '' ? '' : oRow.text.substring(0, 6) ),
            qrpage: (oRow.text === '' ? 0 : parseInt(oRow.text[6], 10)),
            width: 0,
            height: 0,
            publicId: public_id,
            angle: oRow.angle,
            heightPer: oRow.heightPer,
            url: oRow.url,
            full_url: oRow.full_url,
            ocr_url0: oRow.ocr_url0,
            ocr_url180: oRow.ocr_url180,
            thumbnail0: oRow.thumbnail0,
            thumbnail180: oRow.thumbnail180,
            candDetails: oRow.candDetails,
            hide: false,
            noCode: false,
            size: oRow.size
        }
    })
    console.log('SCANSTOADD', oScansToAdd, task)
    await AxiosAll.post(`/scans/insertScans`, { scans: oScansToAdd, task: task })
    return oPageData
  }