import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button, Divider, Fab, Tooltip } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { Check, Close, Add, Edit, Delete, StarHalf } from "@material-ui/icons";
// import "typeface-roboto";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import AxiosAll from "./../axiosAll";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";

const CheckboxTable = checkboxHOC(ReactTable);
const _ = require("lodash");

class TaskLevels extends Component {
  state = {
    selection: [],
    selectAll: false,
    msg: <span />,
    openDialog: false,
    levelName: "",
    levelValue: 0,
    boundary: 0,
    levels: [],
    loaded: false
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/levels/getTaskLevels`, { task: task }).then(oResult => {
      this.setState({
        levels: oResult.data,
        loaded: true
      });
    });
  }
  toggleSelection = (key, shift, row) => {
    key = key.replace('select-', '')
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const {
      selection,
      selectAll,
      msg,
      openDialog,
      levelName,
      levelValue,
      boundary,
      levels,
      loaded
    } = this.state;
    const { locked, match } = this.props
    const task = match.params.task;
    if (!loaded) return <span />;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };
    const columns = [
      {
        Header: "Level Name",
        accessor: "name",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.name}
          </div>
        )
      },
      {
        Header: "Level Value",
        accessor: "value",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.value}
          </div>
        )
      },
      {
        Header: "Target",
        accessor: "include",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.include ? <Check /> : <Close />}
          </div>
        )
      },
      {
        Header: "Scaled Score Boundary",
        accessor: "cutOff",
        Cell: row => (
          <div style={{ flex: 0, textAlign: "center" }}>
            {row.original.cutOff}
          </div>
        )
      }
    ];
    return (
      <Grid item xs={12}>
        {msg}
        <Tooltip title="Add Level">
        <Fab
          size="small"
          color="secondary"
          style={{ margin: "0 5px 5px 0" }}
          disabled={locked}
          onClick={e => {
            AxiosAll.post(`/levels/createLevel`, { task: task }).then(
              oResult => {
                // console.log("createlevel", oResult.data);
                AxiosAll.post(`/levels/getTaskLevels`, { task: task }).then(
                  oResult => {
                    this.setState({
                      levels: oResult.data,
                      loaded: true
                    });
                  }
                );
              }
            );
          }}
        >
          <Add />
        </Fab>
        </Tooltip>
        <Tooltip title="Edit Level">
        <Fab
          size="small"
          color="secondary"
          disabled={selection.length !== 1 || locked}
          style={{ margin: "0 5px 5px 0" }}
          onClick={e => {
            const oFiltered = _(levels)
              .keyBy("_id")
              .at(selection)
              .value();
            this.setState({
              levelName: oFiltered[0].name,
              levelValue: oFiltered[0].value,
              boundary: oFiltered[0].cutOff,
              openDialog: true
            });
          }}
        >
          <Edit />
        </Fab>
        </Tooltip>
        <Tooltip title="Delete Level">
        <Fab
          size="small"
          color="secondary"
          disabled={selection.length === 0 || locked}
          style={{ margin: "0 5px 5px 0" }}
          onClick={e => {
            const c = window.confirm(`Delete Level?\nAre you sure?`);
            if (!c) return;
            AxiosAll.post(`/levels/deleteLevels`, {
              task: task,
              levels: selection
            }).then(oResult => {
              AxiosAll.post(`/levels/getTaskLevels`, { task: task }).then(
                oResult => {
                  this.setState({
                    levels: oResult.data,
                    selection: [],
                    selectAll: false,
                    loaded: true
                  });
                }
              );
            });
          }}
        >
          <Delete />
        </Fab>
        </Tooltip>
        <Tooltip title="Toggle Level Target">
        <Fab
          size="small"
          color="secondary"
          disabled={selection.length === 0 || locked}
          style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
          onClick={e => {
            AxiosAll.post(`/levels/toggleLevelsTarget`, {
              task: task,
              levels: selection
            }).then(oResult => {
              AxiosAll.post(`/levels/getTaskLevels`, { task: task }).then(
                oResult => {
                  this.setState({
                    levels: oResult.data,
                    loaded: true
                  });
                }
              );
            });
          }}
        >
          <StarHalf />
        </Fab>
        </Tooltip>
        <CheckboxTable defaultPageSize={50}
          data={levels}
          columns={columns}
          minRows={0}
          showPagination={false}
          ref={r => (this.checkboxTable = r)}
          xstyle={{ margin: "0 0 20px 0" }}
          {...checkboxProps}
        />
        <Dialog open={openDialog}>
          <Formik
            initialValues={{
              levelName: levelName,
              levelValue: levelValue,
              levelBoundary: boundary
            }}
            onSubmit={(values, { setSubmitting }) => {
              // console.log("submitted", values);
              AxiosAll.post(`/levels/editLevel`, {
                level: selection[0],
                name: values.levelName,
                value: parseInt(values.levelValue, 10),
                cutOff: parseInt(values.levelBoundary, 10)
              }).then(oResult => {
                AxiosAll.post(`/levels/getTaskLevels`, { task: task }).then(
                  oResult => {
                    this.setState({
                      levels: oResult.data,
                      openDialog: false
                    });
                  }
                );
              });
            }}
            validationSchema={Yup.object().shape({
              levelName: Yup.string().required("Required"),
              levelValue: Yup.number()
                .min(-100, "Min value -100")
                .max(100, "Max value 100")
                .required("Required"),
              levelBoundary: Yup.number()
                .min(-10000, "Min value -10000")
                .max(10000, "Max value 10000")
                .required("Required")
            })}
          >
            {({ isSubmitting, values, errors, dirty }) => (
              <Form>
                <DialogTitle>Edit Level</DialogTitle>
                <Divider />
                <DialogContent>
                  <FormikTextField
                    name="levelName"
                    label="Level Name"
                    fullWidth
                    style={{ marginBottom: "15px" }}
                  />
                  <FormikTextField
                    type="number"
                    name="levelValue"
                    label="Level Value"
                    fullWidth
                    style={{ marginBottom: "15px" }}
                  />
                  <FormikTextField
                    type="number"
                    name="levelBoundary"
                    label="Scaled Score Boundary"
                    fullWidth
                  />
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ textTransform: "inherit" }}
                  >
                    Update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </Grid>
    );
  }
}

export default withRouter(TaskLevels);
