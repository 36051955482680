import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { CardMedia, DialogActions, Typography } from "@material-ui/core";
import {
  Grid,
  Button,
  Fab,
  Tooltip,
  Avatar,
  Chip,
  Paper,
  Divider,
  LinearProgress
} from "@material-ui/core";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Check, Close, ArrowDropDownCircle, Link } from "@material-ui/icons";
import { Help } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
// import "typeface-roboto";
import {
  FlexibleWidthXYPlot,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries
} from "react-vis";
import PageTitle from "./../components/pageTitle.js";
// import Alert from "./../components/alert.js";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import AxiosAll from "./../axiosAll";
import TaskMenu2 from './../layout/taskMenu2.js'

const CheckboxTable = checkboxHOC(ReactTable);
const FileSaver = require("file-saver");
const _ = require("lodash");
const moment = require("moment");

class Judges extends Component {
  state = {
    selection: [],
    selectAll: false,
    defaultJudgements: -1,
    judge: null,
    ready: false,
    judges: [],
    oTask: {},
    loaded: false,
    dialogOpen: false,
    recommendedQuota: -1,
    msg: <span />,
    locked: false,
    isModTask: false,
    taskName: '',
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;

    const promJudges = AxiosAll.post(`/judges/getJudgesForTask`, {
      task: task
    });
    const promTask = AxiosAll.post(`/tasks/getTask`, {
      task: task,
      extras: ["UpdateStats", "withModTask"]
    });
    Promise.all([promJudges, promTask]).then(oResult => {
      // console.log("getJudgesForTask", oResult);
      const oTask = oResult[1].data
      const expectedCandidates = ((oTask.expectedCandidates || oTask.candsWithScans) || 1)
      const expectedJudges = Math.max((oTask.expectedJudges || oTask._judges), 1)
      const judgementsPerCandidate = oTask.judgementsPerCandidate
      const perMod = (oTask.isModTask ? (oTask.modFreq === 0 ? 0 : Math.round(1 / oTask.modFreq * 100)) : 0)
      const totalLocalJudgements = expectedCandidates * judgementsPerCandidate
      const totalModJudgements = expectedCandidates * judgementsPerCandidate + Math.round(expectedCandidates * judgementsPerCandidate * (perMod / 100))
      const defaultJudgements = Math.round(expectedCandidates * judgementsPerCandidate * (perMod / 100) / expectedJudges + (expectedCandidates * judgementsPerCandidate))
      const recommendedQuota = Math.round(totalModJudgements / expectedJudges)
      this.setState({
        judges: oResult[0].data,
        oTask: oTask,
        loaded: true,
        expectedCandidates: expectedCandidates,
        expectedJudges: expectedJudges,
        judgementsPerCandidate: judgementsPerCandidate,
        recommendedQuota: recommendedQuota,
        totalLocalJudgements: totalLocalJudgements,
        totalModJudgements: defaultJudgements,
        perMod: perMod,
        defaultJudgements: oTask.defaultJudgements,
        fixedTask: oTask.fixedTask,
        locked: oTask.locked,
        isModTask: oTask.isModTask,
        taskName: oTask.name,
      });
    });
  }
  handleJudgesDownload = (e, oData) => {
    const { match } = this.props;
    const task = match.params.task;
    const arrCols = [
      "email",
      "link",
      "trueScore",
      "localComparisons",
      "modComparisons",
      "quota",
      "lastJudged",
      "_medianTimeTaken",
      "_perLeft",
      "createdAt"
    ];
    const arrTitles = [
      "Email",
      "Link",
      "Infit",
      "Local Comparisons",
      "Mod Comparisons",
      "Quota",
      "Last Judged",
      "Median Time (secs)",
      "% Left Click",
      "Date Created"
    ];
    let oResults = JSON.parse(JSON.stringify(oData));
    oResults = _.map(oResults, function(oRow) {
      _.each(arrCols, function(attr) {
        if (!_.has(oRow, attr)) oRow[attr] = "*";
      })
      oRow._medianTimeTaken = (oRow._medianTimeTaken / 1000).toFixed(1)
      oRow.link = `${window.location.origin}/judging/${task}/${oRow._id}`;
      return oRow;
    });
    let oResult = _.map(oResults, function(oRow) {
      return _.values(_.pick(oRow, arrCols)).join(",");
    });
    oResult.unshift(arrTitles.join(","));
    const blob = new Blob([oResult.join("\n")], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(blob, "judges.csv");
  };
  handleDecisionsDownload = e => {
    const { match } = this.props;
    const task = match.params.task;
    const arrCols = [
      "judgeName",
      "chosen",
      "notChosen",
      "timeTaken",
      "createdAt"
    ];
    AxiosAll.post(`/judges/getTaskDecisions`, { task: task }).then(oRes => {
      /*const oDecs = _.map(oRes.data, function(oRow) {
        oRow.timeTaken = (oRow.timeTaken / 1000).toFixed(1)
        return oRow;
      })*/
      let oResult = _.map(oRes.data, function(oRow) {
        return _.values(_.pick(oRow, arrCols)).join(",");
      });
      oResult.unshift(arrCols.join(","));
      // console.log('oResult', oResult)
      const blob = new Blob([oResult.join("\n")], {
        type: "text/plain;charset=utf-8"
      });
      FileSaver.saveAs(blob, "task_decisions.csv");
    });
  };
  handleJudgeCommentsDownload = e => {
    const { match } = this.props;
    const task = match.params.task;
    const arrCols = [
      "judgeName",
      "chosen",
      "notChosen",
      "comments",
      "createdAt"
    ];
    AxiosAll.post(`/judges/getJudgeComments`, { task: task }).then(oRes => {
      let oResult = _.map(oRes.data, function(oRow) {
        return _.values(_.pick(oRow, arrCols)).join(",");
      });
      oResult.unshift(arrCols.join(","));
      const blob = new Blob([oResult.join("\n")], {
        type: "text/plain;charset=utf-8"
      });
      FileSaver.saveAs(blob, "judge_comments.csv");
    });
  };
  toggleSelection = (key, shift, row) => {
    key = key.replace('select-', '')
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };
  renderNonEditable = cellInfo => {
    const { match } = this.props;
    const { oTask } = this.state;
    const task = match.params.task;
    const modCode = oTask.modCode;
    switch (cellInfo.column.id) {
      case "email":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            <a href={`mailto:${cellInfo.original.email}`}>
              {cellInfo.original.email}
            </a>
          </div>
        );
      case "link":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            <NavLink
              to={`/judging/${task}/${cellInfo.original._id}/${modCode}`}
              target="_blank"
            >{`/judging/${task}/${cellInfo.original._id}/${modCode}`}</NavLink>
          </div>
        );
      case "trueScore":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            {isNaN(cellInfo.original.trueScore) || cellInfo.original.trueScore === null
              ? "-"
              : cellInfo.original.trueScore.toFixed(1)}
          </div>
        );
      case "quota":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            {cellInfo.original.quota}
          </div>
        );
      case "_medianTimeTaken":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>{`${(
            (cellInfo.original._medianTimeTaken || 0) / 1000
          ).toFixed(1)}s`}</div>
        );
      case "_perLeft":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>{`${(
            cellInfo.original._perLeft || 0
          ).toFixed(1)}%`}</div>
        );
      case "exclude":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            {cellInfo.original.exclude ? <Check /> : <Close />}
          </div>
        );
      case "timeChart":
        const data = _.map(_.range(0, 7), function(i) {
          if (i < 6) {
            return {
              x: i,
              y: _.filter(cellInfo.original.timesTaken, function(o) {
                return o >= i * 10 * 1000 && o < (i + 1) * 10 * 1000;
              }).length
            };
          } else {
            return {
              x: i,
              y: _.filter(cellInfo.original.timesTaken, function(o) {
                return o >= i * 10 * 1000;
              }).length
            };
          }
        });
        return (
          <FlexibleWidthXYPlot
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            height={50}
          >
            <VerticalBarSeries data={data} />
          </FlexibleWidthXYPlot>
        );
      case "createdAt":
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            {moment(cellInfo.original.createdAt, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD"
            )}
          </div>
        );
      default:
        return (
          <div style={{ flex: 0, textAlign: "center" }}>
            {cellInfo.original[cellInfo.column.id]}
          </div>
        );
    }
  };
  render() {
    const self = this;
    const { judges, oTask, loaded, dialogOpen, defaultJudgements, expectedCandidates, expectedJudges, judgementsPerCandidate, recommendedQuota, totalLocalJudgements, totalModJudgements, perMod, msg, fixedTask, locked, isModTask, taskName } = this.state;
    if (!loaded) return <Grid style={{ paddingTop: '146px' }} container><Typography gutterBottom>Loading...</Typography></Grid>;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selection, selectAll } = this.state;
    const { match } = this.props;
    const task = match.params.task;

    const columns = [
      {
        Header: "Email",
        accessor: "email",
        Cell: self.renderNonEditable,
        filterMethod: (filter, row) =>
          row[filter.id].match(RegExp(filter.value, "i"))
      },
      {
        Header: (
          <span>
            Link{" "}
            <Tooltip title={<span>A judge's unique judging link</span>}>
              <Help style={{ fontSize: "inherit" }} />
            </Tooltip>
          </span>
        ),
        accessor: "link",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: (
          <span>
            Infit{" "}
            <Tooltip
              title={
                <span>
                  Consistency
                  <br />0 - 1 : Consistent
                  <br />
                  1.0 - 1.3 : Some inconsistency
                  <br />
                  1.3+ : Inconsistent
                </span>
              }
            >
              <Help style={{ fontSize: "inherit" }} />
            </Tooltip>
          </span>
        ),
        maxWidth: 70,
        accessor: "trueScore",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: <span>Local Comparisons</span>,
        maxWidth: 70,
        accessor: "localComparisons",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: <span>Mod Comparisons</span>,
        maxWidth: 70,
        accessor: "modComparisons",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: (
          <span>
            Quota{" "}
            <Tooltip title={<span>Total number of judgemets assigned</span>}>
              <Help style={{ fontSize: "inherit" }} />
            </Tooltip>
          </span>
        ),
        maxWidth: 80,
        accessor: "quota",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: (
          <span>
            Median
            <br />
            Time{" "}
            <Tooltip title={<span>Median time for each judgement</span>}>
              <Help style={{ fontSize: "inherit" }} />
            </Tooltip>
          </span>
        ),
        maxWidth: 70,
        accessor: "_medianTimeTaken",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: (
          <span>
            % Left
            <br />
            Click{" "}
            <Tooltip
              title={
                <span>Percentage of times a judge chose the left script</span>
              }
            >
              <Help style={{ fontSize: "inherit" }} />
            </Tooltip>
          </span>
        ),
        maxWidth: 70,
        accessor: "_perLeft",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: "Exclude",
        maxWidth: 70,
        accessor: "exclude",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: (
          <span>
            Date
            <br />
            Created
          </span>
        ),
        maxWidth: 100,
        accessor: "createdAt",
        Cell: self.renderNonEditable,
        filterable: false
      },
      {
        Header: (
          <span>
            Time Chart{" "}
            <Tooltip title={<span>Histogran of Judging Times</span>}>
              <Help style={{ fontSize: "inherit" }} />
            </Tooltip>
          </span>
        ),
        accessor: "timeChart",
        Cell: self.renderNonEditable,
        filterable: false
      }
    ];
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox"
    };
    const signuplink = `${window.location.origin}/judging/signup/${task}`;

    // const judgements = oTask._judgements;
    const oReliabilities = _.map(oTask.reliabilities || [], function(oRow) {
      return { x: oRow.judgements, y: oRow.value };
    });
    oReliabilities.unshift({ x: 0, y: 0 });
    const oSelJudge = (selection.length === 1 ? _.find(judges, { _id: selection[0] }) : {});
    // const judgements = _.sum(_.map(_.filter(judges, { exclude: false }), function(o) { return (o.localComparisons + o.modComparisons) }))
    const localJudgements = _.sum(_.map(_.filter(judges, { exclude: false, localTask: task }), 'localComparisons'))
    const modJudgements = _.sum(_.map(_.filter(judges, { exclude: false }), function(o) { return o.modComparisons }))
    // const quota = _.sum(_.map(judges, 'quota'))
    let progress = Math.round((localJudgements + modJudgements) / totalModJudgements * 100)
    progress = (isNaN(progress) ? 0 : progress)
    console.log('state', this.state)
    return (
      <Grid container style={{ margin: '0 15px 15px 15px', paddingTop: '146px' }}>
        <Grid item xs={4}>
          <TaskMenu2 taskName={taskName} locked={locked} isModTask={isModTask} />
        </Grid>
        <Grid item xs={8}>
        <Grid item xs={12}>
          <PageTitle content="Judges" />
          <Grid container>
            {fixedTask && <Grid xs={6}>
              <Paper elevation={4} style={{ marginRight: '20px', padding: '20px' }}>
                <Typography color="secondary">Fixed Task</Typography>
              </Paper>
            </Grid>}
            {!fixedTask && <Grid item xs={6}>
              <Paper elevation={4} style={{ marginRight: '20px', padding: '20px' }}>
                <Typography style={{ marginBottom: '25px' }}>
                  To get your judging link, enter the number of judges you expect to take part and click on the Adjust button
                </Typography>
              {msg}
              <Formik validateOnBlur
                initialValues={{
                  expectedCandidates: expectedCandidates,
                  judgementsPerCandidate: judgementsPerCandidate,
                  expectedJudges: expectedJudges,
                  perMod: perMod,
                  recommendedQuota: recommendedQuota,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const modFreq = 1 / (values.perMod / 100)
                  // // console.log("submitted", values, modFreq);
                  const defaultJudgements = Math.round(values.expectedCandidates * values.judgementsPerCandidate * (values.perMod / 100) / values.expectedJudges + values.expectedCandidates * values.judgementsPerCandidate)
                  AxiosAll.post(`/tasks/updateTask`, {
                    task: task,
                    expectedCandidates: values.expectedCandidates,
                    expectedJudges: values.expectedJudges,
                    judgementsPerCandidate: values.judgementsPerCandidate,
                    defaultJudgements: recommendedQuota,
                    modFreq: modFreq,
                    recommendedQuota: recommendedQuota,
                  })
                  .then(oResTask => {
                    AxiosAll.post(`/judges/getJudgesForTask`, { task: task }) // judges: oResult[0].data,
                    .then(oJudges => {
                      if (judgementsPerCandidate !== values.judgementsPerCandidate)
                      {
                        // console.log('judgementsPerCandidate', judgementsPerCandidate, values.judgementsPerCandidate)
                        // Changing the judgements per candidate
                        /*AxiosAll.post(`/judging/resetTaskJudging`, {
                          task: task
                        }).then(oResult => {*/
                          /*AxiosAll.post(
                            `/judging/initialiseTaskJudging`,
                            { task: task }
                          ).then(oResult2 => {*/
                            //// console.log('oResult2', oResult2.data)
                            
                            self.setState({
                              expectedCandidates: values.expectedCandidates,
                              expectedJudges: values.expectedJudges,
                              judgementsPerCandidate: values.judgementsPerCandidate,
                              defaultJudgements: defaultJudgements,
                              msg: <Typography color="secondary" gutterBottom>Default judgements per judge set to {recommendedQuota}</Typography>,
                              judges: oJudges.data,
                            });
                            setSubmitting(false)
                            //self.props.history.push(`/judges/${task}`);
                          //})
                        //})
                      }
                      else {
                            this.setState({
                              defaultJudgements: defaultJudgements,
                              msg: <Typography color="secondary" gutterBottom>Default judgements per judge set to {recommendedQuota}</Typography>,
                              judges: oJudges.data,
                            })
                            setSubmitting(false)
                      }
                    })


                  });
                }}
                validationSchema={Yup.object().shape({
                  expectedCandidates: Yup.number()
                    .min(((expectedCandidates || oTask.candsWithScans) || 1), "Cannot be lower than existing number of candidates")
                    .max(10000, "Max value 10000")
                    .required("Required"),
                  expectedJudges: Yup.number()
                    .min(judges.length, "Cannot be lower than existing number of judges")
                    .max(1000, "Max value 1000")
                    .required("Required"),
                  judgementsPerCandidate: Yup.number()
                    .min(5, "Min value 5")
                    .max(100, "Max value 100")
                    .required("Required"),
                })}
              >
              {({ isSubmitting, values, errors, dirty }) => (
                <Form>
                  <Grid container style={{ marginBottom: '20px' }}>
                    <Grid item xs={12}>
                      <Grid container justify="space-evenly">
                        <FormikTextField type="number" variant="outlined" style={{ width: '100px' }}
                          name="expectedCandidates"
                          label="Expected Candidates" onBlur={e => {
                            const totalLocalJudgements = e.target.value * document.getElementsByName('judgementsPerCandidate')[0].value
                            const totalModJudgements = Math.round(totalLocalJudgements * (perMod / 100) + totalLocalJudgements)
                            const recommendedQuota = Math.round(totalModJudgements / expectedJudges)
                            this.setState({ totalLocalJudgements: totalLocalJudgements, totalModJudgements: totalModJudgements, recommendedQuota: recommendedQuota })
                          }}
                        />
                        <Typography variant="h6" style={{ marginTop: '10px' }}>x</Typography>
                        <FormikTextField type="number" name="judgementsPerCandidate" label="Candidate Judgements" variant="outlined" onBlur={e => {
                          const totalLocalJudgements = e.target.value * document.getElementsByName('expectedCandidates')[0].value
                          const totalModJudgements = Math.round(totalLocalJudgements * (perMod / 100) + totalLocalJudgements)
                          const recommendedQuota = Math.round(totalModJudgements / expectedJudges)
                          this.setState({ totalLocalJudgements: totalLocalJudgements, totalModJudgements: totalModJudgements, recommendedQuota: recommendedQuota })
                        }} style={{ width: '100px' }} />
                        <Typography variant="h6" style={{ marginTop: '10px' }}>=</Typography>
                        <Typography variant="subtitle2" style={{ marginTop: '12px' }}>{totalLocalJudgements} Total Judgements</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                      <Grid container justify="space-evenly">
                        <Typography variant="h6" style={{ marginTop: '10px' }}>+</Typography>
                        <FormikTextField type="number" name="perMod" label="% Moderation" variant="outlined" style={{ width: '100px' }} disabled={!oTask.isModTask} onBlur={e => {
                          const totalModJudgements = Math.round(totalLocalJudgements * (e.target.value / 100) * totalLocalJudgements)
                          const recommendedQuota = Math.round(totalModJudgements / expectedJudges)
                          this.setState({ totalModJudgements: totalModJudgements, recommendedQuota: recommendedQuota })
                        }} />
                        <Typography variant="h6" style={{ marginTop: '12px' }}>=</Typography>
                        <Typography variant="subtitle2" style={{ marginTop: '12px' }}>{totalModJudgements} Total Allocation</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                      <Grid container justify="space-evenly">
                        <Typography variant="h6" style={{ marginTop: '10px' }}>&divide;</Typography>
                        <FormikTextField type="number" name="expectedJudges" label="Expected Judges" variant="outlined" style={{ width: '100px' }} onBlur={e => {
                          const recommendedQuota = Math.round(totalModJudgements / e.target.value)
                          this.setState({ expectedJudges: values.expectedJudges, recommendedQuota: recommendedQuota })
                        }} />
                        <Typography variant="h6" style={{ marginTop: '10px' }}>=</Typography>
                        <Typography variant="subtitle2" style={{ marginTop: '12px' }}>{recommendedQuota} Judgements per Judge</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button variant="contained" type="submit" color="primary" style={{ textTransform: 'inherit' }} disabled={isSubmitting}>Adjust</Button>
                </Form>)}
                </Formik>
              </Paper>
            </Grid>}
            <Grid item xs={6}>
              <Paper elevation={4}>
                <Table padding="dense">
                  <TableBody>
                    <TableRow>
                      <TableCell align="right" padding="dense">
                        { isModTask ? 'Mod Candidates with Scans' : 'Local Candidates with Scans' }
                        <br />
                        Judges
                      </TableCell>
                      <TableCell align="left" padding="dense">
                        {oTask.candsWithScans}
                        <br />
                        {oTask._judges}
                      </TableCell>
                      <TableCell
                        rowSpan={4}
                        padding="dense"
                        style={{ width: "50%" }}
                      >
                        {oTask._judgements > 0 && (
                          <CardMedia>
                            <FlexibleWidthXYPlot height={250}>
                              <HorizontalGridLines />
                              <LineSeries data={oReliabilities} />
                              <YAxis />
                            </FlexibleWidthXYPlot>
                          </CardMedia>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" padding="dense">
                        {isModTask ? 'Moderated judgements' : 'Local judgements'}
                        <br />
                        Quota
                        <br />
                        Remaining
                      </TableCell>
                      <TableCell align="left" padding="dense">
                        {isModTask ? modJudgements : localJudgements}
                        <br />
                        {totalModJudgements} 
                        <br />
                        {Math.max(totalModJudgements - (localJudgements + modJudgements), 0)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" padding="dense">
                        Reliability
                      </TableCell>
                      <TableCell align="left" padding="dense">
                        {isNaN(oTask.reliability) || !oTask.reliability
                          ? "-"
                          : oTask.reliability.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" padding="dense">
                          Task Updated<br />
                          Last Judged
                      </TableCell>
                      <TableCell align="left" padding="dense">
                          {moment(oTask.lastRefreshed, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY') || '-'}<br />
                          {moment(oTask.lastJudged, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY') || '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="h6">Judging Progress</Typography>
                        <LinearProgress color={progress > 75 ? 'primary' : (progress < 25 ? 'secondary' : '')} variant="determinate" value={progress} />
                        <Typography>You have completed {progress}% of your judging</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          {(defaultJudgements > -1 || fixedTask) && <Grid
            container
            justify="space-between"
            alignItems="stretch"
            style={{ marginTop: "40px" }}
          >
            <div>
              <Tooltip title="Delete Judge(s)">
                <Fab
                  size="small"
                  style={{ margin: "0 5px 5px 0" }}
                  color="secondary"
                  disabled={selection.length === 0 || isModTask}
                  onClick={e => {
                    const r = window.confirm(
                      `Delete Judge(s)?\nAll Decisions and comments for Judge will also be deleted and cannot be undone\nAre you sure?`
                    );
                    if (!r) return;
                    AxiosAll.post(`/judges/deleteJudges`, {
                      task: task,
                      judges: selection
                    }).then(oResult => {
                      const promTask = AxiosAll.post(`/tasks/getTask`, {
                        task: task,
                        extras: ["CandsWithScans", "UpdateStats"]
                      });
                      Promise.all([promTask]).then(oResult => {
                        // console.log("getJudgesForTask", oResult);
                        this.setState((prevState, curProps) => {
                          return {
                            oTask: oResult[0].data,
                            selectAll: false,
                            selection: [],
                            judges: _.filter(prevState.judges, oJudge => {
                              return _.indexOf(selection, oJudge._id) === -1;
                            })
                          }
                        });
                      });
                    });
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Decisions for Judge">
                <Fab
                  size="small"
                  style={{ margin: "0 5px 5px 0" }}
                  color="secondary"
                  disabled={selection.length !== 1}
                  component={NavLink}
                  to={`/judgedecisions/${task}/${selection[0]}`}
                >
                  <MenuIcon />
                </Fab>
              </Tooltip>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={selection.length === 0}
                style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
                onClick={e => {
                  // console.log("toggleExcludeJudges", task);
                  AxiosAll.post(`/judges/toggleExcludeJudges`, {
                    task: task,
                    judges: selection
                  }).then(oResult => {
                    self.setState(prevState => ({
                      judges: _.map(prevState.judges, function(oJudge) {
                        if (_.indexOf(selection, oJudge._id) > -1) {
                          oJudge.exclude = !oJudge.exclude;
                        }
                        return oJudge;
                      })
                    }));
                  });
                }}
              >
                Toggle Exclude
              </Button>
              {
                <Tooltip title="Update Judge Quota">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "inherit", margin: "0 5px 5px 0" }}
                    disabled={selection.length !== 1}
                    onClick={e => {
                      this.setState({ dialogOpen: true });
                    }}
                  >
                    Update Judge Quota
                  </Button>
                </Tooltip>
              }
            </div>

            <div>
              <Tooltip title="Sign up Link">
                <Chip
                  avatar={
                    <Avatar>
                      <Link />
                    </Avatar>
                  }
                  label={
                    <a
                      href={signuplink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {signuplink}
                    </a>
                  }
                />
                </Tooltip>
            </div>

            <div>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={e => {
                  this.handleJudgesDownload(e, judges);
                }}
                style={{
                  float: "right",
                  margin: "0 5px 5px 0",
                  textTransform: "inherit"
                }}
              >
                <ArrowDropDownCircle style={{ fontSize: "20px" }} /> Judges
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={e => {
                  this.handleDecisionsDownload(e);
                }}
                style={{
                  float: "right",
                  margin: "0 5px 5px 0",
                  textTransform: "inherit"
                }}
              >
                <ArrowDropDownCircle style={{ fontSize: "20px" }} /> Decisions
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={e => {
                  this.handleJudgeCommentsDownload(e);
                }}
                style={{
                  float: "right",
                  margin: "0 5px 5px 0",
                  textTransform: "inherit"
                }}
              >
                <ArrowDropDownCircle style={{ fontSize: "20px" }} /> Comments
              </Button>
            </div>
          </Grid>}
          {(defaultJudgements > -1 || fixedTask) && <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <CheckboxTable defaultPageSize={50}
                data={judges}
                columns={columns}
                filterable
                minRows={0}
                ref={r => (this.checkboxTable = r)}
                style={{ marginBottom: "20px" }}
                {...checkboxProps}
              />
            </Grid>
          </Grid>}
        </Grid>
        <Dialog open={dialogOpen} maxWidth="md">
          <Formik
            initialValues={{ quota: oSelJudge.quota }}
            onSubmit={(values, { setSubmitting }) => {
              // console.log("submitted", values);
              AxiosAll.post(`/judges/updateJudge`, {
                judge: oSelJudge._id,
                quota: parseInt(values.quota, 10)
              }).then(oResult => {
                AxiosAll.post(`/judges/getJudgesForTask`, { task: task }).then(
                  oJudges => {
                    this.setState({
                      judges: oJudges.data,
                      dialogOpen: false
                    });
                  }
                );
              });
            }}
            validationSchema={Yup.object().shape({
              quota: Yup.number("Must be an integer")
                .min(1, "Min value 1")
                .max(1000, "Max value 1000")
                .required("Required")
            })}
          >
            {({ isSubmitting, values, errors, dirty }) => (
              <Form>
                <DialogTitle>Judge Quota</DialogTitle>
                <Divider />
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12} style={{ padding: "5px" }}>
                      <FormikTextField
                        type="number"
                        name="quota"
                        label="Judge Quota"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ textTransform: "inherit" }}
                  >
                    Update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Judges);
