import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Avatar,
  Button, Fab,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import Papa from "papaparse";
import {
  Input,
  InputLabel,
  FormHelperText,
  FormControl
} from "@material-ui/core";
import {
  CloudUpload,
  Help,
  ArrowDropDownCircle,
  Menu,
  Cancel
} from "@material-ui/icons";
import { Formik, Form } from "formik";
import { FormikSelectField } from "formik-material-fields";
import * as Yup from "yup";
import Alert from "./../components/alert.js";
import AxiosAll from "./../axiosAll";
const moment = require("moment");

Yup.addMethod(Yup.object, "unique", function(propertyName, message) {
  return this.test("unique", message, function(value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter(v => v !== value)
        .some(v => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`
      });
    }

    return true;
  });
});
Yup.addMethod(Yup.date, "format", function(formats) {
  return this.transform(function(value, originalValue) {
    console.log('yup date', formats, value, originalValue, this.isType(value))
    if (this.isType(value)) return value;

    value = moment(originalValue, formats, true);

    return value.isValid() ? value.format('YYYY-MM-DD') : "Invalid";
  });
});

const _ = require("lodash");

const arrDOBFormats = ["DD/MM/YY", "DD/MM/YYYY", "DD-MM-YYYY", "YYYY-MM-DD"];
let oDOBFormatOptions = arrDOBFormats.map(dobFormat => {
  return {
    value: dobFormat,
    label: dobFormat
  };
});
oDOBFormatOptions.unshift({
  value: "Select",
  label: "Please select..."
});

class UploadCandidatesCSV2 extends Component {
  state = {
    dialogOpen: false,
    msg: <span />,
    candidates: [],
    arrFileCols: [],
    Candidate: "",
    QRCode: "",
    First_Name: "",
    Last_Name: "",
    DOB: "",
    Gender: "",
    Group: "",
    PP: "",
    EAL: "",
    Year_Group: "",
    useCodes: false,
    loaded: false,
    disabled: false,
    dobFormat: '',
    nCandidates: 0,
  };
  componentDidMount() {
    const { match } = this.props;
    const task = match.params.task;
    AxiosAll.post(`/tasks/getTask`, {
      task: task
    }).then(oTask => {
      console.log('componentDidMount', oTask.data)
      this.setState({
        loaded: true,
        useCodes: oTask.data.useCodes,
        country: oTask.data.country,
        nCandidates: oTask.data._candidates,
      });
    });
  }
  handleFileChange = event => {
    const self = this;
    const file = event.target.files[0];
    Papa.parse(file, {
      delimiter: true,
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      encoding: "UTF-8",
      complete: function(oResults, file) {
        console.log("oResults", oResults);
        let arrFileCols = [];
        if (oResults.errors.length) {
          self.setState({
            msg: <Alert type="error" text="Error in file" />
          });
        } else if (oResults.data.length === 0) {
          self.setState({
            msg: <Alert type="error" text="Empty file" />
          });
        } else {
          console.log("FIELDS", oResults.meta.fields, _.keys(oResults.data[0]));
          arrFileCols = _.keys(oResults.data[0]);
          let newState = {
            arrFileCols: arrFileCols,
            dialogOpen: true,
            candidates: oResults.data,
          };

          _.each(
            [
              "Candidate",
              "QRCode",
              "First_Name",
              "Last_Name",
              "DOB",
              "Gender",
              "Group",
              "PP",
              "EAL",
              "ESL",
              "Year_Group",
              "Grade"
            ],
            function(column) {
              if (_.includes(arrFileCols, column)) newState[column] = column;
            }
          );
          console.log("newState", newState);
          if (!_.has(newState, "Year_Group") && newState.Grade !== "") {
            newState.Year_Group = newState.Grade;
          }
          if (!_.has(newState, "EAL") && newState.ESL !== "") {
            newState.EAL = newState.ESL;
          }
          console.log("newState", newState);
          self.setState(newState);
        }
      }
    });
  };
  /*handleChangeCol = (column, e) => {
    this.setState({ [column]: e.target.value });
  };
  handleColNamesSubmit = async () => {
    const self = this;
    const { candidates, country, dobFormat } = this.state;
    const { match } = this.props;
    const task = match.params.task;
    self.setState({ disabled: true });
    console.log(
      "handleColNamesSubmit",
      this.state,
      candidates,
      country,
      dobFormat
    );
    let oCandidates = _.map(candidates, function(oCand) {
      const oNewCand = {};
      _.each(
        [
          "Candidate",
          "QRCode",
          "First_Name",
          "Last_Name",
          "DOB",
          "Gender",
          "Group",
          "PP",
          "Year_Group",
          "Grade",
          "EAL",
          "ESL"
        ],
        function(column) {
          oNewCand[column] = oCand[self.state[column]];
        }
      );
      return oNewCand;
    });
    let row = 1;
    let oCandidates2 = [];
    _.each(oCandidates, function(oCand) {
      row++;
      let flag = "N";
      let dob = (oCand.DOB || "").trim();
      let eal = oCand.EAL || oCand.ESL;
      console.log("oCand", oCand);
      if (_.has(oCand, "PP")) flag = (oCand.PP || "").trim().toUpperCase();
      else if (_.has(oCand, "PPI"))
        flag = (oCand.PPI || "").trim().toUpperCase();
      if (oCand.First_Name !== "" || oCand.Last_Name !== "")
        oCandidates2.push({
          row: row,
          Candidate: (oCand.Candidate || "").trim(),
          firstName: (oCand.First_Name || "").trim(),
          lastName: (oCand.Last_Name || "").trim(),
          dob: moment(dob, dobFormat, true).isValid()
            ? moment(dob, dobFormat).format("YYYY-MM-DD")
            : "",
          gender: (
            oCand.Gender.replace(/^Male$/i, "M").replace(/^Female$/i, "F") || ""
          )
            .trim()
            .toUpperCase(),
          yg: _.has(oCand, "Year_Group")
            ? parseInt(oCand.Year_Group, 10)
            : parseInt(oCand.Grade, 10), // (country === 'GB' ? parseInt(oCand.Year_Group, 10) : parseInt(oCand.Grade, 10)),
          group: (oCand.Group || "").trim(),
          PP: flag.replace(/^Yes$/i, "Y").replace(/^No$/i, "N") === "Y",
          EAL: (eal || "").toUpperCase() === "Y", // (country === 'GB' ? (oCand.EAL || "").toUpperCase() === "Y" : (oCand.ESL || "").toUpperCase() === "Y"),
          qrcode: oCand.QRCode || ""
        });
    });
    console.log("oCandidates2", oCandidates2);
    let schema = Yup.array().of(
      Yup.object()
        .shape({
          gender: Yup.mixed()
            .oneOf(["M", "F", ""], "Must be M/F")
            .required("Gender required"),
          yg: Yup.number()
            .min(0, "Minimum 0")
            .max(13, "Maximum 13")
            .required("Year Group/Grade required"),
          PP: Yup.mixed().oneOf([true, false], "Must be Y/N"),
          EAL: Yup.mixed().oneOf([true, false], "Must be Y/N"),
          qrcode: Yup.string().matches(/^[A-Z0-9]{6}[0-9]$|^$/, 'Must be blank or a valid code'),
          dob: Yup.date()
            .format(dobFormat, `Date of Birth must be in ${dobFormat} format`)
            .required("Required")
        })
        .unique(oCandidates2, o => o.qrcode)
    );
    const validated = await schema.validate(oCandidates2)
    .catch(oErr => {
      console.log('validated error', oErr)
      return oErr
    })
    console.log(
      "is valid",
      schema.isValidSync(oCandidates2),
      validated
    );
    const arrValidationInfo = []; // doValidation(oCandidates2, country);
    if (arrValidationInfo.length) {
      self.setState({
        msg: <Alert text={arrValidationInfo.join(",")} type="error" />,
        dialogOpen: false
      });
    } else {
      // console.log("TO ADD", oCandidates2);
      AxiosAll.post(`/candidates/createCandidates`, {
        task: task,
        candidates: oCandidates2
      }).then(oResult => {
        // console.log("*", oCandidates2, task);
        self.setState({
          msg: (
            <Alert
              type="success"
              text="File Successfully uploaded, click on 'View' to see candidates"
            />
          ),
          dialogOpen: false,
          disabled: false
        });
      });
    }
  };
  getErrorStatus = column => {
    if (_.includes(arrOptional, column)) {
      return false;
    }
    return this.state[column] === "";
  };*/
  render() {
    const self = this;
    console.log("state", self.state);
    const { match } = this.props;
    const task = match.params.task;
    const {
      dialogOpen,
      arrFileCols,
      useCodes,
      loaded,
      candidates,
      Candidate,
      First_Name,
      Gender,
      Group,
      Year_Group,
      QRCode,
      Last_Name,
      DOB,
      PP,
      EAL, nCandidates
    } = this.state;
    if (!loaded) return <span />;
    let oSelectOptions = _.map(arrFileCols, col => {
      return {
        label: col,
        value: col
      };
    });
    oSelectOptions.unshift({
      label: "None",
      value: "None"
    });
    console.log("arrFileCols", arrFileCols, oSelectOptions);
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: "10px" }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <CloudUpload />
                    </Avatar>
                  }
                  title="File Import"
                  subheader="Upload candidate details"
                />
                <Divider />
                <CardContent>
                  {self.state.msg}
                  {!useCodes && (
                    <Typography color="secondary" gutterBottom>
                      Only available for with Codes tasks
                    </Typography>
                  )}
                  {nCandidates > 0 && (<Typography color="secondary" gutterBottom>Candidates have already been uploaded for this task, are you sure you want to upload additional candidates?</Typography>)}
                  <FormControl>
                    <InputLabel htmlFor="idUploadCSV">File</InputLabel>
                    <Input
                      id="idUploadCSV"
                      type="file"
                      disabled={!useCodes}
                      onChange={e => {
                        self.handleFileChange(e);
                      }}
                    />
                    <FormHelperText>Select a CSV file</FormHelperText>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <Help />
                    </Avatar>
                  }
                  title="Help"
                />
                <Divider />
                <CardContent>Files must be in CSV format</CardContent>
                <Divider />
                <CardContent>
                  File must contain all the columns described in the file format
                  below
                </CardContent>
                <Divider />
                <CardContent>
                  The first row must have header information
                </CardContent>
                <Divider />
                <CardContent>
                  PDFs will be generated in the same order that the file is
                  provided
                </CardContent>
                <Divider />
                <Divider />
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/csv_Template.csv"
                    target="_blank"
                    style={{ textTransform: "inherit" }}
                  >
                    <ArrowDropDownCircle /> UK Template File
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://s3-eu-west-1.amazonaws.com/nmm-v2/resources/templates/csv_TemplateUSA.csv"
                    target="_blank"
                    style={{ textTransform: "inherit" }}
                  >
                    <ArrowDropDownCircle /> USA Template File
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "20px" }}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Menu />
                </Avatar>
              }
              title="File Format"
            />
            <Divider />
            <CardContent>
              <Table style={{ width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Information</strong>
                    </TableCell>
                    <TableCell>
                      Candidate
                      <br />
                      Identifier
                    </TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Year Group / Grade</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Pupil Premium</TableCell>
                    <TableCell>
                      English as an Additional Language / English as a Second
                      Language
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Column Title</strong>
                    </TableCell>
                    <TableCell>Candidate</TableCell>
                    <TableCell>First_Name</TableCell>
                    <TableCell>Last_Name</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Year_Group / Grade</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>PP</TableCell>
                    <TableCell>EAL / ESL</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Validation Information</strong>
                    </TableCell>
                    <TableCell>Up to 50 characters (Optional)</TableCell>
                    <TableCell>Up to 50 characters</TableCell>
                    <TableCell>Up to 50 characters</TableCell>
                    <TableCell>yyyy-mm-dd or dd/mm/yyyy</TableCell>
                    <TableCell>M / F</TableCell>
                    <TableCell>0 - 13</TableCell>
                    <TableCell>Up to 50 characters (Optional)</TableCell>
                    <TableCell>Y / N (Optional)</TableCell>
                    <TableCell>Y / N (Optional)</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Dialog open={dialogOpen}>
          <DialogTitle>
            Upload CSV
            <Fab
              size="small"
              style={{ float: "right" }}
              onClick={e => {
                self.setState({
                  dialogOpen: false
                });
              }}
            >
              <Cancel />
            </Fab>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <p>
              Ensure the corresponding column from the uploaded file is selected
              for each required field
            </p>
            <Table>
              <TableBody>
                <Formik
                  initialValues={{
                    Candidate,
                    First_Name,
                    Gender,
                    Group,
                    Year_Group,
                    QRCode,
                    Last_Name,
                    DOB,
                    PP,
                    EAL,
                    dobFormat: "Select"
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("onSubmit", values);
                    setSubmitting(true)
                    this.setState({
                      Candidate: values.Candidate,
                      First_Name: values.First_Name,
                      Gender: values.Gender,
                      Group: values.Group,
                      Year_Group: values.Year_Group,
                      QRCode: values.QRCode,
                      Last_Name: values.Last_Name,
                      DOB: values.DOB,
                      PP: values.PP,
                      EAL: values.EAL,
                      dobFormat: values.dobFormat
                    });
                    let oCandidates = _.map(candidates, oCand => {
                      const oNewCand = {};
                      _.each(
                        [
                          "Candidate",
                          "QRCode",
                          "First_Name",
                          "Last_Name",
                          "DOB",
                          "Gender",
                          "Group",
                          "PP",
                          "Year_Group",
                          "Grade",
                          "EAL",
                          "ESL"
                        ],
                        column => {
                          if (self.state[column] !== '') oNewCand[column] = oCand[self.state[column]];
                        }
                      );
                      return oNewCand;
                    });
                    let row = 1;
                    let oCandidates2 = [];
                    _.each(oCandidates, function(oCand) {
                      row++;
                      let flag = "N";
                      let dob = (oCand.DOB || "").trim();
                      let eal = oCand.EAL || oCand.ESL;
                      console.log("oCand", oCand);
                      if (_.has(oCand, "PP")) flag = (oCand.PP || "").trim().toUpperCase();
                      else if (_.has(oCand, "PPI"))
                        flag = (oCand.PPI || "").trim().toUpperCase();
                      if (oCand.First_Name !== "" || oCand.Last_Name !== "")
                        oCandidates2.push({
                          row: row,
                          Candidate: (oCand.Candidate || "").trim(),
                          firstName: (oCand.First_Name || "").trim(),
                          lastName: (oCand.Last_Name || "").trim(),
                          dob: moment(dob, values.dobFormat, true).isValid()
                            ? moment(dob, values.dobFormat).format("YYYY-MM-DD")
                            : "",
                          gender: (
                            oCand.Gender.replace(/^Male$/i, "M").replace(/^Female$/i, "F") || ""
                          )
                            .trim()
                            .toUpperCase(),
                          yg: _.has(oCand, "Year_Group")
                            ? parseInt(oCand.Year_Group, 10)
                            : parseInt(oCand.Grade, 10), // (country === 'GB' ? parseInt(oCand.Year_Group, 10) : parseInt(oCand.Grade, 10)),
                          group: (oCand.Group || "").trim(),
                          PP: flag.replace(/^Yes$/i, "Y").replace(/^No$/i, "N") === "Y",
                          EAL: (eal || "").toUpperCase() === "Y", // (country === 'GB' ? (oCand.EAL || "").toUpperCase() === "Y" : (oCand.ESL || "").toUpperCase() === "Y"),
                          qrcode: oCand.QRCode || ""
                        });
                    });
                    console.log('oCandidates2', oCandidates2)
                    let schema = Yup.array().of(
                      Yup.object()
                        .shape({
                          gender: Yup.mixed()
                            .oneOf(["M", "F", ""], "Must be M/F")
                            .required("Gender required"),
                          yg: Yup.number()
                            .min(0, "Minimum 0")
                            .max(13, "Maximum 13")
                            .required("Year Group/Grade required"),
                          PP: Yup.mixed().oneOf([true, false], "Must be Y/N"),
                          EAL: Yup.mixed().oneOf([true, false], "Must be Y/N"),
                          qrcode: Yup.string().matches(/^[A-Z0-9]{6}[0-9]$|^$/, 'Must be blank or a valid code'),
                          dob: Yup.date()
                            .format(values.dobFormat, `Date of Birth must be in ${values.dobFormat} format`)
                            .required("Required")
                        })
                        .unique(oCandidates2, o => o.qrcode)
                    );
                    schema.validate(oCandidates2)
                    .catch(oErr => {
                      console.log('validated error', oErr)
                      console.log('*Error Text*', oErr.params.path.split('.'))
                      const errorLine = parseInt(oErr.params.path.split('.')[0].replace('[', '').replace(']', ''))
                      const errorField = oErr.params.path.split('.')[1]
                      console.log('Error line', )
                      this.setState({
                        // msg: <Alert text={oErr.errors[0]} type="error" />,
                        msg: <Alert text={`Error in field "${errorField}" on line ${errorLine + 1}`} type="error" />,
                        dialogOpen: false,
                      })
                      setSubmitting(false)
                      return { error: oErr.errors[0] }
                    })
                    .then(oRes => {
                      if (_.has(oRes, 'error')) return;
                      console.log('validated then', oRes)
                      AxiosAll.post(`/candidates/createCandidates`, {
                        task: task,
                        candidates: oCandidates2
                      }).then(oResult => {
                        // console.log("*", oCandidates2, task);
                        self.setState({
                          msg: (
                            <Alert
                              type="success"
                              text="File Successfully uploaded, click on 'View' to see candidates"
                            />
                          ),
                          dialogOpen: false,
                          disabled: false
                        });
                      });
                      setSubmitting(false)
                    })
                  }}
                  validationSchema={Yup.object().shape({
                    Candidate: Yup.string().required(),
                    First_Name: Yup.string().required(),
                    Gender: Yup.string().required(),
                    Group: Yup.string().required(),
                    Year_Group: Yup.string().required(),
                    QRCode: Yup.string(),
                    Last_Name: Yup.string().required(),
                    DOB: Yup.string().required(),
                    PP: Yup.string(),
                    EAL: Yup.string(),
                    dobFormat: Yup.mixed().notOneOf(['Select'], 'Please select a date format'),
                  })}
                >
                  {({ isSubmitting, values, errors, dirty }) => (
                    <Grid container>
                      <Form>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ padding: "0 10px 0 10px" }}
                          >
                            <FormikSelectField
                              name="Candidate"
                              label="Candidate"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="First_Name"
                              label="First Name"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="Gender"
                              label="Gender"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="Group"
                              label="Group"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="Year_Group"
                              label="Year Group"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="dobFormat"
                              label="DOB Format"
                              options={oDOBFormatOptions}
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ padding: "0 10px 0 10px" }}
                          >
                            <FormikSelectField
                              name="QRCode"
                              label="QR Code"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="Last_Name"
                              label="Last Name"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="DOB"
                              label="DOB"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="PP"
                              label="PP"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                            <FormikSelectField
                              name="EAL"
                              label="EAL/ESL"
                              options={oSelectOptions}
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                          </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} style={{ margin: '20px 0 20px 10px', textTransform: "inherit" }}>
                          Submit
                        </Button>
                      </Form>
                    </Grid>
                  )}
                </Formik>
              </TableBody>
            </Table>
          </DialogContent>
          {/*<DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={errors > 0 || disabled}
              onClick={e => self.handleColNamesSubmit()}
              style={{ textTransform: "inherit" }}
            >
              Submit
            </Button>
          </DialogActions>*/}
        </Dialog>
      </Grid>
    );
  }
}

export default withRouter(UploadCandidatesCSV2);
